export default {
    data() {
        return {
            modulesInfo: {
                settings: {
                    key: 'settings',
                    icon: 'SettingsIcon',
                    title: 'Общие вопросы'
                },
                flash: {
                    key: 'flash',
                    icon: 'FlashIcon',
                    title: 'Популярные вопросы'
                },
                cashbox: {
                    key: 'cashbox',
                    icon: 'CashboxIcon',
                    title: 'Денежный учет'
                },
                weighing: {
                    key: 'weighing',
                    icon: 'WeighingIcon',
                    title: 'Весовой терминал'
                },
                integrations: {
                    key: 'integrations',
                    icon: 'IntegrationsIcon',
                    title: 'Интеграции'
                },
                relations: {
                    key: 'relations',
                    icon: 'RelationsIcon',
                    title: 'Отношения'
                },
                contract: {
                    key: 'contract',
                    icon: 'ContractIcon',
                    title: 'Контракты'
                },
                storehouse: {
                    key: 'storehouse',
                    icon: 'StorehouseIcon',
                    title: 'Складской учет',
                    sections: {
                        info: {
                            key: 'info',
                            title: 'Остатки'
                        },
                        turnover: {
                            key: 'turnover',
                            title: 'Обороты'
                        },
                        operations: {
                            key: 'operations',
                            title: 'Операции'
                        },
                        units: {
                            key: 'units',
                            title: 'Склады'
                        },
                    }
                },
                production: {
                    key: 'production',
                    icon: 'ProductionIcon',
                    title: 'Производство'
                },
                order: {
                    key: 'order',
                    icon: 'OrderIcon',
                    title: 'Заказы'
                },
                tech: {
                    key: 'tech',
                    icon: 'TechIcon',
                    title: 'Техника',
                    sections: {
                        vehicles: {
                            key: 'vehicles',
                            title: 'Автопарк'
                        },
                        service: {
                            key: 'service',
                            title: 'Сервис'
                        },
                        tires: {
                            key: 'tires',
                            title: 'Шины'
                        },
                        mixers: {
                            key: 'mixers',
                            title: 'Склад запчастей'
                        },
                    }
                },
                godmode: {
                    key: 'admin',
                    icon: 'AdminIcon',
                    title: 'Админ'
                },
                tts: {
                    key: 'tts',
                    icon: 'TTSIcon',
                    title: 'ТТС.Контроль'
                },
                mix: {
                    key: 'mix',
                    icon: 'MixIcon',
                    title: 'Бетонный завод'
                },
                logistics: {
                    key: 'logistics',
                    icon: 'LogisticsIcon',
                    title: 'Логистика'
                },
                fuel: {
                    key: 'fuel',
                    icon: 'FuelIcon',
                    title: 'Учет ГСМ'
                },
                dispatch: {
                    key: 'dispatch',
                    icon: 'DispatchIcon',
                    title: 'Диспетчерская',
                    sections: {
                        groups: {
                            key: 'groups',
                            title: 'Заказы'
                        },
                        orders: {
                            key: 'orders',
                            title: 'Отгрузки'
                        },
                        posts: {
                            key: 'posts',
                            title: 'Посты'
                        },
                        mixers: {
                            key: 'mixers',
                            title: 'Миксеры'
                        },
                        pumps: {
                            key: 'pumps',
                            title: 'Насосы'
                        }
                    }
                },
                crm: {
                    key: 'crm',
                    icon: 'CRMIcon',
                    title: 'CRM'
                },
                lab: {
                    key: 'lab',
                    icon: 'LabIcon',
                    title: 'Лаборатория'
                },
            }
        }
    },
}
