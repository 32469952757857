<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle :title="details.name"/>

            <div class="techStoreDetails">
                <p class="techStoreDetailsTotal">
                    <AnimateNumber :count="details.values?.find(el => el.storehouseId === details.storehouseUnitId).total"/>
                    {{ details.unit ?? ' ед.' }}
                </p>
                <p class="color-grey">Текущий остаток</p>
            </div>

            <FormSelect
                v-model="details.storehouseUnitId"
                :items="details.units"
                :no-clearable="true"
            />

            <p>История списаний и поступлений</p>

            <div class="d-flex align-center mb-20">
                <el-button-group class="w-75 d-flex align-center">
                    <el-button :type="period_initialize === 'today' ? 'primary' : ''" @click="period_update('today')">Сегодня</el-button>
                    <el-button :type="period_initialize === 'yesterday' ? 'primary' : ''" @click="period_update('yesterday')">Вчера
                    </el-button>
                    <el-button :type="period_initialize === 'week' ? 'primary' : ''" @click="period_update('week')">Неделя</el-button>
                    <el-button :type="period_initialize === 'month' ? 'primary' : ''" @click="period_update('month')">Месяц</el-button>
                    <el-button :type="period_initialize === 'year' ? 'primary' : ''" @click="period_update('year')">Год</el-button>
                </el-button-group>

                <FormDateTimePicker
                    v-model="period_picker"
                    type="datetimerange"
                    label=""
                    class="w-25 mb-0"
                    :default-time="defaultTime"
                    @changeAction="onChangeDateTimePicker()"
                />
            </div>

            <div class="techStoreIODetailWrapper">
                <el-card
                    :body-style="{backgroundColor: '#F9FBFF', padding: '16px'}"
                    shadow="never"
                >
                    <div>Поступило</div>
                    <div class="color-success text-500">
                        + {{ inValue ? formatNumber(inValue, 2) : 0 }} {{ details.unit ?? 'ед.' }}
                    </div>
                </el-card>

                <el-card
                    :body-style="{backgroundColor: '#F9FBFF', padding: '16px'}"
                    shadow="never"
                >
                    <div>Списано</div>
                    <div class="color-error text-500">
                        - {{ outValue ? formatNumber(Math.abs(outValue), 2) : 0 }} {{ details.unit ?? 'ед.' }}
                    </div>
                </el-card>

                <el-card
                    :body-style="{backgroundColor: '#F9FBFF', padding: '16px'}"
                    shadow="never"
                >
                    <div>Сальдо</div>

                    <div class="text-500" :class="{'color-success': saldoValue >= 0, 'color-error': saldoValue < 0}">
                        {{ saldoValue >= 0 ? '+ ' : '' }}{{ saldoValue ? formatNumber(saldoValue, 2) : 0 }} {{ details.unit ?? 'ед.' }}
                    </div>
                </el-card>

            </div>

            <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                <template v-slot:body>

                    <MobileTableDate
                        :date="item.date"
                    />

                    <MobileTableInfo
                        title="Значение"
                        :no-border="true"
                    >
                        <template v-slot:body>
                            <span :class="item.value >= 0 ? 'typeAddition' : 'typeSubtraction'">- {{
                                    formatNumber(Math.abs(item.value), 2)
                                }} {{ item.unit ? item.unit.short_name : 'ед.' }}</span>
                            <span v-if="item.unit_to !== null" class="typeBalance">Переместили {{
                                formatNumber(item.value, 2)
                                }} {{item.unit ? item.unit.short_name : 'ед. ' }}
                            со склада "{{ item.storehouse_from ? item.storehouse_from.name : 'склада' }}" на склад
                                "{{ item.storehouse_to ? item.storehouse_to.name : 'склада' }}"
                            </span>
                        </template>
                    </MobileTableInfo>

                    <MobileTableButton
                        title="Удалить"
                        @action="doRemoveValue(item.id)"
                    />

                </template>
            </MobileTableFramework>

            <MobileTableNoData
                :count="displayData.length"
                :loading="loading"
            />

            <StorehouseGoodDetailsValuesTable
                :data="displayData"
                :isGrouped="isGrouped"
                :dateFormat="dateFormat"
                :loading="loading"
                :tz="tz"
                @doRemoveValue="doRemoveValue"
            />

            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                :loading="loading"
                @action="setPage"
            />

        </template>
        <template v-slot:footer>
            <DrawerEditButton :loading="loading" @action="$emit('openEditDetails', details)"/>
            <DrawerDeleteButton :loading="loading" @action="doRemoveGood()"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableNoData from '@/views/components/Mobile/Table/MobileTableNoData'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import iconsMixin from "@/mixins/iconsMixin";
import AnimateNumber from "@/views/components/AnimateNumber";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination";
import DrawerEditButton from "@/views/components/Drawer/Buttons/DrawerEditButton";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import StorehouseGoodDetailsValuesTable from "@/views/components/StorehousePage/StorehouseGoodDetailsValuesTable.vue";
import moment from "moment/moment";
import FormSelect from "@/views/components/Form/FormSelect.vue";

export default {
    name: 'StorehouseGoodDetails',
    components: {
        FormSelect,
        StorehouseGoodDetailsValuesTable,
        FormDateTimePicker,
        DrawerEditButton,
        TablePagination,
        AnimateNumber,
        MobileTableNoData,
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate, MobileTableFramework, DrawerDeleteButton, DrawerHeadTitle, DrawerFramework
    },
    props: ['detailGood'],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, tableMixin],
    inject: ['api'],
    data() {
        return {
            loading: false,
            details: null,
            period: [moment().startOf("day"), moment().endOf("day")],
            period_initialize: 'today',
            period_picker: null,
            isGrouped: false,
            dateFormat: null,
            inValue: 0,
            outValue: 0,
            saldoValue: 0,
            defaultTime: null,
            tz: 'Europe/Moscow',
        }
    },
    created() {
        this.details = Object.assign({},this.detailGood)
        this.period_picker = [moment().startOf("day"), moment().endOf("day")]
        this.getValues()
    },
    methods: {
        period_update(period_val) {

            this.period_initialize = period_val;

            switch (this.period_initialize) {
                case "today":
                    this.defaultTime = [moment().startOf("day"), moment().endOf("day")];
                    break;
                case "yesterday":
                    this.defaultTime = [moment().subtract(1, 'day').startOf("day"), moment().subtract(1, 'day').endOf("day")];
                    break;
                case "week":
                    this.defaultTime = [moment().startOf("week"), moment().endOf("week")];
                    break;
                case "month":
                    this.defaultTime = [moment().startOf("month"), moment().endOf("month")];
                    break;
                case "year":
                    this.defaultTime = [moment().startOf("year"), moment().endOf("year")];
                    break;
                default:
                // this.defaultTime = this.period_picker;
            }


            this.period_picker = this.defaultTime;
            this.period = this.defaultTime;


            setTimeout(() => {

                this.getValues();
            }, 100)

        },
        getValues() {
            if (!this.loading) {
                this.loading = true
                httpService().post(this.api.storehouseValue.get, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'storehouse',
                    good_id: this.details.good_id,
                    storehouse_unit_id: this.details.storehouseUnitId,
                    period: this.period,
                    period_initialize: this.period_initialize,
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.values = []
                        this.values = data.is_grouped ? data.values : data.values.reverse()
                    }
                    this.details.total = data.total;
                    this.inValue = data.in;
                    this.outValue = data.out;
                    this.saldoValue = data.saldo;
                    this.isGrouped = data.is_grouped;
                    this.dateFormat = data.date_format;
                    this.loading = false;
                    this.tz = data.tz;
                })
            }
        },
        doRemoveValue(id) {
            this.$confirm(
                'Удалить значение и пересчитать количество данной продукции?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.storehouseValue.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'storehouse',
                        valueId: id,
                    }).then(() => {
                        this.$emit('moduleGet')
                        this.getValues()
                        this.viewDetails = false
                        this.$message({
                            message: 'Значение удалено',
                            showClose: true,
                            type: 'success'
                        })
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        doRemoveGood() {
            this.$confirm(
                'Удалить продукцию со склада?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.storehouseGood.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'storehouse',
                        good_id: this.details.good_id,
                        storehouse_unit_id: this.details.storehouseUnitId
                    }).then(() => {
                        this.viewDetails = false;
                        this.$emit('closeDrawerDetails');
                        this.$emit('moduleGet');
                        this.$message({
                            message: 'Значение удалено',
                            showClose: true,
                            type: 'success'
                        })
                    }).catch((error) => {
                        console.log(error);
                        if (error?.response?.data?.message) {
                            this.$message({
                                message: error.response.data.message,
                                showClose: true,
                                type: 'error'
                            })
                        }
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            if (this.isGrouped) {
                return this.values;
            }
            this.filtered = this.values

            this.valueLength = this.filtered.length;
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        onChangeDateTimePicker() {
            this.period = this.period_picker;
            this.period_initialize = 'range';

            this.getValues();
        },

    },
    watch: {
        'details.storehouseUnitId': {
            handler() {
                this.getValues()
            }
        },
    }
}
</script>

<style scoped>
.techStoreIODetailWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    margin-bottom: 20px;
}
</style>
