export default {
    methods: {
        getFullPrice(price, vat, vatInPrice, total = 1) {
            let fullPrice = price * total
            if (vatInPrice === false) {
                fullPrice += fullPrice * (vat / 100)
            }
            return fullPrice
        },
    }
}
