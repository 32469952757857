<template>
    <div>
        <div class="d-flex-full d-flex-start mb-10 gap-30">
            <div>
                <div class="textMedium textSM neutral900">
                    {{ mixOrderGroup.company_short_name ?? mixOrderGroup.company_name ?? '1' }}
                </div>
                <div class="textRegular textSM neutral600">
                    {{ mixOrderGroup.good_name }}
                </div>
            </div>
            <div>
                <div class="no-wrap textMedium textLG neutral900">
                    {{ mixOrderGroup.total }} м³
                </div>
            </div>
        </div>
        <el-tooltip
            effect="dark"
            raw-content
            placement="left"
            :show-after="500"
        >
            <template #content>
                <div>Отгружено: {{ mixOrderGroup.current_mix_delivered }} м³</div>
                <div>Отгружено с других заводов: {{ mixOrderGroup.other_mix_delivered}} м³</div>
                <div>Общий объем: {{ mixOrderGroup.total }} м³</div>
            </template>

            <div class="order-quantity w-100">
                <div class="order-quantity-total d-flex-full textSM textMedium" v-if="mixOrderGroup.done_percent < 100">
                    <div class="cutOverflow">
                        <span class="primary600">{{ mixOrderGroup.current_mix_delivered }} / </span>
                        <span class="success400">{{ mixOrderGroup.other_mix_delivered }} / </span>
                        <span class="neutral600">{{ mixOrderGroup.total }} м³</span>
                    </div>
                </div>
                <div class="order-quantity-total success400 textXS" v-else>
                    <div class="d-flex-full gap-4">
                        <div class="textSM textMedium">
                            Завершено
                        </div>
                        <div class="no-wrap textSM textMedium">
                            {{ mixOrderGroup.done }}  м³
                        </div>
                    </div>
                </div>
                <ProgressMultiBar
                    :donePercent="mixOrderGroup.done_percent"
                    :percent1="mixOrderGroup.delivered_percent"
                    :percent2="mixOrderGroup.all_done_percent"
                />
            </div>
        </el-tooltip>
        <hr class="mt-10 mb-10"/>
    </div>
</template>

<script>
import ProgressMultiBar from "@/views/components/ProgressMultiBar.vue";

export default {
    name: "OperatorMixOrderGroupItem",
    components: {ProgressMultiBar},
    props: ['mixOrderGroup'],
    data() {
        return {

        }
    },
}
</script>

<style scoped>

</style>
