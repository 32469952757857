<template>
    <p class="guide-description">{{text}}</p>
</template>

<script>

export default {
    name: 'GuideDescription',
    props: ['text'],
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-description {
    margin-block: 12px;
    user-select: text;
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 22px;
    color: #64748b;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

    @media (min-width: 640px) {
        -webkit-line-clamp: none;
        font-size: 1rem;
    }
}
</style>
