<template>
    <div class="techBlock pointer" @click="$router.push({ name: 'garageDetails', params: { vehicleId: techDetails.id } })">
        <div class="techBlockBody">
            <div class="techBlockBodyHeading pointer"
                 @click="$router.push({ name: 'garageDetails', params: { vehicleId: techDetails.id } })">
                <div>
                    <span class="neutral900 textMedium textMD mb-5">{{ techDetails.number ?? '-' }}</span>
                    <span class="neutral400 textRegular textSM cutOverflow" style="max-width: 140px">{{
                            techDetails.brand ? techDetails.brand.name + ' ' : ''
                        }}{{ techDetails.name }}</span>
                </div>
                <img v-if="techDetails.type && (techDetails.type === 'mix' || techDetails.type === 'truck' || techDetails.type === 'excavator'|| techDetails.type === 'pump')" :class="'techBlockImg' + techDetails.type" alt="" :src="require('@/assets/tech/' + techDetails.type + '.png')" />
            </div>
            <div class="mt-30">
                <div class="textSM textRegular neutral500">

                    <div class="techBlockBodyIndicatorsItem mb-0">
                        <TechMileageIcon/>
                        <span class="neutral400">{{
                                techDetails.mileage ? formatNumber(techDetails.mileage) : 0
                            }} км. / {{
                                techDetails.engine_hours ? formatNumber(techDetails.engine_hours) : 0
                            }} мтч.</span>
                    </div>

                    <div class="techBlockBodyIndicatorsItem" v-show="false">
                        <TechFuelIcon/>
                        <span>{{
                                techDetails.fuel_total ? (formatNumber(techDetails.fuel_total) + ' л.') : '-'
                            }}</span>
                    </div>

                    <div class="techBlockBodyIndicatorsItem mb-0" v-show="false">
                        <TechWorksIcon/>
                        <span>Работы не проводятся</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TechFuelIcon from '@/views/components/Icons/TechFuelIcon'
import functionsMixin from '@/mixins/functionsMixin'
import TechMileageIcon from "@/views/components/Icons/TechMileageIcon";
import TechWorksIcon from "@/views/components/Icons/TechWorksIcon";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'TechVehicleTableBlock',
    components: {
        TechWorksIcon, TechMileageIcon, TechFuelIcon
    },
    mixins: [functionsMixin, mobileCheckMixin],
    props: ['tech'],
    data() {
        return {
            techDetails: this.tech
        }
    },
    methods: {

    },
    watch: {
        'tech': {
            handler: function () {
                if (this.tech) this.techDetails = this.tech
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
