<template>
    <div class="mt-12">
        <MixOrderDetailsGroupShow
            v-if="!edit && !print"
            :orderGroup="orderGroup"
            :mix-order="mixOrder"
            @loadingAction="loadingAction"
            :loading="loading"
            @get="getOrders"
            @close="$emit('close')"
            @edit="() => edit = true"
            @print="() => print = true"
            @update-order="updateOrder"
        />

        <MixOrderDetailsGroupEdit
            v-if="edit"
            :orderGroup="orderGroup"
            :mix-order="mixOrder"
            :loading="loading"
            @get="getOrders"
            @close="$emit('close')"
            @edit="() => edit = false"
            @update="updateMixOrder"
            :isUpdateMode="true"
        />

        <AllPrintDocs
            from="MixOrderDetailsGroupPrint"
            v-if="!edit && print"
            :orderGroupItem="orderGroup"
            :currentOrder="mixOrder"
            :accessDocuments="accessDocuments"
            @get="$emit('get')"
            @close="$emit('close')"
            @edit="() => edit = true"
            @print="() => print = false"
        />
    </div>
</template>

<script>
import MixOrderDetailsGroupShow from "./MixOrderDetailsGroupShow.vue";
import {httpService} from "@/services/http.service";
import MixOrderDetailsGroupEdit from "./MixOrderDetailsGroupEdit.vue";
import AllPrintDocs from "@/views/dispatch/components/AllPrintDocs.vue";

export default {
    name: "MixOrderDetailsGroup",
    components: {AllPrintDocs, MixOrderDetailsGroupEdit, MixOrderDetailsGroupShow},
    props: ['orderGroup', 'mixOrderId', 'mixOrderDetails'],
    inject: ['api'],
    data() {
        return {
            edit: false,
            loading: true,
            loadingOrder: false,
            mixOrder: null,
            print: false,
            accessDocuments: [],
        }
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelMixOrder`);
    },
    created() {
        if (!this.mixOrderDetails) {
            this.mixOrder = this.orderGroup.orders.find(el => el.id === this.mixOrderId)
        } else {
            this.mixOrder = this.mixOrderDetails
        }
        this.getOrderInfo()

        window.Echo.channel('laravel_database_ChannelMixOrder')
            .listen(`.Update.${this.mixOrder.id}`, () => {
                this.getOrderInfo()
            })
            .listen(`.Delete.${this.mixOrder.id}`, () => {
                this.$emit('close')
            })
    },
    methods: {
        getOrders() {
            this.getOrderInfo()
            this.$emit('get')
        },
        getOrderInfo() {
            this.loading = true
            if (this.loadingOrder) {
                return;
            }
            this.loadingOrder = true;

            httpService().post(this.api.mixOrder.getOrderInfoSimple + '/' + this.mixOrderId, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    if (
                        this.mixOrder && this.mixOrder.total && this.mixOrder.total != data.order.total ||
                        this.mixOrder && this.mixOrder.start_at && this.mixOrder.start_at != data.order.start_at ||
                        this.mixOrder && this.mixOrder.vehicle_id && this.mixOrder.vehicle_id != data.order.vehicle_id ||
                        this.mixOrder && this.mixOrder.status != data.order.status ||
                        this.mixOrder && this.mixOrder.client_total && this.mixOrder.client_total != data.order.client_total
                    )
                    {
                        this.$message({
                            message: 'Данные по отгрузке были актуализированы',
                            showClose: true,
                            type: 'info'
                        })
                        this.$emit('get')
                    }

                    this.mixOrder = data.order
                    this.accessDocuments = data.accessDocuments;
                }
                this.loading = false
                this.loadingOrder = false;
            }).catch(() => {
                this.loading = false
                this.loadingOrder = false;
                this.$message({
                    message: 'Это была неподтвержденная отгрузка. Алгоритм подобрал лучший вариант',
                    showClose: true,
                    type: 'warning'
                })
                this.$message({
                    message: 'Загружаем данные... Повторите снова!',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('get')
                this.$emit('close')
            })
        },
        loadingAction(loading) {
            this.loading = loading
        },
        updateOrder(id, order) {
            this.$emit('updateOrder', id, order)
        },
        updateMixOrder(order) {
            this.mixOrder = order
        }
    },
    computed: {
        mixOrderStatus() {
            const order = this.orderGroup.orders.find((o) => o.id === this.mixOrderId);
            return order ? order.status : null
        },
    },
    watch: {
        'mixOrderStatus': {
            handler: function () {
                this.getOrderInfo()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
