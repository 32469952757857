export default {
    data() {
        return {
            tabs: [{
                route: 'MixInfo',
                name: 'Сводка'
            }, {
                route: 'MixOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixGoods',
                name: 'Рецепты'
            }],
        }
    }
}

// {
//     route: 'MixMixers',
//     name: 'Миксеры'
// },
