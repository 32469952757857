<template>
    <TableHeadBlock v-model="search"
        :loading="loading"
        :disable-filter="true"
        :disable-update-button="true"
        :search-focused="true"
        :disable-create-button="true"
        create-button-label="Прикрепить тариф"/>

        <el-table empty-text="Информация не найдена"
                  class="mt-15 w-100 defaultTableHeight scrollableTable"
                  v-loading="loadingTable"
                  @current-change="handleCurrentChange"
                  :data="displayData">
            <el-table-column prop="id"
                             label="#"
                             width="100"
                             class-name="pointer"
                             sortable>
                <template v-slot="scope">
                            <span class="textSM textMedium"
                                  :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                                #{{ formatNumber(scope.row.id) }}
                            </span>
                </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="Название"
                             class-name="pointer"
                             sortable>
                <template v-slot="scope">
                    <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="depends_on"
                             label="Тип"
                             class-name="pointer"
                             width="220px">
                <template v-slot="scope">
                    <template v-if="scope.row.depends_on">
                        <span
                            class="textRegular textSM neutral400">{{ getInfo(scope.row.depends_on, dependsTypes) }}</span>
                    </template>
                    <template v-else>
                        <span class="textRegular textSM neutral200">-</span>
                    </template>
                </template>
            </el-table-column>
        </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import serviceInfo from "@/mixins/serviceInfo";

export default {
    name: "MixOrderDetailsGroupEditSelectService",
    components: {TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin, serviceInfo],
    props: [
        'modelValue',
        'items',
        'type',
    ],
    data() {
        return {
            itemValue: this.modelValue
        }
    },
    created() {
        this.setValues();
    },
    methods: {
        filterValues() {
            this.filtered = this.values;

            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                this.$emit('updateItem', val)
            }
        },
        setValues() {
            this.values = this.type
                    ? this.items.filter(item => item.type === this.type)
                    : this.items
            this.valueLength = this.values.length
        }
    }
}
</script>

<style lang="scss" scoped>
.scrollableTable {
    & {
        height: 40svh;
        overflow: scroll;
    }

    @media screen and (max-height: 1079px) {
        height: 45svh;
    }

    @media screen and (max-height: 920px) {
        height: 50svh;
    }

    @media screen and (max-height: 820px) {
        height: 55svh;
    }

    @media screen and (max-height: 768px) {
        height: 60svh;
    }
}
</style>
