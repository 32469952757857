<template>
    <article class="mix-driver-task-detailed">
        <div @click="$emit('closeOrder')" class="mix-driver-task-detailed__header">
            <ArrowLeft/>
            <span class="textMD text-500 color-primary">Очередь заявок</span>
        </div>

        <div class="mix-driver-task-detailed__body" v-if="order">
            <NewMixDriverTaskActual v-if="isActual && order" class="m-0" :order="order" :detailsHidden="true"/>
            <NewMixDriverTask v-else :order="order" :detailsHidden="true" :fullLabels="true"/>

            <article
                v-if="!isLoadingPlaceEmpty || (order.status === 'new' && order.confirm)"
                class="mix-driver-task-detailed__body-info d-flex gap-4">
                <div v-if="!isLoadingPlaceEmpty" class="mix-driver-task-detailed__body-card flex-1">
                    <p class="mix-driver-task-detailed__body-text">Место загрузки</p>
                    <div v-if="order.factory_name || order.mix_name || order.mixer_name"
                         class="mix-driver-task-detailed__body-card-loading">
                        <BuildingIcon class="f-shrink-0" :current-color="true" width="16px" height="16px"/>
                        <NewMixDriverTaskLoadingPlace
                            :factory_name="order.factory_name"
                            :mix_name="order.mix_name"
                            :mixer_name="order.mixer_name"/>
                    </div>
                </div>
                <div v-if="order.status === 'new' && order.confirm" class="mix-driver-task-detailed__body-card">
                    <p class="mix-driver-task-detailed__body-text">Очередность</p>
                    <div @click="isVisibleQueueModal = true" class="mix-driver-task-detailed__body-card-sequence">
                        <p>{{ order.vehicle_queue?.length + 1 }}-й</p>
                        <SequenceIcon/>
                    </div>
                </div>
            </article>

            <article class="mix-driver-task-detailed__body-info">
                <div class="mix-driver-task-detailed__body-card">
                    <p class="mix-driver-task-detailed__body-text">Адрес доставки</p>
                    <div class="mix-driver-task-detailed__body-card-location">
                        <div v-if="order.delivery_address">
                            <p>{{ order.delivery_address }}</p>
                            <RoutingIcon v-if="order.delivery_latitude && order.delivery_longitude" class="pointer" @click.prevent="openMap()"/>
                        </div>
                        <div>
                            <p>{{ order.company_name ?? "" }}</p>
                        </div>
                        <div v-if="order.company_manager_name || order.company_manager_phone">
                            <p>{{ order.company_manager_name ?? order.company_manager_phone}}</p>
                            <a v-if="order.company_manager_phone" :href="`tel:${order.company_manager_phone}`">
                                <PhoneIcon/>
                            </a>
                        </div>
                    </div>
                </div>
            </article>

            <el-button v-if="showButton"
                       @click="isVisibleModal = true"
                       :loading="loading"
                       :disabled="isButtonDisabled"
                       class="w-100 button-order-height textXS textMedium br-12"
                       style="font-family: inherit; font-size: 24px; font-weight: 400"
                       type="primary">
                {{ buttonText }}
            </el-button>

            <Label v-if="order.pump || order.take_payment || order.comment_driver" class="m-auto" text="Доп. инфо"
                   :isRounded="true"/>

            <article v-for="(order, index) in order.pumps" :key="index" class="mix-driver-task-detailed__body-info">
                <div class="pump-info mix-driver-task-detailed__body-card">
                    <p class="mix-driver-task-detailed__body-text">Насос</p>
                    <div class="mix-driver-task-detailed__body-card-pump">
                        <div class="pump-info-name bg-neutral30" v-if="order.vehicle_number">
                            <p>{{ getVehicleNumber(order.vehicle_number) }}</p>
                        </div>
                        <div>
                            <p>{{ order.driver_name ?? "" }}</p>
                            <a :href="`tel:${order.driver_phone}`">
                                <PhoneIcon/>
                            </a>
                        </div>
                    </div>
                </div>
            </article>

            <article class="mix-driver-task-detailed__body-info" v-if="order.take_payment || order.comment_driver">
                <div class="mix-driver-task-detailed__body-card" v-if="order.take_payment">
                    <p class="mix-driver-task-detailed__body-text">Взять оплату</p>
                    <div class="mix-driver-task-detailed__body-card-payment bg-neutral30">
                        <p class="primary600 textLG">
                            {{ +order.product_price * +order.total + +order.delivery_price + " " }}
                        </p>
                        <RubleIcon/>
                    </div>
                </div>
                <div v-if="order.comment_driver" class="mix-driver-task-detailed__body-card">
                    <p class="mix-driver-task-detailed__body-text">Комментарий диспетчера</p>
                    <div v-html="getFormattedComment(order.comment_driver)" class="mix-driver-task-detailed__body-card-comment d-flex f-direction-column">
                    </div>
                </div>
            </article>

            <MixDriverTaskConfirmModal
                v-model="isVisibleModal"
                :buttonText="buttonText"
                @approve="changeStatus(order)"
                @close="() => isVisibleModal = false"
            />

            <MixDriverTaskDetailedQueueModal
                :is-visible="isVisibleQueueModal"
                :current-order="order"
                @close-order="isVisibleQueueModal = false"
                :queue="order.vehicle_queue"
            />
        </div>
    </article>
</template>

<script>
// icons
import PhoneIcon from "@/views/components/Icons/PhoneIcon.vue";
import RoutingIcon from "@/views/components/Icons/RoutingIcon.vue";
import SequenceIcon from "@/views/components/Icons/SequenceIcon.vue";
import RubleIcon from "@/views/components/Icons/RubleIcon.vue";
import ArrowLeft from "@/views/components/Icons/ArrowLeft.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import Label from "@/views/components/Label/Label.vue";

import functionsMixin from "../../../../mixins/functionsMixin";
import NewMixDriverTask from "./NewMixDriverTask.vue";
import NewMixDriverTaskActual from "./NewMixDriverTaskActual.vue";
import MixDriverTaskDetailedQueueModal from "./MixDriverTaskDetailedQueueModal";
import MixDriverTaskConfirmModal from "./MixDriverTaskConfirmModal.vue";
import NewMixDriverTaskLoadingPlace from "./NewMixDriverTaskLoadingPlace.vue";

export default {
    name: "NewMixDriverTaskDetailed",
    props: ['order', 'actualOrder'],
    components: {
        ArrowLeft,
        PhoneIcon,
        RoutingIcon,
        NewMixDriverTask,
        NewMixDriverTaskActual,
        MixDriverTaskDetailedQueueModal,
        MixDriverTaskConfirmModal,
        BuildingIcon,
        SequenceIcon,
        Label,
        RubleIcon,
        NewMixDriverTaskLoadingPlace
    },
    mixins: [functionsMixin],
    data() {
        return {
            timerStatus: false,
            loading: false,
            isVisibleModal: false,
            isVisibleQueueModal: false,
        };
    },
    computed: {
        showButton: function () {
            if (this.order?.status === "done"
                || !this.order?.confirm
                || (this.order?.id !== this.actualOrder?.id && this.actualOrder)
                || !this.order?.driver_can_load_order && (this.order?.status === "new" || this.order?.status === 'loading')
            )
                return false
            return true
        },
        buttonText() {
            switch (this.order?.status) {
                case "new":
                    return "Начать загрузку";
                case "loading":
                    return "Начать доставку";
                case "delivery":
                    return "На объекте";
                case "object":
                    return this.order?.is_pouring_available ? "Начать заливку" : "Возвращаюсь";
                case "pouring":
                    return "Возвращаюсь";
                case "return":
                    return "На заводе";
                case "done":
                default:
                    return "Завершен";
            }
        },
        isButtonDisabled() {
            return !this.order?.confirm || this.order?.status === 'done'
        },
        isActual() {
            return this?.order?.id === this?.actualOrder?.id
        },
        isLoadingPlaceEmpty() {
            return !this.order?.factory_name && !this.order?.mix_name && !this.order?.mixer_name
        }
    },
    methods: {
        changeStatus(order) {
            this.$emit("changeStatus", order);
            this.isVisibleModal = false;
            this.$emit('closeOrder')
        },
        openMap() {
            const latitude = this.order?.delivery_latitude; // Широта
            const longitude = this.order?.delivery_longitude; // Долгота

            const isMobile = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

            const yandexUrl = `https://yandex.ru/maps/?ll=${longitude}%2C${latitude}&mode=whatshere&whatshere%5Bpoint%5D=${longitude}%2C${latitude}&whatshere%5Bzoom%5D=15.93&z=15`;

            if (latitude) {
                if (isMobile) {
                    // На мобильных устройствах — перенаправление текущей вкладки
                    window.location.href = yandexUrl;
                } else {
                    // На компьютере — открытие новой вкладки
                    window.open(yandexUrl, '_blank');
                }
            }
        },
    },
    watch: {
        isVisibleQueueModal: {
            handler: function (value) {
                document.body.style.overflow = value ? 'hidden' : 'unset';
            }
        }
    }
}
</script>



<!--
        openMap() {
            const latitude = this.orderDetails.latitude; // Широта
            const longitude = this.orderDetails.longitude; // Долгота

            if (latitude) {
                window.location.href = `https://yandex.ru/maps/?ll=${longitude}%2C${latitude}&mode=whatshere&whatshere%5Bpoint%5D=${longitude}%2C${latitude}&whatshere%5Bzoom%5D=15.93&z=15`;
            }

            // window.location.href = `yandexmaps://maps.yandex.ru/?ll=${longitude},${latitude}&z=12`
            // Проверка на наличие приложения яндекс-навигатора
            // const isYandexNavAvailable = typeof window.navigator !== 'undefined' && typeof window.navigator.openApp !== 'undefined';

            // // URI для яндекс-навигатора
            // window.location.href = `yandexnavi://show_point_on_map?lat=${latitude}&lon=${longitude}`;

            // // URI для Google Maps
            // const googleMapsURI = `https://maps.google.com/maps?daddr=${latitude},${longitude}`;

            // // Открытие навигационного приложения
            // if (isYandexNavAvailable) {
            //     const navigationApp = isYandexNavAvailable ? 'yandexnavi' : 'googlemaps';
            //     const navigationURI = isYandexNavAvailable ? yandexNavURI : googleMapsURI;

            //     const openNavigationApp = () => {
            //         const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            //         if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //             window.location.href = `maps://${navigationURI}`;
            //         } else if (/Android/.test(userAgent)) {
            //             window.location.href = `intent://${navigationURI}#Intent;scheme=maps;package=${navigationApp};end`;
            //         } else {
            //             window.location.href = navigationURI;
            //         }
            //     };

            //     if (isYandexNavAvailable) {
            //         // Открытие яндекс-навигатора или предложение выбора приложения
            //         window.navigator.openApp(yandexNavURI, openNavigationApp);
            //     } else {
            //         // Открытие Google Maps или предложение выбора приложения
            //         window.navigator.openApp(googleMapsURI, openNavigationApp);
            //     }
            // } else {
            //     // Если ни одно навигационное приложение не установлено, открываем Google Maps через браузер
            //     window.location.href = googleMapsURI;
            // }
        }, -->
