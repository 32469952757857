<template>

    <template v-if="user.role_id === 6">
        <OperatorDashboard/>
    </template>

    <template v-else-if="user.role_id === 5">
        <NewMixDriverDashboard/>
    </template>

    <Auth :hideTitle="true" v-else>

        <template v-if="user.role_id === 7">
            <MixDriverDashboard/>
        </template>

        <template v-else>
        <div v-bind:class="{'dashboardCard bg-white':windowWidth > 768}">
            <div v-bind:class="{'dashboardCardBody':windowWidth > 768}">


                <template v-if="widgets.length === 0">

                    <div class="dashboardCard">
                        <div class="dashboardCardBody">

                            <div class="d-flex-full-stroke dashboardCardNoWidgets">
                                <DashboardNoWidgetsIcon/>

                                <div>
                                    <h2 class="color-white mt-0">Добавьте виджеты на рабочий стол</h2>
                                    <p class="color-white text-light mb-30">Виджеты обеспечат вас быстрым доступом к
                                        самым
                                        важным
                                        данным в системе<br/>Отслеживайте показатели вашей компании в режиме онлайн</p>

                                    <el-button
                                        @click="$router.push({ name: 'UsersEdit', params: { id: user.id, settingsWidgets: 'true' } })"
                                        type="primary">Перейти к настройкам виджетов
                                    </el-button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <el-row :gutter="25">

                        <el-col :sm="12">

                            <div class="dashboardCard">
                                <div class="dashboardCardBody">

                                    <DashboardFAQIcon/>

                                    <h3 class="color-white">Познакомьтесь с системой</h3>

                                    <p class="color-white text-light mb-30" style="max-width: 350px;">Посетите страницу
                                        “Справка”, чтобы ознакомиться с работой системы и модулей</p>
                                    <el-button @click="$router.push({ name: 'FaqIndex' })" type="primary">Перейти в
                                        раздел
                                        справки
                                    </el-button>
                                </div>
                            </div>

                        </el-col>

                        <el-col :sm="12">

                            <div class="dashboardCard">
                                <div class="dashboardCardBody">

                                    <DashboardWhatsappIcon/>

                                    <h3 class="color-white">Возникли вопросы?</h3>

                                    <p class="color-white text-light mb-30" style="max-width: 350px;">Если у вас
                                        возникли
                                        затруднения, то свяжитесь с тех. поддержкой в WhatsApp</p>
                                    <el-button @click="openWhatsApp()" type="success">Задать вопрос в WhatsApp
                                    </el-button>

                                </div>
                            </div>

                        </el-col>

                    </el-row>

                </template>

                <template v-else>

                    <div class="widgets mb-10">
                        <div class="left-widgets">
                            <NoRightWidget v-if="leftWidgets.length === 0"/>

                            <template v-if="leftWidgets.length > 0">
                                <div class="left-widgets-one">
                                    <template v-if="leftWidgets.length > 1">
                                        <button type="button" class="el-carousel__arrow el-carousel__arrow--left"
                                                @click="controlLeftWidget('down')"><i class="el-icon">
                                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="currentColor"
                                                      d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"></path>
                                            </svg>
                                        </i></button>
                                        <button type="button" class="el-carousel__arrow el-carousel__arrow--right"
                                                @click="controlLeftWidget('up')"><i class="el-icon">
                                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="currentColor"
                                                      d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path>
                                            </svg>
                                        </i></button>
                                    </template>

                                    <template v-for="(widget, index) in leftWidgets" :key="index">
                                        <div v-show="(index + 1) === leftActive">
                                            <w-manufacturing-products :id="widget.id"
                                                                      v-if="widget.key === 'manufacturing-products'"/>
                                            <w-current-perfomance :id="widget.id"
                                                                  v-if="widget.key === 'current-perfomance'"/>
                                            <w-workshift :id="widget.id" v-if="widget.key === 'workshift'"/>
                                            <w-cashbox :id="widget.id" v-if="widget.key === 'cashbox-realtime'"/>
                                            <ShipmentsRealtimeWidgetLeft :id="widget.id"
                                                                         v-if="widget.key === 'shipments-realtime'"/>
                                            <MixRealtimeWidgetLeft :id="widget.id"
                                                                   v-if="widget.key === 'mix-realtime'"/>
                                            <ProductionControlGoodsWidget :id="widget.id"
                                                                          v-if="widget.key === 'control-goods'"/>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                        <div class="right-widgets">
                            <NoRightWidget class="mb-15" v-if="rightTopWidgets.length === 0"/>
                            <el-carousel
                                v-if="rightTopWidgets.length > 0"
                                id="right-top-widgets"
                                ref="carousel"
                                class="br-15"
                                trigger="click"
                                :autoplay="false"
                                arrow="always"
                                v-bind:class="{ 'oneWidget': rightTopWidgets.length === 1 }"
                                indicator-position="none"
                            >
                                <el-carousel-item v-for="(widget, index) in rightTopWidgets" :key="index">
                                    <w-contracts :id="widget.id" v-if="widget.key === 'contracts-realtime'"/>
                                    <w-shipments :id="widget.id" v-if="widget.key === 'shipments'"/>
                                    <ShipmentsCompaniesWidgetRight :id="widget.id"
                                                                   v-if="widget.key === 'shipments-companies'"/>
                                    <MixCompaniesWidgetRight :id="widget.id" v-if="widget.key === 'mix-companies'"/>
                                    <MixGoodsWidgetRight :id="widget.id" v-if="widget.key === 'mix-goods'"/>
                                </el-carousel-item>
                            </el-carousel>


                            <NoRightWidget v-if="rightBottomWidgets.length === 0"/>
                            <el-carousel
                                v-if="rightBottomWidgets.length > 0"
                                id="right-bottom-widgets"
                                ref="carousel"
                                class="br-15"
                                trigger="click"
                                :autoplay="false"
                                arrow="always"
                                v-bind:class="{ 'oneWidget': rightBottomWidgets.length === 1 }"
                                indicator-position="none"
                            >
                                <el-carousel-item v-for="(widget, index) in rightBottomWidgets" :key="index">
                                    <w-contracts :id="widget.id" v-if="widget.key === 'contracts'"/>
                                    <w-shipments :id="widget.id" v-if="widget.key === 'shipments'"/>
                                    <ShipmentsCompaniesWidgetRight :id="widget.id"
                                                                   v-if="widget.key === 'shipments-companies'"/>
                                    <MixCompaniesWidgetRight :id="widget.id" v-if="widget.key === 'mix-companies'"/>
                                    <MixGoodsWidgetRight :id="widget.id" v-if="widget.key === 'mix-goods'"/>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>

                </template>

            </div>
        </div>

        </template>
    </Auth>
</template>

<script>
import Auth from './layouts/Auth'
import WManufacturingProducts from './widgets/left/manufacturing-products.vue'
import WCashbox from './widgets/left/cashbox.vue'
import WCurrentPerfomance from './widgets/left/current-perfomance.vue'
import WWorkshift from './widgets/left/workshift.vue'
import WShipments from './widgets/right/shipments.vue'
import WContracts from './widgets/right/contracts.vue'
import NoRightWidget from './components/NoWidget'
import ShipmentsRealtimeWidgetLeft from './widgets/left/shipments-realtime'
import ShipmentsCompaniesWidgetRight from './widgets/right/shipments-companies'
import MixCompaniesWidgetRight from '@/views/widgets/right/mix-companies'
import MixGoodsWidgetRight from '@/views/widgets/right/mix-goods'
import MixRealtimeWidgetLeft from '@/views/widgets/left/mix-realtime'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import DashboardNoWidgetsIcon from "@/views/components/Icons/DashboardNoWidgetsIcon";
import DashboardFAQIcon from "@/views/components/Icons/DashboardFAQIcon";
import DashboardWhatsappIcon from "@/views/components/Icons/DashboardWhatsappIcon";
import MixDriverDashboard from "@/views/components/Dashboards/MixDriverDashboard.vue";
import NewMixDriverDashboard from "@/views/components/Dashboards/Driver/NewMixDriverDashboard.vue";
import ProductionControlGoodsWidget from "@/views/widgets/left/control-goods.vue";
import OperatorDashboard from "@/views/components/Dashboards/Operator/OperatorDashboard.vue";

export default {
    name: 'Dashboard',
    components: {
        OperatorDashboard,
        ProductionControlGoodsWidget,
        MixDriverDashboard,
        NewMixDriverDashboard,
        DashboardWhatsappIcon,
        DashboardFAQIcon,
        DashboardNoWidgetsIcon,
        MixRealtimeWidgetLeft,
        MixGoodsWidgetRight,
        MixCompaniesWidgetRight,
        ShipmentsCompaniesWidgetRight,
        ShipmentsRealtimeWidgetLeft,
        NoRightWidget,
        Auth,
        WManufacturingProducts,
        WCashbox,
        WCurrentPerfomance,
        WWorkshift,
        WShipments,
        WContracts
    },
    data() {
        return {
            sliderHeight: '670px',
            windowWidth: null,
            leftActive: 1
        }
    },
    created() {
        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth)

            //Init
            this.getWindowWidth()
        })
    },
    beforeUnmount: function () {
        document.body.className = ''
    },
    computed: {
        ...mapState(useCommonStore, {
            widgets: 'getWidgets',
            user: 'getUser',
        }),
        leftWidgets() {
            return this.widgets.filter(element => element.position === 'left')
        },
        rightTopWidgets() {
            return this.widgets.filter(element => element.position === 'right-top')
        },
        rightBottomWidgets() {
            return this.widgets.filter(element => element.position === 'right-bottom')
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        getWindowWidth(event) {
            this.windowWidth = document.documentElement.clientWidth
            if (this.windowWidth < 1024) {
                this.sliderHeight = '370px'
            }
            if (this.windowWidth < 510) {
                this.sliderHeight = '580px'
            }
        },
        controlLeftWidget(action) {
            if (action === 'up') {
                if (this.leftActive !== this.leftWidgets.length) {
                    this.leftActive++
                } else {
                    this.leftActive = 1
                }
            } else {

                if (this.leftActive !== 1) {
                    this.leftActive--
                } else {
                    this.leftActive = this.leftWidgets.length
                }
            }
        },
        openWhatsApp() {
            window.open("https://wa.me/89997654717?text=Мне%20нужна%20помощь%20с%20Цифровой%20Платформой", "_blank");
        }
    },
    watch: {
        'windowWidth': {
            handler: function () {
                if (this.windowWidth < 768) {
                    if (this.user.role_id !== 5 && this.user.role_id !== 7) document.body.className = 'dark-bg'
                } else {
                    document.body.className = ''
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
