<template>
    <div v-if="details">
        <div class="add-shipping">

            <div class="add-shipping__header__title neutral900 w-100">
                <div class="d-flex-full align-center">
                    <div class="cutOverflow">
                        {{
                            (details.status === 'new' && !details.confirm) ?
                                'Подтверждение отгрузки' :
                                'Подробности отгрузки'
                        }}
                        #{{ formatNumber(details.id) }} -
                        {{
                            orderGroup?.company?.name ?? orderGroup?.company ?? ''
                        }}
                    </div>
                    <div>
                        <template v-if="details.status === 'new'">
                            <div class="group-orders-status-text"
                                 :class="details.confirm ? 'group-orders-status-text-done ' : 'group-orders-status-text-unconfirmed'">
                                {{ details.confirm ? 'Подтверждена' : 'Неподтверждена' }}
                            </div>
                        </template>
                        <template v-else-if="details.status === 'done'">
                            <div class="group-orders-status-text group-orders-status-text-done">
                                Выполнена
                            </div>
                        </template>
                        <template v-else>
                            <div class="group-orders-status-text group-orders-status-text-confirmed">
                                {{ getStatusLabel(details.status) }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="add-shipping__content">
                <div class="d-flex documentPrints">

                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox
                            :label="doc['type'] !== 'other' ? doc['type'] : doc['name']"
                            :value="doc['type'] !== 'other' ? doc['type'] : doc['id']"
                            v-for="(doc, index) in accessDocuments" :key="index">
                            <template #default>
                                <div class="document text-center">
                                    <img :src="doc['image_path']" alt="Документ"/>
                                    <p class="textMedium textSM">{{doc['name']}}</p>
                                </div>
                            </template>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>

            <div class="add-shipping__footer">
                <el-button
                    @click="() => $emit('print')"
                    class="ci-btn ci-btn_default add-shipping__footer__cancel">Отменить печать
                </el-button>

                <el-button
                    @click="printDocs('download')"
                    :disabled="!selectedDocs.length || loadingButton || loadingDownloadButton"
                    :icon="icons.download"
                    :loading="loadingDownloadButton"
                    class="ci-btn"
                    type="default">Скачать
                </el-button>
                <el-button
                    @click="printDocs('print')"
                    :disabled="selectedDocs.length > 1 || loadingButton || loadingDownloadButton"
                    :loading="loadingButton"
                    :icon="icons.print"
                    class="ci-btn ci-btn_blue"
                    type="primary">Распечатать
                </el-button>
            </div>
        </div>
    </div>
    <el-dialog
        class="document-viewer-dialog"
        v-model="showDocViewer"
        :destroy-on-close="true"
        fullscreen
        @close="documentUrl = null">
        <DocViewer :url="documentUrl"/>
    </el-dialog>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";
import DocViewer from "../../../print/DocViewer.vue";

export default {
    name: "MixOrderDetailsGroupPrint",
    components: {DocViewer},
    props: ['orderGroup', 'mixOrder', 'accessDocuments'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin],
    inject: ['api'],
    data() {
        return {
            details: null,
            selectedDocs: [],
            loadingButton: false,
            loadingDownloadButton: false,
            documentUrl: null,
            showDocViewer: false,
        }
    },
    created() {
        this.details = this.mixOrder
    },
    methods: {
        printDocs(type) {
            this.selectedDocs.forEach(template => {
                this.print(template, type)
            })
        },
        print(template, type) {
            if (type === 'download') {
                this.download(template)
            } else if (type === 'print') {
                this.printDoc(template)
            }
        },
        download(template) {
            this.loadingDownloadButton = true
            httpService().post(this.api.print.downloadDoc, {
                account_module_reserve_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: this.details.id,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                let filename = '№' + this.details.id
                if (template === 'invoice') {
                    filename = 'Транспортная накладная по отгрузке №' + this.details.id
                } else if (template === 'passport') {
                    filename = 'Паспорт качества по отгрузке ' + this.details.id
                } else if (template === 'ttn') {
                    filename = 'Товарно-транспортная накладная по отгрузке №' + this.details.id
                }
                link.href = url

                link.setAttribute('download', filename + '.docx')
                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                console.log(error)
            }).finally(() => {
                this.loadingDownloadButton = false
            })
        },
        printDoc(template) {
            this.loadingButton = true;
            httpService().post(this.api.print.printDoc, {
                order: this.details.id,
                template: template,
                orderGroupId: this.orderGroupItem?.id
            }, {responseType: 'blob'}).then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const url = URL.createObjectURL(blob);

                    const printWindow = window.open(url);

                    printWindow.addEventListener('load', function () {
                        printWindow.print();
                        URL.revokeObjectURL(url);
                    }, {once: true});

                }
            }).catch((error) => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                });
                console.log(error)
            }).finally(() => {
                this.loadingButton = false;
            });
        },
    }
}
</script>

<style scoped>

</style>
