<template>
    <GuideTitle text="Как добавить технику на обслуживание"/>

    <GuideDescription text="Пошаговая инструкция, как добавить ТС в модуль обслуживания техники для дальнейшего проведения работ" />

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='Перейдите в модуль обслуживания техники'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/630c1eb6-8a80-4f4c-abde-2edd3983833e/ascreenshot.jpeg?tl_px=0,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0">
            </GuideStep>

            <GuideStep number="2" text='Нажмите на синюю кнопку справа вверху'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/630c1eb6-8a80-4f4c-abde-2edd3983833e/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1036,90">
            </GuideStep>

            <GuideStep number="3" text='Введите гос. номер'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/86cfd005-a001-4ea4-8cc0-c2668654d39c/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=299,129">
            </GuideStep>

            <GuideStep number="4" text='Если ТС уже есть в базе, то появится подсказка с номером. Нажмите на нее, чтоб выбрать уже существующий транспорт'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/2673a3de-aa73-44ff-ad74-d9253a849b03/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=296,189">
            </GuideStep>

            <GuideStep number="5" text='Укажите бренд ТС'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/9fcf3b8b-00bb-43fa-8463-639cafc5f606/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=283,253">
            </GuideStep>

            <GuideStep number="6" text='Выберите тип ТС'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/23609169-cfdb-4a49-b490-e7cf5581bb7e/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=257,369">
            </GuideStep>

            <GuideStep number="7" text='Нажмите на кнопку "Следующий шаг"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/6cd99416-6476-4fee-88da-3b649732367b/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=256,398">
            </GuideStep>

            <GuideStep number="8" text='Укажите пробег в км на начало текущего дня'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/29166f79-08c8-45a6-ba72-c7514a084e29/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=331,176">
            </GuideStep>

            <GuideStep number="9" text='Укажите значение моточасов на начало текущего дня, если оно вам известно и необходимо для отслеживания'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/0ff7b728-1095-401c-a902-c2f54781ffea/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=300,244">
            </GuideStep>

            <GuideStep number="10" text='Опционально укажите объем топливного бака для отслеживания остатка топлива'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/dcd7c25a-7469-4fe3-8a03-923a0b5bb152/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=272,326">
            </GuideStep>

            <GuideStep number="11" text='Нажмите на кнопку "Добавить технику"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/65c7093d-76ed-4cf8-8aa3-78995f3c2afe/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=319,359">
            </GuideStep>

            <GuideStep number="12" text='На экране "Автопарк" появился новый элемент. Нажмите на него'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/90fc81ba-c5d9-437a-b496-be1e601b3c69/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=886,200">
            </GuideStep>

            <GuideStep number="13" text='ТС добавлено и готово для проведения работ'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/b79118ac-0117-40d6-a64e-7cdac51f1bc1/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0">
            </GuideStep>

        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from '@/views/components/Guide/GuideTitle.vue';
import GuideDescription from '@/views/components/Guide/GuideDescription.vue';
import GuideStep from '@/views/components/Guide/GuideStep.vue';
import GuideSteps from '@/views/components/Guide/GuideSteps.vue';

export default {
    name: 'HowAddTechToService',
    components: { GuideTitle, GuideStep, GuideDescription, GuideSteps },
    data() {
        return {}
    },
}
</script>
