<template>
    <svg :class="[{'currentColor' : currentColor}]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 20L21 17L18 14" stroke="#151517" :stroke-width="strokeWidth ?? 2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 4L21 7L18 10" stroke="#151517" :stroke-width="strokeWidth ?? 2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 7H17C15.8744 7 14.8357 7.37194 14 7.99963M3 17H7C8.12561 17 9.16434 16.6281 10 16.0004" stroke="#151517" :stroke-width="strokeWidth ?? 2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 7H7C9.76142 7 12 9.23858 12 12C12 14.7614 14.2386 17 17 17H21" stroke="#151517" :stroke-width="strokeWidth ?? 2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "RedirectIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
