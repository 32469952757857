<template>
    <Auth title="Остатки" :tabs="tabs" :settings="$route.params.id" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loadingButton"
                    create-button-label="Добавить значение"
                    @moduleGet="moduleGet(true)"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openStorehouseActionsDialog"
                    :disable-filter="true"
                    :new="true"
                    add-button-text="Добавить операцию"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="units"
                    :loading="loadingButton"
                    :filter-show="filterShow"
                />

                <el-radio-group v-model="mode" class="mt-15 mb-15">
                    <el-radio-button label="by_product">По продукции</el-radio-button>
                    <el-radio-button label="by_storehouse">По складам</el-radio-button>
                </el-radio-group>

                <div v-if="displayData">
                    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                        <template v-slot:body>

                            <MobileTableHR class="mt-30"/>

                            <MobileTableInfo
                                title="Продукция"
                                :body="item.name ?? '-'"
                            />

                            <MobileTableInfo
                                title="Ед. изменения"
                                :no-border="true"
                                :body="item.unit ?? 'ед.'"
                            />

                            <MobileTableInfo
                                title="Остаток"
                                :no-border="true"
                                :body="formatNumber(item.total, 2) + ' ' + (item.unit ?? 'ед.')"
                            />

                            <template v-if="mode === 'by_product'">
                                <MobileTableInfo
                                    title="Себестоимость"
                                    :body="item.cost_price ?? '-'"
                                />

                                <MobileTableInfo
                                    title="Сумма себестоимости"
                                    :no-border="true"
                                    :body="item.cost_price_sum ?? '-'"
                                />
                            </template>

                            <template v-if="mode === 'by_storehouse'">
                                <MobileTableInfo
                                    v-for="(storehouse) in units" :key="storehouse.id"
                                    :title="storehouse.name"
                                    :no-border="true"
                                    :body="calculateTotal(item.values, storehouse.id)"
                                />
                            </template>

                            <!-- <MobileTableButton
                                title="Переместить"
                                :no-margin="true"
                                type="primary"
                                @action="openDrawerMoving(item, item.storehouseId)"
                            />

                            <MobileTableButton
                                title="Добавить/Списать"
                                :no-margin="true"
                                type="primary"
                                @action="openDrawerNewValue(item, item.storehouseId)"
                            /> -->

                            <MobileTableButton
                                @action="openDrawerDetails(item)"
                            />
                        </template>
                    </MobileTableFramework>

                </div>

                <el-table
                    empty-text="Информация не найдена"
                    v-if="!smallMobile"
                    v-loading="loading"
                    :default-sort="{prop: 'name', order: 'ascending'}"
                    :data="displayData"
                    class="w-100 mt-15"
                    :summary-method="getSummaries"
                    show-summary>
                    <el-table-column
                        prop="name"
                        min-width="220px"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            <a class="text-none" size="small" target="_blank"
                               :href="'/settings/goods/' + scope.row.good_id"
                            >
                                <span class="textMedium textSM neutral900 cutOverflow maxLines3">{{ scope.row.name }}</span>
                            </a>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="unit"
                        label="Ед. измерения"
                        sort-by="unit"
                        width="140px"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM"><good-unit :unit="scope.row.unit"/></span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="total"
                        label="Остаток"
                        width="150px"
                        sort-by="total"
                        sortable>
                        <template v-slot="scope">
                            <span
                                :class="'textMedium textSM ' + (scope.row.total !== 0 ? (scope.row.total > 0 ? 'primary600' : 'danger300') : 'neutral400')">{{
                                    scope.row.total ? formatNumber(scope.row.total, 2) : 0
                                }}</span>
                        </template>
                    </el-table-column>

                    <template v-if="mode === 'by_product'">
                        <el-table-column
                            prop="cost_price"
                            width="150px"
                            label="Себестоимость">
                            <template v-slot="scope">
                                <span class="textMedium textSM">{{ scope.row.cost_price ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="cost_price_sum"
                            width="150px"
                            label="Сумма себестоимости">
                            <template v-slot="scope">
                                <span class="textMedium textSM">{{ scope.row.cost_price_sum ?? '-' }}</span>
                            </template>
                        </el-table-column>
                    </template>

                    <template v-if="mode === 'by_storehouse'">
                        <el-table-column v-for="(storehouse) in units" :key="storehouse.id"
                                         prop="values" :label="storehouse.name"
                                         :min-width="findStringWidth(storehouse.name, '12px Ubuntu', 30) + 'px'">
                            <template v-slot="scope">
                                <span class="textMedium neutral700">
                                    {{ calculateTotal(scope.row.values, storehouse.id) }}
                                </span>
                            </template>
                        </el-table-column>
                    </template>

                    <!-- <el-table-column
                        v-if="mode === 'by_storehouse'"
                        prop="storehouse"
                        label="Внутренние склады">
                        <template v-slot="scope">
                            <template v-for="(value, index) in scope.row.values.filter((el) => el.total !== 0)" :key="index">
                                <div class="textXS textRegular">
                                    <span class="textMedium neutral700">{{ value.storehouse ? value.storehouse.name : 'Склад по умолчанию' }}:</span> {{ formatNumber(value.total, 2) }} <good-unit :unit="scope.row.unit"/>
                                </div>
                            </template>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                        fixed="right"
                        label=""
                        width="90">
                        <template v-slot="scope">
                            <el-button
                                size="mini" plain
                                @click="openDrawerMoving(scope.row)">
                                <el-tooltip effect="dark" placement="top" content="Переместить на другой склад">
                                    <MovingIcon/>
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                        fixed="right"
                        label=""
                        width="170">
                        <template v-slot="scope">
                            <el-button
                                size="mini" type="primary" plain
                                @click="openDrawerNewValue(scope.row.good_id, scope.row.storehouseId)">Добавить/Списать
                            </el-button>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        fixed="right"
                        label=""
                        width="130">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="openDrawerDetails(scope.row)">Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            v-model="viewDetails"
            title=""
            direction="rtl"
            :size="mobile ? '100%' : '40%'"
            :destroy-on-close="true">

            <StorehouseGoodDetails
                :detail-good="detailGood"
                :units="units"
                @moduleGet="moduleGet()"
                @openEditDetails="openEditDetails"
                @openCreateDrawer="openCreateDrawerFromDetails()"
                @closeDrawerDetails="closeDrawerDetails()"
            />

        </el-drawer>

        <el-drawer
            v-model="editDetailsDrawer"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <StorehouseGoodEdit
                @moduleGet="moduleGet()"
                @closeDrawer="editDetailsDrawer=false"
                :detail-good="detailGood"
            />
        </el-drawer>

        <el-dialog
            class="storehouse-actions-dialog"
            title="Добавление операции"
            :width="dialogSize"
            :destroy-on-close="true"
            v-model="isStorehouseActionsDialogOpened"
            @close="closeStorehouseActionsDialog">
            <StorehouseActionsForm
                :storehouse-units="units"
                :goods="values"
                @update="updateActionsForm"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import tableMixin from '../../mixins/tableMixin'
import functionsMixin from '../../mixins/functionsMixin'
import basicMixin from '../../mixins/basicMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import Filter from '@/views/components/Table/Filter/Filter'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import StorehouseGoodDetails from "@/views/components/StorehousePage/StorehouseGoodDetails";
import StorehouseGoodEdit from "@/views/components/StorehousePage/StorehouseGoodEdit";
import GoodUnit from "@/views/components/goodUnit.vue";
import storehouseTabs from "@/mixins/tabs/storehouseTabs";
import storehouseInfoViewModes from "@/mixins/storehouseInfoViewModes";
import StorehouseActionsForm from "./StorehouseActionsForm";

export default {
    name: 'StorehouseInfo',
    components: {
        GoodUnit,
        StorehouseGoodEdit,
        StorehouseGoodDetails,
        TablePagination,
        MobileTableHR,
        MobileTableButton,
        MobileTableInfo,
        MobileTableFramework,
        Filter,
        TableHeadBlock,
        Auth,
        StorehouseActionsForm,
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, functionsMixin, basicMixin, mobileCheckMixin, iconsMixin, storehouseTabs,
        storehouseInfoViewModes
    ],
    data() {
        return {
            module: null,
            moduleName: null,
            values: [],
            formTypes: [],
            filter: {
                goodId: {
                    value: null,
                    type: 'select',
                    typeSelect: 'goods',
                },
            },
            isStorehouseActionsDialogOpened: false,
            editDetailsDrawer: false,
            storehouseGoods: [],
            units: [],
            viewDetails: false,
            // createGood: false,
            // movingGood: false,
            detailGood: null,
            // createGoodForm: {
            //     good_id: null,
            //     unit_id: null,
            //     action_id: null,
            //     value: null
            // },
            movingForm: {
                good_id: null,
                old_storehouse_id: null,
                new_storehouse_id: null,
                value: null, // значение инпута "Количество"
                totalInOldStorehouse: null, // количество на складе, с которого переместить
            },
            integrations: [],
            relations: [],
            pageSize: 30,
            loadingMoving: false,
            totalInputActive: false,
            correct_old_storehouses: [],
            correct_new_storehouses: [],
            selectedGood: null,
            mode: 'by_product',
        }
    },
    created() {
        this.moduleGet(true)
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 60000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        moduleGet(showLoading) {
            if (showLoading) this.loadingButton = true
            httpService().post(this.api.storehouse.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse',
                mode: this.mode,
                filter: this.filter
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.loadingTitle = false
                    this.module = data.module
                    this.moduleName = data.module.name
                    this.values = data.module.values
                    this.valueLength = this.values.length
                    this.formTypes = data.module.formTypes
                    this.units = data.module.units
                    this.drivers = data.drivers
                    this.companies = data.companies
                    this.storehouseGoods = data.goods
                    this.integrations = data.integrations
                    this.relations = data.relations
                    if (this.detailGood) this.detailGood = this.values.find(el => el.id === this.detailGood.id)
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        openDrawerDetails(row) {
            this.detailGood = row
            this.viewDetails = true
        },
        closeDrawerDetails() {
            this.viewDetails = false;
        },
        openCreateDrawerFromDetails() {
            this.viewDetails = false
            // this.openDrawerNewValue(this.detailGood.good_id, this.detailGood.storehouseId)
        },
        // openDrawerNewValue(goodId, storehouseId) {
        //     if (goodId) {
        //         this.createGoodForm.good_id = goodId
        //     } else {
        //         this.createGoodForm.good_id = ''
        //     }

        //     if (storehouseId) {
        //         this.createGoodForm.unit_id = storehouseId
        //     } else {
        //         this.createGoodForm.unit_id = null
        //     }
        //     this.createGood = true
        // },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        // openDrawerMoving(good) {
        //     this.loadingMoving = true
        //     //Получаем только те склады, где есть продукция
        //     httpService().post(this.api.storehouse.correctStorehouseMoving, {
        //         account_module_reserve_id: this.$route.params.id,
        //         module_key: 'storehouse',
        //         good_id: good.good_id
        //     }).then((response) => {
        //         this.correct_old_storehouses = response.data.storehouses
        //     }).catch((error) => {
        //         this.$message({
        //             message: error.response.data.message,
        //             showClose: true,
        //             type: 'error'
        //         })
        //         this.correct_old_storehouses = this.units
        //     })
        //     this.movingForm.name = good.name
        //     if (good.good_id) {
        //         this.movingForm.good_id = good.good_id
        //     } else {
        //         this.movingForm.good_id = ''
        //     }
        //     this.loadingMoving = false
        //     this.movingGood = true
        //     this.selectedGood = good
        // },
        // movingGoodSubmit() {
        //     this.loadingMoving = true
        //     httpService().post(this.api.storehouse.changeStorehouse, {
        //         account_module_reserve_id: this.$route.params.id,
        //         module_key: 'storehouse',
        //         details: this.movingForm
        //     }).then((response) => {
        //         let data = response.data
        //         if (data.success) {
        //             this.$message({
        //                 message: 'Информация сохранена',
        //                 showClose: true,
        //                 type: 'success'
        //             })
        //         }
        //         this.movingGood = false
        //         this.loadingMoving = false
        //         this.moduleGet()
        //     }).catch((error) => {
        //         this.$message({
        //             message: error.response.data.message,
        //             showClose: true,
        //             type: 'error'
        //         })
        //         this.loadingMoving = false
        //     })
        // },
        // movingGoodEsc(){
        //     this.movingGood = false
        // },
        // closeDrawerCreateGood() {
        //     this.createGood = false
        // },
        getSummaries() {
            const total = this.displayData.reduce((prevVal, currVal) => prevVal + +currVal.total, 0)
            return ['Итого', null, this.formatNumber(total, 2) + '  ед.'];
        },
        openEditDetails(details) {
            this.viewDetails = false
            this.detailGood = details
            this.editDetailsDrawer = true
        },
        getOldStorehouseList() {
            const valuesWithoutZero = this.selectedGood?.values.filter((el) => el.total)

            return this.correct_old_storehouses.filter((val) =>
                valuesWithoutZero.find((el) => el.storehouse.id === val.id));
        },
        getNewStorehouseList() {
            // Кладем всё исключая склад, с которого берем
            this.movingForm.new_storehouse_id = null
            this.correct_new_storehouses = this.units.filter(unit => unit.id !== this.movingForm.old_storehouse_id)
        },
        getMaxValue() {
            return this.selectedGood.values.find(value => value?.storehouse?.id === this.movingForm?.old_storehouse_id)?.total ?? 0;
        },
        closeMovingGoodDialog() {
            this.movingForm = {
                good_id: null,
                old_storehouse_id: null,
                new_storehouse_id: null,
                value: null,
                totalInOldStorehouse: null,
            };
            this.totalInputActive = false;
            this.correct_old_storehouses = [];
            this.correct_new_storehouses = [];
            this.selectedGood = null;
        },
        calculateTotal(products, storehouseId) {
            const productByStorehouseId = products.find((p) => p.storehouseId === storehouseId);

            if (!productByStorehouseId || productByStorehouseId.total == 0) {
                return '-'
            }

            return `${this.formatNumber(productByStorehouseId.total, 2)}`
        },
        handleDetailsDialogClose() {
            this.storehouseActionsDialog = false;
        },
        openStorehouseActionsDialog() {
            this.isStorehouseActionsDialogOpened = true;
        },
        closeStorehouseActionsDialog() {
            this.isStorehouseActionsDialogOpened = false;
        },
        updateActionsForm() {
            this.moduleGet();
            this.isStorehouseActionsDialogOpened = false;
        },
    },
    watch: {
        // 'movingForm.old_storehouse_id': {
        //     handler: function (val) {
        //         if(val === null) {
        //             this.movingForm.totalInOldStorehouse = null
        //             return
        //         }
        //         if(val === undefined) {
        //             this.movingForm.totalInOldStorehouse = null
        //             this.movingForm.old_storehouse_id = null
        //             this.movingForm.new_storehouse_id = null
        //             this.totalInputActive = false
        //         } else {
        //             this.getNewStorehouseList()
        //             this.movingForm.totalInOldStorehouse = this.getMaxValue();
        //             if(this.totalInputActive) {
        //                 this.movingForm.value = this.getMaxValue();
        //             }
        //         }

        //     },
        //     deep: true,
        // },
        // 'movingForm.new_storehouse_id': {
        //     handler: function () {
        //         if(this.movingForm.new_storehouse_id === undefined) {
        //             this.movingForm.new_storehouse_id = null
        //         }
        //     },
        //     deep: true,
        // },
        'totalInputActive': {
            handler() {
                this.totalInputActive ? this.movingForm.value = this.getMaxValue() : this.movingForm.value = null
            }
        },
        'movingGood': {
            handler: function () {
                if (!this.movingGood) {
                    this.correct_old_storehouses = []
                }
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) this.moduleGet(true)
        },
        mode() {
            this.moduleGet();
        },
    },
}
</script>

<style scoped>

</style>
