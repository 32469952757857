<template>
    <div class="visual-group" v-if="groups.length > 0">
    <span
        v-for="(group, index) in groups"
        :key="index"
        class="group-part"
        @click="copyToClipboard"
    >
      {{ group }}
      <span
          v-if="showSeparator(index)"
          class="separator"
      ></span>
    </span>
    </div>
</template>

<script>
export default {
    name: "SharedCode",
    props: {
        value: {
            type: [String, Number, null],
            default: null
        },
    },
    computed: {
        normalizedValue() {
            if (this.value === null || this.value === undefined) return ''
            return String(this.value).replace(/\s/g, '')
        },
        groups() {
            const chunks = []
            const str = this.normalizedValue

            for (let i = 0; i < str.length; i += 3) {
                chunks.push(str.slice(i, i + 3))
            }

            return chunks
        },
    },
    methods: {
        showSeparator(index) {
            return index < this.groups.length - 1 && this.groups.length > 1
        },
        async copyToClipboard() {
            if (!this.normalizedValue) return

            await navigator.clipboard.writeText(this.normalizedValue)
            this.$message({
                message: 'Номер скопирован в буфер обмена',
                type: 'success'
            })
        },
    }
}
</script>

<style>
.visual-group {
    display: inline-flex;
    align-items: center;
    font-family: monospace;
    line-height: 1;
}

.group-part {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.separator {
    display: inline-block;
    height: 1em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    opacity: 0.5;
    mask-image: linear-gradient(
        to bottom,
        transparent 30%,
        currentColor 50%,
        transparent 70%
    );
}
</style>
