<template>
    <el-form-item :label="label" :label-width="labelWidth" v-bind:class="[extraClass, { 'is-required': required }]">
        <div class="inputModal"
             :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
            <div class="d-flex">
                <div class="inputModalWrapper" @click="openSelectContractDialog">
                    {{ itemId ? getInfo(this.itemId, this.values) : this.placeholder }}
                </div>
                <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                    <i class="el-icon el-input__icon el-input__clear">
                        <CloseRoundIcon/>
                    </i>
                </div>
            </div>
        </div>
    </el-form-item>


    <Dialog v-model="selectContractDialog" title="Выберите документ" :width="dialogSize" :destroy-on-close="true">
        <template #default v-if="loadingData">
            <TableDataLoader :text="documentType === 'contract' ? 'договоров' : 'счетов'"/>
        </template>

        <template #header v-if="!loadingData">
            <TableHeadBlock
                v-model="search"
                :loading="loading"
                :disable-filter="true"
                :disable-update-button="true"
                :search-focused="true"
                create-button-label="Добавить документ"
                @moduleGet="getContractsData()"
                @openCreateValueDrawer="() => createDocumentDialog = true"
            />
        </template>

        <template #body v-if="!loadingData">
            <el-table
                empty-text="Информация не найдена"
                class="mt-15 w-100 defaultTableHeight"
                v-loading="loadingTable"
                @current-change="handleCurrentChange"
                :data="displayData">

                <el-table-column
                    prop="id"
                    label="#"
                    width="100"
                    class-name="pointer"
                    sortable>
                    <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="Название"
                    class-name="pointer"
                    sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium neutral900 cutOverflow">{{ scope.row.name ?? '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="date"
                    label="Дата"
                    class-name="pointer"
                    width="140"
                >
                    <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date ? getDateFormDB(scope.row.date, 'date') : '-'
                                }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="company_id"
                    label="Контрагент"
                    v-if="!companyId"
                    class-name="pointer"
                >
                    <template v-slot="scope">
                            <span class="textRegular neutral300 cutOverflow">{{
                                    scope.row.company_id ? scope.row.company?.name : '-'
                                }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="Сумма"
                    class-name="pointer"
                    width="200"
                >
                    <template v-slot="scope">
                            <span class="textSM textMedium success400">{{
                                    (scope.row.total && scope.row.total > 0) ? (formatNumber(scope.row.total) + ' руб.') : '-'
                                }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="integration"
                    label="Интеграция"
                >
                  <template v-slot="scope">
                        <template v-if="scope.row.integration_unit_id">
                            <IntegrationLogo
                              :integration-unit-id="scope.row.integration_unit_id"
                            />
                        </template>
                        <template v-else>
                            <span class="textRegular textSM neutral200">-</span>
                        </template>
                  </template>
                </el-table-column>
            </el-table>
        </template>

        <template #footer v-if="!loadingData">
            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                :loading="loading"
                @action="setPage"
            />
        </template>
    </Dialog>

    <CRMCreateAndEditContractDialog
        v-model="createDocumentDialog"
        v-if="createDocumentDialog"
        :company-id="companyId"
        @update="getContractsData()"
        @close="handleCreateDialogClose"
        @create="handleAddContract"
    />

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";
import CRMCreateAndEditContractDialog from "@/views/crm/components/CRMCreateAndEditContractDialog.vue";
import Dialog from "@/views/components/Dialog.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";

export default {
    name: 'FormSelectDocuments',
    components: {
        TableDataLoader,
        TableHeadBlock,
        TablePagination,
        CloseRoundIcon,
        CRMCreateAndEditContractDialog,
        Dialog,
        IntegrationLogo
    },
    mixins: [functionsMixin, mobileCheckMixin, tableMixin],
    inject: ['api'],
    props: [
        'modelValue',
        'label',
        'labelWidth',
        'placeholder',
        'loadFromStore',
        'labelKey',
        'loading',
        'newItem',
        'disabled',
        'companyType',
        'companyId', // id контрагента
        'required',
        'documentType',
        'contractId',
        'integrationUnitId', // фильтр по интеграции,
        'extraClass', // дополнительные классы для стилизации,
        'disableCreateButton' // cкрытие кнопки добавления нового элемента в модалке
    ],
    data() {
        return {
            itemId: this.modelValue,
            loadingButton: false,
            dialog: false,
            loadingData: true,
            loadingTable: false,
            selectContractDialog: false,
            createDocumentDialog: false,
        }
    },
    created() {
    },
    methods: {
        getLabel(item) {
            if (this.labelKey) {
                let label = this.deepFind(item, this.labelKey);
                if (label) {
                    return label
                }
            }
            if (!this.loadFromStore) {
                return item.name
            }
        },
        handleAddContract(val) {
            if (val) {
                this.selectItem(val.id)
                if (this.items) {
                    this.$emit('update')
                } else this.getContractsData()
            }
            this.selectContractDialog = false
        },
        handleCurrentChange(val) {
            if (val) {
                this.selectItem(val.id)
            }
            this.selectContractDialog = false
        },
        openSelectContractDialog() {
            if (!this.disabled) {
                this.selectContractDialog = false
                this.search = null
                this.page = 1
                if (this.page === 1 && this.values.length === 0) {
                    this.getContractsData()
                }
                this.selectContractDialog = true
            }
        },
        getContractsData() {
            this.loadingTable = true
            httpService().post(this.api.document.getAll, {
                paginate: false,
                search: this.search,
                companyId: this.companyId,
                type: this.documentType,
                all: true,
                simple: true,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.items
                    this.valueLength = data.items.length
                    this.loadingData = false
                    this.loadingTable = false
                }
            })
        },
        selectItem(id) {
            this.itemId = id;
            this.$emit('update:modelValue', id);
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        clearItemId() {
            this.itemId = null
            this.$emit('clearAction')
        },
        handleCreateDialogClose() {
            this.createDocumentDialog = false
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemId = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemId': {
            handler: function () {
                this.$emit('update:modelValue', this.itemId)
                if (this.itemId) {
                    this.values.sort((a, b) => {
                        if (a.id === this.itemId) return -1;
                        if (b.id === this.itemId) return 1;
                        return 0;
                    });
                }
            },
            deep: true,
            immediate: true
        },
        'companyId': {
            handler: function (val) {
                if(val) {
                    this.getContractsData()
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
