<template>
    <div>
        <h3>Добавление нового товара</h3>
        <el-form ref="form" :model="createGoodForm" label-position="top">
            <FormInput
                v-model="createGoodForm.name"
                label="Название"
                placeholder="Название товара"
            />
            <el-form-item label="Тип товара">
                <el-select v-model="createGoodForm.type"
                           placeholder="Выберите тип товара">
                    <el-option
                        v-for="type in goodTypes"
                        :key="type.key"
                        :label="type.name"
                        :value="type.key"/>
                </el-select>
            </el-form-item>

            <el-form-item label="Ед. измерения">
                <el-select v-model="createGoodForm.unit"
                           placeholder="Выберите единицу измерения">
                    <el-option
                        v-for="unit in units"
                        :key="unit.id"
                        :label="unit.short_name"
                        :value="unit.id">
                        <span style="float: left">{{ unit.short_name }}</span>
                        <span
                            style="float: right; color: #a2a5bf; font-size: 13px;">{{
                                unit.name
                            }}</span
                        >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Интеграция, к которой привязан">
                <el-select v-model="createGoodForm.integrationId"
                           placeholder="Выберите, если необходимо">
                    <el-option
                        v-for="item in integrations"
                        :key="item.id"
                        :label="item.integration.key"
                        :value="item.id">
                        <span style="float: left">{{ item.integration.key }}</span>
                        <span
                            style="float: right; color: #a2a5bf; font-size: 13px;">#{{
                                item.id
                            }}</span
                        >
                    </el-option>
                </el-select>
            </el-form-item>

            <FormInput
                v-model="createGoodForm.softId"
                v-if="createGoodForm.integrationId"
                label="ID в программе интеграции"
                placeholder="ID"
            />

            <el-form-item label="Описание">
                <el-input type="textarea" :rows="2" id="comment" name="comment" v-model="createGoodForm.description"
                          placeholder="Дополнительное описание"></el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="footer">
        <el-button :loading="loadingButton" type="primary" class="w-100"
                   @click.prevent="doCreateGood">Сохранить
        </el-button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'
import goodTypes from "@/mixins/goodTypes";
import FormInput from "@/views/components/Form/FormInput.vue";

export default {
    name: 'GoodsCreateTemplate',
    components: {FormInput },
    mixins: [goodTypes],
    data() {
        return {
            loading: false,
            loadingButton: false,
            createGoodForm: {
                name: '',
                description: '',
                sku: '',
                unit: '',
                components: [],
                info: [],
                type: 'good',
                accountModuleId: null,
                integrationId: null
            },
            integrations: [],
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.getIntegrations()
    },
    computed: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        })
    },
    methods: {
        getIntegrations() {
            this.loading = true
            httpService().post(this.api.settings.getIntegrations).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.integrations = data.integrations
                }
            })
        },
        doCreateGood() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.good.create, {
                module: this.createGoodForm,
            }).then((response) => {
                if (response.data.success === false) {
                    this.$message({
                        message: response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                    return
                }

                this.createGoodForm = {
                    name: '',
                    description: '',
                    sku: '',
                    price: '',
                    price_count: '',
                    unit: '',
                    components: [],
                    info: [],
                    type: 'good',
                    accountModuleId: null,
                    integrationId: null
                }
                this.$message({
                    message: 'Товар добавлен',
                    showClose: true,
                    type: 'success'
                })
                this.pullGoods.then(() => {
                    this.$emit('moduleGet')
                    this.$emit('updateGoods')
                    this.$emit('closeDrawer')
                })
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            })
            this.loading = false
            this.loadingButton = false
        },
    }
}
</script>

<style scoped>
.buttonAdd {
    width: 100%;
}
</style>
