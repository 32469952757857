<template>
    <svg
        :class="{ currentColor: currentColor }"
        :width="width ?? 20"
        :height="height ?? 20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M14.1656 7.64223C14.0095 7.48702 13.7983 7.3999 13.5781 7.3999C13.358 7.3999 13.1467 7.48702 12.9906 7.64223L9.99894 10.5922L7.04894 7.64223C6.8928 7.48702 6.68159 7.3999 6.46144 7.3999C6.24129 7.3999 6.03008 7.48702 5.87394 7.64223C5.79583 7.7197 5.73384 7.81187 5.69153 7.91342C5.64922 8.01496 5.62744 8.12389 5.62744 8.2339C5.62744 8.34391 5.64922 8.45283 5.69153 8.55438C5.73384 8.65593 5.79583 8.74809 5.87394 8.82556L9.40727 12.3589C9.48474 12.437 9.57691 12.499 9.67846 12.5413C9.78001 12.5836 9.88893 12.6054 9.99894 12.6054C10.1089 12.6054 10.2179 12.5836 10.3194 12.5413C10.421 12.499 10.5131 12.437 10.5906 12.3589L14.1656 8.82556C14.2437 8.74809 14.3057 8.65593 14.348 8.55438C14.3903 8.45283 14.4121 8.34391 14.4121 8.2339C14.4121 8.12389 14.3903 8.01496 14.348 7.91342C14.3057 7.81187 14.2437 7.7197 14.1656 7.64223Z"
        />
        <path
            d="M14.1656 7.64223C14.0095 7.48702 13.7983 7.3999 13.5781 7.3999C13.358 7.3999 13.1467 7.48702 12.9906 7.64223L9.99894 10.5922L7.04894 7.64223C6.8928 7.48702 6.68159 7.3999 6.46144 7.3999C6.24129 7.3999 6.03008 7.48702 5.87394 7.64223C5.79583 7.7197 5.73384 7.81187 5.69153 7.91342C5.64922 8.01496 5.62744 8.12389 5.62744 8.2339C5.62744 8.34391 5.64922 8.45283 5.69153 8.55438C5.73384 8.65593 5.79583 8.74809 5.87394 8.82556L9.40727 12.3589C9.48474 12.437 9.57691 12.499 9.67846 12.5413C9.78001 12.5836 9.88893 12.6054 9.99894 12.6054C10.1089 12.6054 10.2179 12.5836 10.3194 12.5413C10.421 12.499 10.5131 12.437 10.5906 12.3589L14.1656 8.82556C14.2437 8.74809 14.3057 8.65593 14.348 8.55438C14.3903 8.45283 14.4121 8.34391 14.4121 8.2339C14.4121 8.12389 14.3903 8.01496 14.348 7.91342C14.3057 7.81187 14.2437 7.7197 14.1656 7.64223Z"
            fill="#505968"
        />
    </svg>
</template>

<script>
export default {
    name: "ArrowDownIcon",
    props: ["currentColor", "width", "height"],
};
</script>

<style scoped>
.currentColor path {
    stroke: currentColor;
}
</style>
