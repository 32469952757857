<template>
    <el-dialog
        class="custom-dialog"
        :width="width" :title="title"
        :destroyOnClose="destroyOnClose"
        :append-to-body='appendToBody'
        v-model="show"
        @close="closeModal"
        @close-on-click-modal="closeModal"
    >
        <div class="custom-dialog__content">
            <slot name="default">
                <div class="custom-dialog__header">
                    <slot name="header"/>
                </div>
                <div class="custom-dialog__body">
                    <slot name="body"/>
                </div>
                <div class="custom-dialog__footer">
                    <slot name="footer"/>
                </div>
            </slot>
        </div>
    </el-dialog>
</template>

<script>
import functionsMixin from "../../mixins/functionsMixin";

export default {
    name: "Dialog",
    props: {
        value: Boolean,
        title: String,
        width: String,
        destroyOnClose: Boolean,
        appendToBody: Boolean
    },
    mixins: [functionsMixin],
    data() {
        return {
            show: false,
        }
    },
    watch: {
        modelValue: {
            handler: function (show) {
                if (show != undefined) {
                    this.$nextTick(() => {
                        this.show = this.modelValue;
                        this.blockScrollWhileModalOpened(show);
                    })
                }
            },
            deep: true,
            immediate: true
        },
        'show': {
            handler: function () {
                this.$emit('update:modelValue', this.show)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        closeModal() {
            this.$emit('onClose');
            this.show = false
        }
    }
}
</script>

<style scoped lang="scss">
@use "@/sass/_mixins.scss";

.custom-dialog {
    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__body {
        @include mixins.customScrollBarStyles;

        & {
            height: 40svh;
            overflow: auto;
        }

        @media screen and (max-height: 1079px) {
            height: 45svh;
        }

        @media screen and (max-height: 920px) {
            height: 50svh;
        }

        @media screen and (max-height: 820px) {
            height: 55svh;
        }

        @media screen and (max-height: 768px) {
            height: 60svh;
        }
    }
}
</style>

<style lang="scss">
.custom-dialog {
    margin-block: 5svh;
    max-height: 90svh;
    overflow: auto;
}
</style>
