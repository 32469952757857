<template>
    <div v-if="order">
        <div class="d-flex mb-15" style="gap: 10px">
            <div class="operatorInfoBlock" style="flex-grow: 1; flex-basis: 0; max-width: 65%">
                <div class="operatorInfoBlockBody">
                    <div class="textMD textRegular neutral400 mr-5">
                        Рецепт:
                    </div>
                    <div class="textLG textMedium neutral900 cutOverflow">
                        {{ order.good ?? (order.spec_good_name ?? order.order_good_name) }}
                    </div>
                </div>
            </div>
            <div class="operatorInfoBlock" style="flex-grow: 1; flex-basis: 0; max-width: 100%">
                <div class="operatorInfoBlockBody">
                    <div class="textMD textRegular neutral400 mr-5">
                        Объем:
                    </div>
                    <div class="textLG textMedium neutral900 cutOverflow">
                        {{ formatNumber(order.total, 2) }} м³
                    </div>
                </div>
            </div>
            <div class="operatorInfoBlock" style="flex-grow: 1; flex-basis: 0; max-width: 100%">
                <div class="operatorInfoBlockBody">
                    <div class="textMD textRegular neutral400 mr-5">
                        ТС:
                    </div>
                    <div class="textLG textMedium neutral900 cutOverflow">
                        {{ order.vehicle ? getFormattedVehicleNumber(order.vehicle) : '-' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="operatorInfoBlock mb-10">
            <div class="operatorInfoBlockBody">
                <div class="textMD textRegular neutral400 mr-5">
                    Контрагент:
                </div>
                <div class="textLG textMedium neutral900 cutOverflow">
                    {{ order.company_name ?? '-' }}
                </div>
            </div>
        </div>

        <div class="operatorInfoBlock">
            <div class="operatorInfoBlockBody">
                <div class="textMD textRegular neutral400 mr-5">
                    Комментарий оператору:
                </div>
                <div class="textLG textMedium neutral900 cutOverflow">
                    {{ order.comment ?? '-' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import mixOrderInfo from "@/mixins/mixOrderInfo";

export default {
    name: "OperatorOrderDetails",
    props: ['order'],
    mixins: [functionsMixin, mixOrderStatuses, mixOrderInfo]
}
</script>

<style scoped>

</style>
