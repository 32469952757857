<template>
    <div>
            <div class="d-flex-full">
                <el-input
                    class="searchNew w-100 mr-10"
                    v-model="search"
                    :prefix-icon="icons.search"
                    placeholder="Поиск" clearable/>
                <el-button
                    type="info"
                    @click="clearMixes"
                    :icon="icons.remove"
                    plain
                    style=" height: 46px; width: 46px; border-radius: 8px;"/>
            </div>
            <el-table
                :data="displayData"
                @row-click="handleRowClick"
                class="w-100 mt-15 mb-15 scrollTable selectDialogMixes">
                <el-table-column
                    prop="name"
                    label=""
                >
                    <template v-slot="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label=""
                    prop="active"
                    width="60px">
                    <template v-slot="scope">
                        <el-checkbox class="selectDialogMixes__checkbox" size="large" @change="handleRowClick(scope.row)" v-model="scope.row.active"/>
                    </template>
                </el-table-column>
            </el-table>

            <TablePagination
                class="pagination"
                v-if="valueLength > 10"
                v-model="pageSize"
                :total="valueLength"
                @action="setPage"
            />

        <el-button @click="changeMixes" size="large" type="primary">
            Подтвердить выбор
        </el-button>
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";

export default {
    name: 'SelectDialogMixes',
    components: {TablePagination},
    props: ['items','selected'],
    mixins: [tableMixin, iconsMixin],
    data() {
        return {
            addNewDriverForm: false,
            form: {
                name: null,
                phone: null
            },
        }
    },
    created() {
        let mixers = [];
        let mixes = JSON.parse(JSON.stringify(this.items))

        mixes.forEach((i)=>{

            mixers = [...mixers, ...i.mixers.map((mixer) => {
                mixer.name = this.tableTitle(i, mixer)
                mixer.active = !!this.selected.mixers.find((m) => m === mixer.id)
                return mixer
            })]
        })

        this.values = mixers
    },
    methods: {
        changeMixes() {
            let arr = this.values.filter(el => el.active)
            if (arr.length > 0) {
                this.$emit('changeMixes', arr)
            } else {
                this.$message({
                    message: 'Необходимо выбрать заводы',
                    showClose: true,
                    type: 'error'
                })
            }
        },
        clearMixes() {
            this.$emit('changeMixes', [])
        },
        tableTitle(mix, mixer){
            if (mix?.mixers.length > 1) return mix.name + ' - ' + mixer.name
            return mix.name
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleRowClick(row) {
            row.active = !row.active;
        },
    }
}
</script>

<style scoped lang="scss">
    .selectDialogMixes {
        &:deep(.el-table__row) {
            cursor: pointer;

            &:hover {
                &, .el-table__cell {
                    background: #f9f9f9;
                    transition: all .3s;
                }
            }
        }
    }

    .pagination {
        &:deep(.tablePagination__select) {
            top: 0;

            .el-select__wrapper {
                height: 32px !important;
            }
        }

        &:deep(.tablePagination__pagination) {
            margin-top: 0;
        }
    }
</style>
