<template>
    <div>
        <div class="mb-10 grid-gap-20 d-flex-full">
            <el-input v-model="accountId" placeholder="ID аккаунта"/>
            <el-input v-model="integrationUnitId" placeholder="ID интеграции"/>
            <el-input type="date" v-model="date" placeholder="Дата"/>
        </div>
        <div class="mb-10"><el-button @click="doAction('goods-duplicate-delete')">Удалить дубляж в товарах</el-button></div>
        <div class="mb-10"><el-button @click="doAction('goods-duplicate-delete-soft-id')">Удалить дубляж в товарах по софт ид</el-button></div>
        <div class="mb-10"><el-button @click="doAction('command-median-mixing-time')">Запустить медианное время замешивание с ошибками в сентри</el-button></div>
        <div class="mb-10"><el-button @click="doAction('storehouse-recalculate')">Запустить пересчет для склада всех товаров и операций с выбранного дня</el-button></div>
    </div>
</template>

<script>
import {httpService} from '@/services/http.service'

export default {
    name: 'GodModeFunctions',
    data() {
        return {
            loading: false,
            accountId: null,
            integrationUnitId: null,
            date: null,
        }
    },
    inject: ['api'],
    methods: {
        doAction(functionName) {
            this.loading = true

            httpService().post(this.api.admin.function, {
                function: functionName,
                accountId: this.accountId,
                integrationUnitId: this.integrationUnitId,
                date: this.date,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    console.log(data.message);
                    this.$message({
                        message: 'Успешно',
                        showClose: true,
                        type: 'success'
                    })
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>
