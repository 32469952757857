<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle :title="good.name"/>

            <el-form ref="form" :model="detailsMixGoodForm" size="small" label-position="top">

                <FormInput
                    v-model="detailsMixGoodForm.name"
                    label="Название рецепта"
                    placeholder="Введите название рецепта"
                />

                <FormSelect
                    v-model="detailsMixGoodForm.category"
                    label="Категория"
                    placeholder="Выберите категорию"
                    :items="categories"
                />

                <FormSelect
                    v-model="detailsMixGoodForm.unit"
                    label="Ед. измерения"
                    placeholder="Выберите единицу измерения"
                    :items="units"
                />


                <GoodsCreateComponent
                    @components="getComponents"
                    :goods="goods"
                    :good-components="good.goods"
                />

            </el-form>
        </template>
        <template v-slot:footer>
            <el-tooltip content="Укажите количество для всех компонентов" :disabled='!isSaveButtonDisabled'>
                <DrawerCreateButton :disabled='isSaveButtonDisabled' title="Сохранить изменения" @action="updateGood"/>
            </el-tooltip>
            <DrawerDeleteButton :loading="loading" @action="removeGood"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import FormInput from '@/views/components/Form/FormInput'
import FormSelect from '@/views/components/Form/FormSelect'
import GoodsCreateComponent from '@/views/components/SettingsPage/GoodsPage/GoodsCreateComponent'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import {mapState, mapActions} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import _ from 'lodash'

export default {
    name: 'MixGoodDetails',
    props: [
        'good',
        'categories'
    ],
    components: {
        DrawerDeleteButton,
        DrawerCreateButton, GoodsCreateComponent, FormSelect, FormInput, DrawerHeadTitle, DrawerFramework
    },
    data() {
        return {
            loading: false,
            detailsMixGoodForm: {
                id: this.good.id,
                goodId: this.good.goodId,
                name: this.good.name,
                category: this.good.category,
                unit: this.good.unit,
                components: this.good.components,
            },
        }
    },
    inject: [
        'api'
    ],
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        }),
        isSaveButtonDisabled() {
            if(!this.detailsMixGoodForm.components || !this.detailsMixGoodForm.components.length) return false

            return !this.detailsMixGoodForm?.components.every((el) => el.total)
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        getComponents({components}) {
            this.detailsMixGoodForm.components = _.cloneDeep(components)
        },
        updateGood() {
            this.loading = true
            httpService().post(this.api.good.update, {
                module: {
                    ...this.detailsMixGoodForm,
                    id: this.detailsMixGoodForm.goodId,
                    name: this.detailsMixGoodForm.name,
                    short_name: null,
                    unit: this.detailsMixGoodForm.unit,
                    category: this.detailsMixGoodForm.category,
                    components: this.detailsMixGoodForm.components,
                }
            }).then((response) => {
                let answer = response.data

                if (answer.success) {
                    this.$message({
                        message: 'Рецепт обновлен',
                        showClose: true,
                        type: 'success'
                    })

                    this.$emit('moduleGet')
                    this.$emit('closeDrawer')

                    this.pullGoods()
                    this.loading = false
                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        removeGood() {
            this.loading = true

            this.$confirm(
                'Вы действительно хотите удалить рецепт?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.mix.removeGood, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'mix',
                        goodId: this.detailsMixGoodForm.id
                    }).then((response) => {
                        let answer = response.data

                        if (answer.success)
                            this.$message({
                                message: 'Рецепт удален',
                                showClose: true,
                                type: 'success'
                            })

                        this.pullGoods.then(() => {
                            this.$emit('moduleGet')
                            this.$emit('closeDrawer')
                        })
                        this.loading = false
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
