<template>
    <GuideTitle text="Складской учёт - операции"/>

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='В разделе представлены все операции за период, указанный в фильтре'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/0d57609e-6bca-4731-92ba-a6f97805fdac/File.jpeg?tl_px=0,0&br_px=1965,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=343,239">
            </GuideStep>
            <GuideStep number="2" text='Операцию можно удалить - остаток пересчитается'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/0cc05729-e91e-4465-8b28-841e7eaafe42/File.jpeg?tl_px=1842,0&br_px=3808,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1024,237">
            </GuideStep>
            <GuideStep number="3" text='Удаление необходимо подтвердить или отменить'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/ef92ddab-fd20-4651-8728-3ad3d5398886/File.jpeg?tl_px=1039,420&br_px=3004,1519&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>
            <GuideStep number="4" text='Если есть интеграция с модулями Бетонный завод или Производство, списание и зачисление продукции будет происходить автоматически, если такая продукция есть на складе'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/b1b76b17-55a1-4262-b41b-0aa0a4c94280/File.jpeg?tl_px=1056,0&br_px=3808,1538&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=769,193">
            </GuideStep>
        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from "@/views/components/Guide/GuideTitle.vue";
import GuideSteps from "@/views/components/Guide/GuideSteps.vue";
import GuideStep from "@/views/components/Guide/GuideStep.vue";

export default {
    name: "StorehouseFaqInfo",
    components: { GuideTitle, GuideStep, GuideSteps },
};
</script>

<style scoped></style>
