<template>
    <div
        class="gantt-task"
        :class="taskClasses"
            :style="getTaskStyle(task)"
    >
        <div class="d-flex gantt-task__line-container">
            <div class="gantt-task__line" :style="style"
                 v-for="(style, index) in getLineStyle(task)" :key="index"></div>
        </div>
        <div class="gantt-task__content" :style="addInnerPadding(getTaskStyle(task))">
            <div class="gantt-task__content-time">
                <el-tooltip
                    content="Плановое время прибытия"
                    :show-after="200"
                    :hide-after="100"
                >
                    <span>{{ getTime(task.arrive) }}</span>
                </el-tooltip>
                <span> (</span>
                <el-tooltip
                    content="Плановое время загрузки"
                    :show-after="200"
                    :hide-after="100"
                >
                    <span>{{ getTime(task.start) }}</span>
                </el-tooltip>
                -
                <el-tooltip
                    content="Плановое время окончания"
                    :show-after="200"
                    :hide-after="100"
                >
                    <span>{{ getTime(task.end) }}</span>
                </el-tooltip>
                <span>)</span>
            </div>
            <div class="gantt-task__content-vehicle">
                {{ task.total }} м³
                <el-tooltip :show-after="200" :hide-after="100">
                    <template #content>
                        <span v-if="task.order_group_id !== 0">Заказ #{{ task.order_group_id }}</span>
                        <span v-else>Текущий заказ</span>
                        <span v-if="task.order_id"> / Отгрузка #{{ task.order_id }}</span>
                    </template>
                    <span>{{ task.company_name }}</span>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import functionsMixin from "../../mixins/functionsMixin";

export default {
    name: "SelfGanttTask",
    mixins: [functionsMixin],
    props: {
        from: String,
        to: String,
        tz: {
            type: String,
            default: 'UTC' // Часовой пояс по умолчанию
        },
        task: Object,
        currentTask: Boolean,
    },
    computed: {
        taskClasses() {
            const classes = ['gantt-task'];

            const isCurrentOrderGroupTask = this.currentTask?.order_group_id === this.task.order_group_id;

            if (this.task.color !== 'gray' && (!this.currentTask || isCurrentOrderGroupTask)) {
                classes.push(`gantt-task--${this.task.color}`);
            }

            if (isCurrentOrderGroupTask) {
                classes.push('gantt-task--highlight');
            }

            if (this.currentTask?.order_id === this.task.order_id) {
                classes.push('gantt-task--current-task');
            }

            return classes;
        },
    },
    methods: {
        getTaskStyle(task) {
            const start = moment.tz(this.from, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone);
            // Время начала и окончания задачи
            const taskStart = moment.tz(task.start, 'YYYY-MM-DD HH:mm', this.timezone);
            const taskEnd = moment.tz(task.end, 'YYYY-MM-DD HH:mm', this.timezone);
            // Всего минут в диапазоне времени на диаграмме
            const totalMinutes = end.diff(start, 'minutes');
            // Длительность задачи в минутах
            const taskDurationMinutes = taskEnd.diff(taskStart, 'minutes');
            // Смещение задачи от начала временного диапазона
            const offsetMinutes = taskStart.diff(start, 'minutes');
            // Вычисляем стиль для правильного отображения задачи

            return {
                left: `${(offsetMinutes / totalMinutes) * 100}%`,
                width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
            };
        },
        addInnerPadding(task) {
            const {left} = task;
            if (left[0] === '-') {
                return {
                    'margin-left': 'auto',
                    'text-align': 'right',
                }
            }
            return '0'
        },
        getLineStyle(task) {
            const dayEnd = moment.tz(this.to, 'YYYY-MM-DD HH:mm', this.timezone)

            const start = moment.tz(task.start, 'YYYY-MM-DD HH:mm', this.timezone);
            const load = moment.tz(task.load, 'YYYY-MM-DD HH:mm', this.timezone);
            const arrive = moment.tz(task.arrive, 'YYYY-MM-DD HH:mm', this.timezone);
            const unload = moment.tz(task.unload, 'YYYY-MM-DD HH:mm', this.timezone);
            const end = moment.tz(task.end, 'YYYY-MM-DD HH:mm', this.timezone);

            if (end.isBefore(start) && !task.load) {
                return [{width: "100%"}]
            }

            // Временные отрезки по задаче
            const time = [start, load, arrive, unload, end];

            // Временная разница между каждым отрезком
            const countDiff = (start, end) => {
                // Условие, если временной отрезок переходит на след. день
                if (end.isBefore(start)) {
                    return dayEnd.diff(start, 'minutes')
                }
                return end.diff(start, 'minutes')
            }

            // Массив, в котором исключаем отрезки, переходящие на след. день
            const filter = [time[0]]

            for (const [index, item] of time.entries()) {
                const nextItem = time[index + 1]

                if (nextItem) {
                    if (nextItem.isBefore(item)) {
                        break
                    }
                    filter.push(nextItem)
                }
            }

            // общее кол-во минут
            const totalMinutes = countDiff(filter[0], filter[filter.length - 1])

            // высчитываем длину каждого отрезка
            return filter.map((period, index, self) => {
                const nextPeriod = self[index + 1];

                if (nextPeriod) {
                    const taskDurationMinutes = nextPeriod.diff(period, 'minutes')
                    return {
                        width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
                    };
                }
            })
        },
        getTime(time) {
            return moment(time).format("HH:mm")
        }
    }
}
</script>
<style scoped lang="scss">
@use "@/sass/_variables.scss";

.gantt-task {
    position: absolute;
    height: 40px;
    border-radius: 4px;
    text-align: left;
    z-index: 0;
    top: 1px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    font-weight: 400;
    font-size: 12px !important;
    line-height: 1 !important;
    border: 1px solid white;
    background: variables.$neutral30;
    color: variables.$neutral200;

    // запрет на выделение текста
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover, &--highlight {
        border-color: currentColor;
        transition: all 0.5s;
        z-index: 1;
    }

    &--highlight {
        background: #ededed;
        color: #9DA1AA;
    }

    &__line-container {
        transition: all 0.3s;
        background: #E8E8E8;
    }

    &__line {
        height: 4px;

        &:nth-child(odd) {
            transition: all 0.3s;
            background: #C9CCD0;
        }
    }

    &--current-task {
        background: #E0E0E0;
        color: #9296A0;
        z-index: 2;

        .gantt-task__line-container {
            background: #CCCCCC;
        }
        .gantt-task__line:nth-child(odd) {
            background: #A8ACB3;
        }

        border-color: currentColor;
    }

    &--middle-gray {
        background: variables.$neutral50;
        color: variables.$neutral300;
    }

    &--middle-gray &__line-container {
        background: variables.$neutral100;
    }

    &--middle-gray &__line:nth-child(odd) {
        background: variables.$neutral300;
    }

    &--highlight.gantt-task--middle-gray {
        background: #DFDFE2;
        color: #7C828D;

        .gantt-task__line-container {
            background: #B3B7BD;
        }

        .gantt-task__line:nth-child(odd) {
            background: #7C828D;
        }
    }

    &--current-task.gantt-task--middle-gray {
        background: #D4D4D8;
        color: #727883;

        .gantt-task__line-container {
            background: #A8ACB3;
        }

        .gantt-task__line:nth-child(odd) {
            background: #727883;
        }
    }

    // Темно-серая карточка
    &--dark-gray {
        background: #DEE1E3;
        color: #596273;
    }

    &--dark-gray &__line-container {
        background: #BAC0CA;
    }

    &--dark-gray &__line:nth-child(odd) {
        background: #596273;
    }

    &--highlight.gantt-task--dark-gray {
        background: #D3D6D9;
        color: variables.$neutral600;

        .gantt-task__line-container {
            background: #AEB5C1;
        }

        .gantt-task__line:nth-child(odd) {
            background: variables.$neutral600;
        }
    }

    &--current-task.gantt-task--dark-gray {
        background: #C8CCD0;
        color: #474F5C;

        .gantt-task__line-container {
            background: #A3ABB8;
        }

        .gantt-task__line:nth-child(odd) {
            background: #474F5C;
        }
    }

    // Зеленая карточка
    &--green {
        background: #d5f7f0;
        color: #007F74;
    }

    &--green &__line-container {
        background: #96ecd9;
    }

    &--green &__line:nth-child(odd) {
        background: #007F74;
    }

    &--highlight.gantt-task--green {
        background: #BAF2E7;
        color: #00665E;

        .gantt-task__line-container {
            background: #75E6CD;
        }

        .gantt-task__line:nth-child(odd) {
            background: #00665E;
        }
    }

    &--current-task.gantt-task--green {
        background: #A9EFE1;
        color: #00524B;

        .gantt-task__line-container {
            background: #64E3C7;
        }

        .gantt-task__line:nth-child(odd) {
            background: #00524B;
        }
    }

    // Голубая карточка
    &--blue {
        background: #D1E9FF;
        color: variables.$primary600;
    }

    &--blue &__line-container {
        background: variables.$primary200;
    }

    &--blue &__line:nth-child(odd) {
        background: variables.$primary600;
    }

    &--highlight.gantt-task--blue {
        background: #ADD8FF;
        color: #1673CA;

        .gantt-task__line-container {
            background: #72BAFE;
        }

        .gantt-task__line:nth-child(odd) {
            background: #1673CA;
        }
    }

    &--current-task.gantt-task--blue {
        background: #99CEFF;
        color: #1469B8;

        .gantt-task__line-container {
            background: #5DB0FE;
        }

        .gantt-task__line:nth-child(odd) {
            background: #1469B8;
        }
    }

    // Желтая карточка
    &--yellow {
        background: #FBEFCB;
        color: #AE641F;
    }

    &--yellow &__line-container {
        background: variables.$warning100;
    }

    &--yellow &__line:nth-child(odd) {
        background: variables.$warning400;
    }

    &--highlight.gantt-task--yellow {
        background: #F9E8B3;
        color: #8B5018;

        .gantt-task__line-container {
            background: #F2CB54;
        }

        .gantt-task__line:nth-child(odd) {
            background: #BD630F;
        }
    }

    &--current-task.gantt-task--yellow {
        background: #F7E2A1;
        color: #7A4615;

        .gantt-task__line-container {
            background: #F1C541;
        }

        .gantt-task__line:nth-child(odd) {
            background: #AA590E;
        }
    }

    // Красная карточка
    &--red {
        background: #FDC6C2;
        color: variables.$danger400;
    }

    &--red &__line-container {
        background: variables.$danger100;
    }

    &--red &__line:nth-child(odd) {
        background: variables.$danger400;
    }

    &--highlight.gantt-task--red {
        background: #FCA29C;
        color: #952A23;

        .gantt-task__line-container {
            background: #F86C62;
        }

        .gantt-task__line:nth-child(odd) {
            background: #952A23;
        }
    }

    &--current-task.gantt-task--red {
        background: #FB9088;
        color: #84261F;

        .gantt-task__line-container {
            background: #F75B50;
        }

        .gantt-task__line:nth-child(odd) {
            background: #84261F;
        }
    }

    &__content {
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &-time {
            font-size: 10px;
            line-height: 8px;
        }

        &-vehicle {
            font-size: 11px;
            line-height: 8px;
        }
    }
}
</style>
