<template>
    <div @click.self="handleClose" class="p-20 pt-5">
        <div class="neutral700 textLG textBold">Отправка доступа заказчику</div>

        <div class="neutral300 textMD textRegular400">
            Код доступа:
            <div class="gray-field headingSM headingMedium">
                <SharedCode :value="value"/>
            </div>
        </div>

        <div class="neutral700 textSM textMedium">
            Просмотр заказа будет доступен по ссылке:
            <a :href="orderLink" target="_blank" class="text-none primary600">
                {{ orderLink }}
            </a>
        </div>

        <div class="copy-btn" @click="copyLink">
            Скопировать публичную ссылку
        </div>
    </div>
</template>

<script>
import SharedCode from "@/views/components/SharedCode.vue";

export default {
    name: "SharedCodeModal",
    components: {SharedCode},
    props: {
        value: {
            type: [String, Number, null],
            default: ''
        },
    },
    computed:{
        orderLink() {
            return 'https://client.cifra.ai/' + this.value;
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },

        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.orderLink)
                alert('Ссылка успешно скопирована в буфер обмена')
            } catch (error) {
                alert('Ошибка копирования ссылки')
                console.error('Copy error:', error)
            }
        }
    }
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";
.copy-btn {
    background: variables.$primary600;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
    text-align: center;
}

.copy-btn:hover {
    background: variables.$primary700;
}

.gray-field {
    background-color: rgb(238, 238, 240);
    color: #000;
    border-radius: 15px;
    height: 66px;
    display: flex;
    justify-content: center;
}
</style>
