<template>
<div class="mobileTableButton">
    <el-button
        size="mini"
        v-bind:class="{ 'mb-0': noMargin }"
        :type="type"
        plain
        :disabled="disabled"
        @click="$emit('action')">
        {{ title ?? 'Подробнее' }}
    </el-button>
</div>
</template>

<script>
export default {
    name: "MobileTableButton",
    props: [
        'title',
        'type',
        'noMargin',
        'disabled'
    ]
}
</script>

<style scoped>

</style>
