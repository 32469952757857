<template>
    <Auth title="Отгрузки" :tabs="tabs" :settings="$route.params.id">
        <template v-slot:additionalButtons>
            <div class="d-flex justify-between flex-1">
                <DispatchOrderHeaderButton
                    :to="{ name: 'DispatchOrderGroupsCreate', params: { redirectedFrom: 'DispatchOrders' }}"
                    />
                <EventButtons
                    :account-events="accountEvents"
                    :dialog="eventDialogOpened"
                    v-model="selectedEventTab"
                    @clickOpenDialogButton="toggleEventDialogOpened"
                    @readAllEvents="readAllEvents"
                />
            </div>
        </template>
        <div class="card">
            <div class="card-body">

                <div class="mixPageOrdersTable">
                    <TableHeadBlock
                        :new="true"
                        v-model="search"
                        :disable-search="true"
                        :loading="loading"
                        :show-date-filter="true"
                        :current-date="getDate"
                        :disable-filter="true"
                        :disable-create-button="true"
                        create-button-label="Добавить отгрузку"
                        :filterShow="filterShow"
                        :disable-update-button="true"
                        @moduleGet="moduleGet"
                        @openFilterBlock="openFilterBlock"
                        @changeDate="changeDate"
                    >
                        <template v-slot:buttonsBlock>
                            <el-button :icon="icons.download"
                                       @click="openPrinterDialog"
                                       class="ci-btn ci-btn_default"
                                       :disabled="values.length === 0">
                                Скачать график
                            </el-button>
                            <!--                        <button-->
                            <!--                            @click="printerDialogShow=true"-->
                            <!--                            v-if="!mobile"-->
                            <!--                            class="ci-btn ci-btn_default"-->
                            <!--                            :disabled="values.length === 0"-->
                            <!--                        >-->
                            <!--                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                            <!--                                <path stroke="currentColor"-->
                            <!--                                      d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"-->
                            <!--                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
                            <!--                                <path stroke="currentColor"-->
                            <!--                                      d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"-->
                            <!--                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
                            <!--                                <path stroke="currentColor" d="M10 11H14" stroke-width="2" stroke-linecap="round"-->
                            <!--                                      stroke-linejoin="round"/>-->
                            <!--                                <path stroke="currentColor"-->
                            <!--                                      d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"-->
                            <!--                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
                            <!--                            </svg>-->
                            <!--                            <span v-if="windowWidth > 1200">Печать</span>-->
                            <!--                        </button>-->
                        </template>
                    </TableHeadBlock>

                    <Filter
                        :filter="filter"
                        :loading="loading"
                        :filter-show="filterShow"
                    />

                    <DispatchOrdersTable :date="getDate" @updateCountOrders="updateCountOrders"/>
                </div>

                <!-- <EventList
                    :selected-tab="selectedEventTab"
                    class="eventList"
                    :account-events="accountEvents"
                    @update-events="updateEvents"
                    :mix-order-groups="orderGroupsForEvents"
                    :loading="loading"
                /> -->

                <EventModal :account-events="accountEvents" :modelValue="eventDialogOpened"
                            :selectedEventTab="selectedEventTab" @update:modelValue="eventDialogOpened = $event"
                            @update-events="updateEvents" @readAllEvents="readAllEvents"
                            :mix-order-groups="orderGroupsForEvents" :loading="loadingEvents"/>
            </div>
        </div>

        <el-dialog
            v-model="printerDialogShow"
            title="Печать документов"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <AllPrintDocs
                from="MixOrderGroupOrdersPrinter"
                :orders="values.filter(el => el.delivery !== 'take-away')"
                :date="getDate"
            />
        </el-dialog>

        <el-dialog
            v-model="printerReportsDialogShow"
            title="Печать документов"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupReportsPrinter
                :orders="values"
                :orderGroups="orderGroups"
            />
        </el-dialog>
        <EventNotification ref="notification" @update-events="updateEvents" :mix-order-groups="orderGroupsForEvents"/>
        <el-dialog
            class="group-order-action-dialog"
            v-model="orderDetailsDialog"
            title=""
            :width="dialogSize"
            :destroy-on-close="true"
            @close="closeOrderDetailsDialog"
        >
            <MixOrderDetailsGroup
                :mix-order-id="selectedMixOrderId"
                :order-group="selectedMixOrderGroup"
                @close="closeOrderDetailsDialog"
                @get="$emit('get')"
                @update-order="updateOrder"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import {httpService} from "@/services/http.service";
import {useOrderGroupsStore} from '@/store/orderGroups'
import {mapState, mapActions} from 'pinia'
import {useCommonStore} from "@/store/common";
import MixOrderGroupReportsPrinter from "@/views/components/MixPage/OrdersGroup/MixOrderGroupReportsPrinter.vue";
import EventModal from "@/views/components/Events/EventModal.vue";
import EventButtons from '../components/Events/EventButtons.vue';
import EventNotification from '@/views/components/Events/EventNotification.vue'
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import DispatchOrdersTable from "@/views/dispatch/components/DispatchOrdersTable.vue";
import DispatchOrderHeaderButton from "@/views/dispatch/components/buttons/DispatchOrderHeaderButton.vue";
import AllPrintDocs from "@/views/dispatch/components/AllPrintDocs.vue";

export default {
    name: 'DispatchOrders',
    components: {
        AllPrintDocs,
        DispatchOrdersTable,
        MixOrderGroupReportsPrinter,
        TableHeadBlock,
        Auth,
        EventButtons,
        EventModal,
        EventNotification,
        MixOrderDetailsGroup,
        DispatchOrderHeaderButton
    },
    mixins: [tableMixin, basicMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: false,
            loadingEvents: false,
            printerDialogShow: false,
            printerReportsDialogShow: false,
            ordersCount: 0,
            eventDialogOpened: false,
            accountEvents: [],
            selectedEventTab: 'new',
            orderDetailsDialog: false,
            selectedMixOrderId: null,
            selectedMixOrderGroup: null,
            actual: false
        }
    },
    created() {
        this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters);
        this.getEvents();

        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters);
            })

        window.Echo.channel('laravel_database_ChannelAccountEvent')
            .listen(`.Create.${this.$route.params.id}`, (data) => {
                const newEvent = {...data.event, tz: data.tz ? data.tz : 'Europe/Moscow'};
                const isCountBehindByOne = this.accountEvents.length === data.eventsCount - 1;
                const isCountBehind = this.accountEvents.length < data.eventsCount;

                if (isCountBehindByOne) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.accountEvents.push(newEvent);
                } else if (isCountBehind) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.getEvents();
                }
            })
            .listen(`.Update.${this.$route.params.id}`, (data) => {
                let oldEvent = this.accountEvents.find((el) => el.id === data.event.id);

                if (oldEvent && oldEvent.status !== data.event.status) {
                    oldEvent.status = data.event.status
                }
            });
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelAccountEvent`);
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            orderGroups: 'getOrderGroups',
            orderGroupsForEvents: 'getOrderGroupsForEvents',
            filters: 'getFilters',
        }),
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies'
        }),
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups']),
        updateCountOrders(orders) {
            this.values = orders
            this.ordersCount = orders.length ?? 0
        },
        changeDate(date) {
            // TODO сделать по человечески
            // не запускать смену даты при mounted
            // но компонент таааак много где используется, что уже не понятно, сломает ли это логику
            // еще он в стольких местах используется, что во многих остались моменты, которых в компонтете нет
            // компонент TableHeadBlock
            if (moment(date).format('YYYY-MM-DD') !== this.filters['filter[date]']) {
                this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
                this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters);
            }
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        toggleEventDialogOpened() {
            this.eventDialogOpened = !this.eventDialogOpened
        },
        getEvents() {
            this.loadingEvents = true

            httpService().post(this.api.accountEvents.index, {}).then(({data}) => {
                if (data.success) {
                    this.accountEvents = data.accountEvents.map((el) => {
                        return {...el, tz: data.tz ? data.tz : 'Europe/Moscow'}
                    })
                }
                this.loadingEvents = false
            }).catch((error) => {
                this.loadingEvents = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openPrinterDialog() {
            this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters);
            this.printerReportsDialogShow = !this.printerReportsDialogShow
        },
        showNotification(event) {
            this.$refs.notification.addNotification({
                event: event,
            });
        },
        readAllEvents() {
            httpService().post(this.api.accountEvents.readAll, {}).then(() => {
                this.getEvents()
            })
        },
        updateEvents(mixOrderGroup, mixOrderId) {
            this.selectedMixOrderGroup = mixOrderGroup;
            this.selectedMixOrderId = mixOrderId
            this.orderDetailsDialog = true
            this.getEvents()
        },
        closeOrderDetailsDialog() {
            this.unlockOrder();
            this.orderDetailsDialog = false
            this.selectedMixOrderId = null
            this.selectedMixOrderGroup = null
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        updateOrder(id, order) {
            this.selectedMixOrderId = id
            this.selectedMixOrderGroup = order
        }
    }
}
</script>

<style lang="scss">
//.eventList {
//    grid-area: eventList;
//    display: none;
//    border-left: 1px solid #eeeeee;
//
//    @media screen and (min-width: 1920px) {
//        display: block;
//    }
//}

.mixPageOrdersTable {
    grid-area: mixPageOrdersTable;
}
</style>

<style scoped lang="scss">
.card-body {
    position: relative;

    &__filters {
        padding: 12px 0;
    }

    //@media screen and (min-width: 1920px) {
    //    display: grid;
    //    gap: 10px;
    //    grid-template-columns: minmax(0, 1fr) 456px;
    //    grid-template-areas: "mixPageOrdersTable eventList" 'mixPageOrdersTable eventList';
    //    grid-template-rows: auto 1fr;
    //}
}
</style>
