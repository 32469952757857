<template>

    <div style="height: 210px;" class="mb-15 operatorMapBlock">
        <MixersMap
            v-if="coords"
            :vehicles="vehiclesMap"
            :factories="factories"
            :coords="coords"
            :zoom="16"
        />
    </div>

    <div class="mb-15">
        <div v-for="(schedule, index) in postSchedules" :key="index">
            <div class="operatorPostBlock">
                <div class="operatorPostBlockBody d-flex-full">
                    <div class="textMedium textSM neutral900">
                        {{ schedule.mixer.name }}
                    </div>
                    <div>
                        <el-tooltip v-if="schedule.active" effect="dark"
                                    :show-after="150"
                                    placement="top">
                            <template #content>
                                <div class="text-center">
                                    <div class="textMedium mb-5">Остановить работу</div>
                                    <div class="textXS">Пост будет выключен из расписания<br/>Все отгрузки будут
                                        перераспределены
                                    </div>
                                </div>
                            </template>
                            <button
                                class="ci-btn pause-btn icon-btn"
                                type="button"
                                @click="$emit('changeStatus', schedule.id, 0)"
                            >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.5 12.4444L11.5 3.55556C11.5 3.24873 11.3209 3 11.1 3L9.9 3C9.67909 3 9.5 3.24873 9.5 3.55556L9.5 12.4444C9.5 12.7513 9.67909 13 9.9 13H11.1C11.3209 13 11.5 12.7513 11.5 12.4444Z"
                                        stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path
                                        d="M6.5 12.4444L6.5 3.55556C6.5 3.24873 6.32091 3 6.1 3L4.9 3C4.67909 3 4.5 3.24873 4.5 3.55556L4.5 12.4444C4.5 12.7513 4.67909 13 4.9 13H6.1C6.32091 13 6.5 12.7513 6.5 12.4444Z"
                                        stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </el-tooltip>
                        <el-tooltip effect="dark" v-else placement="top" :show-after="150">
                            <template #content>
                                <div class="text-center">
                                    <div class="textMedium mb-5">Возобновить работу</div>
                                    <div class="textXS">Пост будет возвращен в расписание<br/>Все отгрузки будут
                                        перераспределены
                                    </div>
                                </div>
                            </template>
                            <button
                                class="ci-btn work-btn icon-btn"
                                type="button"
                                @click="$emit('changeStatus', schedule.id, 1)"
                            >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3333 7.99984L3.33325 2.6665V13.3332L13.3333 7.99984Z"
                                          stroke="white" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!firstLoad">
        <el-tabs v-model="tab">
            <el-tab-pane label="Общее" name="info">
                <div class="d-flex-full">
                    <div class="w-100" style="padding-right: 20px">
                        <div v-for="(statistic, index) in statistics.main" :key="index" :class="{'mb-5': index !== statistics.length - 1}">
                            <div class="d-flex-full">
                                <div class="textRegular textSM neutral900">
                                    {{
                                        statistic.name
                                    }}
                                </div>
                                <div class="textMedium textSM" :class=
                                    "{
                                        'neutral900': statistic.name === 'Всего',
                                        'neutral400': statistic.name === 'В очереди',
                                        'primary600': statistic.name === 'В работе',
                                        'success400': statistic.name === 'Отгружено'
                                    }"
                                >
                                    {{
                                        statistic.value
                                    }} м³
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <OperatorMixOrdersCountPie :statuses="statistics.pie"/>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Заказы" name="orders">
                <div v-if="statistics.groups.length === 0" class="operator-mixers-empty neutral400 textRegular">
                    Нет информации
                </div>
                <div v-else class="operator_orders">
                    <OperatorMixOrderGroupItem
                        v-for="(orderGroup, index) in statistics.groups"
                        :key="index"
                        :mixOrderGroup="orderGroup"
                    />
                </div>
            </el-tab-pane>
            <el-tab-pane label="Миксера" name="vehiclesReturn">
                <div class="textMedium neutral900 mb-15">
                    Скоро вернутся
                    <el-tooltip placement="bottom">
                        <template #content> Здесь отображаются миксера,<br/>которые скоро вернутся на завод<br/><br/>Вы
                            можете
                            заранее замешать<br/>первый куб к их приезду
                        </template>
                        <span class="tooltip-icon"><FaqIcon3/></span>
                    </el-tooltip>
                </div>

                <div v-if="vehiclesReturn.length === 0" class="operator-mixers-empty neutral400 textRegular">
                    Нет информации
                </div>
                <div v-else>
                    <div v-for="(vehicle, index) in vehiclesReturn" :key="index">
                        <div class="d-flex-full">
                            <div class="textMedium textSM neutral900">
                                {{ getVehicleNumber(vehicle.vehicle_name) }}
                            </div>
                            <div>
                        <span class="textMedium textSM primary600">{{
                                getDateFromUnix(vehicle.return_at, 'HH:mm', tz)
                            }}</span>
                                <span class="textRegular textSM neutral900"> - {{
                                        getDiff(vehicle.return_at)
                                    }} мин</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import FaqIcon3 from "@/views/components/Icons/FaqIcon3.vue";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import MixersMap from "@/views/dispatch/components/mixers/MixersMap.vue";
import OperatorMixOrdersCountPie from "@/views/components/Dashboards/Operator/OperatorMixOrdersCountPie.vue";
import OperatorMixOrderGroupItem from "@/views/components/Dashboards/Operator/OperatorMixOrderGroupItem.vue";

export default {
    name: "OperatorMixers",
    components: {OperatorMixOrderGroupItem, OperatorMixOrdersCountPie, MixersMap, FaqIcon3},
    inject: ['api'],
    props: ['mixId', 'tz', 'coords', 'factories', 'postSchedules', 'orders', 'statistics'],
    mixins: [functionsMixin],
    data() {
        return {
            vehiclesReturn: [],
            statuses: [],
            firstLoad: true,
            vehiclesMap: [],
            tab: 'info',
        }
    },
    created() {
        this.moduleGet()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    computed: {

    },
    methods: {
        async moduleGet() {
            this.loading = true

            try {
                const response = await httpService().post(this.api.operator.getMixers);

                let data = response.data;
                if (data.success) {
                    this.vehiclesReturn = data.mixers
                    this.loading = false;
                    this.firstLoad = false
                    this.vehiclesMap = data.gpsVehicles
                }

            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
        getDiff(date) {
            return moment.unix(date).diff(moment(), 'minutes')
        },

    },
}
</script>

<style scoped>
.tooltip-icon {
    position: relative;
    top: 2px;
    margin-left: 10px;
}
.operator_orders {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
}
</style>
