<template>
    <PageH1 title="Работы по технике"/>

    <TableHeadBlock
        v-model="search"
        :loading="loading"
        create-button-label="Новый заказ-наряд"
        :disable-search="true"
        :disable-filter="true"
        @moduleGet="getTechService"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="$router.push({ name: 'techCreateService', params: { id: $route.params.id } })"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
        :filter-items1="workStatuses"
    />

    <el-table
        :data="displayData"
        class="mt-15"
        v-loading="loading">
        <el-table-column
            prop="id"
            label="#"
            width="100"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.number) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
        >
            <template v-slot="scope">
                <div class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</div>
            </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="Тип работы"
            width="160"
        >
            <template v-slot="scope">
                <div class="textSM textRegular neutral400 cutOverflow">
                    <span v-if="scope.row.type === 1">Осмотр</span>
                    <span v-if="scope.row.type === 2">Обслуживание</span>
                    <span v-if="scope.row.type === 3">Ремонт</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="mileage"
            label="Пробег"
        >
            <template v-slot="scope">

                <template
                    v-if="scope.row.statusId === 1 || scope.row.statusId === 2 || scope.row.statusId === 3">

                    <template v-if="scope.row.planType === 'mileage'">
                        <span class="textSM textMedium warning300 mr-5">{{ formatNumber(scope.row.mileageIndicatorPlan) }} км.</span>
                        <span class="textSM textRegular neutral300">(Через {{
                                formatNumber(scope.row.mileageIndicatorPlan - scope.row.vehicleMileage)
                            }} км.)</span>
                    </template>
                    <template v-else-if="scope.row.planType === 'hours'">
                        <span class="textSM textMedium warning300 mr-5">{{ formatNumber(scope.row.hoursIndicatorPlan) }} мтч.</span>
                        <span class="textSM textRegular neutral300">(Через {{
                                formatNumber(scope.row.hoursIndicatorPlan - scope.row.vehicleHours)
                            }} мтч.)</span>
                    </template>
                </template>

                <template v-if="scope.row.statusId === 4">
                    <template v-if="scope.row.planType === 'mileage'">
                        <span class="textSM textMedium warning300">{{ formatNumber(scope.row.mileageIndicatorDone) }} км.</span>
                    </template>
                    <template v-else-if="scope.row.planType === 'hours'">
                        <span class="textSM textMedium warning300">{{ formatNumber(scope.row.hoursIndicatorDone) }} мтч.</span>
                    </template>
                </template>

                <template v-if="scope.row.statusId === 5">
                    <span class="textSM textRegular neutral300">-</span>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="date"
            label="Дата"
            width="160"
        >
            <template v-slot="scope">
                <template v-if="scope.row.statusId === 4">
                    {{ getDateFromUnix(scope.row.dateDone, 'DD.MM.YY') }}
                </template>
                <template v-else>
                    <span class="textSM textRegular neutral400">-</span>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="Статус"
            width="180"
        >
            <template v-slot="scope">
                <div class="techWorkDetailsStatus">
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle v-if="scope.row.statusId === 1 || scope.row.statusId === 2" cx="4"
                                cy="4"
                                r="4" fill="#afb3ba"/>
                        <circle v-if="scope.row.statusId === 3 || scope.row.statusId === 4" cx="4"
                                cy="4"
                                r="4" fill="#1984e6"/>
                        <circle v-if="scope.row.statusId === 5" cx="4" cy="4" r="4" fill="#f74539"/>
                    </svg>
                    <span class="textSM textRegular">{{ scope.row.statusName }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            label=""
            fixed="right"
            width="130">
            <template v-slot="scope">
                <el-button size="small"
                           @click="$router.push({name: 'serviceDetails', params: { id: $route.params.id, serviceId: scope.row.id, routeName: 'serviceDetails' }})">
                    Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import PageH1 from '@/views/components/PageH1'
import {httpService} from "@/services/http.service";
import tableMixin from "@/mixins/tableMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import Filter from "@/views/components/Table/Filter/Filter";
import TablePagination from "@/views/components/Table/TablePagination";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'TechDetailsService',
    components: {TablePagination, Filter, TableHeadBlock, PageH1},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [tableMixin, functionsMixin],
    data() {
        return {
            loading: false,
        }
    },
    created() {
        this.getTechService()
    },
    methods: {
        getTechService() {
            this.loading = true
            httpService().post(this.api.tech.getTechService, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.values
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
