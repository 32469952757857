<template>
    <h2>Как добавить модуль и ссылку в меню</h2>
    <p>Для создания модуля вам необходимо выполнить следующие действия:</p>
    <section class="guide-steps">
        <div class="guide-steps__container d-flex f-direction-column">
            <ul>
                <li>Перейдите в
                    <router-link :to="{ name: 'ModulesIndex' }">настройки -> модули</router-link>
                </li>
                <li>Нажать на кнопку
                    <router-link :to="{ name: 'ModuleCreate' }">"Добавить модуль"</router-link>
                    в правом верхнем углу
                </li>
                <li>На открывшейся странице вам необходимо выбрать тип модуля, задать его название и отметить пользователей,
                    которым будет доступен новый модуль
                </li>
            </ul>
            <el-image
                :src="require(`@/assets/faq/SettingsFaqHowCreateNewModule-1.jpg`)"
                fit="contain"
                lazy
            ></el-image>
            <p>После создания модуля ссылка на него появится в меню у всех выбранных пользователей.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SettingsFaqHowCreateNewModule',
    data() {
        return {}
    },
}
</script>

<style scoped>

</style>
