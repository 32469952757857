<template>
    <div v-if="details">
        <el-row :gutter="20">
            <el-col :sm="8" v-if="details.type === 'delivery'">
                <MixOrderDetailsGroupMap
                    :coor-lat-from="details.mix?.latitude"
                    :coor-lon-from="details.mix?.longitude"
                    :coor-lat-to="details.address?.latitude"
                    :coor-lon-to="details.address?.longitude"
                    :vehicle="vehicle"
                    :status="details.status"
                    :checkpoint="checkpoint"
                    :style="{opacity: loadingVehicles ? 0.7 : 1}"
                />
            </el-col>
            <el-col :sm="details.type === 'delivery' ? 16 : 24">
                <div class="add-shipping">
                    <div class="add-shipping__content">
                        <div class="add-shipping__content__header d-flex-full-stroke">
                            <div>
                                <div v-if="!details.confirm" class="add-shipping__content__header__title">
                                    Лучший вариант автоматического подбора
                                </div>
                                <div v-else class="add-shipping__content__header__title">
                                    Отгрузка #{{ formatNumber(details.id) }} подтверждена пользователем
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="d-flex">
                                    <el-tooltip raw-content effect="dark" popper-class="content__delivery__tooltip--redirection"
                                        :content="redirectOrderTooltip" :show-after="300"
                                            placement="top">
                                        <el-button
                                            class="ci-btn ci-btn_default content__delivery__button"
                                            @click="redirectOrder"
                                            :disabled="loading || !isOrderStatusActive || !redirectOrderGroups?.length"
                                        >
                                            <RedirectIcon :currentColor="true" />
                                        </el-button>
                                    </el-tooltip>

                                    <el-tooltip raw-content effect="dark" content="Печать документов"
                                        :show-after="200" placement="top">
                                        <el-button
                                            class="ci-btn ci-btn_default content__delivery__button"
                                            @click="showPrint"
                                            v-if="details.confirm"
                                            :disabled="loading"
                                        >
                                            <PrinterIcon />
                                        </el-button>
                                    </el-tooltip>
                                    <el-tooltip
                                        raw-content
                                        effect="dark"
                                        placement="top"
                                        popper-class="content__delivery__tooltip--status"
                                         :show-after="200"
                                        :content="orderStatusTooltip"
                                    >
                                        <span>
                                            <el-button @click="() => statusChange = true"
                                                class="ci-btn ci-btn_default content__delivery__button"
                                                :disabled="loading || details.status === 'done' || !details.confirm"
                                            >
                                                <SkipForwardIcon :currentColor="true" />
                                            </el-button>
                                        </span>
                                    </el-tooltip>
                                </div>
                                <el-tooltip
                                    raw-content effect="dark"
                                    :show-after="200"
                                    :content="details.status !== 'new' ? 'Отгрузка находится в работе' : 'Редактировать'"
                                    placement="top">
                                    <el-button @click="() => $emit('edit')"
                                        class="ci-btn ci-btn_default content__delivery__button"
                                        :disabled="loading || details.status !== 'new'"
                                    >
                                        <EditIcon :currentColor="true" />
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </div>

                        <hr class="add-shipping__content__hr">

                        <div class="content__delivery justify-between mb-30 f-wrap gap-10">
                            <div class="d-flex">
                                <div class="content__delivery__item">
                                    <div class="content__delivery__item__data">
                                        <template
                                            v-if="!details.vehicle || !details.vehicle?.soft_id">
                                            <VehicleIcon/>
                                        </template>
                                        <template v-else>
                                            <el-tooltip effect="dark" placement="top" :show-after="150"
                                                        :disabled="details.vehicle.gps_online">
                                                <template #content>
                                                    <template v-if="details.vehicle.gps_last_date">
                                                        GPS сигнал потерян<br/>
                                                        Время последнего обновления:<br/>
                                                        {{
                                                            getDateMoment(details.vehicle.gps_last_date, 'DD.MM HH:mm')
                                                        }}
                                                    </template>
                                                    <template v-else>
                                                        GPS сигнал не обнаружен
                                                    </template>
                                                </template>
                                                <span class="ordersTableItemGPSIcon">
                                                <SignalIcon
                                                    :class="{ordersTableItemGPSIconOnline: details.vehicle.gps_online}"/>
                                            </span>
                                            </el-tooltip>
                                        </template>
                                        {{ details.number ? getVehicleNumber(details.number) : '-' }}
                                    </div>
                                </div>

                                <!-- <div class="content__delivery__item">
                                    <div class="content__delivery__item__data">
                                        <PackageGoodIcon :currentColor="true"/>
                                        {{ details.total }} м³
                                    </div>
                                </div> -->

                                <div class="content__delivery__item">
                                    <div class="content__delivery__item__data d-flex align-center gap-2">
                                        <div class="d-flex align-center gap-2">
                                            <PackageIcon width="16" height="16" />
                                            <p class="m-0">{{ details.total }} м³</p>
                                            <p v-if="details.client_total || isClientTotalInputActive" class="m-0">/</p>
                                        </div>
                                        <div class="d-flex align-center primary600 gap-2">
                                            <EditVolumeInput
                                                v-if="isClientTotalInputActive"
                                                @close="() => isClientTotalInputActive = false"
                                                @submit="submitVolumeChange"
                                                v-model="details.client_total"
                                            />
                                            <p v-if="details.client_total && !isClientTotalInputActive" class="m-0">{{ details.client_total }} м³</p>
                                            <el-tooltip popper-class="content__delivery__item__tooltip" effect="dark" content="Объём" placement="top" :disabled="isClientTotalInputActive">
                                                <template #content>
                                                    <p>Корректировка для учёта различия данных у заявки для оборудования и для учетной документации.</p>
                                                    <p><b>Объём заявки</b> - фактический объём, который необходимо отгрузить на БСУ. <b>Запрошенный объём</b> - объём для документации.</p>
                                                </template>
                                                <EditIcon v-if="!isClientTotalInputActive && appSettings?.volumeCorrection === 'dispatcher'" class="pointer" width="20" height="20" @click="isClientTotalInputActive = true"/>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div class="content__delivery__item">
                                    <div class="content__delivery__item__data">
                                        <ClockIcon :currentColor="true" />
                                        <span v-if="details.type === 'take-away'">{{
                                                moment.parseZone(details.start_at).format('HH:mm')
                                            }}</span>
                                        <span v-else>
                                        <el-tooltip :show-after="500" effect="dark" content="Старт загрузки на заводе"
                                                    placement="top">{{
                                                moment.parseZone(details.start_at).format('HH:mm')
                                            }}</el-tooltip>
                                        – <el-tooltip :show-after="500" effect="dark" content="Возвращение на завод"
                                                      placement="top">{{
                                                moment.parseZone(details.return_at).format('HH:mm')
                                            }}</el-tooltip>
                </span>
                                    </div>
                                </div>
                            </div>

                            <div class="content__delivery__item mr-0">
                                <div class="content__delivery__item__text text-right"
                                     :class="[
         {'neutral600': details.status === 'new' || !details.confirm},
         {'primary600': ['loading', 'delivery', 'object', 'unloading', 'return'].includes(details.status)},
         {'success400': details.status === 'done'}
     ]">
                                    {{ getStatusLabel(details.status) }}
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <MixPageOrderProgressTimeline
                                :checkpoint="checkpoint"
                                :points="vehicle?.points"
                                :order="details"
                                @update:checkpoint="checkpoint = $event"
                            />
                        </div>

                        <hr class="add-shipping__content__hr">


                        <el-tabs v-model="tab">
                            <el-tab-pane label="Информация" name="info">

                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ClientBoldIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true"/>
                                        <div class="neutral900"><span class="neutral300">Покупатель:</span>
                                            {{ orderGroup?.company?.name ?? orderGroup?.company }}
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <MixBoldIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true"/>
                                        <div class="neutral900"><span class="neutral300">Завод:</span>
                                            {{ mixerName(details.factory_name, details.mix.mix_name, details.mixer.mixer_name) }}
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title  mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ArrowDownRightIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" />
                                        <div class="neutral900"><span class="neutral300">Адрес доставки:</span>
                                            {{ details.address?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <UserProfileIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.8"/>

                                        <div class="neutral900"><span class="neutral300">Водитель:</span>
                                            {{ details.driver?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.2"/>
                                        <div class="neutral900"><span class="neutral300">Время на загрузку:</span>
                                            {{ details?.info?.loading ? formatNumber(details.info.loading) : '-' }} мин
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <RouteIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" />
                                        <div class="neutral900"><span class="neutral300">До объекта:</span>
                                            {{ details?.info?.dist ?? '-' }}
                                            км
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10"
                                     v-if="details.type === 'delivery'">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.2" />
                                        <div class="neutral900"><span class="neutral300">Время на дорогу:</span>
                                            {{ details?.info?.toObject ? formatNumber(details.info.toObject) : '-' }}
                                            мин
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <RecipeIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.7"/>
                                        <div class="neutral900"><span class="neutral300">Рецепт на заводе:</span>
                                            {{ details?.good?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>

                                <div class="add-shipping__content__order-terms__title mb-10" v-if="details?.service">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <TariffIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.7"/>
                                        <div class="neutral900"><span class="neutral300">Тариф доставки:</span>
                                            {{ details?.service?.name ?? '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-shipping__content__order-terms__title mb-10" v-if="details?.service">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <CoinIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" strokeWidth="1.7"/>
                                        <div class="neutral900"><span class="neutral300">Стоимость доставки:</span>
                                            {{ details?.service?.full_price ? details?.service?.full_price + '₽' : '-' }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="details?.mix_order_groups_comment_driver || details?.mix_order_comment"
                                    class="add-shipping__content__order-terms__title mb-10">
                                    <div class="add-shipping__content__order-terms__title__item">
                                        <el-tooltip :disabled='!details.mix_order_comment' content="Индивидуальный комментарий" position="top">
                                            <ChatCircleDotsIcon strokeWidth="1.1"
                                                :class="['add-shipping__content__order-terms__title__item__icon align-self-start',
                                                    details.mix_order_comment ? 'warning300 pointer' : 'neutral300']"
                                                :currentColor="true"/>
                                        </el-tooltip>
                                        <div class="neutral900">
                                            <p class="neutral300 m-0">Комментарий водителю:</p>
                                            <div
                                                v-html="getFormattedComment(details.mix_order_comment && details.mix_order_comment.length ? details.mix_order_comment : details.mix_order_groups_comment_driver)"/>
                                        </div>
                                    </div>
                                </div>

                            </el-tab-pane>
                            <el-tab-pane label="История" name="log" v-if="details.confirm">
                                <MixOrderDetailsGroupLog
                                    :mix-order-id="details?.id"
                                    :mix-order-group-id="orderGroup?.id"
                                    :tab="tab"
                                    height="240px"/>
                            </el-tab-pane>
                        </el-tabs>

                    </div>

                    <div class="add-shipping__footer">
<!--                        <button-->
<!--                            class="ci-btn back-g-none mr-10"-->
<!--                            @click="$emit('close')"-->
<!--                        >-->
<!--                            Закрыть-->
<!--                        </button>-->
                        <el-button
                            v-if="!details.confirm && details.status === 'new'"
                            class="ci-btn ci-btn_blue"
                            @click="update(true)"
                            :disabled="loading"
                        >
                            Подтвердить
                        </el-button>
                        <el-button
                            v-else-if="details.confirm && details.status === 'new'"
                            class="ci-btn ci-btn_blue add-shipping__footer__cancel"
                            @click="update(false)"
                            :disabled="loading"
                        >
                            Отменить подтверждение
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>

    <div class="add-shipping__loader" v-else-if="loading">
        <TableDataLoader text="деталей отгрузки"/>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="statusChange"
        width="600"
        :destroy-on-close="true"
    >
        <StatusChangeModal
            :order="details"
            @get="$emit('get')"
            @loadingAction="loadingAction"
            @close="() => statusChange = false"
        />
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="redirectOrderModalOpened"
        :width="redirectOrderModalSize"
        :destroy-on-close="true"
    >
        <RedirectOrderModal
            :order-groups="redirectOrderGroups"
            :order="details"
            @loadingAction="loadingAction"
            @close="closeRedirectOrderModal"
            @cancel="() => redirectOrderModalOpened = false"
        />
    </el-dialog>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";

import {httpService} from "@/services/http.service";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useCommonStore} from "@/store/common";
import MixOrderDetailsGroupMap from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupMap.vue";
import StatusChangeModal from "@/views/dispatch/components/StatusChangeModal.vue";
import RedirectOrderModal from "@/views/dispatch/components/RedirectOrderModal.vue";
import moment from "moment/moment";
import MixOrderDetailsGroupLog from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupLog.vue";
import EditVolumeInput from "@/views/components/EditVolumeInput";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import MixPageOrderProgressTimeline from "@/views/components/MixPage/OrdersGroup/MixPageOrderProgressTimeline.vue";

// icons
import iconsMixin from "@/mixins/iconsMixin";
import ClockIcon from '@/views/components/Icons/ClockIcon.vue';
import UserProfileIcon from '@/views/components/Icons/UserProfileIcon.vue';
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';
import RecipeIcon from '@/views/components/Icons/RecipeIcon.vue';
import TariffIcon from '@/views/components/Icons/TariffIcon.vue';
import CoinIcon from '@/views/components/Icons/CoinIcon.vue';
import RouteIcon from '@/views/components/Icons/RouteIcon.vue';
import MixBoldIcon from "@/views/components/Icons/MixBoldIcon.vue";
import SignalIcon from "@/views/components/Icons/SignalIcon.vue";
import VehicleIcon from "@/views/components/Icons/VehicleIcon.vue";
import PackageIcon from "@/views/components/Icons/PackageIcon.vue";
import EditIcon from "@/views/components/Icons/EditIcon.vue";
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import SkipForwardIcon from "@/views/components/Icons/SkipForwardIcon.vue";
import RedirectIcon from "@/views/components/Icons/RedirectIcon.vue";
import ClientBoldIcon from "@/views/components/Icons/ClientBoldIcon.vue";

export default {
    name: "MixOrderDetailsGroupShow",
    computed: {
        ...mapState(useCommonStore, {
            appSettings: 'getAppSettings',
        }),
        ...mapState(useOrderGroupsStore, {
            orderGroups: 'getOrderGroups',
        }),
        moment() {
            return moment
        },
        isOrderStatusActive() {
            const status = this.details?.status;
            return (status === 'new' || status === 'loading' || status === 'delivery' || (status === 'object' && !+this.appSettings.isPouringAvailable)) && this.details.confirm;
        },
        redirectOrderGroups() {
            return this.orderGroups
                ?.filter(el => el.id !== this.orderGroup?.id) // исключаем текущий заказ из выборки
                .filter(el => el.status !== 'done' && el.status !== 'completed' && el.status !== 'failed' && el.status !== 'unconfirmed') // только активные, подтвержденные заказы
                .filter(el => el.goodId === this.orderGroup?.goodId) // с одинаковой продукцией
        },
        redirectOrderModalSize() {
            return Number(this.dialogSize.substring(0, this.dialogSize.length - 1)) - 5 + '%'
        },
        redirectOrderTooltip() {
            if(!this.details.confirm) {
                return 'Перенаправление доступно только для подтвержденных отгрузок';
            }
            if(!this.isOrderStatusActive) {
                if(this.details?.status !== 'object') {
                    return 'Машина разгружена'
                }
                return 'Перенаправление недоступно в статусе "На объекте", если в настройках включен этап Заливки'
            }

            if(!this.redirectOrderGroups?.length) return 'Подходящие заказы для перенаправления отгрузки отсутствуют'

            return 'Перенаправить'
        },
        orderStatusTooltip() {
            if(this.details?.status === 'done') return 'Отгрузка выполнена'
            if(this.details?.confirm) return 'Смените статус вручную, если отгрузка зависла по причине сбоя GPS или водитель не сменил статус'
            return 'Смена статуса доступна только для подтвержденных отгрузок'
        }
    },
    components: {
        ClientBoldIcon,
        MixPageOrderProgressTimeline,
        RedirectOrderModal,
        MixOrderDetailsGroupLog, StatusChangeModal, MixOrderDetailsGroupMap,
        ClockIcon, SignalIcon, VehicleIcon, EditVolumeInput, PackageIcon, EditIcon,
        ArrowDownRightIcon, UserProfileIcon, RecipeIcon, TariffIcon, CoinIcon, RouteIcon, MixBoldIcon, ChatCircleDotsIcon,
        TableDataLoader, PrinterIcon, SkipForwardIcon, RedirectIcon
    },
    props: ['orderGroup', 'mixOrder', 'loading'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            edit: false,
            details: null,
            statusChange: false,
            tab: 'info',
            vehicle:  null,
            isClientTotalInputActive: false,
            checkpoint: 0,
            loadingVehicles: true,
            redirectOrderModalOpened: false,
        }
    },
    created() {
        this.details = this.mixOrder
        if (this.details?.type === 'delivery') this.getMapData()
    },
    methods: {
        update(confirm) {

            this.loadingAction(true)
            httpService().post(this.api.dispatch.orders.confirmMixOrder, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                mix_order_id: this.mixOrder.id,
                confirm: confirm
            }).then(() => {
                this.$message({
                    message: 'Отгрузка успешно обновлена',
                    showClose: true,
                    type: 'success'
                })
                if (confirm !== null) this.details.confirm = confirm
                this.$emit('get')
                if (!confirm) this.$emit('close')
                this.loadingAction(false)
            }).catch((error) => {
                const {errors} = error.response.data

                if (errors) {
                    for (let key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errors[key].forEach(errorMessage => {
                                this.$message({
                                    message: errorMessage,
                                    showClose: true,
                                    type: 'error'
                                });
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })

                    if (error.response.data.message === 'Данная отгрузка уже находится в работе, обновите данные') {
                        this.$emit('get')
                    }

                    if (error.response.data.message === 'Не найден рецепт для данной продукции на выбранном заводе') {
                        setTimeout(() => {
                            this.$message({
                                message: 'Выберите рецепт',
                                showClose: true,
                                type: 'info'
                            })
                        }, 200)

                        setTimeout(() => {
                            this.$emit('edit')
                        }, 400)
                    }
                }
                this.loadingAction(false)
            })
        },
        getMapData() {
            this.loadingVehicles = true
            httpService().post(this.api.dispatch.orders.getMapDataOrder + '/' + this.mixOrder.id, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_id: this.mixOrder.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicle = data
                    let count = data.points.length
                    let elemNumber = count - 1
                    this.checkpoint = elemNumber < 0 ? 0 : elemNumber
                    this.loadingVehicles = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingVehicles = false
            })
        },
        showPrint() {
            this.$emit('print')
        },
        loadingAction(loading) {
            this.$emit('loadingAction', loading)
        },
        redirectOrder() {
            this.redirectOrderModalOpened = true;
        },
        closeRedirectOrderModal(id) {
            this.redirectOrderModalOpened = false;
            this.$emit('get')

            const order = this.orderGroups.find((item) => item.id == id);
            this.$emit('updateOrder', id, order)
        },
        submitVolumeChange() {
            this.update(true);
            this.isClientTotalInputActive = false;
        }
    },
    watch: {
        'mixOrder': {
            handler() {
                this.details = this.mixOrder
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
