<template>
    <EmptyState
        :values="getTableData()"
        :filter="filter"
        :search="search"
        :loading="loading"
        :list-types="type === 'mix' ? 'миксеров' : 'насосов'"
        action-text="Добавьте ТС в график работы"
    />

    <el-table
        v-loading="loading"
        :data="getTableData()"
        v-if="getTableData().length"
        class="dispatchMixersTable w-100 mt-15 mb-15">

        <el-table-column
            prop="selected"
            label=""
            width="30"
        >
            <template v-slot="scope">
                <el-checkbox
                    v-model="scope.row.selected"
                    @change="$emit('select', schedule.filter((e) => e.selected))"
                />
            </template>
        </el-table-column>

        <el-table-column
            prop="soft_id"
            label="GPS"
            :width="type === 'mix' ? '76px' : '56px'"
        >
            <template v-slot="scope">
                <div class="d-flex-center gap-4">
                    <div class="d-flex-center" :class="{'gap-4': type === 'mix'}">
                        <el-tooltip :content="`${scope.row.rent ? 'Арендованное' : 'Собственное'} ТС`" placement="top" :show-after="200" :hide-after="100">
                            <RentIcon :class="scope.row.rent ? 'neutral500' : 'neutral100'" :current-color="true"/>
                        </el-tooltip>
                        <el-tooltip v-if="type === 'mix'" :content="`${scope.row.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'} ТС`" placement="top" :show-after="200" :hide-after="100">
                            <GidrolotokLetterIcon :class="scope.row.gidrolotok ? 'neutral500' : 'neutral100'" :current-color="true"/>
                        </el-tooltip>
                    </div>
                    <template v-if="!scope.row.vehicle_id || !scope.row.soft_id">
                        <div class="text-center pt-0 d-flex-center">
                            <el-tooltip effect="dark" placement="right" content="Авто без GPS">
                                <SignalIcon :disabled="true"/>
                            </el-tooltip>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center">
                            <el-tooltip effect="dark" placement="left">
                                <template #content>
                                    <template v-if="scope.row.gps_last_date">
                                        <template v-if="!scope.row.gps_online">GPS сигнал потерян<br/></template>
                                        Время последнего обновления:<br/>
                                        {{ getDateMoment(scope.row.gps_last_date, 'DD.MM HH:mm') }}
                                    </template>
                                    <template v-else>
                                        GPS сигнал не обнаружен
                                    </template>
                                </template>
                                <div class="ordersTableItemGPSIcon pt-0">
                                    <SignalIcon
                                        :class="{ordersTableItemGPSIconOnline: scope.row.gps_online}"/>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </div>
            </template>
        </el-table-column>

        <el-table-column
            prop="number"
            label="Номер"
            width="120"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ getVehicleNumber(scope.row.number) }}</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="driver_id"
            width="160px"
            label="Водитель"
        >
            <template v-slot="scope">
                <span class="textSM textRegular400 neutral900">
                    {{ scope.row.driver_id ? ( formatName(scope.row.driver_name) ?? '-' ) : '-' }}
                </span>
            </template>
        </el-table-column>

        <el-table-column
            v-if="type !== 'pump'"
            prop="mixers"
            label="Заводы"
        >
            <template v-slot="scope">
                <template v-if="scope.row.mixers">
                    <span class="textSM textRegular400 neutral900 cutOverflow"
                          v-for="(mixId, index) in scope.row.mixers" :key="index"
                    >
                        {{ currentMixers(mixId) }}
                        <span v-if="index < scope.row.mixers.length - 1">, </span>
                    </span>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            prop="volume"
            label="Объем"
            width="110"
            v-if="type === 'mix'"
        >
            <template v-slot="scope">
                <span v-if="scope.row.volume && scope.row.volume > 0"
                      class="textSM textRegular400 primary600"
                >
                    {{ scope.row.volume }}
                    <good-unit unit="м3."/>
                </span>
                <span v-else class="textSM textMedium neutral700" >-</span>
            </template>
        </el-table-column>

        <el-table-column
            prop="objectsStart"
            label="Объект начала"
            width="200"
            v-if="type === 'mix' && factories && isMoreThanTwoFactories"
        >
            <template  v-slot="scope">
                <span class="textSM textRegular400 neutral800">
                    {{ scope.row.factory_start ? factories.find(f => f.id == scope.row.factory_start)?.name : '-' }}
                </span>
            </template>
        </el-table-column>

        <el-table-column
            prop="objectsEnd"
            label="Объект завершения"
            width="200"
            v-if="type === 'mix' && factories && isMoreThanTwoFactories"
        >
            <template  v-slot="scope">
                <span class="textSM textRegular400 neutral800">
                    {{ scope.row.factory_end ? factories.find(f => f.id == scope.row.factory_end)?.name : '-' }}
                </span>
            </template>
        </el-table-column>


        <el-table-column
            prop="tariff"
            label="Тариф"
            v-if="type === 'pump'"
            :min-width="windowWidth < 1250 ? '200px' : 'auto'"
        >
            <template v-slot="scope">
                <FormSelectTariff
                    extraClass="dispatchMixersCreateForm__formSelect"
                    v-model="scope.row.tariff_id"
                    type="pump"
                    label=""
                    placeholder="Выберите тариф"
                    @change="$emit('select', schedule.filter((e) => e.selected), 'comment', scope.row.comment)"
                    :items="pumpItems"
                />
            </template>
        </el-table-column>

        <el-table-column
            prop="comment"
            label="Комментарий"
            v-if="type === 'pump'"
            :min-width="windowWidth < 1250 ? '200px' : 'auto'"
        >
            <template v-slot="scope">
                <el-input
                    v-model="scope.row.comment"
                    type="text"
                    placeholder="Комментарий водителю насоса"
                    @change="$emit('select', schedule.filter((e) => e.selected), 'comment', scope.row.comment)"
                />
            </template>
        </el-table-column>

        <el-table-column
            prop="volume"
            label="Объем (план)"
            width="105"
            v-if="type === 'pump'"
        >
            <template v-slot="scope">
                <FormNumber
                    class="dispatchMixersCreateForm__formNumber"
                    label=""
                    v-model="scope.row.plan_total"
                    placeholder="30"
                    precision="2"
                    @change="$emit('select', schedule.filter((e) => e.selected), 'plan_total', scope.row.plan_total)"
                />
            </template>
        </el-table-column>

        <el-table-column
            prop="pump_length"
            label="Длина"
            width="80"
            v-if="type === 'pump'"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral700">{{ scope.row.pump_length ?? '-' }}</span>
            </template>
        </el-table-column>

        <el-table-column
            class=""
            prop="start_time"
            label="График"
            :width="110"
        >
            <template v-slot="scope">
                <template v-if="scope.row.start_time && scope.row.end_time">
                    <span class="textSM textRegular400 neutral800">
                        {{ scope.row.start_time }} - {{ scope.row.end_time }}
                    </span>
                </template>
            </template>
        </el-table-column>

        <el-table-column
            fixed="right"
            label=""
            width="120px">
            <template v-slot="scope">
                <div class="text-right">
                    <a class="el-button el-button--small el-button text-none"
                       size="small"
                       :href="'/settings/vehicles/' + scope.row.vehicle_id"
                       target="_blank">
                        Подробнее
                    </a>
                </div>
            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        class="pb-20"
        v-model="pageSize"
        :total="tableDataLength"
        @action="setPage"
        @reset-page="resetPage"
        ref="tablePagination"
    />
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import {httpService} from "@/services/http.service";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import GoodUnit from "@/views/components/goodUnit.vue";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useCommonStore} from '@/store/common';

// icons
import SignalIcon from "@/views/components/Icons/SignalIcon.vue";
import GidrolotokLetterIcon from "@/views/components/Icons/GidrolotokLetterIcon.vue";
import RentIcon from "@/views/components/Icons/RentIcon.vue";
import FormSelectTariff from "@/views/components/Form/Select/FormSelectTariff.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";

export default {
    name: 'DispatchMixersCreateForm',
    mixins: [dispatchTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin],
    props: ['type', 'dateOrder', 'selectedIds', 'pumpItems', 'orderId'],
    data() {
        return {
            loading: false,
            moduleMixers: [],
            tableDataLength: 0,
            pageSize: 100,
            schedule: [],
        }
    },
    components: {
        FormNumber,
        FormSelectTariff,
        RentIcon, GidrolotokLetterIcon,
        SignalIcon,
        GoodUnit,
        TablePagination, EmptyState,
    },
    inject: ['api'],
    created() {
        this.getVehicleSchedules()
        this.getCurrentModule()
    },
    computed: {
        ...mapState(useCommonStore, {
            factories: 'getFactories',
            modules: 'modules',
        }),
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        isMoreThanTwoFactories() {
            const dispatchModule = this.modules.find((m) => m.accountModuleId == this.$route.params.id)

            const mixIds = [...new Set(dispatchModule.mixers.map((m) => m.mix_id))]

            if(!mixIds.length || mixIds.length <= 1) return false

            const allMixes = this.modules.filter((m) => m.key === 'mix')
            const mixesByDispatchModule = allMixes.filter((m) => {
                return mixIds.includes(m.id)
            });

            const factories = [...new Set(mixesByDispatchModule.map(m => m.factory.id).flat())]

            return factories.length >= 2
        }
    },
    methods: {
        getCurrentModule() {
            this.moduleMixers = this.modules.find(module => +module.accountModuleId === +this.$route.params.id).mixers;
        },
        currentMixers(mixerId) {
            const findMixer = this.moduleMixers.find(mixer => +mixer.id === +mixerId);
            return findMixer.mix_count > 1 ? findMixer.name : findMixer.mix_name
        },
        getVehicleSchedules() {
            this.loading = true

            httpService().post(this.api.dispatch.mixers.getVehicleSchedulesForForm, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.dateOrder,
                type: this.type,
                orderId: this.orderId
            }).then(({data}) => {
                if (data.success) {
                    this.tableDataLength = data.schedule.length;

                    // Если массив selectedIds существует и не пустой
                    // Сравниваем vehicle_id с id из массива selectedIds
                    if (Array.isArray(this.selectedIds) && this.selectedIds.length > 0) {
                        data.schedule.forEach(item => {item.selected = !!this.selectedIds.includes(item.vehicle_id);});
                    } else if (this.type === 'pump'){
                        data.schedule.forEach((item) => item.selected = false)
                    }

                    this.schedule = data.schedule
                    this.$emit('select', data.schedule.filter((e) => e.selected))
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getTableData() {
            return this.schedule.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        resetPageInTable() {
            this.$refs.tablePagination.resetPage();
            this.page = 1;
        },
    },
    watch: {
        dateOrder() {
            this.getVehicleSchedules()
            this.resetPageInTable();
        }
    },
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.button-LG button {
    height: 44px !important;
    padding: 12px 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
</style>

<style lang="scss">
.dispatchMixersCreateForm {
    &__formNumber, &__formSelect {
        margin-bottom: 0 !important;
    }
}
</style>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.dispatchMixersTable {
    &:deep(.el-table__inner-wrapper) {
        th.el-table__cell > .cell {
            font-weight: 300;
            color: variables.$neutral200;
            font-size: 13px;
        }

        thead {
            tr {
                th {
                    padding-block: 0;
                    & .cell {
                        padding-inline: 8px;
                    }

                    &:nth-child(2) {
                        text-align: end;
                    }
                }
            }
        }

        tbody tr td .cell {
            padding-inline: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
