export default {
    data() {
        return {
            storehouseActionTypes: [
                {
                    id: 'posting',
                    name: 'Оприходование'
                },
                {
                    id: 'add',
                    name: 'Приёмка'
                },
                {
                    id: 'shipment',
                    name: 'Отгрузка'
                },
                {
                    id: 'remove',
                    name: 'Списание'
                },
                {
                    id: 'move',
                    name: 'Перемещение'
                },
                {
                    id: 'production',
                    name: 'Производство'
                },
            ],
        }
    },
    methods: {
        getTypeName(type) {
            return this.storehouseActionTypes.find(el => el.key === type)?.name ?? '-'
        },
    }
}
