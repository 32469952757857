export default {
    data() {
        return {
            tabs: [{
                route: 'StorehouseInfo',
                name: 'Остатки'
            }, {
                route: 'StorehouseTurnover',
                name: 'Обороты'
            }, {
                route: 'StorehouseOperations',
                name: 'Операции'
            }, {
                route: 'StorehouseUnits',
                name: 'Склады'
            },],
        }
    }
}
