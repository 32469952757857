<template>
    <div class="storehouse-actions">
        <div
            v-if="!form.good_id || goodBalanceTotal <= 0"
            class="storehouse-actions__callout"
            :class="{
              'storehouse-actions__callout--danger': !form.good_id || !form.unit_from_id,
              'storehouse-actions__callout--warning': form.good_id && goodBalanceTotal <= 0 && form.unit_from_id
            }"
        >
            <TriangleWarningIcon :currentColor="true" width="20" height="20" :test="true" />
            <p> {{ getGoodTotalMessage }} </p>
        </div>
        <div
          v-else
          class="storehouse-actions__callout"
          :class="{
            'storehouse-actions__callout--success': goodBalanceTotal > 0
          }"
        >
            <p>
                Остаток по складу для продукции {{ selectedGood.name }}:
                {{ formatNumber(goodBalanceTotal, 2) }}
                {{ getData(form.good_id, 'good', 'unit') ?? 'ед.' }}
            </p>
        </div>

        <el-form class="storehouse-actions__form" label-position="left" :label-width="windowWidth > 860 ? '140' : '110'">
            <FormDateTimePicker
                class="pointer"
                v-model="form.date"
                type="datetime"
                label="Дата и время"
                editable="false"
                size="default"
                :customIcon="true"
                color="grey"
            />

            <div class="d-flex gap-8 storehouse-actions__form-count">
                <FormNumber
                    class="mb-8"
                    v-model="form.count"
                    label="Количество"
                    required="true"
                    placeholder="Пр.: 100"
                    step="0.1"
                    :min="0"
                    color="grey"
                />

                <FormSelect
                    extraClass="storehouse-actions__form-select--unit"
                    v-model="form.good_unit_id"
                    label="Ед. изм."
                    :label-width="windowWidth > 560 ? '70' : null"
                    required="true"
                    :items="unitsList"
                    placeholder="кг."
                    :min="0"
                    color="grey"
                    label-key="short_name"
                />
            </div>

            <FormSelect
                v-model="form.good_id"
                label="Продукция"
                placeholder="Выберите продукцию"
                new-item="good"
                good-type="good"
                required="true"
                @getChangedValue="changeGood"
                color="grey"
            />

            <FormNumber
                v-model="form.price"
                label="Цена (за 1 ед)"
                placeholder="Например: 1000"
                :precision="2"
                :min="0"
                color="grey"
            />

            <FormSelect
                v-model="form.action"
                label="Тип операции"
                placeholder="Выберите тип операции"
                :items="storehouseUnits.length > 1 ? storehouseActionTypes : storehouseActionTypesWithoutMove"
                required="true"
                color="grey"
            />

            <FormVatInput
                v-model:vat="form.vat"
                v-model:vatInPrice="form.vat_in_price"
                label="Налог в стоимости"
                size="large"
                color="grey"
                placeholder="Например: 20"
            />

            <FormSelect
                v-model="form.unit_id"
                label="Склад"
                placeholder="Выберите склад"
                required="true"
                :items="storehouseUnitsList"
                color="grey"
            />

            <FormNumber
                v-model="fullPrice"
                label="Стоимость, итого"
                @input="updateCost"
                placeholder="Например: 1000"
                :precision="2"
                :min="0"
                color="grey"
            />
            <FormSelectCompany
                v-if="showCompanyInput"
                v-model="form.company_id"
                label="Контрагент"
                placeholder="Выберите контрагента"
                size="large"
                color="grey"
            />

            <FormSelect
                :extraClass="windowWidth > 560 && form.action !== 'move' ? 'hidden' : null"
                v-model="form.unit_from_id"
                label="Склад списания"
                placeholder="Выберите, откуда переместить"
                required="true"
                :items="storehouseUnitsToList"
                color="grey"
            />
        </el-form>

        <hr class="storehouse-actions__hr mt-16 mb-24"/>

        <div class="d-flex">
            <el-button class="storehouse-actions__save-btn"
                @click="submit" :loading="loadingButton"
                type="primary"
                :disabled="!form.good_id || !form.count || !form.action || !form.unit_id || loadingButton"
                size="large">
                Сохранить
            </el-button>
        </div>
    </div>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormSelectCompany from "@/views/components/Form/Select/FormSelectCompany.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import FormVatInput from "@/views/components/Form/FormVatInput.vue";
import iconsMixin from "@/mixins/iconsMixin";
import storehouseActionTypes from "@/mixins/storehouseActionTypes"
import TriangleWarningIcon from "@/views/components/Icons/TriangleWarningIcon.vue";
import moment from "moment";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'StorehouseActionsForm',
    props: [
        'storehouseUnits', // склады
        'goods' // продукция
    ],
    data() {
        return {
            loading: true,
            loadingButton: false,
            selectedGood: null,
            form: {
                date: moment(),
                unit_id: this.storehouseUnits.length === 1 ? this.storehouseUnits[0]?.id : null,
                unit_from_id: null,
                price: null,
                action: null,
                count: null,
                vat: null,
                vat_in_price: false,
                good_id: null,
                company_id : null,
                good_unit_id: 5, // ед. изм
            },
            goodBalanceTotal: null,
            remainsLoading: false,
            fullPrice: null
        }
    },
    components: {
        FormDateTimePicker,
        FormSelect,
        FormNumber,
        FormSelectCompany,
        TriangleWarningIcon,
        // FormSwitch,
        FormVatInput,
    },
    mixins: [
        mobileCheckMixin, functionsMixin, iconsMixin, storehouseActionTypes
    ],
    inject: [
        'api'
    ],
    created() {
    },
    computed: {
        ...mapState(useCommonStore, {
            units: 'getUnits',
        }),
        selectDialogTitle() {
            const titles = {
                document_goods: 'Выберите продукцию',
                downtime: 'Выберите тариф на простой 123456',
                pump: 'Выберите тариф на насос',
                mix: 'Выберите тариф на доставку',
                addresses: 'Выберите объект',
            }
            return titles[this.tab]
        },
        storehouseUnitsList() {
            if(!this.form.unit_from_id) return this.storehouseUnits

            return [...this.storehouseUnits].filter((unit) => unit.id !== this.form.unit_from_id)
        },
        storehouseUnitsToList() {
            if(!this.form.unit_id) return this.storehouseUnits

            return [...this.storehouseUnits].filter((unit) => unit.id !== this.form.unit_id)
        },
        getGoodTotalMessage() {
            const message = 'Загрузка остатков по складу'
            if (!this.form.good_id) {
              return 'Выберите продукцию';
            }
            if (!this.form.unit_id) {
              return 'Выберите склад';
            }
            if (!this.form.unit_from_id && this.form.action === 'move') {
              return 'Выберите склад списания';
            }
            if (this.goodBalanceTotal === null && !this.remainsLoading) {
              return 'Продукция отсутствует на складе';
            }
            if (this.goodBalanceTotal === 0 && !this.remainsLoading) {
              return 'Нет остатков продукции на складе';
            }
            return message
        },
        storehouseActionTypesWithoutMove() {
            return this.storehouseActionTypes.filter((type) => type.id !== 'move')
        },
        showCompanyInput() {
            return this.form.action
                && this.form.action !== 'posting'
                && this.form.action !== 'remove'
                && this.form.action !== 'move'
        },
        unitsList() {
            if(!this.selectedGood || !this.form.good_id) return this.units
            if(this.selectedGood.unit_id !== 5 && this.selectedGood.unit_id !== 7) return this.units

            return this.units.filter((u) => u.id === 5 || u.id === 7)
        },
        isMoveAction() {
            return this.form.action === 'move'
        }
    },
    methods: {
        getContract(id) {
            this.loading = true
            httpService().post(`/api/document/getOne/${id}`).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit() {
            this.loadingButton = true
            const isValid = true;
            if (!isValid) {
                this.loadingButton = false
                return
            }

            httpService().post(this.api.storehouseValue.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse',
                module: this.form
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.$message({
                        message: 'Значение добавлено',
                        showClose: true,
                        type: 'success'
                    })

                    this.loadingButton = false;
                    this.resetForm();
                    this.$emit('update')
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const fieldErrors = error.response.data.errors;

                    Object.keys(fieldErrors).forEach((field) => {
                        fieldErrors[field].forEach((message) => {
                            this.$message({
                                message: `${message}`,
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: false
                            });
                        });
                    });
                } else {
                    this.$message({
                        message: error.response?.data?.message,
                        type: 'error',
                        showClose: true
                    });
                }
                this.loadingButton = false;
            })
        },
        getRemains() {
            this.remainsLoading = true
            httpService().post(this.api.storehouseValue.getRemains, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse',
                storehouse_unit_id: this.form[this.isMoveAction ? 'unit_from_id' : 'unit_id'],
                good_id: this.form.good_id
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.goodBalanceTotal = data.total ? +data.total : null;
                }
            }).catch((error) => {
                this.$message({
                        message: error.response?.data?.message,
                        type: 'error',
                        showClose: true
                    });
                }).finally(() => {
                    this.remainsLoading = false;
                })
        },
        resetForm() {
            this.form = {
                unit_id: null,
                unit_from_id: null,
                price: null,
                action: null,
                count: null,
                vat: null,
                vat_in_price: false,
                company_id: null,
                good_id: null,
                good_unit_id: null
            }
        },
        changeGood(val) {
            this.selectedGood = val;
            this.form.good_unit_id = val.unit_id;
        },
        updateFullPrice() {
            let total;
            if (this.form.vat_in_price) {
                total = this.form.price * this.form.count;
            } else {
                total = this.form.price * this.form.count * (1 + this.form.vat / 100);
            }
            // Округляем итоговую стоимость до двух знаков после запятой
            this.fullPrice = total == 0 ? null : parseFloat(total.toFixed(2));
        },
        updateCost() {
            // Обновление стоимости при изменении итоговой стоимости

            if (this.form.count === 0) {
                this.form.price = 0;
                return;
            }
            let cost;
            if (this.form.vat_in_price) {
                cost = this.fullPrice / this.form.count;
            } else {
                cost = this.fullPrice / (this.form.count * (1 + this.form.vat / 100));
            }
            // Округляем стоимость до двух знаков после запятой
            this.form.price = cost == 0 ? null : parseFloat(cost.toFixed(2));
        }
    },
    watch: {
        'form.action': {
            handler(val) {
                if(val !== 'move' && this.form.unit_from_id) this.form.unit_from_id = null
            }
        },
        'form.unit_from_id': {
            handler(val, prevVal) {
                if(val && this.form.good_id) {
                    this.getRemains()
                }
                if(!val && prevVal) {
                    this.goodBalanceTotal = null
                }
            }
        },
        'form.unit_id': {
            handler(val, prevVal) {
                if(val && this.form.good_id && this.form.action !== 'move') {
                    this.getRemains()
                }
                if(!val && prevVal) {
                    this.goodBalanceTotal = null
                }
            }
        },
        'form.good_id': {
            handler(val, prevVal) {
                if(val && this.form[this.isMoveAction ? 'unit_from_id' : 'unit_id']) {
                    this.getRemains()
                }
                if(!val && prevVal) {
                    this.goodBalanceTotal = null
                }
            }
        },
        'showCompanyInput': {
            handler(val) {
                if(!val) this.form.company_id = null
            }
        },
        'form.price': {
            handler() {
                this.updateFullPrice();
            }
        },
        'form.count': {
            handler() {
                this.updateFullPrice();
            }
        },
        'form.vat': {
            handler() {
                this.updateFullPrice();
            }
        },
        'form.vat_in_price': {
            handler() {
                this.updateFullPrice();
            }
        },
    }
}
</script>

<style lang="scss">
@use "@/sass/_variables";
.storehouse-actions {
    &__form {
        padding: 8px 8px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;

        @media screen and (max-width: 860px) {
            grid-template-columns: 1fr;
        }


        &-count {

            @media screen and (max-width: 560px) {
                flex-direction: column;
            }

            .el-form-item:first-child {
                @media screen and (max-width: 860px) {
                    flex-grow: 1;
                }
                .el-form-item__content {
                    max-width: 86px !important;


                    @media screen and (max-width: 860px) {
                        max-width: initial !important;
                        flex-grow: 1;
                    }

                    .el-input__wrapper {
                        padding-right: 2px;
                    }
                }
            }

            .storehouse-actions__form-select--unit {
                .el-form-item__label {
                    padding-right: 0;
                    max-width: 70px;

                    @media screen and (max-width: 560px) {
                        max-width: initial !important;
                    }
                }

                .el-form-item__content {
                    max-width: 92px !important;

                    @media screen and (max-width: 560px) {
                        max-width: initial !important;
                    }
                }
            }
        }
    }

    &__callout {
        width: fit-content;
        padding: 8px 12px;
        border-radius: 8px;
        margin-block: 0 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        p {
            margin: 0;
            font-weight: 500;
        }
        &--danger {
            color: variables.$danger200;
            background: variables.$danger50;
        }
        &--warning {
            color: variables.$warning400;
            background: variables.$warning50;
        }
        &--success {
            color: variables.$success600;
            background: variables.$success50;
        }

    }

    &__save-btn {
        padding: 12px 24px !important;
        height: 44px !important;
        box-shadow: none;
        border-radius: 8px !important;
        width: fit-content;
        font-family: 'Ubuntu';

        .el-icon, svg {
            width: 20px !important;
            height: 20px !important;
        }
    }

    &__hr {
        border-color: variables.$neutral50;
    }
}
</style>
