export default {
    data() {
        return {
            serviceTypes: [{
                id: 'mix',
                name: 'Доставка бетона'
            }, {
                id: 'pump',
                name: 'Услуги бетононасоса'
            }, {
                id: 'downtime',
                name: 'Неустойка за простой на объекте'
            }, {
                id: 'other',
                name: 'Прочее'
            }],
            dependsTypes: [{
                id: 'distance',
                name: 'Расстояние'
            }, {
                id: 'time',
                name: 'По времени'
            }, {
                id: 'geo',
                name: 'По геозонам'
            }, {
                id: 'other',
                name: 'Ручной'
            }],
            priceTypes: [{
                id: 'by_one',
                name: 'За единицу'
            }, {
                id: 'by_all',
                name: 'За все единоразово'
            }],
            priceTypesDowntime: [{
                id: 'free',
                name: 'Бесплатно'
            }, {
                id: 'by_one',
                name: 'За час'
            }],
            priceTypesPump: [{
                id: 'by_one',
                name: 'За один м3'
            }, {
                id: 'by_all',
                name: 'За все единоразово'
            }],
            mixVolumeType:[{
                id: 'group',
                name: 'По объему заказа'
            }, {
                id: 'order',
                name: 'По объему продукция'
            }, {
                id: 'vehicle',
                name: 'По объему миксера'
            }],
        }
    },
}
