<template>
    <div class="filter-date-period" :class="[`filter-date-period--${typePicker}`]">
        <el-tabs class="filter-date-period__tabs" v-model="typePicker">
            <el-tab-pane label="Мес." name="month" />
            <el-tab-pane label="Нед." name="week" />
            <el-tab-pane label="Сег." name="today" />
            <el-tab-pane label="Вч." name="yesterday" />
            <el-tab-pane label="Пер." name="daterange" />
        </el-tabs>
        <div class="filter-date-period__date-picker-wrapper">
            <el-button
                class="filter-date-period__date-picker-button filter-date-period__date-picker-button--left"
                :icon="icons.arrowLeft"
                v-if="typePicker === 'month' || typePicker === 'week'"
                @click="prevPeriod"
            />
            <el-date-picker
                class="filter-date-period__date-picker"
                v-model="itemDate"
                :type="
                    typePicker === 'today' || typePicker === 'yesterday'
                        ? 'normal'
                        : typePicker
                "
                :format="format"
                :disabled="disabled"
                :prefix-icon="customIcon"
                :placeholder="placeholder ?? 'Выберите дату'"
                :size="size ?? 'default'"
                :editable="editable"
                :readonly="readonly"
                :clearable="false"
                @focusout="$emit('focusoutAction')"
                @change="convertToTimezone"
            />
            <el-button
                class="filter-date-period__date-picker-button filter-date-period__date-picker-button--right"
                :icon="icons.arrowRight"
                v-if="typePicker === 'month' || typePicker === 'week'"
                @click="nextPeriod"
            />
        </div>
    </div>
</template>

<script>
import DateIconWithDots from "@/views/components/Icons/DateIconWithDots";
import moment from "moment";
import "moment/locale/ru";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "FilterDatePeriod",
    mixins: [functionsMixin, iconsMixin],
    props: [
        "modelValue",
        "type",
        "label",
        "placeholder",
        "disabled",
        "default-time",
        "required",
        "size",
    ],
    data() {
        return {
            customIcon: DateIconWithDots,
            itemDate: moment.tz(this.moduleTimezone).startOf("month"),
            typePicker: "month",
        };
    },
    watch: {
        'itemDate': {
            handler: function (val) {
                this.$emit('update:modelValue', val
                ? val
                : null)
                this.$emit('updateDate', val, this.typePicker)
            },
            deep: true,
            immediate: true
        },
        modelValue: {
            handler: function () {
                this.itemDate = this.modelValue;
            },
            deep: true,
            immediate: true,
        },
        typePicker: {
            handler: function (val) {
                if (val === "today" || val === "yesterday") {
                    this.itemDate =
                        val === "today" ? this.today : this.yesterday;
                }
                if (val === "daterange") {
                    this.itemDate = this.daterange;
                }
                if (val === "week") {
                    this.itemDate = this.week;
                }
                if (val === "month") {
                    this.itemDate = this.month;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        format() {
            // if (this.formatDate) {
            //     return this.formatDate
            // }

            if (this.typePicker && this.typePicker === "daterange") {
                return "DD.MM.YY";
            }

            if (this.typePicker && this.typePicker === "week") {
                return this.formatDate();
            }

            if (this.typePicker && this.typePicker === "month") {
                return this.formatDate();
            }

            if (
                (this.typePicker && this.typePicker === "today") ||
                (this.typePicker && this.typePicker === "yesterday")
            ) {
                return this.formatDate();
            }

            return "DD.MM.YY";
        },
        yesterday() {
            return moment.tz(this.moduleTimezone).subtract(1, "days").startOf("day");
        },
        today() {
            return moment.tz(this.moduleTimezone).startOf("day");
        },
        week() {
            return moment.tz(this.moduleTimezone).startOf("week");
        },
        month() {
            return moment.tz(this.moduleTimezone).startOf("month");
        },
        daterange() {
            return [moment.tz(this.moduleTimezone).startOf("month"), moment.tz(this.moduleTimezone).endOf("month")];
        },
        readonly() {
            return (
                this.typePicker === "yesterday" || this.typePicker === "today"
            );
        },
    },
    methods: {
        formatDate() {
            const selectedDate = moment(this.itemDate, this.moduleTimezone);

            if (this.typePicker === "week") {
                const startOfWeek = selectedDate.startOf("week").format("D");
                const endOfWeek = selectedDate.endOf("week").format("D MMMM");

                // return `Неделя, ${startOfWeek}-${endOfWeek}`;
                return `${startOfWeek}-${endOfWeek}`;
            }
            if (this.typePicker === "month") {
                const dateString = selectedDate.format("MMMM, YYYY");
                return (
                    dateString.charAt(0).toUpperCase() +
                    String(dateString).slice(1)
                );
            }
            if (selectedDate.isSame(this.today, "day"))
                // return `Сегодня, ${selectedDate.format("D MMMM")}`;
                return `${selectedDate.format("D MMMM")}`;
            if (selectedDate.isSame(this.yesterday, "day"))
                // return `Вчера, ${selectedDate.format("D MMMM")}`;
                return `${selectedDate.format("D MMMM")}`;

            return selectedDate.format("D MMMM");
        },
        prevPeriod() {
            this.itemDate = moment(this.itemDate, this.moduleTimezone).subtract(1, this.typePicker);
        },
        nextPeriod() {
            this.itemDate = moment(this.itemDate, this.moduleTimezone).add(1, this.typePicker);
        },
    },
};
</script>

<style lang="scss" scoped>
@use "@/sass/_variables";

.filter-date-period {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 170px;

    &--week, &--month {
        &:deep(.el-input) {
            .el-input__wrapper, .el-input__inner {
                cursor: pointer;
            }
        }
    }

    &--week, &--month, &--today, &--yesterday {
        &:deep(.el-input) {
            .el-input__wrapper {
                box-shadow: none !important;
            }

            .el-input__prefix {
                display: none;
            }

            .el-input__inner {
                display: block;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                font-family: 'Ubuntu';
            }
        }

        .filter-date-period__date-picker-wrapper {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background: variables.$neutral100;
            }
        }
   }

    &:deep(.el-tabs) {
        .el-tabs__header {
            margin-bottom: 0;
        }

        .el-tabs__nav {
            display: flex;
            gap: 4px;
            justify-content: space-between;
            width: 100%;
        }

        .el-tabs__active-bar {
            display: none;
        }

        .el-tabs__nav-wrap {
            &:after {
                display: none;
            }
        }

        .el-tabs__item {
            padding: 0;
            height: 12px;
            font-size: 12px;
            color: variables.$neutral100 !important;

            &.is-active {
                color: variables.$primary600 !important;
            }
        }
    }

    &:deep(.el-date-editor) {
        max-width: 170px;
        height: 30px;
        border-radius: 8px;
        box-shadow: 0 0 0 1px variables.$neutral100 inset;

        .el-range-input {
            max-width: 80px;
            font-size: 14px;
            font-weight: 500;
            font-family: 'Ubuntu';
        }

        .el-input {
            height: 26px;
        }

        .el-range__close-icon {
            display: none;
        }

        .el-icon {
            width: 18px;
            padding-right: 4px;

            svg {
                path {
                    fill: variables.$regular;
                }
            }
        }

        .el-range-separator {
            padding: 0;
        }
    }

    &__date-picker {
        width: 100%;

        &-wrapper {
            position: relative;
        }

        &-button {
            padding: 0;
            height: 16px !important;
            width: 16px !important;
            position: absolute;
            top: 0;
            transform: translate(0, 50%);
            z-index: 2;
            border: 0;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }
    }
}
</style>
