<template>
    <el-row :gutter="20">
        <el-col :sm="12">
            <CRMInfoText
                head="Тип оплаты"
                :text="getInfo(service.price_type, priceTypes)"
            />
            <CRMInfoText
                head="Цена"
                :text="service.good_price?.price"
            />
            <CRMInfoText
                head="Цена включена в НДС"
                :text="service.good_price?.vat_in_price ? 'Да' : 'Нет'"
            />
            <CRMInfoText
                head="НДС"
                :text="service.good_price?.vat + '%'"
            />
<!--            <CRMInfoText-->
<!--                head="Цена (c НДС)"-->
<!--                :text="service.good_price.vat_in_price ? service.good_price.price :-->
<!--                service.good_price.price * (!service.good_price.vat ? 1 : service.good_price.vat)"-->
<!--            />-->
            <CRMInfoText
                head="Тип подсчета"
                :text="getInfo(service.mix_volume_type, mixVolumeType)"
            />
        </el-col>
        <el-col :sm="12">
            <CRMInfoText
                head="Объем ОТ"
                :text="service.volume_min ?? 0"
            />
            <CRMInfoText
                head="Объем ДО"
                :text="service.volume_max ?? 0"
            />
        </el-col>
    </el-row>
</template>

<script>
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import serviceInfo from "@/mixins/serviceInfo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "ServiceDetailsMixOther",
    components: {CRMInfoText},
    props: ['service'],
    mixins: [serviceInfo, functionsMixin]
}
</script>

<style scoped>

</style>
