<template>
    <div v-if="user.id === 1">
        <div class="p-4" style="color: black;">
            <h2 class="text-xl font-bold mb-4">Поиск зависимостей таблицы</h2>

            <div class="mb-4">
                <label class="block mb-2">Выберите таблицу:</label>
                <select v-model="selectedTable" class="border p-2 rounded w-full">
                    <option value="" disabled>-- Выберите таблицу --</option>
                    <option v-for="table in tables" :key="table" :value="table">
                        {{ table }}
                    </option>
                </select>
            </div>

            <button
                @click="fetchDependencies()"
                class="bg-blue-500 text-white px-4 py-2 rounded"
                :disabled="!selectedTable"
            >
                Найти зависимости
            </button>

            <div v-if="loading" class="mt-4 text-gray-500">Загрузка...</div>

            <table v-if="dependencies.length" class="mt-4 w-full border">
                <thead>
                <tr class="bg-gray-100">
                    <th class="border p-2">Таблица</th>
                    <th class="border p-2">Колонка</th>
                    <th class="border p-2">Ссылается на</th>
                    <th class="border p-2">Колонка</th>
                    <th class="border p-2">Удаление</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="dep in dependencies" :key="dep.referencing_table" class="border-b">
                    <td class="border p-2">{{ dep.referencing_table }}</td>
                    <td class="border p-2">{{ dep.referencing_column }}</td>
                    <td class="border p-2">{{ dep.referenced_table }}</td>
                    <td class="border p-2">{{ dep.referenced_column }}</td>
                    <td class="border p-2">{{ dep.delete_rule }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import {mapState} from 'pinia';
import {useCommonStore} from "@/store/common";
import {httpService} from "@/services/http.service";

//import moment from "moment";
export default {
    name: "ForeignKeyInfo",
    components: {

    },
    inject: ['api'],
    mounted() {
        console.log('ForeignKeyInfo');
    },
    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser',
        }),
    },
    created() {
        this.fetchTables();
    },
    data() {
        return {
            tables : [],
            dependencies: [],
            selectedTable: null,
            loading: false,
        };
    },
    methods: {
        async fetchTables() {
            try {
                const response = await httpService().post(this.api.test.getTables);
                this.tables = response.data;
            } catch (error) {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            }
        },
        async fetchDependencies() {
            if (!this.selectedTable) {
                return;
            }

            this.loading = true;
            this.error = null;

            try {
                const response = await httpService().post(this.api.test.getDependencies, {
                    table: this.selectedTable,
                });

                this.dependencies = response.data;
            } catch (error) {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<style>
.border.p-2 {
    padding: 2px 5px;
    border: 1px black double;
}
</style>
