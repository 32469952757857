export default {
    data() {
        return {
            viewModes: [{
                id: 'by_product',
                name: 'По продукции'
            }, {
                id: 'by_storehouse',
                name: 'По складам'
            },
        ]}
    },
}
