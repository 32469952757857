<template>
    <svg :class="{'currentColor': currentColor}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 18L6 6" stroke="#151517" :stroke-width="strokeWidth ?? '2'" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 6L5.99994 18" stroke="#151517" :stroke-width="strokeWidth ?? '2'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
</template>

<script>
export default {
    name: "CloseMDIcon",
    props: ['color', 'currentColor', 'strokeWidth', 'width', 'height']
}
</script>

<style scoped lang="scss">
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
