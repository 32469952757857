<template>
    <svg
        :class="[{ currentColor: currentColor }]"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        x="0"
        y="0"
        viewBox="0 0 64 64"
        xml:space="preserve"
        class="serviceIcon"
    >
        <g>
            <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            >
                <circle cx="21" cy="46" r="3" fill="" opacity="1"></circle>
                <circle cx="50" cy="46" r="3" fill="" opacity="1"></circle>
                <circle cx="11" cy="46" r="3" fill="" opacity="1"></circle>
                <path d="m25 39 4 6h14" fill="" opacity="1"></path>
                <path
                    d="M47 39V21h6.353a3 3 0 0 1 2.531 1.39l5.647 8.873A3 3 0 0 1 62 32.874V43.5a1.5 1.5 0 0 1-1.5 1.5h-2.669a1 1 0 0 1-.972-.767A7.276 7.276 0 0 0 52 39H8l-4 5H2V33l45-3"
                    fill=""
                    opacity="1"
                ></path>
                <path
                    d="M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M25.27 31.45 5.52 24.14a.969.969 0 0 1-.66-.71l-.76-2.16a.857.857 0 0 1-.05-.27.662.662 0 0 1 .01-.28l.71-3.12a.987.987 0 0 1 .9-.68L50.97 15a1.029 1.029 0 0 1 .85.42 1.054 1.054 0 0 1 .11.96L50.52 21M7 21h40M4.05 21H7"
                    fill=""
                    opacity="1"
                ></path>
                <path
                    d="m8 21 25.466 5.877a1.5 1.5 0 0 1 .863.562l2.431 3.241"
                    fill=""
                    opacity="1"
                ></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "PumpServiceIcon",
    props: ["currentColor", "strokeWidth"],
};
</script>

<style scoped>
.currentColor {
    path,
    circle {
        stroke: currentColor;
    }
}
</style>
