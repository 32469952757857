<template>
    <Auth title="Запчасти" :tabs="tabs">
        <div class="card">
            <div class="card-body">
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        create-button-label="Добавить запчасть"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="() => addNewGoodDrawer = true"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
        :filter-items1="storeCategories"
        :filter-items2="vehicles"
    />

    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
        <template v-slot:body>

            <MobileTableHR/>

            <MobileTableInfo
                title="Запчасть"
                :body="item.good?.name ?? '-'"
            />

            <MobileTableInfo
                title="Количество"
            >
                <template v-slot:body>

                    <el-button class="w-100-table-button" type="text" @click="openChangeStoreTotalDialog(item)">
                        {{ formatNumber(item.total) }} {{ item.good?.unit?.short_name ?? 'ед.' }}
                    </el-button>

                </template>
            </MobileTableInfo>

            <MobileTableInfo
                title="Категория"
                :body="item.category_id ? getInfo(item.category_id, storeCategories) : '-'"
            />

            <MobileTableInfo
                title="Техника"
                :no-border="true"
                :body="item.tech_vehicle_id ? getInfo(item.tech_vehicle_id, vehicles) : '-'"
            />


            <MobileTableButton
                @action="openStoreDetailsDrawer(item)"
            />
        </template>
    </MobileTableFramework>

    <el-table
        v-if="!mobile"
        :data="displayData"
        class="w-100 mt-15"
        v-loading="loading">
        <el-table-column
            prop="good_id"
            label="Запчасть"
            width="450px"
        >
            <template v-slot="scope">
                <span class="textMedium neutral900">{{ scope.row.good?.name ?? '-' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="Количество"
        >
            <template v-slot="scope">
                <el-button class="w-100-table-button" type="text" @click="openChangeStoreTotalDialog(scope.row)">
                    {{ formatNumber(scope.row.total) }} {{ scope.row.good?.unit?.short_name ?? 'ед.' }}
                </el-button>
            </template>
        </el-table-column>
        <el-table-column
            prop="category_id"
            label="Категория"
        >
            <template v-slot="scope">
                {{ scope.row.category_id ? getInfo(scope.row.category_id, storeCategories) : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="tech_vehicle_id"
            label="Техника"
        >
            <template v-slot="scope">
                {{ scope.row.tech_vehicle_id ? getInfo(scope.row.tech_vehicle_id, vehicles) : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            label=""
            width="140">
            <template v-slot="scope">
                <el-button size="mini" @click="openStoreDetailsDrawer(scope.row)">Подробнее</el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />

            </div>
        </div>

    <el-dialog
        v-model="changeStoreTotalDialog"
        :title="storeDetails ? storeDetails.good?.name : '-'"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
        :width="drawerSize"
    >
        <TechChangeStoreTotal
            :details="storeDetails"
            @closeDialog="this.changeStoreTotalDialog=false"
            @moduleGet="moduleGet"
        />
    </el-dialog>

    <el-drawer
        v-model="addNewGoodDrawer"
        title=""
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <TechAddNewStore
            :vehicles="vehicles"
            @closeDrawer="addNewGoodDrawer=false"
            @moduleGet="moduleGet"
        />

    </el-drawer>

    <el-drawer
        v-model="storeDetailsDrawer"
        title=""
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <TechStoreDetails
            :details="storeDetails"
            :vehicles="vehicles"
            @closeDrawer="storeDetailsDrawer=false"
            @moduleGet="moduleGet"
        />

    </el-drawer>
    </Auth>
</template>

<script>
// components


import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import Filter from "@/views/components/Table/Filter/Filter";
import {httpService} from "@/services/http.service";
import {mapState} from "pinia/dist/pinia";
import {useCommonStore} from "@/store/common";
import TechAddNewStore from "@/views/components/TechPage/Store/TechAddNewStore";
import techStoreCategoriesMixin from "@/mixins/techStoreCategoriesMixin";
import TablePagination from "@/views/components/Table/TablePagination";
import TechStoreDetails from "@/views/components/TechPage/Store/TechStoreDetails";
import TechChangeStoreTotal from "@/views/components/TechPage/Store/TechChangeStoreTotal";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableHR from "@/views/components/Mobile/Table/MobileTableHR";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";
import Auth from "@/views/layouts/Auth.vue";
import techTabs from "@/mixins/techTabs";

export default {
    name: 'TechStoreTab',
    props: ['tab'],
    components: {
        Auth,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TechChangeStoreTotal, TechStoreDetails, TablePagination, TechAddNewStore, Filter, TableHeadBlock},
    inject: ['api'],
    mixins: [tableMixin, functionsMixin, techStoreCategoriesMixin, mobileCheckMixin, techTabs],
    data() {
        return {
            loading: false,
            filter: {
                categoryId: {
                    value: null,
                    type: 'select',
                    label: 'Категория',
                    placeholder: 'Выберите категорию',
                    items: '1'
                },
                techId: {
                    value: null,
                    type: 'select',
                    label: 'Техника',
                    placeholder: 'Выберите технику',
                    items: '2'
                },
            },
            addNewGoodDrawer: false,
            storeDetailsDrawer: false,
            storeDetails: null,
            name: null,
            vehicles: [],
            changeStoreTotalDialog: false,
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        })
    },
    created() {
        this.vehiclesGet()
        this.moduleGet()
    },
    methods: {
        vehiclesGet() {
            this.loading = true
            httpService().post(this.api.tech.getTech, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.vehicles = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        moduleGet() {
            this.loading = true
            httpService().post(this.api.tech.getTechGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        openChangeStoreTotalDialog(details) {
            this.storeDetails = details
            this.changeStoreTotalDialog = true
        },
        openStoreDetailsDrawer(details) {
            this.storeDetails = details
            this.storeDetailsDrawer = true
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.good.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.categoryId.value) this.filtered = this.filtered.filter(data => data.category_id === this.filter.categoryId.value)
            if (this.filter.techId.value) this.filtered = this.filtered.filter(data => data.tech_vehicle_id === this.filter.techId.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
