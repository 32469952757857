<template>
    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
        <template v-slot:body>

            <MobileTableHR/>

            <MobileTableInfo
                title="Материал"
            >
                <template v-slot:body>
                    <span class="cutOverflow textSM textMedium neutral700">
                        {{ item.good_id ? getData(item.good_id, 'good') : '-' }}
                    </span>
                </template>
            </MobileTableInfo>

            <MobileTableInfo
                title="По рецепту"
            >
                <template v-slot:body>
                    <span class="cutOverflow textSM textMedium primary600">
                        {{ item.totalFormula ? formatNumber(item.totalFormula, 2) : 0 }} <good-unit
                        :unit="item.good_id ? getData(item.good_id, 'good', 'unit') : ''"/>
                    </span>
                </template>
            </MobileTableInfo>

            <MobileTableInfo
                title="Факт"
            >
                <template v-slot:body>
                    <span :class="'cutOverflow textSM textMedium ' + getColor(item.totalFormula, item.totalFact)">
                    {{ item.totalFact ? formatNumber(item.totalFact, 2) : 0 }} <good-unit
                        :unit="item.good_id ? getData(item.good_id, 'good', 'unit') : ''"/>
                    </span>
                </template>
            </MobileTableInfo>

            <MobileTableInfo
                title="Корректировка"
            >
                <template v-slot:body>
                    <el-tooltip content="Корректировка по влажности" placement="top">
                        <el-button type="text" class="neutral300">{{ item.adjustmentMoisture ?? 0 }} (вл)</el-button>
                    </el-tooltip>
                    /
                    <el-tooltip content="Корректировка авто" placement="top">
                        <el-button type="text" class="neutral300">{{ item.adjustmentAuto ?? 0 }} (а)</el-button>
                    </el-tooltip>
                    /
                    <el-tooltip content="Корректировка ручная" placement="top">
                        <el-button type="text" class="neutral300">{{ item.adjustmentManual ?? 0 }} (р)</el-button>
                    </el-tooltip>
                </template>
            </MobileTableInfo>

        </template>
    </MobileTableFramework>

    <el-table
        v-loading="loading"
        ref="table"
        v-if="!mobile"
        empty-text="Нет информации о материалах"
        :data="displayData"
        class="w-100">
        <el-table-column
            prop="goodId"
            label="Материал"
            width="220px"
        >
            <template v-slot="scope">
                <span class="cutOverflow textSM textMedium neutral900">{{
                        scope.row.good?.name ?? '-'
                    }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="totalFormula"
            label="По рецепту"
            width="190px"
        >
            <template v-slot="scope">
                <span class="cutOverflow textSM textMedium primary600">{{
                        scope.row.totalFormula ? formatNumber(scope.row.totalFormula, 2) : 0
                    }} <good-unit :unit="scope.row.good?.unit?.short_name ?? ''"/></span>
            </template>
        </el-table-column>
        <el-table-column
            prop="totalFact"
            label="Факт"
            width="190px"
        >
            <template v-slot="scope">
                <span :class="'cutOverflow textSM textMedium ' + getColor(scope.row.totalFormula, scope.row.totalFact)">{{
                        scope.row.totalFact ? formatNumber(scope.row.totalFact, 2) : 0
                    }} <good-unit :unit="scope.row.good?.unit?.short_name ?? ''"/></span>
            </template>
        </el-table-column>
        <el-table-column
            prop="adjustmentMoisture"
            label="Корректировка"
        >
            <template v-slot="scope">
                <div class="cutOverflow textSM textMedium neutral300">
                    <el-tooltip content="Корректировка по влажности" placement="top">
                        <el-button type="text">{{ scope.row.adjustmentMoisture ?? 0 }} (вл)</el-button>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="adjustmentAuto"
            label=""
        >
            <template v-slot="scope">
                <div class="cutOverflow textSM textMedium neutral300">
                    <el-tooltip content="Корректировка авто" placement="top">
                        <el-button type="text">{{ scope.row.adjustmentAuto ?? 0 }} (а)</el-button>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="adjustmentManual"
            label=""
        >
            <template v-slot="scope">
                <div class="cutOverflow textSM textMedium neutral300">
                    <el-tooltip content="Корректировка ручная" placement="top">
                        <el-button type="text">{{ scope.row.adjustmentManual ?? 0 }} (р)</el-button>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import tableMixin from '../../../../mixins/tableMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import TablePagination from '../../Table/TablePagination'
import GoodUnit from "@/views/components/goodUnit";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableHR from "@/views/components/Mobile/Table/MobileTableHR";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";

export default {
    name: 'MixOrderMaterialExpenseTable',
    components: {MobileTableInfo, MobileTableHR, MobileTableFramework, GoodUnit, TablePagination},
    props: [
        'materials'
    ],
    mixins: [
        tableMixin, functionsMixin, mobileCheckMixin
    ],
    data() {
        return {
            loading: false,
            values: this.materials
        }
    },
    methods: {
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getColor(formula, fact) {
            if ((fact * 0.95) > formula) {
                return 'danger300'
            } else if ((fact * 1.05) < formula) {
                return 'warning300'
            } else {
                return 'success400'
            }
        }
    },
    watch: {
        'materials': {
            handler: function () {
                this.values = this.materials
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
