<template>
    <div class="faqCard">
        <h3 @click="$router.push({ name: 'FaqView', params: { module } })">
            <component :is="modulesInfo[name]?.icon" />
            {{ modulesInfo[name]?.title }}
          </h3>
        <el-button
            class="faqQuestion"
            v-for="(question, index) in questions?.slice(0, limit ?? 3)"
            @click="$router.push({name: 'FaqViewQuestion', params: { module: question.module, key: question.key }})"
            :key="index"
        >
            {{ index + 1 }}. {{ question.title }}
        </el-button>

        <el-button
            type="text"
            class="pl-0 ml-0"
            @click="$router.push({name: 'FaqView', params: { module: name }})"
            v-if="!unlimited && questions.length > 3"
        >
            + Еще {{ getQuestionText(questions.length - 3)}}
        </el-button>
    </div>
</template>

<script>
import FlashIcon from '@/views/components/Icons/FlashIcon'
import CashboxIcon from '@/views/components/Icons/CashboxIcon'
import WeighingIcon from '@/views/components/Icons/WeighingIcon'
import IntegrationsIcon from '@/views/components/Icons/IntegrationsIcon'
import RelationsIcon from '@/views/components/Icons/RelationsIcon'
import SettingsIcon from "@/views/components/Icons/SettingsIcon";
import MixIcon from "@/views/components/Icons/MixIcon";
import ContractIcon from '@/views/components/Icons/ContractIcon'
import StorehouseIcon from '@/views/components/Icons/StorehouseIcon'
import ProductionIcon from '@/views/components/Icons/ProductionIcon'
import OrderIcon from '@/views/components/Icons/OrderIcon'
import TechIcon from '@/views/components/Icons/TechIcon'
import AdminIcon from '@/views/components/Icons/AdminIcon'
import TTSIcon from '@/views/components/Icons/TTSIcon'
import LogisticsIcon from "@/views/components/Icons/LogisticsIcon";
import FuelIcon from "@/views/components/Icons/FuelIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";
import modulesInfo from '@/mixins/modulesInfo';
import functionsMixin from '@/mixins/functionsMixin';

export default {
    name: 'FaqCard',
    props: ['title', 'questions', 'unlimited', 'name', 'limit'],
    mixins: [modulesInfo, functionsMixin],
    components: {SettingsIcon, RelationsIcon, IntegrationsIcon, WeighingIcon, CashboxIcon, FlashIcon,
        ContractIcon, StorehouseIcon, ProductionIcon, OrderIcon, TechIcon, AdminIcon, TTSIcon, MixIcon,
        LogisticsIcon, FuelIcon, DispatchIcon, LabIcon, CRMIcon
    },
    data() {
        return {
            iconMap: {
                settings: 'SettingsIcon',
                flash: 'FlashIcon',
                cashbox: 'CashboxIcon',
                weighing: 'WeighingIcon',
                integrations: 'IntegrationsIcon',
                relations: 'RelationsIcon',
                contract: 'ContractIcon',
                storehouse: 'StorehouseIcon',
                production: 'ProductionIcon',
                order: 'OrderIcon',
                tech: 'TechIcon',
                godmode: 'AdminIcon',
                tts: 'TTSIcon',
                mix: 'MixIcon',
                logistics: 'LogisticsIcon',
                fuel: 'FuelIcon',
                dispatch: 'DispatchIcon',
                crm: 'CRMIcon',
                lab: 'LabIcon',
            },
        }
    },
    methods: {
        convertToKebabCase(str) {
            return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        },
        getQuestionText(count) {
            if (count % 100 >= 11 && count % 100 <= 14) {
                return `${count} вопросов`;
            }

            const lastDigit = count % 10;

            if (lastDigit === 1) {
                return `${count} вопрос`;
            } else if (lastDigit >= 2 && lastDigit <= 4) {
                return `${count} вопроса`;
            } else {
                return `${count} вопросов`;
            }
        }
    }
}
</script>

<style scoped>

</style>
