<template>
    <div class="converter-container">
        <div class="input-section">
            <el-input
                type="textarea"
                v-model="htmlInput"
                placeholder="Paste your HTML here..."
                rows="12"
            />
        </div>
        <div class="output-section">
            <el-input
                type="textarea"
                v-model="convertedOutput"
                rows="12"
            />
            <el-button type="primary" @click="copyToClipboard">Copy</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            htmlInput: '',
            convertedOutput: ''
        };
    },
    watch: {
        htmlInput(newVal) {
            this.convertedOutput = this.convertHtmlToComponents(newVal);
        }
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard.writeText(this.convertedOutput);
            this.$message.success("Copied to clipboard!");
        },
        convertHtmlToComponents(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");

            let result = "";

            // Extract title
            const title = doc.querySelector(".scribe-title")?.textContent.trim();
            if (title) {
                result += `<GuideTitle text="${title}"/>\n\n`;
            }

            // Extract description
            const description = doc.querySelector(".scribe-description")?.textContent.trim();
            if (description) {
                result += `<GuideDescription text="${description}" />\n\n`;
            }

            result += `<GuideSteps>\n    <template v-slot:steps>\n`;

            const steps = doc.querySelectorAll(".scribe-step, .scribe-screenshot-container");
            let stepNumber = 1;
            let lastStep = null; // Store the last step for attaching images

            steps.forEach((element) => {
                if (element.classList.contains("scribe-step")) {
                    // Process step text
                    const textElement = element.querySelector(".scribe-step-text");
                    if (!textElement) return;

                    let text = textElement.innerHTML.replace(stepNumber + ". ", "").trim();
                    const isTip = textElement.classList.contains("scribe-step-tip");

                    if (isTip) {
                        result += `        <GuideCallout text="${text.replace('Tip: ', '')}" />\n`;
                    } else {
                        lastStep = `        <GuideStep number="${stepNumber}" text='${text}'`;
                        result += lastStep + ` />\n`;
                        stepNumber++;
                    }
                } else if (element.classList.contains("scribe-screenshot-container")) {
                    // Process screenshot
                    const screenshot = element.querySelector("img");
                    if (screenshot && lastStep) {
                        const src = screenshot.getAttribute("src");
                        result = result.replace(/\/>\n$/, `\n            src="${src}">\n        </GuideStep>\n`);
                        lastStep = null; // Reset last step to avoid applying the image multiple times
                    }
                }
            });

            result += `    </template>\n</GuideSteps>`;
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.converter-container {
    display: grid;
    gap: 40px;
    padding: 20px;
    grid-template-columns: repeat(2, 1fr);
    height: 90svh;
    margin: auto;
    &:deep(.el-textarea__inner) {
        height: 90svh;
        border: 2px solid variables.$neutral100;
        border-radius: 8px;
        background: variables.$neutral50;
        box-shadow: none;
        font-size: 14px;
        line-height: 1;
    }
}

.input-section, .output-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.el-button {
    align-self: flex-end;
}
</style>
