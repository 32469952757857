<template>
    <div class="mb-30">

        <template v-if="works.length === 0">
            <h3 v-if="!disableHeading" class="p-0 mb-15 text-500">Список {{ !disableEdit ? 'необходимых' : 'проведенных' }} работ</h3>
            <div class="text-center topCard">
                <EmptyListIcon
                    :height="120"
                    :non-circle="true"
                />
                <p class="text-light" v-if="!disableEdit">Здесь будет показаны работы, после того, как вы их добавите</p>

                <p class="text-light" v-else>Для данного заказ-наряда список работ отсутствует</p>

                <el-button type="text" class="w-100" v-if="!disableEdit" :icon="icons.plus" @click="createWorksDialog=true">Добавить
                    работы
                </el-button>
            </div>
        </template>
        <template v-else>
            <div class="d-flex-full">
                <h3 v-if="!disableHeading" class="p-0 mb-15 text-500">Список {{ !disableEdit ? 'необходимых' : 'проведенных' }} работ</h3>
                <el-button v-if="!disableEdit" :icon="icons.edit" type="text" @click="createWorksDialog=true">Редактировать список
                </el-button>
            </div>
            <div class="mt-10 mb-15">
                <div v-for="(work, index) in works" :key="index" class="serviceWorkLine">
                    <div>{{ index + 1 }}. {{ work.name }}</div>
                    <div>{{ work.price ? work.price + ' руб.' : 'Стоимость не указана' }}</div>
                </div>
            </div>
        </template>

    </div>

    <el-dialog
        v-model="createWorksDialog"
        :title="dialogTitle"
        :width="!mobile ? '50%' : '100%'"
        :destroy-on-close="true"
    >

        <ServiceCreateWorksDialog
            v-model="works"
            @changeTitle="dialogTitle=$event"
            @closeDialog="closeDialog()"
        />

    </el-dialog>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import ServiceCreateWorksDialog from "@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateWorksDialog";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: 'ServiceCreateWorks',
    components: {EmptyListIcon, ServiceCreateWorksDialog},
    props: ['modelValue', 'disableEdit', 'disableHeading', 'techWorkId'],
    inject: ['api'],
    mixins: [mobileCheckMixin, iconsMixin],
    data() {
        return {
            works: this.modelValue,
            createWorksDialog: false,
            dialogTitle: 'Список работ для проведения заказ-наряда'
        }
    },
    methods: {
        closeDialog() {
            this.createWorksDialog = false
            if (this.techWorkId) {
                httpService().post(this.api.tech.saveTechServices, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'tech',
                    techWorkId: this.techWorkId,
                    serviceWorks: this.works
                }).then(() => {

                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    })
                })
            }
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.works = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'works': {
            handler: function () {
                this.$emit('update:modelValue', this.works)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
