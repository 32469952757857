<template>
    <svg :class="[{'currentColor' : currentColor}]" width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5 12.4444L11.5 3.55556C11.5 3.24873 11.3209 3 11.1 3L9.9 3C9.67909 3 9.5 3.24873 9.5 3.55556L9.5 12.4444C9.5 12.7513 9.67909 13 9.9 13H11.1C11.3209 13 11.5 12.7513 11.5 12.4444Z"
            stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M6.5 12.4444L6.5 3.55556C6.5 3.24873 6.32091 3 6.1 3L4.9 3C4.67909 3 4.5 3.24873 4.5 3.55556L4.5 12.4444C4.5 12.7513 4.67909 13 4.9 13H6.1C6.32091 13 6.5 12.7513 6.5 12.4444Z"
            stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "PauseIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
