<template>
    <GuideTitle text="Управление складом запчастей"/>

    <GuideDescription text="Как управлять остатками запчастей" />

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='Перейдите на вкладку "Склад запчастей"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d4e9507f-3289-4e7e-a03f-fa209af012f7/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=335,42">
            </GuideStep>
            <GuideStep number="2" text='Чтобы добавить новую запчасть, нажмите на синюю кнопку справа сверху'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d01c80e2-c56e-4c92-9e05-1277dfe4bb89/ascreenshot.jpeg?tl_px=914,0&br_px=2879,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1011,138">
            </GuideStep>
            <GuideStep number="3" text='Введите название'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/e1c7b978-bcfc-48b0-8771-289e85e43d6e/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=707,100">
            </GuideStep>
            <GuideStep number="4" text='Укажите текущее количество на складе'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/de240c87-f1bd-4fa0-944b-3feb71725202/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=699,175">
            </GuideStep>
            <GuideStep number="5" text='Выберите категорию'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/1347c038-60b9-4987-b2b0-c4a207fa2151/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=671,331">
            </GuideStep>
            <GuideStep number="6" text='Выберите единицу измерения'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/f1582283-d7b9-42f8-a063-3d41cc11c791/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=671,388">
            </GuideStep>
            <GuideStep number="7" text='Нажмите на кнопку "Добавить на склад"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/76d2edcb-6c49-466f-9879-64be5f484577/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=704,521">
            </GuideStep>
            <GuideStep number="8" text='Для изменения количества на складе нажмите на количество в строке интересующей вас запчасти'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/ca2ab78d-8f43-4fc9-bc3f-ac26eeba642c/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=435,195">
            </GuideStep>
            <GuideStep number="9" text='Укажите количество и нажмите "Добавить" или "Списать"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/b5afff10-5f70-4e0e-a38a-a5f91c7abc52/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=378,331">
            </GuideStep>
        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from '@/views/components/Guide/GuideTitle.vue';
import GuideDescription from '@/views/components/Guide/GuideDescription.vue';
import GuideStep from '@/views/components/Guide/GuideStep.vue';
import GuideSteps from '@/views/components/Guide/GuideSteps.vue';

export default {
    name: 'HowManageSparePartsStorehouse',
    components: { GuideTitle, GuideStep, GuideDescription, GuideSteps },
    data() {
        return {}
    },
}
</script>
