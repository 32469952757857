<template>
    <Auth title="Справка">
        <div class="card">
            <div class="card-body">
                <el-button
                    v-if="!isDialog"
                    type="text"
                    :icon="icons.back"
                    class="color-grey mb-15"
                    @click="$router.push({ name: 'FaqIndex' })">Вернуться в основной раздел справки
                </el-button>

                <div class="w-100">

                    <el-row gutter="20">
                        <el-col :sm="6">
                            <div class="faqMenu">
                                <div class="d-flex align-center gap-12">
                                    <div class="faqMenu__icon">
                                        <component :is="modulesInfo[$route.params.module || module]?.icon" />
                                    </div>
                                    <h4 class="textXL pl-0 mt-18 mb-18" @click="$router.push({name: 'FaqView', params: { module: $route.params.module }})">
                                        {{ modulesInfo[$route.params.module || module].title }}
                                    </h4>
                                </div>
                                <div v-for="(questions, section) in faqQuestionsBySection[$route.params.module || module]" :key="section">
                                    <h3>{{ section === 'general' ? 'Общие вопросы' : getSectionName(section) }}</h3>

                                    <el-button
                                        class="textMD"
                                        v-for="question in questions"
                                        :key="question.key"
                                        :class="{ active: question.key === $route.params.key }"
                                        @click="$router.push({
                                            name: 'FaqViewQuestion',
                                            params: { module: $route.params.module || module, key: question.key
                                        }})">{{ question.title }}
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :sm="18" id="faq-details">

                            <template v-if="$route.params.key">
                                <FaqRouter
                                    :component="$route.params.key || section"
                                />
                            </template>
                            <template v-else>
                                <component :is="componentName" />
                            </template>

                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth'
import FaqRouter from "@/views/components/FaqPage/FaqRouter";
import iconsMixin from "@/mixins/iconsMixin";
import faqQuestions from "@/mixins/faqQuestions";
import FlashIcon from "@/views/components/Icons/FlashIcon";
import CashboxIcon from "@/views/components/Icons/CashboxIcon";
import WeighingIcon from "@/views/components/Icons/WeighingIcon";
import IntegrationsIcon from "@/views/components/Icons/IntegrationsIcon";
import RelationsIcon from "@/views/components/Icons/RelationsIcon";
import SettingsIcon from "@/views/components/Icons/SettingsIcon";
import ContractIcon from '@/views/components/Icons/ContractIcon'
import StorehouseIcon from '@/views/components/Icons/StorehouseIcon'
import ProductionIcon from '@/views/components/Icons/ProductionIcon'
import OrderIcon from '@/views/components/Icons/OrderIcon'
import TechIcon from '@/views/components/Icons/TechIcon'
import AdminIcon from '@/views/components/Icons/AdminIcon'
import TTSIcon from '@/views/components/Icons/TTSIcon'
import MixIcon from '@/views/components/Icons/MixIcon'
import LogisticsIcon from "@/views/components/Icons/LogisticsIcon";
import FuelIcon from "@/views/components/Icons/FuelIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";
import modulesInfo from '@/mixins/modulesInfo';

import CashboxFaq from "@/views/components/FaqPage/cashbox/CashboxFaq";
import SettingsFaq from "@/views/components/FaqPage/settings/SettingsFaq";
import DispatchFaq from "@/views/components/FaqPage/dispatch/DispatchFaq";

export default {
    name: 'FaqView',
    components: {
        SettingsFaq,
        SettingsIcon,
        CashboxFaq, RelationsIcon, IntegrationsIcon, WeighingIcon, CashboxIcon, FlashIcon, FaqRouter, Auth,
        ContractIcon, StorehouseIcon, ProductionIcon, OrderIcon, TechIcon, AdminIcon, TTSIcon, MixIcon,
        LogisticsIcon, FuelIcon, DispatchIcon, LabIcon, CRMIcon, DispatchFaq
    },
    props: ['module', 'section', 'isDialog'],
    mixins: [iconsMixin, faqQuestions, modulesInfo],
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName]
            var top = element.offsetTop

            window.scrollTo({
                top: top,
                behavior: 'smooth'
            })
        },
        getComponentName(name) {
            const module = this.$route.params.module || this.module;
            const firstLetter = module[0].toUpperCase();

            return firstLetter + name.slice(1) + 'Faq'
        },
        convertToKebabCase(str) {
            return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        },
        getSectionName(section) {
            const title =  this.modulesInfo[this.$route.params.module]?.sections[section]?.title

            return title ? title : null
        }
    },
    computed: {
        componentName() {
            const module = this.$route.params.module || this.module;
            const firstLetter = module[0].toUpperCase();

            return firstLetter + module.slice(1) + 'Faq'
        }
    }
}
</script>

<style scoped>

</style>
