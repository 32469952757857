<template>
    <div class="ai-feedback">
        <button @click="runFullAnalysis" :disabled="loading">
            {{ loading ? 'Анализ...' : 'Проверить весь проект' }}
        </button>

        <div v-if="results" class="results">
            <div v-for="(item, index) in results" :key="index" class="result-item">
                <h4>{{ item.file }}</h4>
                <pre>{{ item.feedback }}</pre>
            </div>
        </div>

        <div v-if="error" class="error">
            {{ error }}
        </div>
    </div>
</template>

<script>
import {httpService} from "@/services/http.service";

export default {
    name: "AiFeedback",
    data() {
        return {
            loading : null,
            results : null,
            error : null,
        };
    },
    created() {
        this.getAnalyze()
    },
    methods: {
        async getAnalyze() {
            this.loading = true;
            try {
                const response = await httpService().post('/api/ai/full-review');
                this.results = response.data.results;
            } catch (err) {
                this.error = `Ошибка: ${err.response?.data?.error || err.message}`;
            } finally {
                this.loading = false;
            }
        },
    }

}

</script>
<style scoped>
button {
    padding: 12px 24px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:disabled {
    background: #cccccc;
}

.results {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.result-item {
    margin: 15px 0;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 4px;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.error {
    color: #ff4444;
    margin-top: 10px;
}
</style>
