<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.05039 1.3999C4.38328 1.3999 1.40039 4.38303 1.40039 8.04993C1.40039 11.7168 4.38328 14.6997 8.05039 14.6997C11.7173 14.6997 14.7002 11.7168 14.7004 8.04993C14.7004 4.38303 11.7175 1.3999 8.05039 1.3999ZM8.05039 13.3499C4.85288 13.3499 2.75 11.2474 2.75 8.04993C2.75 4.85242 4.85288 2.74991 8.05039 2.74991C11.2479 2.74991 13.35 4.85239 13.35 8.0499C13.35 11.2474 11.2479 13.3499 8.05039 13.3499Z"
            fill="#8A909A"/>
        <path
            d="M8.58209 4.70557H6.7581C6.52296 4.70557 6.33253 4.896 6.33253 5.13113V7.83832H5.79861C5.56348 7.83832 5.37305 8.02875 5.37305 8.26388C5.37305 8.49902 5.56348 8.68969 5.79861 8.68969H6.33253V9.27618H5.79861C5.56348 9.27618 5.37305 9.46684 5.37305 9.70198C5.37305 9.93711 5.56348 10.1278 5.79861 10.1278H6.33253V10.9688C6.33253 11.2039 6.52296 11.3946 6.7581 11.3946C6.99323 11.3946 7.18366 11.2037 7.18366 10.9688V10.1278H9.51692C9.75185 10.1278 9.94249 9.93716 9.94249 9.702C9.94249 9.46684 9.75206 9.2762 9.51692 9.2762H7.18366V8.68971H8.58232C9.29329 8.68971 9.89292 8.48985 10.3166 8.11177C10.7116 7.75951 10.929 7.27609 10.929 6.7507C10.929 5.75934 10.1062 4.70557 8.58209 4.70557ZM9.74954 7.4766C9.48442 7.71337 9.08075 7.83832 8.58212 7.83832H7.18366V5.55649H8.58209C9.5637 5.55649 10.0776 6.15704 10.0776 6.75044C10.0776 7.02983 9.96116 7.2878 9.74954 7.4766Z"
            fill="#8A909A"/>
    </svg>
</template>

<script>
export default {
    name: "CoinIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
.currentColor {
    path {
        stroke: currentColor;
    }
}
</style>
