<template>
    <div id="y-map2" class="y-map"></div>
</template>

<script setup>
import {defineProps, onMounted, watch} from "vue";
import {loadYmap} from "vue-yandex-maps";

const props = defineProps({
    coords: {
        default: () => [55.811511, 37.312518],
        type: Array,
    },
    factories: {
        default: () => [],
        type: Array
    },
    address: {
        default: () => null,
        type: Object
    },
    vehicles: {
        default: () => null,
        type: Object
    },
    zoom: {
        default: 10,
        type: Number
    }
});

let map = null;

const init = async () => {
    const settings = {
        apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
    };
    await loadYmap({...settings, debug: true});
    // eslint-disable-next-line no-undef
    await ymaps.ready;


    // задаем центр карты по адресу доставки
    // eslint-disable-next-line no-undef
    map = new ymaps.Map(document.getElementById("y-map2"), {
        center: [...props.coords],
        zoom: props.zoom,
        controls: ['trafficControl', 'fullscreenControl'] // скрываем все контролы, включая кнопку слоев и поиска
    });

    displayMapContent();
};

const displayMapContent = () => {
    if (map) {
        map.geoObjects.removeAll();

        // Выводим заводы
        props.factories.forEach(factory => {
            if (!factory.latitude || !factory.longitude) {
                console.warn("Factory is missing coordinates:", factory);
                return;
            }
             // eslint-disable-next-line no-undef
            const factoryPoint = new ymaps.Placemark([factory.latitude, factory.longitude], {
                iconContent: 'Завод'
            }, {
                preset: "islands#blueFactoryCircleIcon"
            });
            map.geoObjects.add(factoryPoint);
        });

        // Выводим транспортные средства на карте
        if (props.vehicles) {
            props.vehicles.forEach(vehicle => {
                if (!vehicle.latitude || !vehicle.longitude) {
                    console.warn("Vehicle is missing coordinates:", vehicle);
                    return;
                }
                // eslint-disable-next-line no-undef
                const myPlacemark = new ymaps.Placemark([vehicle.latitude, vehicle.longitude], {
                    iconContent: vehicle.number
                }, {
                    preset: `islands#${vehicle.color}StretchyIcon`
                });

                map.geoObjects.add(myPlacemark);
            });
        }
    }
};

watch(() => props.vehicles, () => {
    displayMapContent();
});

watch(() => props.coords, () => {
    displayMapContent();
});

onMounted(() => init());
</script>

<style lang="scss" scoped>
.y-map {
    height: 100%;
    width: 100%;
}
</style>

<style lang="scss">
.yandex-container {
    width: 100%;
    height: 100%;
}
</style>

