<template>
  <svg :class="[{'currentColor' : currentColor}]" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
       <path
           d="M3.33325 8.6665L5.6842 10.5473C6.23574 10.9885 7.03568 10.9231 7.50819 10.3981L12.6666 4.6665"
           stroke="white" :stroke-width="strokeWidth ?? 1.5" stroke-linecap="round"
           stroke-linejoin="round"/>
   </svg>
</template>

<script>
export default {
    name: "CheckIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
