<template>
    <el-tooltip placement="bottom" effect="light" :disabled="!ordersCount" :show-after="100">
        <template #content>
            <div style="width: 350px">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th>
                            <div class="cell textXS textRegular neutral200 pb-15 pl-5">
                                Отгрузки
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Кол-во
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15 pt-5">
                                Объем
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(status, index) in statuses" :key="index" class="hover-row">
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 no-wrap pl-5">
                                <span :style="{ 'background-color': status.color }" class="status-dot"></span>
                                {{ status.name }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5">
                                {{ status.count }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium success400 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(status.value, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                Итого
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ statuses.reduce((acc, status) => acc + status.count, 0) }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(allTotal, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="mt-20 w-100" v-if="unallocatedTotal > 0">
                    <h3 class="neutral900 textMedium textSM mb-10">Нераспределённый объем</h3>
                    <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                        <thead>
                        <tr>
                            <th>
                                <div class="cell textXS textRegular neutral200 pt-2-5 pb-2-5 pl-5">
                                    Заказ
                                </div>
                            </th>
                            <th style="width: 120px">
                                <div class="cell textXS textRegular neutral200 pt-2-5 pb-2-5 pr-5">
                                    Объем
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(status, index) in unallocatedOrders" class="hover-row" :key="index">
                            <td>
                                <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 no-wrap pl-5">
                                    {{ status.name }}
                                </div>
                            </td>
                            <td>
                                <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 pr-5">
                                    {{ formatNumber(status.value, 2) }} м³ из {{ formatNumber(status.total, 2) }} м³
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                    Итого нераспределено
                                </div>
                            </td>
                            <td>
                                <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(unallocatedTotal, 2) }} м³
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <div class='d-flex content-center pl-10 pr-10 summary-order-groups-container'>
            <div :style="`min-width: ${mobile ? 'max-content' : '130px'}`">
                <div class="order-groups">
                    <img v-if="unallocatedTotal > 0" :src="require('@/assets/warning-2.svg')" class="order-groups__icon"/>
                    <img v-else :src="require('@/assets/box.svg')" class="order-groups__icon"/>
                    <div class="order-groups__info">
                        <div class="order-groups__info__title">
                            <div class="d-flex">
                                <AnimateNumber :count="activeMixOrderCount" :fraction="2"/>
                                <span class="ml-5">м³</span>
                            </div>
                        </div>
                        <div class="order-groups__info__desc" :class="{ 'order-groups__info__desc--yellow': unallocatedTotal > 0 }">
                            Объем на день
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'opacity-0-1': activeMixOrderCount === 0 }" :style="{ opacity: activeMixOrderCount > 0 ? 1 : 0.2 }"
                 class="transition-opacity duration-500">
                <SummaryMixOrdersCountPie :statuses="statuses"/>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import SummaryMixOrdersCountPie from "@/views/dispatch/components/summaryBlocks/SummaryMixOrdersCountPie.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "SummaryMixOrdersCount",
    components: {SummaryMixOrdersCountPie, AnimateNumber},
    mixins: [functionsMixin, mobileCheckMixin],
    props: ['summaryMixOrdersCount'],
    computed: {
        ordersCount() {
            return this.summaryMixOrdersCount.orders_count ?? 0;
        },
        activeMixOrderCount() {
            return this.summaryMixOrdersCount.active_total ?? 0;
        },
        allTotal() {
            return this.summaryMixOrdersCount.all_total ?? 0;
        },
        unallocatedTotal() {
            return this.summaryMixOrdersCount.unallocated_total ?? 0;
        },
        unallocatedOrders() {
            return this.summaryMixOrdersCount.unallocated_orders ?? [];
        },
        statuses() {
            return this.summaryMixOrdersCount.statuses ?? [];
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">


</style>
