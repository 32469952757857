<template>
    <div class="mb-30">
        <el-button @click="print('passport')" :loading="loadingButton" :disabled="multipleSelection.length === 0" class="mb-15" plain>Распечатать
            паспорта качества
        </el-button>
        <el-button @click="generateWord()" :loading="loadingButton" :disabled="multipleSelection.length === 0" class="mb-15" plain>Печать ТТН </el-button>
        <br/>
        <el-button @click="print('invoice')" :loading="loadingButton" :disabled="multipleSelection.length === 0" plain>Распечатать транспортные
            накладные
        </el-button>
        <!-- <el-button @click="print('daily')" :loading="loading" plain>Печать графика на день </el-button> -->
        <br/>
    </div>
    <el-checkbox class="w-100" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
        Выбрать все
    </el-checkbox>
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group class="mixOrderGroupOrdersPrinter__checkboxes" v-model="multipleSelection" @change="handleCheckedCitiesChange">
        <el-checkbox class="w-100" v-for="order in tableData" :label="order.id" :key="order.id">
            Заявка{{ order.doc ? ' №' + order.doc : '' }} для {{ getData(order.companyId, 'company') }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "MixOrderGroupOrdersPrinter",
    props: ['tableData','date'],
    mixins: [functionsMixin],
    data() {
        return {
            checkAll: false,
            multipleSelection: [],
            orders: this.tableData,
            dateSelect: this.date,
            isIndeterminate: false,
            loadingButton: false,
        }
    },
    created() {

    },
    methods: {
        handleCheckAllChange(val) {
            this.multipleSelection = val ? this.tableData.map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orders.length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orders.length;
            this.isIndeterminate = false;
        },
        print(template) {
            if (template === 'mixOrderReport'){
                this.generateMixOrderReport()
                return
            }
            this.multipleSelection.forEach((item) => {
                    this.download(template, item)
                })
        },
        download(template, item = null){
            this.loadingButton = true
            httpService().post(this.api.print.downloadDoc, {
                account_module_reserve_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: item,
                template: template,
            }, {
                responseType: 'blob'
            }).then((response) => {
                let data = response.data
                const url = window.URL.createObjectURL(response.data)

                const link = document.createElement('a')
                let filename = 'Паспорт качества по отгрузке №' + item
                if (template === 'invoice') {
                    filename = 'Транспортная накладная по отгрузке №' + item
                } else if (template === 'ttn') {
                    filename = 'Товарно-транспортная накладная по отгрузке №' + item
                }
                // else if (template === 'daily') {
                //     type = 'График заявок'
                // }
                link.href = url

                link.setAttribute('download', filename  + '.docx')
                document.body.appendChild(link)
                link.click()

                if (data.success) {
                    this.step = 1
                    this.selectedDocs = []
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loadingButton = false
            })
        },
        generateWord() {
            this.loadingButton = true

            for(let i = 0; i < this.multipleSelection.length; i++){
                httpService().post(this.api.exportDocument.generateTTN, {
                orderId : this.multipleSelection[i]
            }, {
                responseType: 'blob'
            }).then((response) => {
                let fileName = 'Товарно-транспортная накладная для заявки №' + this.multipleSelection[i] + '.docx';
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                });
                console.log(error)
            }).finally(() => {
                this.loadingButton = false
            });
            }
        }
    }
}
</script>

<style scoped>
.mixOrderGroupOrdersPrinter__checkboxes.el-checkbox-group {
    max-height: 60svh;
    overflow: hidden auto;

    .el-checkbox__label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
