<template>
    <h1 class="guide-title">{{text}}</h1>
</template>

<script>

export default {
    name: 'GuideTitle',
    props: ['text'],
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-title {
    margin-block: 0 8px;
    user-select: text;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 30px;
    color: #0f172a;

    @media (min-width: 640px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 768px) {
        padding-top: 18px;
    }
}
</style>
