<template>
    <template v-if="step === 'choice'">
        <p class="textMedium neutral900 mb-5">Выберите документы для печати:</p>
        <el-tabs v-model="tab" @tabChange="() => selectedDocs = []">
            <el-tab-pane v-if="!hideOrders" label="По отгрузкам" name="orders">
                <div class="d-flex documentPrints">
                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox :disabled="!orders.filter(el => el.confirm).length"
                                     :label="doc['type'] !== 'other' ? doc['type'] : doc['name']"
                                     :value="doc['type'] !== 'other' ? doc['type'] : doc['id']"
                            v-for="(doc, index) in accessDocuments" :key="index">
                            <template #default>
                                <div class="document text-center">
                                    <img :src="doc['image_path']" alt="Документ"/>
                                    <p class="textMedium textSM">{{doc['name']}}</p>
                                </div>
                            </template>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-tab-pane>
            <el-tab-pane label="По заказу" name="orderGroup">
                <div class="d-flex documentPrints">
                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox label="passportOrder" value="passportOrder">
                            <template #default>
                                <div class="document text-center">
                                    <img src="/images/docs/passport.jpg"/>
                                    <p class="textMedium textSM">Паспорт качества</p>
                                </div>
                            </template>
                        </el-checkbox>
                        <template v-if="orderGroupItem.pump">
                            <el-checkbox label="invoicePump" :key="index"
                                         v-for="(pump, index) in orderGroupItem.pumps"
                                         :value="'invoicePump_' + pump.id"
                            >
                                <template #default>
                                    <div class="document text-center">
                                        <img src="/images/docs/invoice.jpg"/>
                                        <p class="textMedium textSM">ТН на насос {{ pump.vehicle_number }}</p>
                                    </div>
                                </template>
                            </el-checkbox>
                        </template>
                    </el-checkbox-group>
                </div>
            </el-tab-pane>
        </el-tabs>


    </template>

    <template v-else-if="step === 'mixOrders'">
        <p class="textMedium neutral900">Выберите отгрузки для печати:</p>

        <template v-if="orders.filter(el => el.confirm).length">

            <el-checkbox class="w-100" :indeterminate="isIndeterminate" v-model="checkAll"
                         @change="handleCheckAllChange">
                Выбрать все
            </el-checkbox>
            <el-checkbox-group v-model="selectedOrders" @change="handleCheckedCitiesChange">
                <el-checkbox class="w-100"
                             v-for="order in orders.filter(el => el.confirm).sort((a, b) => a.start_at - b.start_at)"
                             :label="order.id"
                             :key="order.id">
                    #{{ order.id }} / {{ moment(order.start_at).format('HH:mm') }} - {{ order.vehicle.number }} -
                    {{ order.total }} м³
                </el-checkbox>
            </el-checkbox-group>
        </template>
        <template v-else>
            <p class="mt-30 textSM neutral400 text-center w-100">
                Нет подтвержденных заявок по заказу<br/>Нет возможности распечатать ТТН или Паспорта качества для
                отгрузок
                <template v-if="orderGroupItem.pump"><br/>Доступна печать ТТН для насоса</template>
            </p>
        </template>
    </template>

    <template>

    </template>

    <div class="add-shipping__footer mb-15">

        <el-button
            v-if="step !== 'choice'"
            @click="() => step = 'choice'"
            class="ci-btn ci-btn_default add-shipping__footer__cancel">К выбору документов
        </el-button>

        <el-button
            v-if="step === 'choice' && tab === 'orders'"
            @click="nextStep()"
            :disabled="!selectedDocs.length"
            class="ci-btn ci-btn_blue"
            type="primary">Далее
        </el-button>

        <template v-if="step === 'mixOrders' || tab === 'orderGroup'">
            <el-button
                @click="printDocs('download')"
                :disabled="!selectedDocs.length || loadingDownloadButton|| loadingButton || (tab === 'orders' && !selectedOrders.length)"
                :loading="loadingDownloadButton"
                :icon="icons.download"
                class="ci-btn"
                type="default">Скачать
            </el-button>
            <el-button
                @click="printDocs('print')"
                :disabled="
                    !selectedDocs.length
                    || loadingButton
                    || loadingDownloadButton
                    || selectedDocs.length > 1
                    || selectedDocs.includes('mixOrderReport')
                    || (tab === 'orders' && selectedOrders.length !== 1)"
                :icon="icons.print"
                :loading="loadingButton"
                class="ci-btn ci-btn_blue"
                type="primary">Распечатать
            </el-button>
        </template>

        <el-dialog
            class="document-viewer-dialog"
            v-model="showDocViewer"
            :destroy-on-close="true"
            fullscreen
            @close="documentUrl = null">
            <DocViewer :url="documentUrl"/>
        </el-dialog>
    </div>
</template>
<script>
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import iconsMixin from "@/mixins/iconsMixin";
import DocViewer from "@/views/print/DocViewer.vue";

export default {
    name: 'PrintDocs',
    components: {DocViewer},
    computed: {
        moment() {
            return moment
        },
        companyNameForDocs() {
            return this.orderGroupItem.company ? ` для ${this.orderGroupItem.company}` : null
        },
        dateForDocs() {
            return `от ${moment(this.orderGroupItem?.date).format('DD.MM.YYYY')}`
        }
    },
    props: {
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        orders: Array,
        accessDocuments: Array,
        hideOrders: {
            type: [Boolean, null],
            default: false
        }
    },
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            selectedDocs: [],
            selectedOrders: [],
            step: 'choice',
            checkAll: false,
            isIndeterminate: false,
            loadingButton: false,
            loadingDownloadButton: false,
            tab: this.hideOrders ? 'orderGroup' : 'orders',
            documentUrl: null,
            showDocViewer: false,
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.selectedOrders = val ? this.orders.filter(el => el.confirm).map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orders.filter(el => el.confirm).length;
            this.isIndeterminate = false;
        },
        printDocs(type) {
            this.selectedDocs.forEach(template => {
                this.print(template, type)
            })
            this.$emit('close')
        },
        nextStep() {
            this.step = 'mixOrders';

            if (this.selectedDocs.length === 1) {
                if (this.selectedDocs[0] === 'invoicePump') {
                    this.printDocs()
                    this.$emit('close')
                }
            }
        },
        print(template, type) {
            if (template === 'mixOrderReport') {
                this.generateMixOrderReport()
                return;
            }
            if (type === 'download') {
                if (template.includes("invoicePump")) {
                    // Разбиваем строку по символу '_'
                    const parts = template.split("_");
                    this.download(parts[0], parts[1])
                    return;
                }
                if (template === 'passportOrder') {
                    this.download(template)
                    return;
                }
                this.selectedOrders.forEach((item) => {
                    this.download(template, item)
                })
            } else if (type === 'print') {
                this.loadingButton = true;
                let order = this.selectedOrders[0]

                if (template.includes("invoicePump")) {
                    // Разбиваем строку по символу '_'
                    const parts = template.split("_");
                    template = parts[0];
                    order = parts[1];
                }

                httpService().post(this.api.print.printDoc, {
                    order: order,
                    template: template,
                    orderGroupId: this.orderGroupItem?.id
                }, {responseType: 'blob'}).then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob);

                        const printWindow = window.open(url);

                        printWindow.addEventListener('load', function () {
                            printWindow.print();
                            URL.revokeObjectURL(url);
                        }, {once: true});
                    }
                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    });
                    this.loadingButton = false;
                }).finally(() => {
                    this.loadingButton = false;
                });
            }
        },
        download(template, item = null) {
            this.loadingDownloadButton = true;

            httpService().post(this.api.print.downloadDoc, {
                account_module_reserve_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: item,
                template: template,
                orderGroupId: this.orderGroupItem?.id
            }, {
                responseType: 'blob'
            }).then((response) => {
                let data = response.data
                const url = window.URL.createObjectURL(response.data)

                const link = document.createElement('a')
                let filename = '№' + this.orderGroupItem.id
                if (template === 'invoice') {
                    filename = 'Транспортные накладные по заказу №' + this.orderGroupItem.id
                } else if (template === 'passport') {
                    filename = 'Паспорта качества по заказу №' + this.orderGroupItem.id
                } else if (template === 'daily') {
                    filename = 'График заявок'
                } else if (template === 'invoicePump') {
                    filename = 'Транспортная накладная на насос по заказу №' + this.orderGroupItem.id
                } else if (template === 'passportOrder') {
                    filename = 'Паспорт качества по заказу №' + this.orderGroupItem.id
                } else if (template === 'ttn') {
                    filename = 'Товарно-транспортная накладная по заказу №' + this.orderGroupItem.id
                }
                link.href = url

                link.setAttribute('download', filename + '.docx')
                document.body.appendChild(link)
                link.click()

                if (data.success) {
                    this.step = 'choice'
                    this.selectedDocs = []
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loadingDownloadButton = false
            })
        },
        generateMixOrderReport() {
            this.loadingButton = true

            httpService().post(this.api.exportExcel.exportMixOrderReport, {
                groupIds: [this.orderGroupItem.id],
                type: 'byOrders'
            }, {
                responseType: 'blob'
            }).then((response) => {
                let fileName = `График отгрузок ${this.dateForDocs}${this.companyNameForDocs ?? ''}.xlsx`;
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            }).finally(() => {
                this.loadingButton = false
            })
        },
    },
    watch: {

    },
}
</script>
<style lang="scss">
.change-volume {
    &__planned {
        &__table {
            .cell {
                padding: 0 12px !important;
            }

            .el-table__body {
                width: 100% !important;
            }
        }
    }
}

.change-volume {

    .el-input-number {
        width: 100px;
    }

    .el-input-number__increase {
        background-color: #fff;
        height: 16px !important;
        width: 16px !important;
        right: 3px !important;
        border: none !important;
        border-radius: 4px 4px 0 0 !important;
    }

    .el-input-number__decrease {
        background-color: #fff;
        height: 16px !important;
        width: 16px !important;
        right: 3px !important;
        border: none !important;
        border-radius: 0 0 4px 4px !important;
    }

    &__quantity__input {
        height: 28px;
        width: 76px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #393f4a;

        .el-input {
            .el-input__wrapper {
                height: 28px;
                display: flex;
                border-radius: 6px;
                box-shadow: none;
                padding: 0 0 0 4px !important;
                background-color: #F7F7F8 !important;
            }
        }

        .el-input__inner {
            height: 40px;
            color: #393F4A;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            text-align: left !important;
        }
    }

    &__quantity__input--total {
        height: 28px;
        width: 76px !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #393f4a;

        .el-input {
            .el-input__wrapper {
                height: 28px;
                display: flex;
                border-radius: 6px;
                box-shadow: none;
                padding: 0 0 0 15px !important;
                background-color: #F7F7F8 !important;
            }
        }

        .el-input__inner {
            height: 40px;
            color: #393F4A;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            text-align: left !important;
        }
    }
}
</style>
<style scoped lang="scss">
.change-volume {

    min-height: 556px;
    position: relative;

    &__header {
        display: flex;
        align-items: center;

        &__number {
            color: #393F4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 8px;
        }
    }

    &__quantity {
        margin-top: 16px;

        &__title {
            color: #393F4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        &__input {
            width: 42px;
            height: 28px;
            margin-top: 8px;
        }
    }

    &__planned {
        margin-top: 16px;

        &__title {
            color: #393F4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        &__table {

            margin-top: 8px;

            &__number {
                color: #FFB500;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &__driver {

                color: #49515F;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                display: flex;
                align-items: center;

                &__name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                svg {
                    margin-right: 5px;
                    min-width: 17px;
                }
            }

            &__vehicle {

                display: flex;
                align-items: center;

                color: #49515F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                &__number {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                svg {
                    margin-right: 5px;
                    min-width: 17px;
                }
            }

            &__mix {

                color: #49515F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                display: flex;
                align-items: center;

                &__name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                svg {
                    margin-right: 5px;
                    min-width: 17px;
                }
            }

            &__start_at {

                color: #49515F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                    min-width: 17px;
                }
            }

            &__arrive_at {

                color: #49515F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                    min-width: 17px;
                }
            }

            &__total {
                width: fit-content;
                margin-bottom: 0;
            }
        }
    }

    &__navigation {

        display: flex;
        justify-content: end;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;

        &__cancel {
            margin-right: 16px;
        }
    }
}
</style>

<style lang="scss">
.document-viewer-dialog {
    padding: 0 !important;

    & .el-dialog__header {
        padding: 0 !important;
    }
}
</style>
