<template>
    <el-form-item class="form-vat"
                  :class="[{ 'error-border': validationMessage, 'is-required': required, empty: !itemValue }, color, direction]">
        <template #label>
            % НДС
            <el-checkbox class="form-vat__checkbox"
                         label="Включен в цену"
                         v-model="localVatInPrice"
                         @change="updateVatInPrice"
                         size="small" />
        </template>
        <el-input class="form-vat__input"
                  type="number"
                  :placeholder="placeholder ?? 0"
                  :min="0"
                  :max="100"
                  v-model.number="localVat"
                  @input="updateVat"
                  @keypress="preventNegative" />
    </el-form-item>
</template>

<script>
export default {
    props: {
        vat: {
            type: Number,
            required: true,
        },
        vatInPrice: {
            type: Boolean,
            required: true,
        },
        placeholder: String,
        color: String,
        direction: String,
        validationMessage: String,
        required: Boolean,
    },
    emits: ['update:vat', 'update:vatInPrice'],
    computed: {
        localVat: {
            get() {
                return this.vat;
            },
            set(value) {
                this.$emit('update:vat', value);
            },
        },
        localVatInPrice: {
            get() {
                return this.vatInPrice;
            },
            set(value) {
                this.$emit('update:vatInPrice', value);
            },
        },
    },
    methods: {
        updateVatInPrice(value) {
            this.$emit('update:vatInPrice', value);
        },
        updateVat(value) {
            if (!value || value == '-') {
                this.$emit('update:vat', null);
                return
            }

            const clampedValue = Math.min(Math.max(value, 0), 100);
            if (clampedValue !== value) {
                this.localVat = clampedValue;
            }
            this.$emit('update:vat', clampedValue);
        },
        preventNegative(e) {
            if (e.key === '-') {
                e.preventDefault();
            }
        },
    },
};
</script>