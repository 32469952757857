<template>
    <Auth title="Техника" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <div v-if="!techVehicleDetails">
                    <TableHeadBlock
                        v-model="search"
                        :loading="loading"
                        create-button-label="Добавить технику"
                        @moduleGet="moduleGet"
                        @openFilterBlock="openFilterBlock"
                        @openCreateValueDrawer="opennewTechDialog"
                        :disable-filter="true"
                        :new="true"
                    />

                    <Filter
                        :filter="filter"
                        :loading="loading"
                        :filter-show="filterShow"
                        :filter-items1="techStatuses"
                    />

                    <el-radio-group v-model="activeTab" class="mt-15 mb-15">
                        <el-radio-button label="all">Все <span>{{ values.length }}</span></el-radio-button>
                        <el-radio-button label="active">Активные <span>{{
                                values.filter(el => el.status === 'active').length
                            }}</span></el-radio-button>
                        <el-radio-button label="repair">В ремонте <span>{{
                                values.filter(el => el.status === 'repair').length
                            }}</span></el-radio-button>
                        <el-radio-button label="unused">Не используются <span>{{
                                values.filter(el => el.status === 'unused').length
                            }}</span></el-radio-button>
                    </el-radio-group>

                    <div class="techFlex">

                        <TechVehicleTableBlock
                            v-for="(item, index) in displayData"
                            :key="index"
                            :tech="item"
                            @openDetails="openTechDetails(item)"
                            @moduleGet="moduleGet()"
                        />

                    </div>

                    <EmptyState
                        :values="displayData"
                        :filter="filter"
                        :search="search"
                        :loading="loading"
                        list-types="транспортных средств на обслуживании"
                    />

                </div>

            </div>
        </div>

        <el-dialog
            v-model="newTechDialog"
            title="Добавление техники"
            :width="mobile ? '100%' : '65%'"
            :destroy-on-close="true"
        >
            <TechCreateVehicle
                @moduleGet="moduleGet"
                @closeDrawer="() => newTechDialog = false"
            />

        </el-dialog>
    </Auth>
</template>

<script>
import {httpService} from '@/services/http.service'
import TechCreateVehicle from "@/views/components/TechPage/TechCreateVehicle";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import functionsMixin from "@/mixins/functionsMixin";
import Filter from "@/views/components/Table/Filter/Filter";
import TechVehicleTableBlock from "@/views/components/TechPage/Garage/TechVehicleTableBlock";
import techVehicleStatus from "@/mixins/techVehicleStatus";
import EmptyState from "@/views/components/EmptyState";
import Auth from "@/views/layouts/Auth.vue";
import techTabs from "@/mixins/techTabs";

export default {
    components: {
        Auth,
        EmptyState,
        TechVehicleTableBlock,
        Filter, TableHeadBlock, TechCreateVehicle
    },
    inject: ['api'],
    props: ['vehicles', 'tab'],
    mixins: [tableMixin, iconsMixin, mobileCheckMixin, functionsMixin, techVehicleStatus, techTabs],
    name: 'TechGarageTab',
    data() {
        return {
            loading: false,
            activeTab: 'all',
            filter: {
                status: {
                    value: null,
                    type: 'select',
                    label: 'Статус',
                    placeholder: 'Выберите статус',
                    items: '1'
                },
            },
            newTechDialog: false,
            techDetails: [],
            techVehicleDetails: false,
            newTechForm: {
                vehicle_id: null,
                unitType: null,
                engine_hours: null,
                engine_hours_period: null,
                mileage: null,
                mileage_period: null,
                fuel_total: null,
            },
            newTechValueDrawerInfo: {
                fuelShow: false,
                historyShow: false,
                showFuelOrHistory: true,
                fuel: null,
                mileage: null,
                vehicleId: null,
            },
            selectedTech: null,
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.tech.getTech, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        openTechDetails(details) {
            this.techDetails = details
            this.techVehicleDetails = true
        },
        opennewTechDialog() {
            this.newTechDialog = !this.newTechDialog
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.name.toLowerCase().includes(this.search.toLowerCase()) || data.number.toLowerCase().includes(this.search.toLowerCase())))
            if (this.filter.status.value) this.filtered = this.filtered.filter(data => data.status === this.filter.status.value)

            this.valueLength = this.filtered.length

            if (this.activeTab !== 'all') {
                this.filtered = this.filtered.filter(el => el.status === this.activeTab)
            }

            return this.filtered
        }
    }
}
</script>

<style scoped>

</style>
