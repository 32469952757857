<template>
    <div :class="!order.confirm ? 'NotConfirm' : ''" class="operatorOrder"
         v-for="(order, index) in orders" :key="index">
        <div class="operatorOrderBody d-flex f-direction-column gap-12">
            <div class="d-flex-full align-start" style="gap: 15px">
                <div class="d-flex-full-stroke">
                    <div class="operatorOrderInfo">
                        <div :class="!order.confirm ? 'not-confirm' : ''">
                            <div class="textXS neutral200 mb-5" v-if="order.confirm">
                                <el-tooltip :disabled="!order.confirm" effect="dark"
                                            content="Внутренний номер отгрузки в Цифре"
                                            placement="top">
                                    <template #content>
                                        Внутренний номер отгрузки в Цифре
                                        <template v-if="order.doc">
                                            <br/><br/>Номер отгрузки с оборудования
                                            завода:<br/>#{{ order.doc }}
                                        </template>
                                    </template>
                                    <div :class="'textMedium ' + getColor(order)">
                                        {{ order.confirm ? '#' + order.id : '-' }}
                                    </div>
                                </el-tooltip>
                            </div>
                            <div>
                                <div class="textMedium primary600"
                                     v-if="order.type === 'delivery'">
                                    <el-tooltip :disabled="!order.confirm" content="Доставка"
                                                placement="top">
                                        Д
                                    </el-tooltip>
                                </div>
                                <div class="textMedium success400"
                                     v-else-if="order.type === 'take-away'">
                                    <el-tooltip :disabled="!order.confirm" content="Самовывоз"
                                                placement="top">
                                        С
                                    </el-tooltip>
                                </div>
                                <div class="textMedium warning300"
                                     v-else-if="order.type === 'production'">
                                    <el-tooltip :disabled="!order.confirm"
                                                content="Производство"
                                                placement="top">
                                        П
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="!order.confirm ? 'not-confirm' : ''" style="width: 100px">
                        <div class="textXS neutral200 mb-5" v-if="order.confirm">
                            <el-tooltip :disabled="!order.confirm" effect="dark"
                                        content="Время загрузки миксера"
                                        placement="top">
                                Время загрузки
                            </el-tooltip>
                        </div>
                        <div class="headingXS textMedium neutral900">
                            {{ getDateFromUnix(order.date, 'HH:mm', tz) }}
                        </div>
                    </div>
                </div>
                <div class="w-100" style="min-width: 120px">
                    <div :class="!order.confirm ? 'not-confirm' : ''">
                        <div class="textXS neutral200 mb-5" v-if="order.confirm">
                            <el-tooltip :disabled="!order.confirm" effect="dark"
                                        content="Рецепт для отгрузки"
                                        placement="top">
                                Рецепт
                            </el-tooltip>
                        </div>
                        <div class="headingXS textMedium break-words neutral900">
                            {{ order.good ?? (order.spec_good_name ?? order.order_good_name) }}
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="mixerCount > 1" :class="!order.confirm ? 'not-confirm' : ''" style="width: 100px">
                        <div class="textXS neutral200 mb-5" v-if="order.confirm">
                            <el-tooltip :disabled="!order.confirm" effect="dark"
                                        content="Миксер"
                                        placement="top">
                                Пост
                            </el-tooltip>
                        </div>
                        <div class="headingXS textMedium cutOverflow neutral900">
                            {{ order.mixer_name }}
                        </div>
                    </div>
                </div>
                <div class="d-flex-full-stroke align-self-stretch align-start" style="gap: 15px">
                    <div :class="['table-field--ts align-self-start', !order.confirm ? 'not-confirm' : '']">
                        <div class="textXS neutral200 mb-5" v-if="order.confirm">
                            <el-tooltip :disabled="!order.confirm" effect="dark"
                                        content="Гос.номер автобетоносмесителя"
                                        placement="top">
                                ТС
                            </el-tooltip>
                        </div>
                        <div class="headingXS textMedium break-words neutral900">
                            {{ order.vehicle ? getVehicleNumber(order.vehicle) : '-' }}
                        </div>
                    </div>
                    <div :class="['table-field--volume align-self-start', !order.confirm ? 'not-confirm' : '']">
                        <div class="textXS mb-5 neutral200" v-if="order.confirm">
                            <el-tooltip effect="dark" content="Объем продукции"
                                        placement="top">
                                Объем
                            </el-tooltip>
                        </div>
                        <div class="headingXS textMedium neutral900">
                            <template v-if="order.status === 'loading'">
                                <div>
                                    {{ order.done ? formatNumber(order.done, 2) : 0 }} м³
                                    /
                                    {{ formatNumber(order.total, 2) }} м³
                                </div>
                            </template>
                            <template v-else-if="order.status === 'new'">
                                <div>
                                    {{ formatNumber(order.total, 2) }} м³
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    {{ formatNumber(order.done, 2) }} м³
                                </div>
                            </template>
                        </div>
                    </div>

                    <OperatorElcon v-if="factoryName === 'Elkon'" :order="order" :operator-print="operatorPrint"
                                   @openMixOrderAction="this.$emit('openMixOrderAction', order)"
                                   @printOrder="this.$emit('printOrder', order)"/>
                    <OperatorMeka v-else-if="factoryName === 'MEKA'" :order="order"
                                  @printOrder="this.$emit('printOrder', order)" :operator-print="operatorPrint"
                                  :operatorCheckTimeStart="operatorCheckTimeStart"
                                  @openMixOrderAction="this.$emit('openMixOrderAction', order)"/>
                    <OperatorTTC v-else-if="factoryName === 'SmartMix'" :order="order"
                                 @printOrder="this.$emit('printOrder', order)" :operator-print="operatorPrint"/>
                    <OperatorPromServ v-else-if="factoryName === 'PromServ'" :order="order"
                                      @printOrder="this.$emit('printOrder', order)" :operator-print="operatorPrint"/>
                    <OperatorBaseFactory v-else :order="order" :operatorCheckTimeStart="operatorCheckTimeStart"
                                         @openMixOrderAction="this.$emit('openMixOrderAction', order)"
                                         @printOrder="this.$emit('printOrder', order)" :operator-print="operatorPrint"/>
                </div>
            </div>
            <div class="d-flex-row gap-12 bg-neutral30 br-4" style="padding: 10px 20px"
                 v-if="order.confirm && (+appSettings?.operatorOutputContragents || order.comment)">
                <div
                    v-if="+appSettings?.operatorOutputContragents"
                    class="operatorOrderAdditionalInfo operatorOrderAdditionalInfo--company"
                    :class="{half: order.comment && order.confirm,'non-confirm': !order.confirm}">
                    <div>
                        <p class="operatorOrderAdditionalInfo__text textSM neutral900">
                            <span class="neutral200 textRegular mr-3">Контрагент:</span>
                            {{
                                +appSettings?.operatorOutputContragentsByShortName && order.company_short_name
                                    ? order.company_short_name
                                    : order.company_name
                            }}
                        </p>
                    </div>
                </div>
                <div
                    v-if="order.comment && order.confirm"
                    class="operatorOrderAdditionalInfo operatorOrderAdditionalInfo--comment">
                    <div class="operatorOrderAdditionalInfo__text textSM neutral900">
                        <span class="neutral200 textRegular mr-3">Комментарий:</span>
                        {{ order.comment }}
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import iconsMixin from '@/mixins/iconsMixin'
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import OperatorElcon from "./Factories/OperatorElcon";
import OperatorMeka from "./Factories/OperatorMeka";
import OperatorTTC from "./Factories/OperatorTTC";
import OperatorPromServ from "./Factories/OperatorPromServ";
import OperatorBaseFactory from "./Factories/OperatorBaseFactory";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: "OperatorTable",
    props: ['orders', 'operatorCheckTimeStart', 'tz', 'mixerCount', 'factoryName', 'getOperatorComment', 'operatorPrint'],
    components: {
        OperatorElcon,
        OperatorMeka,
        OperatorTTC,
        OperatorPromServ,
        OperatorBaseFactory,
    },
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, mixOrderStatuses, mixOrderInfo],
    data() {
        return {
            // factoryName: "PromServ"
            // factoryName: "SmartMix"
            // factoryName: "Elkon"
            // factoryName: "MEKA"
        }
    },
    created() {

    },
    computed: {
        ...mapState(useCommonStore, {
            appSettings: 'getAppSettings',
        }),
        isBaseFactory() {
            return this.factoryName !== 'Elkon'
                && this.factoryName !== 'MEKA'
                && this.factoryName !== 'PromServ'
                && this.factoryName !== 'SmartMix'
        },
    },
    methods: {
        getColor(order) {
            if (order.status === 'wait' || order.status === 'new' || !order.doc || order.type === 'manual') {
                return 'neutral300'
            } else if (order.status !== 'done') {
                return 'success400'
            } else {
                return 'primary600'
            }
        },
        getContentColor(status) {
            if (!this.isBaseFactory) {
                return 'neutral900'
            }
            return status !== 'new' && status !== 'loading' ? 'success400'
                : status === 'loading' ? 'primary600' : 'neutral900'
        }
    },
}
</script>

<style scoped>
.not-confirm {
    opacity: 0.2;
}

.table-field--ts {
    width: 160px;
}

.table-field--volume {
    width: 170px;
}

.table-field--ts, .table-field--volume {
    @media screen and (max-width: 1300px) {
        align-self: flex-start;
        width: 80px;
    }
}
</style>
