<template>
    <GuideTitle text="Складской учёт  - Добавление операции"/>

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='Откройте раздел "Остатки"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/cba5bf2f-c412-493c-a2eb-0d46baf3093e/File.jpeg?tl_px=0,0&br_px=2752,1538&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=81,33">
            </GuideStep>
            <GuideStep number="2" text='Нажмите кнопку "+ Добавить операцию"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/c8dbea9c-0d62-4b62-8730-3d7cb1a59e08/File.jpeg?tl_px=1842,0&br_px=3808,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1025,131">
            </GuideStep>
            <GuideStep number="3" text='Выберите дату и время. По умолчанию подставится "Сейчас"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/e0fba3d6-19a5-4fbf-9ff7-c2d08a4a9294/File.jpeg?tl_px=181,116&br_px=2146,1215&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>
            <GuideStep number="4" text='Выберите продукцию'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/d918d462-0475-497d-804b-a3a3721ee202/File.jpeg?tl_px=139,236&br_px=2104,1335&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,276">
            </GuideStep>
            <GuideStep number="5" text='В продукции можно воспользоваться поиском'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/9b1896ea-2e41-4479-af5b-0c0d68e6e4bc/File.jpeg?tl_px=192,202&br_px=2158,1300&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=396,133">
            </GuideStep>
            <GuideStep number="6" text='Так же можно отфильтровать продукцию по её источнику'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/1078451a-db5b-4ef3-9d66-74ae69d021b2/File.jpeg?tl_px=0,50&br_px=2752,1589&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=444,277">
            </GuideStep>
            <GuideStep number="7" text='Жмём на продукцию для выбора'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/a9ae4e36-5270-4924-9638-9f7fc4d2f5e3/File.jpeg?tl_px=101,210&br_px=2066,1309&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>
            <GuideStep number="8" text='Укажите склад, по которому нужно добавить / списать продукцию'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/13d60193-f626-4993-b389-f9fd1aafe204/File.jpeg?tl_px=115,319&br_px=2080,1418&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,298">
            </GuideStep>
            <GuideCallout text="Склады можно настроить на вкладке 'Склады'" />
            <GuideStep number="9" text='Укажите цену за 1 единицу'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/cbbf50b6-c238-4ef9-854a-472848d9b93e/File.jpeg?tl_px=91,339&br_px=2056,1438&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,332">
            </GuideStep>
            <GuideStep number="10" text='Выберите контрагента, если это приёмка или отгрузка (то есть покупка или продажа)'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/b41e9cab-b38e-4d34-b805-211bc6976bba/File.jpeg?tl_px=79,323&br_px=2045,1422&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=527,400">
            </GuideStep>
            <GuideStep number="11" text='Выберите тип операции:'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/9aa4a25b-bb0f-4372-96f1-68094b455c11/File.jpeg?tl_px=1398,310&br_px=3363,1409&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=522,182">
            </GuideStep>
            <GuideStep number="12" text='Укажите количество'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/f69ab26c-f348-459a-a0b9-11f32a881e4f/File.jpeg?tl_px=1333,317&br_px=3298,1415&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,226">
            </GuideStep>
            <GuideStep number="13" text='Кажите % НДС, если без НДС - оставьте пустым'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/621650df-0fb6-4d63-9d82-a64991993252/File.jpeg?tl_px=872,219&br_px=3624,1758&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,281">
            </GuideStep>
            <GuideStep number="14" text='Укажите включен ли НДС в цену'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/b3518116-5aa2-4165-b30d-b353a0246f6c/File.jpeg?tl_px=566,219&br_px=3318,1758&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,288">
            </GuideStep>
            <GuideStep number="15" text='Сохраните операцию'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/6f66d974-386b-4643-8e34-1c1247fbfb7c/File.jpeg?tl_px=0,219&br_px=2752,1758&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=318,377">
            </GuideStep>
            <GuideStep number="16" text='Операция добавлена, остаток обновлён'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/9b7166e8-ffe7-4f06-b022-08eb5ffefe23/File.jpeg?tl_px=1665,58&br_px=3630,1157&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,276">
            </GuideStep>
        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from "@/views/components/Guide/GuideTitle.vue";
import GuideSteps from "@/views/components/Guide/GuideSteps.vue";
import GuideStep from "@/views/components/Guide/GuideStep.vue";

export default {
    name: "StorehouseFaqAddOperation",
    components: { GuideTitle, GuideStep, GuideSteps },
};
</script>

<style scoped></style>
