<template>
    <el-form-item
        :label="label"
        :label-width="labelWidth"
        class="form-number"
        :class="[{'error-border': validationMessage, 'is-required': required, empty: !itemValue}, color, direction]"
    >
        <slot></slot>
        <el-tooltip
            placement="top"
            :content="tooltipContent"
            :disabled="!tooltipContent?.length"
            popper-class="dispatch-order-groups-create-form__tooltip"
        >
            <el-input
                class="custom-input"
                v-model="itemValue"
                @input="handleInput"
                type="number"
                :placeholder="placeholder"
                :disabled="disabled"
                :precision="precision ?? 0"
                :step="step ?? '0.01'"
                :max="max"
                :min="min"
                @keypress="preventNegative"
                @focusout="$emit('focusoutAction')"
            />
        </el-tooltip>
        <p
            v-if='validationMessage'
            class="formInput__validation-message m-0">
            {{ validationMessage }}
        </p>
    </el-form-item>
</template>

<script>

export default {
    name: "FormNumber",
    props: [
        'modelValue',
        'label',
        'placeholder',
        'required',
        'precision',
        'step',
        'max',
        'min',
        'disabled',
        'validationMessage',
        'color', // grey
        'direction', // column
        'labelWidth',
        'tooltipContent',
    ],
    data() {
        return {
            itemValue: this.modelValue
        }
    },
    methods: {
        preventNegative(e) {
            if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
            }
        },
        handleInput(val) {
            if (this.min > 0 && val < this.min) {
                this.itemValue = null
            }
            this.$emit('update:modelValue', this.itemValue)
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
