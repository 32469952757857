<template>
    <div class="mt-30 mb-20" v-if="orderDetails.statusHistory && orderDetails.deliveryAddressId">
        <MixPageOrderTimeline v-if="orderDetails.statusHistory.length > 0" :order="orderDetails" v-show="false"/>
    </div>

    <div class="infoBlock">
        <div class="textSM textRegular neutral300">Объем</div>
        <div class="textMD textMedium neutral700">
            {{ orderDetails.done }}
            <good-unit
                :unit="orderDetails.nGoodUnit"
            />
            <span class="neutral300 textRegular"> из {{ orderDetails.total }}
            <good-unit
                :unit="orderDetails.nGoodUnit"
            /></span>
        </div>
        <div v-if="orderDetails.clientTotal && orderDetails.clientTotal !== orderDetails.total"
             class="textSM textRegular neutral300 mt-15">
            Запрошенный объем - {{ orderDetails.clientTotal }}
            <good-unit
                :unit="orderDetails.nGoodUnit"
            />
        </div>
    </div>

    <div class="d-flex-full-stretch">
        <div v-if="orderDetails.nGoodId" class="infoBlock pointer" @click="orderDetails.nGoodId ? $router.push({name: 'GoodDetails', params: { id: orderDetails.nGoodId },}) : undefined">
            <div class="textSM textRegular neutral300">Рецепт</div>
            <div class="textMD textMedium neutral700">
                {{ orderDetails.nGoodName}}
            </div>
        </div>
        <div class="infoBlock">
            <div class="textSM textRegular neutral300">Адрес доставки</div>
            <div class="textMD textMedium neutral700">
                <template v-if="orderDetails.deliveryAddress">
                    {{ orderDetails.deliveryAddress.address }}
                </template>
                <template v-else>
                    <template v-if="orderDetails.type === 'take-away'">
                        Самовывоз
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
            </div>
        </div>
    </div>

    <div class="d-flex-full-stretch">
        <div class="infoBlock pointer" @click="orderDetails.vehicleId ? $router.push({name: 'VehicleDetails',params: { id: orderDetails.vehicleId },}) : undefined">
            <div class="textSM textRegular neutral300">Миксер</div>
            <div class="textMD textMedium neutral700">
                <template v-if="orderDetails.vehicle">
                    {{ orderDetails.vehicle.number ?? 'Миксер не указан' }}
                </template>
                <template v-else>-</template>
            </div>
        </div>
        <div class="infoBlock">
            <div class="textSM textRegular neutral300">Поставщик</div>
            <div class="textMD textMedium neutral700">
                <template v-if="orderDetails.seller">
                    {{ orderDetails.seller?.name ?? '-' }}
                </template>
                <template v-else>-</template>
            </div>
        </div>
    </div>

    <div class="infoBlock pointer" @click="orderDetails.companyId ? $router.push({name: 'CompanyEdit',params: { id: orderDetails.companyId },}) : undefined">
        <div class="textSM textRegular neutral300">Контрагент</div>
        <div class="textMD textMedium neutral700">
            {{ orderDetails.company?.name }}
        </div>

        <el-row :gutter="20" class="mt-30">
            <el-col :sm="8">
                <div class="textSM textRegular neutral300">ИНН
                    {{ orderDetails.company?.inn ?? '-' }}
                </div>
                <div class="textSM textRegular primary500">{{
                        orderDetails.company?.phone ?? '-'
                    }}
                </div>
            </el-col>
            <el-col :sm="8">
                <div class="textSM textRegular neutral300">ИНН
                    {{ orderDetails.company?.inn ?? '-' }}
                </div>
                <div class="textSM textRegular primary500">{{
                        orderDetails.company?.phone ?? '-'
                    }}
                </div>
            </el-col>
        </el-row>

    </div>

<!--    <div class="infoBlock" v-if="orderDetails.carrierCompanyId">-->
<!--        <div class="textSM textRegular neutral300">Перевозчик</div>-->
<!--        <div class="textMD textMedium neutral700">-->
<!--            {{ getData(orderDetails.carrierCompanyId, 'company')}}-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
import GoodUnit from "@/views/components/goodUnit.vue";
import functionsMixin from "@/mixins/functionsMixin";
import getUnitFromGoodsMixin from "@/mixins/getUnitFromGoodsMixin";
import MixPageOrderTimeline from "@/views/components/MixPage/OrdersGroup/MixPageOrderTimeline.vue";

export default {
    name: 'MixPageOrderDetailsInfo',
    components: {MixPageOrderTimeline, GoodUnit},
    props: ['orderDetails'],
    inject: ['api'],
    mixins: [functionsMixin, getUnitFromGoodsMixin],
    data(){
        return{

        }
    },
    methods: {

    },
}
</script>

<style scoped>

</style>
