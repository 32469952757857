<template>
  <div class="custom-modal">
      <div class="top">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3307 6.0327C18.3314 5.92302 18.3104 5.81431 18.2689 5.71277C18.2274 5.61124 18.1663 5.5189 18.0891 5.44103L14.5557 1.9077C14.4779 1.83046 14.3855 1.76936 14.284 1.72789C14.1825 1.68642 14.0737 1.6654 13.9641 1.66603C13.8544 1.6654 13.7457 1.68642 13.6442 1.72789C13.5426 1.76936 13.4503 1.83046 13.3724 1.9077L11.0141 4.26603L1.90574 13.3744C1.82851 13.4522 1.7674 13.5446 1.72593 13.6461C1.68446 13.7476 1.66344 13.8564 1.66408 13.966V17.4994C1.66408 17.7204 1.75187 17.9323 1.90815 18.0886C2.06443 18.2449 2.2764 18.3327 2.49741 18.3327H6.03074C6.14735 18.339 6.26399 18.3208 6.37309 18.2792C6.4822 18.2375 6.58134 18.1734 6.66408 18.091L15.7224 8.9827L18.0891 6.66603C18.1651 6.58526 18.2271 6.49231 18.2724 6.39103C18.2804 6.3246 18.2804 6.25745 18.2724 6.19103C18.2763 6.15224 18.2763 6.11315 18.2724 6.07436L18.3307 6.0327ZM5.68908 16.666H3.33074V14.3077L11.6057 6.0327L13.9641 8.39103L5.68908 16.666ZM15.1391 7.21603L12.7807 4.8577L13.9641 3.6827L16.3141 6.0327L15.1391 7.21603Z" fill="#49515F"/>
          </svg>
          <h4>Редактирование</h4>
          <p class="changes" v-if="!isSame">Изменения не сохранены</p>
      </div>
      <p class="description">Подтвердите или укажите данные по отгрузкам, данных по которым нет или были определены системой автоматически</p>
      <div class="custom-modal__table" v-if="tempData">
          <div class="reportMixItem">
              <div class="reportMixItemBody neutral200 textRegular">
                  <el-row :gutter="28">
                      <el-col :sm="2">
                          Отгрузка
                      </el-col>
                      <el-col :sm="3">
                          Дата и время
                      </el-col>
                      <el-col :sm="6">
                          Рецепт факт
                      </el-col>
                      <el-col :sm="3">
                          Объем факт
                      </el-col>
                      <el-col :sm="6">
                          Контрагент
                      </el-col>
                      <el-col :sm="4">
                          Тип оплаты
                      </el-col>
                  </el-row>
              </div>
          </div>
          <div v-for="item in tempData?.mix_orders.slice((pagination.page - 1) * pagination.pageSize, pagination.page * pagination.pageSize)" :key="item.id" class="reportMixItem">
              <div class="reportMixItemBody textRegular">
                  <el-row :gutter="28">
                      <el-col style="display: flex; align-items: center" :sm="2">
                          <span class="green">{{ item.doc ? `#${item.doc}` : '-' }}</span>
                      </el-col>
                      <el-col style="display: flex; align-items: center" :sm="3">
                          <span class="grey">
                              {{ item.date ? getDateByTz(item.date, 'DD.MM.YYYY HH:mm', item.tz) : '-' }}
                          </span>
                      </el-col>
                      <el-col style="display: flex; align-items: center" :sm="6">
                          <span class="grey">
                              {{ item.name ?? '-' }}
                          </span>
                      </el-col>
                      <el-col style="display: flex; align-items: center" :sm="3">
                          <span class="grey">
                              {{ item.volume ?? '-' }} м<sup>3</sup>
                          </span>
                      </el-col>
                      <el-col :sm="6">
                          <div class="custom-modal__select" :class="{ red: !item.company_id, orange: item?.system_fields?.includes('company_id') }">
                              <FormSelect @changeValue="handleChangeIsSame" :items="tempData?.companies" v-model="item.company_id" />
                          </div>
                      </el-col>
                      <el-col :sm="4">
                          <div class="custom-modal__select" :class="{ red: !item.payment_method, orange: item?.system_fields?.includes('payment_method') }">
                              <FormSelect @changeValue="handleChangeIsSame" :items="Object.keys(tempData?.payment_methods ?? {}).map(key => ({ id: key, name: tempData?.payment_methods[key] }))" v-model="item.payment_method" />
                          </div>
                      </el-col>
                  </el-row>
              </div>
          </div>
      </div>
      <TablePagination
          v-if="tempData"
          v-model="pagination.pageSize"
          :total="tempData?.mix_orders?.length ?? 0"
          @action="setPage"
      />
      <div class="buttons">
          <el-button :disabled="tempData?.mix_orders?.some(i => !i.company_id) || tempData?.mix_orders?.some(i => !i.payment_method)" @click="handleSubmit" type="primary">Применить изменения</el-button>
          <el-button plain @click="handleClose">Отмена</el-button>
      </div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { API_ROUTES } from '@/const';
import {defineProps, defineEmits, ref, onMounted} from 'vue';
import moment from "moment-timezone";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import {httpService} from "@/services/http.service";
import TablePagination from "@/views/components/Table/TablePagination.vue";

const props = defineProps(['data', 'route']);
const emits = defineEmits(['close', 'submit']);

const pagination = ref({
    page: 1,
    pageSize: 10,
})

const setPage = (v) => pagination.value.page = v;

const tempData = ref();

const handleClose = () => {
    emits('close');
}

const getDateByTz = (timestamp, format, timezone) => {
    if (!timestamp || !timezone) {
        return ''
    }

    // переделывает timestamp в нужный формат, например 'HH:mm DD.MM.YY'
    if (!format) format = 'DD.MM.YY в HH:mm'

    if (format.includes('DD.MM')) {
        var given = moment(moment.unix(timestamp).tz(timezone), 'YYYY-MM-DD')
        var current = moment().tz(timezone).startOf('day')
        var diff = moment.duration(given.diff(current)).asDays()

        if (diff >= 0 && diff < 1) {
            if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Сегодня'
            if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Сегодня в HH:mm'
        }

        if (diff >= -1 && diff < 0) {
            if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Вчера'
            if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Вчера в HH:mm'
        }
    }

    return moment.unix(timestamp).tz(timezone).locale('ru').format(format)
}

const isSame = ref(true);
const handleChangeIsSame = () => {
    isSame.value = false;
}

const handleSubmit = () => {
    httpService().post(API_ROUTES.report.postGoodsReportMixOrders, {
        reportKey: props.route.meta.key,
        mix_orders: tempData.value.mix_orders,
    }).then(() => {
        ElMessage({
            message: 'Отгрузки успешно сохранены',
            type: 'success'
        });
        handleClose();
        emits('submit')
    }).catch(() => {
        ElMessage({
            message: 'Что-то пошло не так',
            type: 'error'
        });
    })
}

onMounted(() => {
    tempData.value = props.data;
})
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.custom-modal {
    width: 90vw;
    height: 95vh;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.25);
  background-color: variables.$white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: scroll;

    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
    z-index: 10;

    &__table {
        .green {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            color: #009272;
        }

        .grey {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: variables.$neutral400;
        }
    }

    &__select {
        div.el-form-item {
            margin-bottom: 0 !important;
        }

        //&.red {
        //    .el-input__wrapper {
        //        box-shadow: 0 0 0 1px #F74539 inset !important;
        //    }
        //}

        //&.orange {
        //    .el-input__wrapper {
        //        box-shadow: 0 0 0 1px #ECAF02 inset !important;
        //    }
        //}
    }
}

.top {
    display: flex;
    align-items: center;

  h4 {
    margin: 0 20px 0 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: variables.$neutral600;
  }

    .changes {
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #E27612;
    }
}

.description {
    margin: 12px 0 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: variables.$neutral600;
}

.buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
</style>

<style lang="scss">
.body-overflow-hidden {
    overflow: hidden !important;
}

.custom-modal {
    &__select {
        div.el-form-item {
            margin-bottom: 0 !important;
        }

        &.red {
            .el-input__wrapper {
                box-shadow: 0 0 0 1px #F74539 inset !important;
            }
        }

        &.orange {
            .el-input__wrapper {
                box-shadow: 0 0 0 1px #ECAF02 inset !important;
            }
        }
    }
}
</style>
