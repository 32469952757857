<template>
    <div class="group-orders-summary">
        <div class="group-orders-summary-body">
            <el-row class="summary">
                <el-col class="date-picker-container">
                    <div class="current-day">{{ currentDayName }}</div>
                    <el-date-picker
                        class="summary-date-picker pointer"
                        v-model="filters['filter[date]']"
                        :type="'date'"
                        :format="'D MMMM'"
                        :clearable="false"
                        :editable="false"
                        :size="'default'"
                        @change="$emit('changeDate', filters['filter[date]'])"
                    />
                </el-col>
                <el-col class="summary__empty">
                </el-col>
                <el-col class="summary__orders">
                    <SummaryOrdersCount :summaryOrdersCount="summary?.summaryOrdersCount ?? []"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__mix-orders">
                    <SummaryMixOrdersCount :summaryMixOrdersCount="summary?.summaryMixOrdersCount ?? []"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__vehicles">
                    <SummaryVehiclesCount :mixOrders="mixOrders" :summaryVehiclesCount="summary?.summaryVehiclesCount ?? []" :hours="hours"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__mixers">
                    <SummaryMixersCount :mixOrders="mixOrders" :hours="hours"/>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment/moment'
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from '../../../mixins/mobileCheckMixin';
import SummaryOrdersCount from "@/views/dispatch/components/summaryBlocks/SummaryOrdersCount.vue";
import SummaryMixOrdersCount from "@/views/dispatch/components/summaryBlocks/SummaryMixOrdersCount.vue";
import SummaryVehiclesCount from "@/views/dispatch/components/summaryBlocks/SummaryVehiclesCount.vue";
import SummaryMixersCount from "@/views/dispatch/components/summaryBlocks/SummaryMixersCount.vue";

export default {
    name: 'Summary',
    components: {SummaryMixOrdersCount, SummaryOrdersCount, SummaryMixersCount, SummaryVehiclesCount},
    props: ['orders', 'summary'],
    data() {
        return {}
    },
    mixins: [mixOrderGroupStatusDeclension, functionsMixin, mobileCheckMixin],
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        mixOrders() {
            return this.orders.flatMap(order => order.orders).filter(el => el.type === 'delivery')
        },
        hours() {
            const now = moment().tz(this.moduleTimezone);
            const normalizeDates = (orders) => {
                return orders.map(order => ({
                    startAt: moment(order.start_at).tz(this.moduleTimezone),
                    returnAt: order.return_at ? moment(order.return_at).tz(this.moduleTimezone) : null
                }));
            };

            let orders = normalizeDates(this.mixOrders);

            let earliest = moment.min(orders.map(order => moment(order.startAt)));
            let latest = moment.max(orders.map(order => moment(order.returnAt || order.startAt)));

            if (!earliest.isSame(latest, 'day')) {
                const uniqueDates = orders.flatMap(o => [o.startAt, o.endAt]).filter(Boolean);
                const latestDate = moment.max(uniqueDates.map(date => date.clone().startOf('day')));
                orders = orders.filter(order => order.startAt.isSame(latestDate, 'day') || (order.returnAt && order.returnAt.isSame(latestDate, 'day')));
                earliest = moment.min(orders.map(order => order.startAt));
                latest = moment.max(orders.map(order => order.returnAt || order.startAt));
            }

            const isSameDay = now.isSame(earliest, 'day');
            if (isSameDay) {
                orders = orders.filter(o => o.startAt.isAfter(now) || (o.endAt && o.endAt.isAfter(now)));
                earliest = moment.min(orders.map(o => o.startAt));
                latest = moment.max(orders.map(o => o.endAt || o.startAt));
            }

            const adjustedEarliest = earliest.clone().subtract(1, 'hour').hour();

            const hoursArray = Array.from({length: 8}, (_, i) => {
                const hour = (adjustedEarliest + i) % 24;
                return {
                    value: hour,
                    itemStyle: {
                        color: hour === now.hour() ? '#1984e6' : '#c9ccd0'
                    }
                };
            });

            return hoursArray;

        },
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.getDate).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">

.order-groups {
    &__info {
        margin-top: auto;
        margin-bottom: auto;

        &__desc {

            &--yellow {
                color: #FFB500;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
</style>
