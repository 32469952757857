<template>
    <GuideTitle text="Как создать и провести обслуживание"/>

<GuideDescription text="Пошаговая инструкция по создания работы, ее проведению, редактированию" />

<GuideSteps>
    <template v-slot:steps>
        <GuideStep number="1" text='Перейдите в модуль обслуживания техники'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d7da217d-9b68-4fd6-a62b-ba0eade22c95/ascreenshot.jpeg?tl_px=0,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0">
        </GuideStep>
        <GuideStep number="2" text='Перейдите на вкладку "Сервис"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d7da217d-9b68-4fd6-a62b-ba0eade22c95/ascreenshot.jpeg?tl_px=0,0&br_px=1965,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=245,68">
        </GuideStep>
        <GuideStep number="3" text='Нажмите на синюю кнопку справа вверху'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/86ef3cb6-6fc1-4df1-98f3-aa777e2996e6/ascreenshot.jpeg?tl_px=1312,0&br_px=2880,876&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1019,165">
        </GuideStep>
        <GuideStep number="4" text='Укажите название работы. Например, "ТО 1000 км"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/5db80bf7-c25a-4fa5-92ef-103c14b2d229/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=262,153">
        </GuideStep>
        <GuideStep number="5" text='Выберите ТС'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/f362b8e5-0926-454a-82ee-7fed9308662b/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=175,346">
        </GuideStep>
        <GuideStep number="6" text='Укажите тип работы. Функционально есть отличия только для типа работ "Осмотр". В этом случае будет отсутствовать список запчастей'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/ae60a56c-4dd2-4c2c-b3e9-2e6b509133f3/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=155,361">
        </GuideStep>
        <GuideStep number="7" text='Укажите на каком пробеге собираетесь проводить работу'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d9c019ba-67ad-4092-8904-f5b2f5e9d148/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=294,279">
        </GuideStep>
        <GuideStep number="8" text='Вы можете задать периодическое повторение работы. Например, вы создали работу "Осмотр каждые 1000 км". С помощью данного функционала вы можете сделать так, чтобы система автоматически назначала такую же работу каждые 1000 км'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/d5c47749-572d-44d7-bbd9-858c5500a609/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=48,250">
        </GuideStep>
        <GuideStep number="9" text='Укажите периодичность повторения. Например, 1000. То есть повторение будет каждые 1000 км пробега'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/01c21f15-48a5-45ab-b0c6-a4df3a8f88cd/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=361,160">
        </GuideStep>
        <GuideStep number="10" text='Вы можете указать за сколько км до начала назначить работу. Или оставьте пустым, чтобы применить настройки по умолчанию'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/bc2c1a71-88e9-41cf-b19b-f5399297da1e/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=352,296">
        </GuideStep>
        <GuideStep number="11" text='Нажмите на "Сохранить настройки повторения". Предварительно прочитайте синий текст над кнопкой, убедитесь, что все верно'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/71caa829-d29c-44f5-b1f0-4e4d4a28a93a/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=370,392">
        </GuideStep>
        <GuideStep number="12" text='Пролистайте ниже и нажмите на "Добавить работы"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/499e1205-86a1-4629-bd32-42c2be7b4eff/ascreenshot.jpeg?tl_px=41,0&br_px=2794,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,322">
        </GuideStep>
        <GuideStep number="13" text='Введите "Наименование работы" первого необходимого действия'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/ecc2b394-2914-47dc-959f-3f127f6dc6f9/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=384,191">
        </GuideStep>
        <GuideStep number="14" text='Нарпимер, введите "Осмотр ремня ГРМ"' />
        <GuideStep number="15" text='Нажмите на кнопку "Добавить работу"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/8cab3294-fd9b-49a5-8478-efeaea788409/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=348,346">
        </GuideStep>
        <GuideStep number="16" text='Чтобы добавить еще одно действие, снова нажмите на кнопку "Добавить работу"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/5b3d2626-ef1e-4a40-bb0e-482ba8461f6f/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=401,252">
        </GuideStep>
        <GuideStep number="17" text='Введите, например "Проверка уровня антифриза"' />
        <GuideStep number="18" text='Снова нажмите на кнопку "Добавить работу"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/0a648657-6b20-4cf5-b16a-b8d25113c5c2/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=358,349">
        </GuideStep>
        <GuideStep number="19" text='Если вы закончили добавление работ, что нажмите на "Сохранить список работ"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/1a600887-6b66-44a2-b6ae-787fabe45f86/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=360,346">
        </GuideStep>
        <GuideStep number="20" text='Чтобы добавить расход запчастей нажмите на кнопку "Добавить запчасти"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/40cc863b-9ede-4ad7-a1d7-1827e6187475/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=521,412">
        </GuideStep>
        <GuideStep number="21" text='Выберите запчасть из списка. Здесь отображаются только запчасти из раздела "Склад запчастей"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/6e26cc9f-8594-42b6-b325-45d52ea6347a/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=417,244">
        </GuideStep>
        <GuideStep number="22" text='Например, выберете масло'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/f1a8ccbf-4f5a-409d-b432-23adec9e2959/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=364,289">
        </GuideStep>
        <GuideStep number="23" text='Укажите количество'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/98ef0cf1-de73-4c1c-b112-44731fe3222a/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=690,252">
        </GuideStep>
        <GuideStep number="24" text='Нажмите на кнопку "Добавить запчасть"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/34db8d7c-fb61-4881-8c9c-c37a18f0ff16/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=305,295">
        </GuideStep>
        <GuideStep number="25" text='Если вы добавили все необходимые запасные части для проведения работы, то нажмите на кнопку "Сохранить список запчастей"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/0ba28e93-cd70-44c9-92c7-e94a11553b69/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=328,311">
        </GuideStep>
        <GuideStep number="26" text='Нажмите на кнопку "Создать заказ-наряд"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/a2ee76b4-ee5d-41bf-9a48-fdfd09a5ee01/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=128,486">
        </GuideStep>
        <GuideStep number="27" text='Работа создана. Если она ближайшая, то появится на экране ТС. Также она доступна в вкладках "Сервис"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/189e8092-a245-420d-b866-4d8f6fea5110/user_cropped_screenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=528,421">
        </GuideStep>
        <GuideStep number="28" text='Чтобы перейти в работу, нажмите на кнопку "Подробнее"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/0a2c2f8b-efb1-4719-81ab-4c96d1fc6b9e/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=729,225">
        </GuideStep>
        <GuideStep number="29" text='Чтобы начать действие, нажмите "Взять в работу". Вы можете делать это, например, перед началом работ, чтобы видеть список работ, которые сейчас проводятся в гараже'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/8cd59a37-5c51-4e74-88d1-29c473439e56/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=111,502">
        </GuideStep>
        <GuideStep number="30" text='После окончания работ, нажмите на кнопку "Завершить"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/5666aead-c952-49bc-84ca-b56b1779ca29/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=97,501">
        </GuideStep>
        <GuideStep number="31" text='Вы можете заполнить "Отчет о работе" или оставьте пустым'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/077962b2-7e06-43cd-8671-fe8d167abb60/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=380,312">
        </GuideStep>
        <GuideStep number="32" text='Нажмите на кнопку "Продолжить"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/88b95a85-4726-418e-868d-13111e065926/ascreenshot.jpeg?tl_px=634,290&br_px=2163,1145&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=195,499">
        </GuideStep>
        <GuideStep number="33" text='Вы можете изменить список работ. Для этого нажмите на кнопку "Редактировать список"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/ad1c333c-c331-4392-9033-50462cbe16d6/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=432,261">
        </GuideStep>
        <GuideStep number="34" text='Как делали ранее, нажмите на кнопку "Добавить работу"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/b478ad97-543c-4fbd-90f8-5e2db0f35e06/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=444,283">
        </GuideStep>
        <GuideStep number="35" text='Укажите "Наименование работы"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/e6cd0d14-e77d-416e-96bc-4a1b308e2211/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=426,202">
        </GuideStep>
        <GuideStep number="36" text='Нажмите на кнопку "Добавить работу"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/f9e01fe2-738d-40b7-bd62-3ce49df2221c/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=376,351">
        </GuideStep>
        <GuideStep number="37" text='Если вы завершили редактирование, то нажмите на кнопку "Сохранить список работ"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/2be8d714-5411-4d11-bf26-762cc43fac19/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=395,353">
        </GuideStep>
        <GuideStep number="38" text='Нажмите на кнопку "Продолжить"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/93a40ac3-6ac8-4913-8aa1-53d3745545ea/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=385,389">
        </GuideStep>
        <GuideStep number="39" text='Чтобы изменить список запчастей нажмите на кнопку "Редактировать список"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/df0d6999-988e-44c4-b140-866a20bac1ea/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=429,274">
        </GuideStep>
        <GuideStep number="40" text='Например, вы можете удалить запчасть из списка, так как в процессе выполнения работ взяли другую'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/1cdee4c5-bc4d-424b-a054-6677238ed2d8/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=744,211">
        </GuideStep>
        <GuideStep number="41" text='Выберите новую запчасть'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/bdedca01-2e46-4b93-a1a7-3062d084bfcb/ascreenshot.jpeg?tl_px=60,0&br_px=2812,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,239">
        </GuideStep>
        <GuideStep number="42" text='Укажите количество в поле "Кол-во"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/fc9bed24-dd77-4315-a587-093131ea8f57/ascreenshot.jpeg?tl_px=127,0&br_px=2880,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=627,252">
        </GuideStep>
        <GuideStep number="43" text='Нажмите на кнопку "Добавить запчасть"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/ba330491-f4d5-432d-b3d1-fdbf6668052b/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=331,292">
        </GuideStep>
        <GuideStep number="44" text='Если вы завершили редактирование списка запчастей, то нажмите на кнопку  "Сохранить список запчастей"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/92c8a1f8-80d0-4122-8fe0-fe270d8390d0/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=351,308">
        </GuideStep>
        <GuideStep number="45" text='Нажмите на кнопку "Завершить"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/85fe948a-44a2-4f7b-ae7e-dbfbe3345289/ascreenshot.jpeg?tl_px=0,0&br_px=2752,1420&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=381,352">
        </GuideStep>
        <GuideStep number="46" text='Обслуживание проведено! Теперь оно в статусе "Завершено"'
            src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-16/217f6b80-571e-42ce-af29-a94628fe950d/ascreenshot.jpeg?tl_px=0,0&br_px=1528,854&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1376,236">
        </GuideStep>
    </template>
</GuideSteps>
</template>

<script>
import GuideTitle from '@/views/components/Guide/GuideTitle.vue';
import GuideDescription from '@/views/components/Guide/GuideDescription.vue';
import GuideStep from '@/views/components/Guide/GuideStep.vue';
import GuideSteps from '@/views/components/Guide/GuideSteps.vue';

export default {
    name: 'HowCreateAndPerformService',
    components: { GuideTitle, GuideStep, GuideDescription, GuideSteps },
    data() {
        return {}
    },
}
</script>
