<template>
    <GuideTitle text="Складской учёт - Остатки"/>

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='Откройте раздел "Остатки"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/06c22887-0d4d-454e-b0d9-cd6aaf0041c1/File.jpeg?tl_px=0,0&br_px=2752,1538&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=81,33">
            </GuideStep>
            <GuideStep number="2" text='Изначально он пуст, нужно добавить продукцию на склад через операции "Оприходование" / "Приёмка"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/807699c2-4c6f-4ee6-8603-96f2ea836cc7/File.jpeg?tl_px=423,0&br_px=3176,1538&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,219">
            </GuideStep>
            <GuideStep number="3" text='Остатки можно просматривать как "По продукции" - где выводится всё наличие продукции'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/703fcc85-3c3a-4261-a886-64871f747079/File.jpeg?tl_px=0,0&br_px=1965,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=125,191">
            </GuideStep>
            <GuideStep number="4" text='Так и "По складам"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/5f7acc36-3756-49a8-9c15-b5ff2110752f/File.jpeg?tl_px=0,0&br_px=1965,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=272,197">
            </GuideStep>
            <GuideStep number="5" text='Где выводится продукция с разбивкой по всем складам в модуле'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/3ddfe373-b0ba-4627-8ac6-d66489d3d79c/File.jpeg?tl_px=197,0&br_px=2950,1538&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,179">
            </GuideStep>
            <GuideCallout text="Склады можно настроить на вкладке 'Склады'" />
        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from "@/views/components/Guide/GuideTitle.vue";
import GuideSteps from "@/views/components/Guide/GuideSteps.vue";
import GuideStep from "@/views/components/Guide/GuideStep.vue";

export default {
    name: "StorehouseFaqInfo",
    components: { GuideTitle, GuideStep, GuideSteps },
};
</script>

<style scoped></style>
