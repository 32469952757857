<template>
    <h2>Как пригласить сотрудника</h2>
    <p>Для приглашения нового пользователя необходимо выполнить следующие действия:</p>
    <section class="guide-steps">
        <div class="guide-steps__container d-flex f-direction-column">
            <ul>
                <li>Перейдите в
                    <router-link :to="{ name: 'UsersIndex' }">настройки -> пользователи</router-link>
                </li>
                <li>Нажмите на кнопку
                    <router-link :to="{ name: 'UsersCreate' }">"Пригласить пользователя"</router-link>
                    в правом верхнем углу
                </li>
                <li>Введите ФИО, логин, телефон, электронную почту нового пользователя</li>
                <li>Выбери роль пользователя</li>
                <li>Справа выберите доступные модули для этого пользователя</li>
                <li>Задайте пароль</li>
            </ul>
            <p>После ввода всех данных и нажатия на кнопку "Создать пользователя" он получит на почту письмо с данными для
                входа</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "SettingsFaqHowInviteNewUser"
}
</script>

<style scoped>

</style>
