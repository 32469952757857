<template>
    <div class="progress-bar">
        <div
            class="progress-bar-section"
            :style="{ width: `${firstSectionPercent}%`, backgroundColor: firstColor }"
            @animationend="onAnimationEnd"
        ></div>
        <div
            class="progress-bar-section"
            :style="{ width: `${secondSectionPercent}%`, backgroundColor: secondColor }"
            @animationend="onAnimationEnd"
        ></div>
        <div
            class="progress-bar-section"
            :style="{ width: `${thirdSectionPercent}%`, backgroundColor: thirdColor }"
            @animationend="onAnimationEnd"
        ></div>
        <div class="progress-bar-delivered" :class="{'progress-bar-delivered--finished': donePercent == 100}">
            {{ donePercent == 100 ? donePercent : parseFloat(percent1).toFixed(2) }}%
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressMultiBar',
    props: {
        percent1: {
            type: Number,
            required: true,
        },
        percent2: {
            type: Number,
            required: true,
        },
        donePercent: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            firstSectionPercent: 0,
            secondSectionPercent: 0,
            thirdSectionPercent: 0,
            firstColor: '#1677FF',
            secondColor: '#00D0A3',
            thirdColor: '#F0F0F0',
        };
    },
    watch: {
        'percent1': {
            handler: function () {
                this.animateFirstSection(this.percent1);
                this.animateSecondSection(this.percent2);
                this.animateThirdSection();
            },
            deep: true,
            immediate: true
        },
        'percent2': {
            handler: function () {
                this.animateFirstSection(this.percent1);
                this.animateSecondSection(this.percent2);
                this.animateThirdSection();
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        animateFirstSection(percent) {
            this.firstSectionPercent = percent;
        },
        animateSecondSection(percent) {
            this.secondSectionPercent = percent - this.percent1;
        },
        animateThirdSection() {
            this.thirdSectionPercent = 100 - this.percent2;
        },
        onAnimationEnd() {
            if (
                this.firstSectionPercent >= this.percent1 &&
                this.secondSectionPercent >= this.percent2 - this.percent1
            ) {
                this.thirdSectionPercent = 100 - this.percent2;
            }
        },
    },
    mounted() {
        this.animateFirstSection(this.percent1);
        this.animateSecondSection(this.percent2);
        this.animateThirdSection();
    },
};
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.progress-bar {
    width: 100%;
    height: 14px;
    background-color: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    position: relative;

    &section {
        height: 100%;
        display: inline-block;
        transition: width 0.5s ease;
    }

    &-delivered {
        position: absolute;
        top: 50%;
        right: 2px;
        transform: translate(0%, -50%);
        font-size: 10.5px;
        color: #49515f;
        font-weight: 500;
        background: #F0F0F0;
        min-width: fit-content;
        min-height: fit-content;
        line-height: 1;
        border-radius: 4.5px;
        padding-left: 1.5px;
        padding-right: 1.5px;

        &--finished {
            background: transparent;
            color: variables.$white;
        }
    }
}
</style>
