<template>
    <el-form-item

        :label="label ?? 'Дата и время'"
        class="form-datetime-picker"
        :class="[{ 'is-required': required, empty: !itemDate, 'error-border': validationMessage }, color, direction]"
    >
        <el-date-picker
            class="custom-datepicker"
            v-model="itemDate"
            :type="type ?? typePicker"
            :format="format"
            :disabled="disabled"
            :prefix-icon="customIcon ? icon : null"
            :placeholder="placeholder ?? 'Выберите дату'"
            :size="size ?? 'default'"
            :editable="editable"
            @focusout="$emit('focusoutAction')"
            @change="$emit('changeAction')"
        >
        </el-date-picker>
    </el-form-item>
</template>

<script>

import { toServerIsoDate } from '@/utils/common';
import DateIconWithDots from '@/views/components/Icons/DateIconWithDots';

export default {
    name: "FormDateTimePicker",
    props: [
        'modelValue',
        'type',
        'label',
        'placeholder',
        'disabled',
        'default-time',
        'required',
        'formatDate',
        'size',
        'customIcon',
        'editable',
        'color', // grey,
        'direction', // column
        'validationMessage',
    ],
    data () {
        return {
            icon: DateIconWithDots,
            itemDate: this.modelValue,
            typePicker: 'date'
        }
    },
    watch: {
        'itemDate': {
            handler: function () {
                if (this.type === 'datetimerange') {
                    this.$emit('update:modelValue', this.itemDate)
                    return;
                }

                this.$emit('update:modelValue', this.itemDate
                ? toServerIsoDate(this.itemDate)
                : null)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.itemDate = this.modelValue
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        format() {
            if (this.formatDate) {
                return this.formatDate
            }

            if (this.type && this.type === 'datetime') {
                return 'DD.MM.YY HH:mm'
            }

            if (this.type && this.type === 'datetimerange') {
                return 'DD.MM.YY HH:mm'
            }

            return 'DD.MM.YY'
        },
    }
}
</script>