<template>
    <svg :class="[{'currentColor' : currentColor}]" xmlns="http://www.w3.org/2000/svg"
         width="28" height="28" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
         class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="19" cy="46" r="3" fill="" opacity="1"></circle><circle
        cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="9" cy="46" r="3" fill=""
                                                                   opacity="1"></circle><path
        d="m2 44 4-5h46a7.274 7.274 0 0 1 4.859 5.233 1 1 0 0 0 .972.767H60.5a1.5 1.5 0 0 0 1.5-1.5V32.873a3 3 0 0 0-.469-1.61l-5.647-8.874A3 3 0 0 0 53.353 21H47"
        fill="" opacity="1"></path><path
        d="m22 39 5 6h16M47 39V17.621a1.5 1.5 0 0 0-.44-1.061L45 15M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M39.1 39l3.7-5.9a1.345 1.345 0 0 0-.125-1.6l-6.734-7.9-14.77-7.7-9.671-.893a1.649 1.649 0 0 0-1.423.571L5.23 23.106a1.426 1.426 0 0 0 .119 1.711l6.319 7.442L26.438 39"
        fill="" opacity="1"></path><circle cx="44" cy="27" r="3" fill="" opacity="1"></circle><path
        d="M35.933 23.593 27.493 39M21.163 15.889l-9.495 16.37M8 39V27.94" fill=""
        opacity="1"></path></g></g>
    </svg>
</template>

<script>
export default {
    name: "MixServiceIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path, circle {
            stroke: currentColor;
        }
    }
</style>
