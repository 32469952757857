<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">Заказ изменен</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5" v-for="(value, index) in displayData" :key="index">
                <span class="neutral300 mr-5">{{ value.name }}:</span>
                <span class="text-line-through mr-5 neutral100" v-if="value.oldData">{{ value.oldData }}</span>
                <span>{{ value.data }}</span>
            </div>
            <el-button class="show-more-btn" v-if="values.length - 1 > 5" @click="() => showMore = !showMore" type="text">
                {{showMore ? 'Скрыть' : 'Показать ещё'}}
            </el-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import functionsMixin from "@/mixins/functionsMixin";
import {mapState, mapActions} from 'pinia'
import {useCommonStore} from '@/store/common'
import {useOrderGroupsStore} from "@/store/orderGroups";
import paymentMethods from "@/mixins/paymentMethods";
import intervalTypes from "@/mixins/intervalTypes";

export default {
    name: "MixOrderGroupEditLog",
    mixins: [functionsMixin, paymentMethods, intervalTypes],
    props: ['log', 'companyId', 'tz'],
    async created() {
        await this.getAdditionalData(this.logs);
    },
    data() {
        return {
            displayData: [],
            showMore: false
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
            contracts: 'getContracts',
            vehicles: 'getVehicles',
            users: 'getUsers',
            serviceTariffs: 'getServiceTariffs',
            modules: 'getModules',
        }),
        ...mapState(useOrderGroupsStore, {
            companyManagers: 'getCompanyManagers',
            address: 'getAddress',
        }),
        logs() {
            return JSON.parse(this.log.content.info)
        },
        values() {
            const elems = [];
            const values = {...this.logs};

            const mappings = [
                {
                    key: 'spec_good_id',
                    name: 'Спецификация',
                    getData: (id) => this.goods.find((el) => el.id === id)?.name,
                },
                {
                    key: 'good_id',
                    name: 'Продукция',
                    getData: (id) => this.goods.find((el) => el.id === id)?.name,
                },
                {
                    key: 'buyer_company_id',
                    name: 'Покупатель',
                    getData: (id) => this.companies.find((el) => el.id === id)?.name,
                },
                {
                    key: 'manager_id',
                    name: 'Менеджер',
                    getData: (id) => this.users.find((el) => el.id === id)?.name,
                },
                {
                    key: 'delivery_address_id',
                    name: 'Адрес доставки',
                    getData: (id) => this.address.find((el) => el.id === id)?.name,
                },
                {
                    key: 'company_manager_id',
                    name: 'Представитель контрагента',
                    getData: (id) => this.companyManagers.find((el) => el.id === id)?.name,
                },
                {
                    key: 'carrier_company_id',
                    name: 'Перевозчик',
                    getData: (id) => this.companies.find((el) => el.id === id)?.name,
                },
                {
                    key: 'seller_company_id',
                    name: 'Поставщик',
                    getData: (id) => this.companies.find((el) => el.id === id)?.name,
                },
                {
                    key: 'payment_method',
                    name: 'Способ оплаты',
                    getData: (method) => this.getInfo(method, this.paymentMethods),
                },
                {
                    key: 'distributed',
                    name: 'Распределение',
                    getData: (value) => (value === true ? 'Распределено' : 'Не распределено'),
                },
                {
                    key: 'total',
                    name: 'Объем продукции',
                    getData: (value) => (value ? this.formatNumber(value) : null),
                },
                {
                    key: 'date_shipment',
                    name: 'Дата отгрузки',
                    getData: (date) => moment(date).format('DD.MM.YYYY'),
                },
                {
                    key: 'first_order_time_delivery',
                    name: 'Время первой доставки',
                    getData: (time) => this.timeAddTimezone(time, this.tz),
                },
                {
                    key: 'good_price',
                    name: 'Цена продукции',
                    getData: (price) => `${this.formatNumber(price === null ? '0' : price)} ₽`,
                },
                {
                    key: 'gidrolotok',
                    name: 'Гидролоток',
                    getData: (value) => value ? 'Да' : 'Нет',
                },
                {
                    key: 'pump',
                    name: 'Насос',
                    getData: (value) => (value === true ? 'Да' : 'Нет'),
                },
                {
                    key: 'pump_vehicle_id',
                    name: 'Номер насоса',
                    getData: (value) => this.getVehicleNumber(this.vehicles.find((vehicle) => vehicle.id === value)?.number)
                },
                {
                    key: 'prepayment',
                    name: 'Предоплата',
                    getData: (price) => `${this.formatNumber(price === null ? '0' : price)} ₽`,
                },
                {
                    key: 'comment_driver',
                    name: 'Комментарий водителю',
                    getData: (comment) => comment,
                },
                {
                    key: 'comment_pump_driver',
                    name: 'Комментарий водителю насоса',
                    getData: (comment) => comment,
                },
                {
                    key: 'comment_dispatch',
                    name: 'Комментарий диспетчеру',
                    getData: (comment) => comment,
                },
                {
                    key: 'comment',
                    name: 'Комментарий для лаборатории',
                    getData: (comment) => comment,
                },
                {
                    key: 'comment_operator',
                    name: 'Комментарий оператору',
                    getData: (comment) => comment,
                },
                {
                    key: 'custom_mixers_enabled',
                    name: 'Тип выбора миксеров',
                    getData: (value) => (value ? 'Выбранные' : 'Все'),
                },
                {
                    key: 'lab',
                    name: 'Необходимость лаборатории',
                    getData: (value) => value ? 'Да' : 'Нет',
                },
                {
                    key: 'contract_id',
                    name: 'Договор',
                    getData: (value) => this.contracts.find((el) => el.id === value)?.name,
                },
                {
                    key: 'invoice_id',
                    name: 'Счет',
                    getData: (value) => this.contracts.find((el) => el.id === value)?.name,
                },
                {
                    key: 'time_unloading',
                    name: 'План. время разгрузки, мин',
                    getData: (value) => value,
                },
                {
                    key: 'type_delivery',
                    name: 'Интервал между доставками',
                    getData: (value) => this.intervalTypes.get(value)
                },
                {
                    key: 'intensity',
                    name: 'Интенсивность, куб/ч',
                    getData: (value) => value,
                },
                {
                    key: 'plan',
                    name: 'План',
                    getData: (value) => value
                },
                {
                    key: 'time_interval_client',
                    name: 'Интервал от клиента (мин)',
                    getData: (value) => value
                },
                {
                    key: 'constructive',
                    name: 'Конструктив',
                    getData: (value) => value
                },
                {
                    key: 'mixer_max_volume',
                    name: 'Макс. объем миксера',
                    getData: (value) => value
                },
                {
                    key: 'downtime_service_tariff_id',
                    name: 'Тариф на простой',
                    getData: (value) => this.serviceTariffs.find((tariff) => tariff.id === value)?.name
                },
                {
                    key: 'mix_service_tariff_id',
                    name: 'Тариф на доставку',
                    getData: (value) => this.serviceTariffs.find((tariff) => tariff.id === value)?.name
                },
                {
                    key: 'vehicles',
                    name: 'Миксеры',
                    getData: (value) => {
                        const arr = value.map((el) => this.getVehicleNumber(this.vehicles.find((vehicle) => vehicle.id === el)?.number));
                        return arr
                    },
                },
                {
                    key: 'mixers',
                    name: 'Посты',
                    getData: (value) => {
                        const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
                        const selectedMixers = mixers.filter((mixer) => value.includes(mixer.id));
                        const mixerNames = selectedMixers.map((mixer) => this.setMixName(mixer, mixers));

                        return mixerNames
                    }
                },
            ];

            mappings.forEach(({key, name, getData}) => {
                if(key === 'vehicles' && values[key] || key === 'mixers' && values[key]) {
                    const newData = getData(values[key].new);
                    const oldData = getData(values[key].old);
                    elems.push({
                        name,
                        data: newData.join(', '),
                        oldData: oldData.join(', '),
                    })
                } else if (values[key]) {
                    elems.push({
                        name,
                        data: getData(values[key].new),
                        oldData: getData(values[key].old),
                    });
                }
            });

            return elems;
        }
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullAddress', 'pullCompanyManagers']),
        async getAdditionalData(values) {
            if(values.delivery_address_id){
                await this.pullAddress(this.companyId);
            }
            if(values.company_manager_id) {
                this.pullCompanyManagers(this.companyId);
            }
        },
        setUniqueValues(oldArr, newArr) {
            if(!oldArr.length && !newArr.length) return []
            if(!newArr.length || !oldArr.length) return oldArr.join(', ')

            const uniqueItemsFromOldArr = oldArr.filter(element => !newArr.includes(element));
            return uniqueItemsFromOldArr.length ? uniqueItemsFromOldArr.join(', ') : ''
        },
        setMixName(mixer, allMixers) {
            const amountOfMixersPerMix = allMixers.filter((item) => item.mix_id === mixer.mix_id)?.length;
            return amountOfMixersPerMix > 1 ? mixer.name : mixer.mix_name;
        },
    },
    watch: {
        'values': {
            handler: function(val) {
                this.displayData = val.length - 1 > 5 ? val.slice(0, 5) : val;
            },
            immediate: true
        },
        showMore: function(val) {
            this.displayData = val ? this.values : this.values.slice(0, 5);
        }
    }
}
</script>

<style scoped>
.show-more-btn {
    height: fit-content !important;
    padding-block: 0 !important;
    font-size: 12px;
    line-height: 16px;
}
</style>
