<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        @openCreateValueDrawer="() => addVehicleDialog = true"
        :disable-update-button="true"
        :disable-filter="true"
        class="editSelectVehicles"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 tableWithScroll"
        v-loading="loading"
        @sort-change="sortVehicles"
        @current-change="handleCurrentChange"
        :data="displayData">

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            :sort-orders="['ascending', 'descending', null]"
            sortable='custom'>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">
                    # {{ isSchedule ? formatNumber(scope.row.vehicle.id) : formatNumber(scope.row.id) }}
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Номер авто"
            class-name="pointer"
            :sort-orders="['ascending', 'descending', null]"
            sortable='custom'>
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">
                    {{
                        (isSchedule ? getVehicleNumber(scope.row.vehicle.number) : getVehicleNumber(scope.row.number)) ?? '-'
                    }}
                </span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <Dialog
        class="editSelectVehicles__createDialog modal-border-radius"
        v-model="addVehicleDialog"
        title="Создание нового миксера для самовывоза"
        :width="windowWidth < 540 ? '100%' : '520px'"
        :destroy-on-close="true"
    >
        <div>
            <el-form
                class="editSelectVehicles__createForm ml-4 d-flex f-direction-column gap-4"
                ref="form"
                label-position="left"
                label-width="130px"
            >
                <FormInput
                    v-model.vehicleNumber="form.number"
                    label="Рег. номер"
                    placeholder="Пр.: Е290КХ750"
                    required="true"
                    color="grey"
                    :direction="windowWidth < 520 ? 'column' : null"
                    :validation-message="validationMessages.number ?? null"
                />
                <FormNumber
                    v-model="form.volume"
                    label="Объем миксера"
                    placeholder="Пр.: 8"
                    required="true"
                    min="0"
                    color="grey"
                    :direction="windowWidth < 520 ? 'column' : null"
                    :validation-message="validationMessages.volume ?? null"
                />
                <FormSelect
                    v-model="form.driver_id"
                    required="true"
                    label="Водитель по умолчанию"
                    placeholder="Выберите водителя"
                    :items="drivers"
                    color="grey"
                    :direction="windowWidth < 520 ? 'column' : null"
                    :validation-message="validationMessages.driver_id ?? null"
                />
                <FormSelectCompany
                    v-model="form.rent_company_id"
                    label="Контрагент арендного авто"
                    placeholder="Выберите контрагента"
                    :items="companies"
                    new-item="company"
                    required="true"
                    color="grey"
                    :integration-unit-id="isOneCIntegrationRequiredForCompanies ? oneCIntegrationUnitId : null"
                    :direction="windowWidth < 520 ? 'column' : null"
                    :validation-message="validationMessages.rent_company_id ?? null"
                />
            </el-form>
            <div class="mt-30">
                <el-button type="primary"
                    @click="doCreateVehicle"
                    :disabled="loadingButton">
                    Сохранить
                </el-button>
            </div>
        </div>
    </Dialog>

</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormSelectCompany from "@/views/components/Form/Select/FormSelectCompany.vue";
import Dialog from "@/views/components/Dialog.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'EditSelectVehicles',
    components: {FormSelect, FormInput, TableHeadBlock, TablePagination, Dialog, FormNumber, FormSelectCompany},
    mixins: [tableMixin, functionsMixin, mobileCheckMixin],
    props: {
        vehicles: {},
        schedules: {
            default: false,
        },
        schedule: {
            default: false,
            type: Boolean
        },
        takeAway: {
            default: false
        }
    },
    inject: ['api'],
    data() {
        return {
            loading: true,
            isSchedule: this.schedule,
            form: {
                number: null,
                driver_id: null,
                rent_company_id: null,
                volume: null,
            },
            addVehicleDialog: false,
            oneCIntegrationUnitId: null,
            defaultVehicles: null,
            validationMessages: {
                number: null,
                driver_id: null,
                rent_company_id: null,
                volume: null
            }
        }
    },
    created() {
        this.getVehicles()
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
            companies: 'getCompanies',
            drivers: 'getDrivers',
        }),
        oneCIntegration() {
            //TODO сделать фильтр не по id
            return this.integrations?.find((el) => el.integration.key == '1C');
        },
        isOneCIntegrationRequiredForCompanies() {
            if(!this.oneCIntegrationUnitId) return false
            if(this.form.rent_company_id)  {
                const company = this.companies?.find(({id}) => id === this.form.rent_company_id);
                if(this.oneCIntegrationUnitId !== company?.integration_unit_id) return false
            }
            return true
        },
    },
    methods: {
        getVehicles() {
            this.isSchedule = false;
            httpService().post(this.api.vehicle.getMixersWithGpsOrWithoutIntegrations).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.vehicles
                    this.defaultVehicles = data.vehicles
                }
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.name.replace(/\s+/g, "").toLowerCase().includes(this.search.replace(/\s+/g, "").toLowerCase()) || data.number.toLowerCase().includes(this.search.toLowerCase())))
            this.valueLength = this.filtered.length

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                if (this.isSchedule) {
                    val.vehicle.driver_id = val.driver_id ?? val.vehicle.driver_id;
                    this.$emit('update', val.vehicle)
                } else {
                    this.$emit('update', val)
                }
            }
        },
        doCreateVehicle() {
            let data = this.form
            data.rent = true
            data.type = 'mix'
            data.pickup = true
            httpService().post(this.api.settings.createOrUpdateVehicle, {
                ...this.form,
                number: this.form.number?.replace(/\s/g, '')
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.getVehicles()
                    this.handleCurrentChange(data.vehicle)
                    this.addVehicleDialog = false
                    this.resetForm();
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const fieldErrors = error.response.data.errors;

                    const validationMessages = {};

                    Object.keys(fieldErrors).forEach((field) => {
                        fieldErrors[field].forEach((message) => {
                            validationMessages[field] = message;
                        });
                    });

                    this.validationMessages = validationMessages;
                } else {
                    this.$message({
                        message: error.response?.data?.message,
                        type: 'error',
                        showClose: true
                    });
                }
            })
        },
        resetForm() {
            this.form = {
                number: null,
                driver_id: null,
                rent_company_id: null,
                volume: null,
            }
        },
        sortVehicles(sort) {
            if (!sort.prop || !sort.order) {
                this.values = this.defaultVehicles;
                return;
            }

            if (sort.prop === 'name') {
                this.values = this.sortByName(sort.order);
            } else if (sort.prop === 'id') {
                this.values = this.sortById(sort.order);
            }
        },
        sortByName(order) {
            return [...this.values].sort((a, b) => {
                const aValue = this.isSchedule ? a.vehicle.name : a.name;
                const bValue = this.isSchedule ? b.vehicle.name : b.name;

                return order === 'ascending'
                    ? aValue.localeCompare(bValue, 'ru', { numeric: true })
                    : bValue.localeCompare(aValue, 'ru', { numeric: true });
            });
        },
        sortById(order) {
            return [...this.values].sort((a, b) => {
                return order === 'ascending'
                    ? a.id - b.id
                    : b.id - a.id;
            });
        },
        clearValidationMessage(field, val) {
            if (val && this.validationMessages[field]) {
                this.validationMessages[field] = null;
            }
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'oneCIntegration': {
            handler: function (val) {
                if (val) {
                    this.oneCIntegrationUnitId = val.id
                }
            },
            deep: true,
            immediate: true
        },
        'form.driver_id': {
            handler: function (val) {
                this.clearValidationMessage('driver_id', val);
            },
        },
        'form.number': {
            handler: function (val) {
                this.clearValidationMessage('number', val);
            },
        },
        'form.rent_company_id': {
            handler: function (val) {
                this.clearValidationMessage('rent_company_id', val);
            },
        },
        'form.volume': {
            handler: function (val) {
                this.clearValidationMessage('volume', val);
            },
        },
    }
}
</script>

<style scoped lang="scss">
.editSelectVehicles {
    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        &:deep(.tableHeadBlock__create-btn) {
            min-height: 36px;
            width: 48px;
        }

        &:deep(.tableHeadBlock__search) {
            margin-bottom: 0;
            width: 100%;

            .el-input__wrapper {
                border-radius: 6px
            }
        }
    }

    &__createForm {
        &:deep(.el-form-item__label) {

            @media screen and (max-width: 520px) {
                max-width: 100%;
            }
        }

        &:deep(.el-overlay) {
            &:has(.formSelectCompanyDialog) {
                background: transparent;
            }
        }
    }

    &__createDialog {
        min-width: 500px;

        @media screen and (max-width: 520px) {
            min-width: 100%;
        }
    }
}
</style>
