import { API_ROUTES } from '@/const'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'
import router from '@/router'
import { ElNotification } from 'element-plus'
import { sendCookies } from '@/mixins/cookiesManager'

export function authService() {
    const commonStore = useCommonStore()
    let roleId = null

    const getMe = async () => {
        commonStore.setToken(localStorage.getItem('api_token'))

        return await httpService().post(API_ROUTES.account.me, sendCookies()).then((response) => {
            if (response.data.user.banned === 1) {
                doLogout()
            }

            roleId = response.data.user.role_id
            commonStore.setUser(response.data.user)
            commonStore.setNav(response.data.links)
            commonStore.setModules(response.data.modules)
            commonStore.setWidgets(response.data.widgets)
            commonStore.setReports(response.data.reports)
            commonStore.setReportFunctions(response.data.reportFunctions)
            commonStore.setAnalyticsAvailable(response.data.analyticsAvailable)
            commonStore.setReportsAvailable(response.data.reportsAvailable)
            commonStore.setUserModuleAccess(response.data.userModuleAccess)
            commonStore.setUserReportAccess(response.data.userReportAccess)
            commonStore.setAppSettings(response.data.appSettings)
            commonStore.setFactories(response.data.factories);
            commonStore.pullContracts();
            commonStore.pullVehicles();
            commonStore.pullUsers();
            commonStore.pullServiceTariffs();
            commonStore.pullCompanies()

            if (roleId !== 5 && roleId !== 6 && roleId !== 7) {
                commonStore.setIntegrations(response.data.integrations)
                commonStore.pullGoods()
                commonStore.pullGoodsFirstRequest()
                commonStore.pullCompaniesFirstRequest()
            }

            window.Echo.channel('laravel_database_ChannelAccount')
                .listen(`.Create.${response.data.user.account_id}`, () => {
                    accountEchoUpdate()
                })
                .listen(`.Update.${response.data.user.account_id}`, () => {
                    accountEchoUpdate()
                })
                .listen(`.Delete.${response.data.user.account_id}`, () => {
                    accountEchoUpdate()
                });

            window.Echo.channel('laravel_database_ChannelUser')
                .listen(`.Ban.${response.data.user.id}`, () => {
                    doLogout();
                });
        })
    }

    const initApp = async () => {
        if (window.location.pathname !== '/login') {
            return await getMe()
        }
    }

    const doLogin = async (username, password) => {
        return await httpService().post(API_ROUTES.account.login, {
            username: username,
            password: password,
        }).then((response) => {
            commonStore.setToken(response.data.accessToken)
            commonStore.setUser(response.data.user)
            getMe()
            router.push({
                name: 'dashboard'
            })
        }).catch((error) => {
            ElNotification({
                message: error.response.data.message,
                type: 'error'
            })
        })
    }

    const doLoginByToken = async (token) => {
        return await httpService().post(API_ROUTES.account.loginByToken, {
            token: token
        }).then((response) => {
            commonStore.setToken(response.data.accessToken)
            commonStore.setUser(response.data.user)
            getMe()

            router.push({
                name: 'dashboard'
            })
        }).catch(() => {
            ElNotification({
                message: 'Срок действия токена истек',
                type: 'error'
            })
        })
    }

    const doLogout = () => {
        commonStore.clearToken()

        router.push({ name: 'login' })
            .then(() => {
                commonStore.reset();
            });
    }

    const impersonateUser = async (token) => {
        return await httpService().post(API_ROUTES.impersonate.asUser, {
            token: token
        }).then((response) => {
            let data = response.data
            if (data.success) {
                ElNotification({
                    message: data.message,
                    showClose: true,
                    type: 'success'
                });
                commonStore.setToken(response.data.accessToken)
                commonStore.setUser(response.data.user)
                initApp().finally(() => {
                    router.push({name: 'dashboard'})
                })

            }

        }).catch((error) => {
            ElNotification({
                message: error.response.data.message,
                showClose: true,
                type: 'error'
            })
        })
    }

    const accountEchoUpdate = async () => {
        return await httpService().post(API_ROUTES.account.echoAccountUpdate, sendCookies()).then((response) => {
            commonStore.setAppSettings(response.data.appSettings)
        })
    }

    return {
        getMe,
        initApp,
        doLogin,
        doLoginByToken,
        impersonateUser,
        doLogout
    }
}
