<template>
    <Auth
        title="Операции"
        :tabs="tabs"
    >
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    create-button-label="Добавить значение"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="openStorehouseActionsDialog"
                    :disable-filter="true"
                    :new="true"
                    add-button-text="Добавить операцию"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-select v-model="filter.storehouseGoodId.value" clearable filterable class="custom-select searchNew" placeholder="Продукция">
                            <el-option
                                v-for="item in storehouseGoods"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <FilterDatePeriod
                            class="ml-10"
                            v-model="date"
                            @update-date="updateDateFilter"
                        />
                    </template>
                </TableHeadBlock>

                <div v-if="values.length">
                    <MobileTableFramework
                        v-for="(item, index) in values"
                        :key="index"
                    >
                        <template v-slot:body>
                            <MobileTableHR class="mt-30"/>
                            <MobileTableInfo title="#" :body="item.id"/>

                            <MobileTableInfo
                                title="Дата"
                                :body="
                                    item.date
                                        ? getDateFromUnix(item.date, 'DD.MM в HH:mm')
                                        : '-'
                                "
                            />

                            <MobileTableInfo
                                title="Продукция"
                                :body="item.product.name"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Операция"
                                :body="item.operation"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :body="`${item.count} ${
                                    item.count
                                        ? item.product.unit.name ?? 'ед.'
                                        : 'ед.'
                                }`"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Склад"
                                :body="item.storehouseUnit.name"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Контрагент"
                                :body="item.company?.name ?? '-'"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Производство"
                                :body="item.production ?? '-'"
                                :no-border="true"
                            />

                            <MobileTableInfo
                                title="Цена"
                                :body="
                                    item.price
                                        ? formatNumber(item.price, 2)
                                        : '-'
                                "
                                :no-border="true"
                            />

                            <MobileTableButton
                                title="Удалить"
                                :disabled="true"
                            />

                            <MobileTableButton
                                title="Редактировать"
                                :disabled="true"
                            />
                        </template>
                    </MobileTableFramework>
                </div>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!smallMobile"
                    class="w-100 mt-15"
                    :summary-method="getSummaries"
                    show-summary
                >
                    <el-table-column prop="date" label="#" width="80" sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200"
                            >#{{ formatNumber(scope.row.number) }}</span
                            >
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="130"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date
                                        ? getDateFromUnix(scope.row.date, 'DD.MM в HH:mm')
                                        : "-"
                                }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="product"
                        label="Продукция"
                        sortable
                        min-width="220px"
                    >
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900 cutOverflow maxLines3">
                                {{ scope.row.product?.name }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="operation"
                        label="Операция"
                        sortable
                        width="140px"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular maxLines3 neutral600">
                                {{ storehouseActionTypes.find(el => el.id === scope.row.operation)?.name }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="total"
                        label="Количество"
                        sortable
                        width="120px"
                    >
                        <template v-slot="scope">
                            <div :class="getClass(scope.row.operation, scope.row.count)">
                                <span class="mr-5">{{ getSign(scope.row.operation) }}{{ formatNumber(scope.row.count) }}</span>
                                <span>{{ scope.row.count ? scope.row.product?.unit?.short_name ?? 'ед.' : 'ед.' }}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="storehouseUnit"
                        label="Склад"
                        sortable
                        min-width="100px"
                    >
                        <template v-slot="scope">
                            <span class="textSM maxLines3 textRegular neutral600">
                                {{ scope.row.storehouseUnit?.name }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="company"
                        label="Контрагент"
                        min-width="100px"
                    >
                        <template v-slot="scope">
                            <span
                                :class="[
                                    'textMedium',
                                    'neutral700',
                                    'cutOverflow',
                                    'maxLines3',
                                    'counterparty-row',]"
                            >
                                {{ scope.row.company?.name ?? "-" }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="production"
                        label="Источник"
                        min-width="100px"
                    >
                        <template v-slot="scope">
                            <div v-if="!scope.row.production.type">
                                -
                            </div>
                            <div v-else>
                                <div
                                    v-if="scope.row.production.type === 'mix'"
                                    class="primary600 textMedium maxLines3">
                                    {{
                                        scope.row.production ? "Отгрузка БЗ #" + scope.row.production.info.id : "-"
                                    }}
                                </div>
                                <div
                                    v-else-if="scope.row.production.type === 'production'"
                                    class="primary600 textMedium maxLines3">
                                    Производство
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="cost" label="Цена" width="100">
                        <template v-slot="scope">
                            <span class="textMedium neutral700">
                                {{
                                    scope.row.price
                                        ? formatNumber(scope.row.price * Math.abs(scope.row.count), 2)
                                        : "-"
                                }}
                                <RubleIcon
                                    v-if="scope.row.price"
                                    :current-color="true"
                                    width="8px"
                                    height="10px"
                                    stroke-width="0.5"/>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="" width="110">
                        <template v-slot="scope">
                            <div>
                                <el-tooltip effect="dark" content="Удалить" placement="top">
                                    <el-button
                                        size="small"
                                        @click="remove(scope.row.number)"
                                        :icon="icons.delete"
                                    />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-dialog
            class="storehouse-actions-dialog"
            title="Добавление операции"
            :width="dialogSize"
            :destroy-on-close="true"
            v-model="isStorehouseActionsDialogOpened"
            @close="closeStorehouseActionsDialog"
        >
            <StorehouseActionsForm
                :storehouse-units="storehouseUnits"
                :goods="goods"
                @update="updateActionsForm"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from "./../layouts/Auth";
import tableMixin from "../../mixins/tableMixin";
import functionsMixin from "../../mixins/functionsMixin";
import mobileCheckMixin from "../../mixins/mobileCheckMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";
import storehouseTabs from "@/mixins/tabs/storehouseTabs";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import storehouseActionTypes from "../../mixins/storehouseActionTypes";
import StorehouseActionsForm from "./StorehouseActionsForm";
import FilterDatePeriod from "@/views/components/Table/Filter/FilterDatePeriod";
import moment from "moment";
import RubleIcon from "@/views/components/Icons/RubleIcon";

export default {
    name: "StorehouseOperations",
    components: {
        TableHeadBlock,
        Auth,
        TablePagination,
        StorehouseActionsForm,
        MobileTableFramework,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        FilterDatePeriod,
        RubleIcon
    },
    inject: ["api"],
    mixins: [
        tableMixin,
        functionsMixin,
        mobileCheckMixin,
        iconsMixin,
        storehouseTabs,
        storehouseActionTypes
    ],
    data() {
        return {
            loading: false,
            pageSize: 15,
            isStorehouseActionsDialogOpened: false,
            storehouseUnits: [],
            filter: {
                date: {
                    value: moment.tz(this.moduleTimezone).startOf("month"),
                    type: 'month'
                },
                storehouseGoodId: {
                    value: null
                }
            },
            date: moment.tz(this.moduleTimezone).startOf("month"),
            storehouseGoods: [],
            loadingData: false
        }
    },
    created() {
        this.moduleGet();
        this.counterInterval = setInterval(
            function () {
                this.moduleGet(false);
            }.bind(this),
            30000
        );
        this.getStorehouseGoods()
        this.getStorehouseUnits();
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval);
    },
    methods: {
        async moduleGet(loading = true) {
            if (!this.loadingData) {
                this.loadingData = true
                if (loading) this.loading = true;
                let url = this.api.storehouse.getOperations;

                try {
                    const response = await httpService().post(url, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: "storehouse",
                        pagination: true,
                        page: this.page,
                        pageSize: this.pageSize,
                        search: this.search,
                        filter: this.filter
                    });

                    let data = response.data;
                    if (data.success) {

                        this.values = data.values.data;
                        this.valueLength = data.values.total;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false
                    this.loadingData = false
                }
            }
        },
        async getStorehouseUnits() {
            this.loading = true;
            let url = this.api.storehouse.getUnits;

            try {
                const response = await httpService().post(url, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: "storehouse",
                });

                let data = response.data;
                if (data.success) {
                    this.storehouseUnits = data.units;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        async getStorehouseGoods() {
            this.loading = true;
            let url = this.api.storehouseGood.getAll;

            try {
                const response = await httpService().post(url, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: "storehouse",
                });

                let data = response.data;
                if (data.success) {
                    this.storehouseGoods = data.data;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        getClass(operation, count) {
            const classes = {
                'add': 'success400 textBold',
                'remove': 'danger300 textBold',
                'move': 'warning300 textBold',
                'posting': 'neutral700 textBold',
            };
            if (operation === 'production') {
                return count < 0 ? 'danger300 textBold' : 'success400 textBold';
            } else {
                return classes[operation] || 'primary600 textBold';
            }
        },
        getSign(operation) {
            const signs = {
                'add': '+',
            };
            return signs[operation] || '';
        },
        filterValues() {
        },
        remove(id) {
            this.$confirm(
                'Удалить значение и пересчитать количество данной продукции?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.storehouseValue.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'storehouse',
                        valueId: id,
                    }).then(() => {
                        this.viewDetails = false
                        this.moduleGet()
                        this.$message({
                            message: 'Значение удалено',
                            showClose: true,
                            type: 'success'
                        })
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getSummaries(param) {
            const { columns } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 4) {
                    let total = 0
                    this.values.forEach(function (value) {
                        total = total + Number(value.count)
                    })
                    sums[index] = this.formatNumber(total)
                    return
                }
            })

            return sums
        },
        openStorehouseActionsDialog() {
            this.isStorehouseActionsDialogOpened = true;
        },
        closeStorehouseActionsDialog() {
            this.isStorehouseActionsDialogOpened = false;
        },
        updateActionsForm() {
            this.moduleGet();
            this.isStorehouseActionsDialogOpened = false;
        },
        updateDateFilter(value, type) {
            this.filter.date.type = type
            if(type === 'daterange') {
                this.filter.date.value = [
                    value[0],
                    value[1]
                ]
                return
            }
            this.filter.date.value = value
        }
    },
    watch: {
        pageSize: {
            handler: function (val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet();
                }
            },
            immediate: false
        },
        page: {
            handler: function (val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet();
                }
            },
            immediate: false
        },
        search: {
            handler: function (val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet();
                }
            },
            immediate: false
        },
        'date': {
            handler: function (val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet();
                }
            },
            immediate: false,
        },
        'filter.storehouseGoodId.value': {
            handler: function (val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet();
                }
            },
            immediate: false,
        },
    },
};
</script>

<style scoped>
.w-200px {
    width: 200px;
}

.storehouse-actions-dialog {
    .el-dialog__close, .el-dialog__close svg {
        width: 20px;
        height: 20px;

        path {
            fill: #141414;
        }
    }
}
</style>
