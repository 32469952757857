<template>
    <Auth title="Настройки приложений">
        <div class="card">
            <div class="card-body">
                <p class="textRegular textSM neutral400">Ниже представлены настройки приложений. Они действуют для всех пользователей во всех модулях:</p>
                <div class="d-block w-100" v-for="(value, key) in settings" :key="key">
                    <el-checkbox
                        v-if="!isDocument(key) && !isOperatorOutputContragents(key)"
                        :true-label="1"
                        :false-label="0"
                        :checked="settings[key] === '1'"
                        @change="updateSetting(key, $event)"
                        :label="getLabel(key)"
                        size="large"
                        :disabled="isDisabled(key)"
                    />

                    <div class="volumeCorrection" v-if="settings['volumeCorrection'] && settings['volumeCorrection'] != '0' && key === 'volumeCorrection'">
                        <el-radio-group v-model="volumeCorrectionRole">
                            <el-radio
                                label="dispatcher"
                                @change="updateSetting('volumeCorrection', 'dispatcher')"
                                size="large"
                            >
                                Объем корректирует диспетчер
                            </el-radio>

                            <el-tooltip placement="top" content="В разработке">
                                <el-radio
                                label="operator"
                                @change="updateSetting('volumeCorrection', 'operator')"
                                size="large"
                                :disabled="true"
                            >
                                Объем корректирует оператор
                            </el-radio>
                            </el-tooltip>
                        </el-radio-group>
                    </div>
                </div>

                <div class="d-flex f-direction-column">
                    <el-checkbox
                        v-if="operatorOutputContragentsCheck()"
                        :true-label="1"
                        :false-label="0"
                        :checked="settings['operatorOutputContragentsByShortName'] === '1'"
                        @change="updateSetting('operatorOutputContragentsByShortName', $event)"
                        :label="getLabel('operatorOutputContragentsByShortName')"
                        size="large"
                    />
                </div>

                <hr class="mt-10 mb-20"/>

                <p class="textRegular textSM neutral400 mb-10">Видимость документов:</p>
                    <div class="d-block w-100" v-for="(value, key) in settings" :key="key">
                        <el-tooltip :content="getTooltipMessage(key)" placement="top" :disabled="!isDisabled(key)">
                            <el-checkbox
                                v-if="isDocument(key)"
                                :true-label="1"
                                :false-label="0"
                                :checked="settings[key] === '1'"
                                @change="updateSetting(key, $event)"
                                :label="getLabel(key)"
                                size="large"
                                :disabled="isDisabled(key)"
                                class="documentCheckbox"
                            />
                        </el-tooltip>
                    </div>

                <hr class="mt-30 mb-30"/>

                <el-button :disabled="loading" @click="save()" type="primary">Сохранить настройки</el-button>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import {useCommonStore} from '@/store/common'
import {mapActions} from "pinia";

export default {
    name: 'AccountSettingsIndex',
    components: {
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, functionsMixin
    ],
    data() {
        return {
            loading: true,
            settings: [],
            all: [],
            volumeCorrectionRole: 'dispatcher',
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            httpService().post(this.api.settings.getAppSettings).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.settings = data.settings
                    this.volumeCorrectionRole = this.settings['volumeCorrection']

                    if (this.volumeCorrectionRole === null || this.volumeCorrectionRole === 0 || this.volumeCorrectionRole === '0') {
                        this.volumeCorrectionRole = 'dispatcher'
                    } else {
                        this.settings['volumeCorrection'] = '1' //Нужно чтобы чекбокс не сбрасывался
                    }
                    this.all = data.all
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        save() {
            this.loading = true
            const settings = this.settings
            httpService().post(this.api.settings.updateAppsSettings, {
                settings: settings
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.$message({
                        message: 'Сохранено',
                        showClose: true,
                        type: 'success'
                    })
                    this.setAppSettings(settings);
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getLabel(key) {
            return this.all.find(el => el.key === key)?.label ?? key
        },
        updateSetting(key, newValue) {
            if(key === 'volumeCorrection' && newValue != 0) {
                this.settings['volumeCorrection'] = this.volumeCorrectionRole
                return
            }
            this.settings[key] = newValue ? "1" : "0";
        },
        ...mapActions(useCommonStore, [
            'setAppSettings'
        ]),
        isDisabled(key) {
            if (key === 'passport_is_visible' || key === 'invoice_is_visible' || key === 'ttn_is_visible') {
                const {invoice_is_visible, ttn_is_visible, passport_is_visible} = this.settings
                const items = [+invoice_is_visible, +ttn_is_visible, +passport_is_visible].filter(Boolean).length
                return items === 1 && this.settings[key] === '1'
            }

            return false
        },
        getTooltipMessage(key) {
            switch (key) {
                case 'invoice_is_visible':
                case 'ttn_is_visible':
                case 'passport_is_visible':
                    return 'Как минимум один документ для печати должен быть выбран';
                default:
                    return '';
            }
        },
        isDocument(key) {
            return key === 'invoice_is_visible' || key === 'ttn_is_visible' || key === 'passport_is_visible'
        },
        isOperatorOutputContragents(key) {
            return key === 'operatorOutputContragentsByShortName'
        },
        operatorOutputContragentsCheck() {
            return this.settings['operatorOutputContragents'] === '1'
        },
    },
}
</script>
