<template>
    <el-link class="guide-link" :href="link" target="_blank">{{text}}</el-link>
</template>

<script>

export default {
    name: 'GuideLink',
    props: ['text', 'link'],
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-link {
    margin-left: 8px;
    padding: 4px;
    text-decoration: none;
    background: variables.$primary50;
    color: variables.$primary600;
    border: 1px solid variables.$primary300;
    border-radius: 4px;
    line-height: 1;

    &:hover {
        text-decoration: none;
        background: variables.$primary100;
        transition: all 0.2s ease;

        &:after {
            display: none;
        }
    }
}
</style>
