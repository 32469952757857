<template>
    <div ref="orderServices">
        <div>
            <el-button v-if="goodsType" size="small" @click="openAddGoodDialog()" :disabled="loading">
                Добавить продукцию
            </el-button>
            <el-button v-else size="small" @click="openAddServiceDialog()" :disabled="loading">
                Добавить услугу
            </el-button>
        </div>

        <div :class="{'table-container': paginate}">
            <el-tabs v-if="!goodsType && isTabsNeeded" v-model="tab" class="orderServices__tabs mt-10 mb-20">
                <el-tab-pane v-if="tariffsByType.mix.length" label="Доставка" name="mix" />
                <el-tab-pane v-if="tariffsByType.downtime.length" label="Простой" name="downtime" />
                <el-tab-pane v-if="tariffsByType.pump.length" label="Насос" name="pump" />
                <el-tab-pane v-if="tariffsByType.other.length" label="Прочее" name="other" />
            </el-tabs>

            <el-table
                v-loading="loading"
                :data="paginate ? displayData : (goodsType ? goods : servicesData)"
                empty-text="Услуг не найдено"
                :summary-method="goodsType ? getSummaries : getServiceSummaries"
                :show-summary="goodsType ||  tariffsByType[this.tab].length && isTabsNeeded"
                class="w-100 mt-15 serviceTable" :class="[{'serviceTable--paginate': paginate}]">
                <el-table-column
                    prop="type"
                    label="Тип"
                    width="55"
                    v-if="windowWidth > 1300"
                >
                    <template v-slot="scope">
                        <template v-if="scope.row.type === 'pump'">
                            <el-tooltip :content="scope.row.confirm ? 'Подтвержденная услуга насоса' : 'Услуги насоса'"
                                        placement="left">

                                <PumpServiceIcon class='serviceIcon' :class="{ serviceIconConfirm: scope.row.confirm }" />
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.type === 'mix'">
                            <el-tooltip
                                :content="scope.row.confirm ? 'Подтвержденная услуга доставки бетона' : 'Услуга доставки бетона'"
                                placement="left">
                                <MixServiceIcon class='serviceIcon' :class="{ serviceIconConfirm: scope.row.confirm }" />
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.type === 'downtime'">
                            <el-tooltip
                                :content="scope.row.confirm ? 'Подтвержденная услуга простоя миксера на объекте' : 'Простой миксера на объекте'"
                                placement="left">
                                <DowntimeServiceIcon class="serviceIcon" :class="{ serviceIconConfirm: scope.row.confirm }" />
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.type === 'good'">
                            <el-tooltip
                                :content="scope.row.confirm ? 'Подтвержденная продукция' : 'Продукция'"
                                placement="left">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     class="serviceIcon" :class="{ serviceIconConfirm: scope.row.confirm }"
                                     width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
                                     xml:space="preserve"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M2 5a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v13H2zM5 23v36a3 3 0 0 0 3 3h48a3 3 0 0 0 3-3V23" fill="" opacity="1"></path><path
                                    d="M20 27h24v10H20z" fill="" opacity="1"></path></g></g></svg>
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.type === 'other'">
                            <el-tooltip :content="scope.row.confirm ? 'Подтвержденная услуга' : 'Услуга'"
                                        placement="left">
                                    <OtherServiceIcon class='serviceIcon' :class="{ serviceIconConfirm: scope.row.confirm }"/>
                            </el-tooltip>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    min-width='120px'
                    :label="goodsType ? 'Продукция' : 'Услуга'"
                >
                    <template v-slot="scope">
                        <span class="maxLines3 textSM textMedium" :class="[
                            { neutral200: !scope.row.confirm },
                            { neutral900: scope.row.confirm }
                        ]">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mix_order_id"
                    label="Отгрузка"
                    width="100"
                >
                    <template v-slot="scope">
                        <template v-if="scope.row.mix_order_id">
                            <div>
                                <div @click="openOrderDetails(scope.row.mix_order_id)" class="textMedium d-flex f-direction-column textSM pointer">
                                    <span
                                        :class="[
                            { neutral200: !scope.row.confirm },
                            { warning300: scope.row.confirm }
                        ]">#{{
                                            formatNumber(scope.row.mix_order_id)
                                        }}</span>
                                    <span
                                        class="textRegular"
                                        :class="[
                            { neutral200: !scope.row.confirm },
                            { neutral400: scope.row.confirm }
                        ]">{{ getOrderData(scope.row.mix_order_id, 'time') }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="vehicle_id"
                    label="ТС"
                    width="120"
                >
                    <template v-slot="scope">
                        <span
                            :class="[
                            { neutral200: !scope.row.confirm },
                            { primary600: scope.row.confirm }
                        ]"
                            class="textMedium textSM cutOverflow">{{
                                scope.row.vehicle_number ? getVehicleNumber(scope.row.vehicle_number) : '-'
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="price"
                    label="Цена (за ед.)"
                    width="115"
                >
                    <template v-slot="scope">
                        <span
                            :class="[
                            { neutral200: !scope.row.confirm },
                            { success400: scope.row.confirm }
                        ]"
                            class="textMedium textSM">{{ formatNumber(scope.row.price, 2) }} руб.</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="Кол-во"
                    width="80"
                >
                    <template v-slot="scope">
                        <span
                            :class="[
                            { neutral200: !scope.row.confirm },
                            { neutral900: scope.row.confirm }
                        ]"
                            class="textMedium textSM">{{ formatNumber(scope.row.total, 2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="vat"
                    label="НДС (%)"
                    width="70"
                >
                    <template v-slot="scope">
                        <span
                            :class="[
                            { neutral200: !scope.row.confirm },
                            { neutral900: scope.row.confirm }
                        ]"
                            class="textRegular textSM">{{ formatNumber(scope.row.vat ?? 0) }}%</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="full_price"
                    label="Итого"
                    width="130"
                >
                    <template v-slot="scope">
                        <span
                            :class="[
                            { neutral200: !scope.row.confirm },
                            { primary600: scope.row.confirm }
                        ]"
                            class="textMedium textSM">{{ formatNumber(scope.row.full_price, 2) }} руб.</span>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label=""
                    width="150"
                    v-if="windowWidth > 1300">
                    <template v-slot="scope">
                        <div class='d-flex'>
                            <el-button
                                class="serviceBtn"
                                size="small"
                                v-if="!scope.row.confirm"
                                :disabled="checkConfirmMixOrder(scope.row.mix_order_id)"
                                @click="confirmService(scope.row.id, true)"
                                :icon="icons.check"
                            />
                            <el-button
                                class="serviceBtn"
                                size="small"
                                v-if="scope.row.confirm"
                                @click="confirmService(scope.row.id, false)"
                                :icon="icons.remove"
                            />
                            <el-button
                                class="serviceBtn"
                                size="small"
                                :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                @click="editOrderDetails(scope.row)"
                                :icon="icons.edit"
                            />
                            <el-button
                                class="serviceBtn"
                                size="small"
                                :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                @click="removeService(scope.row.id)"
                                :icon="icons.delete"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label=""
                    width="60"
                    v-if="windowWidth <= 1300">
                    <template v-slot="scope">
                        <el-dropdown trigger="click">
                            <el-button class="serviceBtn" size="small" :icon="icons.operation"/>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        v-if="!scope.row.confirm"
                                        :disabled="checkConfirmMixOrder(scope.row.mix_order_id)"
                                        @click="confirmService(scope.row.id, true)"
                                        :icon="icons.check"
                                    >Подтвердить
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="scope.row.confirm"
                                        @click="confirmService(scope.row.id, false)"
                                        :icon="icons.remove"
                                    >Снять подтверждение
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                        @click="editOrderDetails(scope.row)"
                                        :icon="icons.edit"
                                    >Подробнее
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                        @click="removeService(scope.row.id)"
                                        :icon="icons.delete"
                                    >Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <el-table
                    v-if="!goodsType && servicesData.length"
                    :data="serviceSummaryArray"
                    class="serviceTable--summary">
                <el-table-column v-if="windowWidth > 1300" width="55"/>
                <el-table-column min-width='120px'>
                    <span class="textSM textBold neutral900">
                        Итого по всем услугам
                    </span>
                </el-table-column>
                <el-table-column width="100"/>
                <el-table-column width="120"/>
                <el-table-column width="115"/>
                <el-table-column width="80">
                    <template v-slot="scope">
                        <span class="cutOverflow textSM textBold neutral900">
                            {{ formatNumber(scope.row.count, 2) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column width="70"/>
                <el-table-column width="130">
                    <template v-slot="scope">
                        <span class="cutOverflow textSM textBold neutral900">
                            {{formatNumber(scope.row.cost, 2)}} руб.
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :width="windowWidth > 1300 ? '150px' : '60px'"
                    />
            </el-table>


            <TablePagination
                v-if="paginate"
                v-model="pageSize"
                :total="valueLength"
                :disable-size="true"
                @action="setPage"
                :pageSize="pageSize"
            />
        </div>
        <el-dialog
            v-model="addServiceDialog"
            title="Добавить услугу"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupCreateService
                :group-id="groupId ?? $route.params.groupId"
                :orders="orders"
                @update="updateOrder"
            />
        </el-dialog>


        <el-dialog
            v-model="addGoodDialog"
            title="Добавить продукцию"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupCreateService
                :group-id="groupId ?? $route.params.groupId"
                :orders="orders"
                :service="selectService"
                @update="updateOrder()"
            />
        </el-dialog>


        <el-drawer
            title=""
            v-model="orderDetailsDrawer"
            direction="rtl"
            :size="!mobile ? '60%' : '100%'"
            :destroy-on-close="true">

            <MixPageOrderDetails
                :order-id="selectOrderId"
            />

        </el-drawer>

        <el-dialog
            v-model="editServiceDialog"
            title="Редактировать услугу"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupCreateService
                :group-id="groupId ?? $route.params.groupId"
                :orders="orders"
                :service="selectService"
                @update="updateOrder"
            />
        </el-dialog>
    </div>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import serviceInfo from "@/mixins/serviceInfo";
import moment from "moment";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import MixPageOrderDetails from "@/views/components/MixPage/Orders/OrderDetails/MixPageOrderDetails.vue";
import MixOrderGroupCreateService from "@/views/components/MixOrderGroup/MixOrderGroupCreateService.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";

import DowntimeServiceIcon from "@/views/components/Icons/DowntimeServiceIcon.vue";
import PumpServiceIcon from "@/views/components/Icons/PumpServiceIcon.vue";
import MixServiceIcon from "@/views/components/Icons/MixServiceIcon.vue";
import OtherServiceIcon from "@/views/components/Icons/OtherServiceIcon.vue";

export default {
    name: "OrderServices",
    components: {
        TablePagination, MixOrderGroupCreateService, MixPageOrderDetails,
        DowntimeServiceIcon, PumpServiceIcon, MixServiceIcon, OtherServiceIcon
    },
    mixins: [mobileCheckMixin, serviceInfo, functionsMixin, iconsMixin, tableMixin],
    props: [
        'loading',
        'services',
        'goods',
        'orders',
        'groupId',
        'goodsType',
        'paginate',
        'count',
        'cost'
    ],
    inject: ['api'],
    data() {
        return {
            loadingButton: false,
            orderDetailsDrawer: false,
            selectOrderId: null,
            selectService: null,
            addServiceDialog: false,
            editServiceDialog: false,
            addGoodDialog: false,
            pageSize: 5,
            tab: 'mix',
            table2: null
        }
    },
    computed: {
        moment() {
            return moment
        },
        servicesData() {
            return this.services?.filter((service) => service.type === this.tab);
        },
        tariffsByType() {
            return {
                mix: this.services?.filter(tariff => tariff.type === 'mix'),
                downtime: this.services?.filter(tariff => tariff.type === 'downtime'),
                pump: this.services?.filter(tariff => tariff.type === 'pump'),
                other: this.services?.filter(tariff => tariff.type === 'other'),
            }
        },
        isTabsNeeded() {
            const arraysToCheck = [
                this.tariffsByType.mix,
                this.tariffsByType.downtime,
                this.tariffsByType.pump,
                this.tariffsByType.other
            ];
            return arraysToCheck.filter(array => array.length > 0).length >= 2;
        },
        serviceCounts() {
            return {
                downtime: this.count.downtime_count,
                mix: this.count.mix_count,
                pump: this.count.pump_count,
                other: this.count.other_count
            }
        },
        serviceCosts() {
            return {
                downtime: this.cost.downtime_cost,
                mix: this.cost.mix_cost,
                pump: this.cost.pump_cost,
                other: this.cost.other_cost
            }
        },
        serviceSummaryArray() {
            if (!this.count || !this.cost) return [];

            return [{
                count: this.serviceCounts.downtime + this.serviceCounts.mix + this.serviceCounts.pump + this.serviceCounts.other,
                cost: this.serviceCosts.downtime + this.serviceCosts.mix + this.serviceCosts.pump + this.serviceCosts.other,
            }]
        },
    },
    methods: {
        openOrderDetails(id) {
            this.selectOrderId = id
            this.orderDetailsDrawer = true
        },
        editOrderDetails(service) {
            this.selectOrderId = service.id
            this.selectService = service
            this.editServiceDialog = true
        },
        removeService(id) {
            this.$confirm(
                'Вы действительно хотите удалить услугу?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loadingButton = true

                    httpService().post(this.api.mixOrderGroup.removeService, {
                        account_modules_id: this.$route.params.id,
                        module_key: this.$route.meta.moduleKey,
                        orderId: this.groupId ?? this.$route.params.groupId,
                        serviceId: id
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Услуга удалена',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('update')

                        if(!this.tariffsByType[this.tab].length) {
                            if(this.tariffsByType.mix.length) {
                                this.tab = 'mix'
                                return
                            }
                            if(this.tariffsByType.downtime.length) {
                                this.tab = 'downtime'
                                return
                            }
                            if(this.tariffsByType.pump.length) {
                                this.tab = 'pump'
                                return
                            }
                            if(this.tariffsByType.other.length) {
                                this.tab = 'other'
                                return
                            }
                        }
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loadingButton = false
            })
        },
        confirmService(id, confirm) {
            let message = 'Вы действительно хотите подтвердить услугу?'
            if (!confirm) message = 'Вы действительно хотите снять подтверждение с услуги?'
            this.$confirm(
                message,
                'Подтвердите действие',
                {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loadingButton = true

                    httpService().post(this.api.mixOrderGroup.confirmService, {
                        account_modules_id: this.$route.params.id,
                        module_key: this.$route.meta.moduleKey,
                        orderId: this.groupId ?? this.$route.params.groupId,
                        serviceId: id,
                        confirm: confirm
                    }).then((response) => {
                        let data = response.data


                        if (data.success)
                            this.$message({
                                message: 'Сохранено',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('update')
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loadingButton = false
            })
        },
        updateOrder(type) {
            this.addServiceDialog = false
            this.editServiceDialog = false
            this.addGoodDialog = false
            this.selectOrderId = null
            this.selectService = null
            this.$emit('update')
            this.tab = type;
        },
        getOrderData(id, data) {
            let order = this.orders.find(el => el.id === id)

            if (order) {
                if (data === 'vehicle') {
                    return order.vehicle_number
                } else if (data === 'time') {
                    return moment(order.start_at).format('HH:mm')
                }
            }

            return null
        },
        checkConfirmMixOrder(id) {
            let order = this.orders.find(el => el.id === id)
            if (order) {
                if (!order.confirm) {
                    return true
                }
            }

            return false
        },
        openAddServiceDialog() {
            this.addServiceDialog = true
        },
        filterValues() {
            if (this.paginate) {
                this.filtered = this.goodsType ? this.goods : this.servicesData;
                this.valueLength = this.filtered.length
                return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
            }
        },
        openAddGoodDialog() {
            this.selectService = {
                id: null,
                name: null,
                type: 'good',
                depends_on: null,
                vat: 0,
                vat_in_price: false,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                manual: true,
                tariff_id: null,
                good_id: null
            }
            this.addGoodDialog = true
        },
        getSummaries(param) {
            const { columns } = param
            const sums = []

            columns.forEach((column, index) => {
              sums[this.windowWidth > 1300 ? 1 : 0] = 'Итого'

              if (column.property === 'total') {
                sums[index] = this.goodsType
                    ? this.formatNumber(this.count?.good_count, 2)
                    : this.formatNumber(this.serviceCounts[this.tab], 2)
              } else if (column.property === 'full_price') {
                sums[index] = `${this.formatNumber(this.cost?.goods_cost, 2)} руб.`
              } else {
                sums[index] = ''
              }
            })
          return sums
        },
        getServiceSummaries(param) {
            if(!this.count || !this.cost) return []

            const { columns } = param
            const sums = []

            const serviceNames = {
                downtime: 'простой',
                mix: 'доставку',
                pump: 'насос',
                other: 'прочее'
            };

            columns.forEach((column, index) => {
              sums[this.windowWidth > 1300 ? 1 : 0] = `Итого за ${serviceNames[this.tab]}`

              if (column.property === 'total') {
                const sum = this.formatNumber(this.serviceCounts[this.tab], 2);

                sums[index] = sum;
              } else if (column.property === 'full_price') {

                sums[index] = `${this.formatNumber(this.serviceCosts[this.tab], 2)} руб.`
              } else {
                sums[index] = ''
              }
            })
          return sums
        },
    },
    watch: {
        'tariffsByType': {
            handler: function(val) {
                if (!this.goodsType && this.services && val && !val[this.tab].length) {
                    const tabs = ['mix', 'downtime', 'pump', 'other'];
                    for (const tab of tabs) {
                        if (val[tab]?.length) {
                            this.tab = tab;
                            return;
                        }
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.table-container {
    min-height: 395px
}

.serviceTable__container {
    background: #f5f7fa;
}

.serviceTable--summary {
    margin-top: 0;

    &:deep(.el-table__inner-wrapper) {
        .el-table__header-wrapper {
            display: none;
        }

        .el-table__row, .el-table__append-wrapper {
            background: #f5f7fa;
        }

        tbody tr td {
            & .cell {
                padding-inline: 8px;
            }
        }
    }
}

.orderServices__tabs {
    margin-top: 16px;

    &:deep(.el-tabs__item) {
        padding: 12px 28px !important;
        line-height: 1;
        height: fit-content;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        &.is-active {
            background-color: variables.$grey-light;
        }
    }

    &:deep(.el-tabs__header) {
        margin-bottom: 0;

        .el-tabs__nav-wrap {

            .el-tabs__active-bar {
                display: none;
            }
        }
    }
}

.serviceTable {
    margin-top: 10px;
    max-width: 100%;
    overflow-x: auto;

    &:deep(.el-table__append-wrapper) {
        background: #f5f7fa;
    }

    &:deep(.el-table__inner-wrapper) {
        th.el-table__cell > .cell {
            font-size: 13px;
            font-weight: 300;
            color: variables.$neutral200;
        }

        thead tr th {
            padding-block: 0;
            & .cell {
                padding-inline: 8px;
            }
        }

        tbody tr td {
            padding-block: 8px;
        }

        tbody tr td .cell {
            padding-inline: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

        tfoot tr td {
            & .cell {
                padding-inline: 8px;
                color: variables.$neutral900;
            }
        }
    }
}
</style>

