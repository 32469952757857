<template>
    <template v-if="step === 1">
        <p class="textMedium neutral900 mb-5">Выберите документы для печати:</p>

        <el-tabs v-model="tab" @tabChange="() => (selectedDocs = [])">
            <el-tab-pane label="Все отгрузки" name="orders">
                <div class="d-flex documentPrints">
                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox
                            :disabled="!orders.length"
                            label="mixOrderReport"
                            value="mixOrderReport"
                        >
                            <template #default>
                                <div class="document text-center">
                                    <img
                                        src="/images/docs/mixOrdersReport.png"
                                    />
                                    <p class="textMedium textSM">
                                        График отгрузок
                                    </p>
                                </div>
                            </template>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-tab-pane>
            <el-tab-pane label="По заказам" name="orderGroups">
                <div class="d-flex documentPrints">
                    <el-checkbox-group v-model="selectedDocs">
                        <el-checkbox
                            :disabled="!orders.length"
                            label="mixOrderReport"
                            value="mixOrderReport"
                        >
                            <template #default>
                                <div class="document text-center">
                                    <img
                                        src="/images/docs/mixOrderGroupsReport.png"
                                    />
                                    <p class="textMedium textSM">
                                        График заказов
                                    </p>
                                </div>
                            </template>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-tab-pane>
        </el-tabs>
    </template>
    <template v-else-if="step === 2">
        <p class="textMedium neutral900">Выберите заказы для печати:</p>

        <template v-if="orderGroups.length">
            <el-checkbox
                class="w-100"
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
            >
                Выбрать все
            </el-checkbox>
            <div class="documentPrints__order-checkboxes">
                <el-checkbox-group
                    v-model="selectedOrderGroups"
                    @change="handleCheckedCitiesChange"
                >
                    <el-checkbox
                        class="w-100"
                        v-for="orderGroup in [...orderGroups].sort(
                            (a, b) => a.id - b.id
                        )"
                        :label="orderGroup.id"
                        :key="orderGroup.id"
                    >
                        #{{ orderGroup.id }} для
                        {{ getData(orderGroup.companyId, "company") }} -
                        {{ orderGroup.total }} м³
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </template>
        <template v-else>
            <p class="mt-30 textSM neutral400 text-center w-100">
                Нет подтвержденных заявок по заказу<br />
            </p>
        </template>
    </template>

    <div class="add-shipping__footer mb-15">
        <el-button
            v-if="step !== 1"
            @click="() => (step = 1)"
            class="ci-btn ci-btn_default add-shipping__footer__cancel"
            >К выбору документов
        </el-button>

        <el-button
            v-if="step === 1"
            @click="nextStep()"
            :disabled="!selectedDocs.length"
            class="ci-btn ci-btn_blue"
            type="primary"
            >Далее
        </el-button>

        <template v-if="step === 2 || tab === 'orderGroup'">
            <el-button
                @click="printDocs('download')"
                :disabled="
                    loadingButton ||
                    loadingDownloadButton ||
                    !selectedOrderGroups.length ||
                    !selectedDocs.length
                "
                :icon="icons.download"
                :loading="loadingDownloadButton"
                class="ci-btn"
                type="default"
                >Скачать
            </el-button>
            <el-button
                @click="printDocs('print')"
                :disabled="
                    loadingDownloadButton ||
                    loadingButton ||
                    !selectedOrderGroups.length ||
                    !selectedDocs.length
                "
                :loading="loadingButton"
                :icon="icons.print"
                class="ci-btn ci-btn_blue"
                type="primary">Распечатать
            </el-button>
        </template>
    </div>
</template>
<script>
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'MixOrderGroupReportsPrinter',
    computed: {
        moment() {
            return moment
        },
        chosenOrders() {
            const array = [];
                this.selectedOrderGroups.forEach((orderGroupId) => {
                    const orderGroup = this.orderGroups.find((el) => el.id === orderGroupId)
                    orderGroup.orders.forEach((el) => array.push(el))
                })
            return array
        },
        chosenOrderIds() {
            return this.chosenOrders.map(el => el.id)
        },
        dateForDocs() {
            return `от ${moment(this.orderGroups[0]?.date).format('DD.MM.YYYY')}`
        }
    },
    props: {
        orders: Array,
        orderGroups: Array
    },
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            selectedDocs: [],
            selectedOrderGroups: [],
            step: 1,
            checkAll: false,
            isIndeterminate: false,
            loadingButton: false,
            loadingDownloadButton: false,
            tab: 'orders' // 'orders' | 'orderGroups'
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.selectedOrderGroups = val ? this.orderGroups.map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orderGroups.length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orders.length;
            this.isIndeterminate = false;
        },
        printDocs(type) {
            if(type === 'download') {
                this.generateMixOrderReport();
                this.$emit('close')
            } else {
                this.loadingButton = true

                httpService().post(this.api.exportExcel.printMixOrderReport, {
                    groupIds: this.selectedOrderGroups,
                    type: this.tab === 'orders' ? 'byOrders' : "byOrderGroups",
                }, {responseType: 'blob'}).then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob);

                        const printWindow = window.open(url);

                        printWindow.addEventListener('load', function () {
                            printWindow.print();
                            URL.revokeObjectURL(url);
                        }, {once: true});
                    }
                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    });
                }).finally(() => {
                    this.loadingButton = false;
                });
            }
        },
        nextStep() {
            this.step = 2;
        },
        generateMixOrderReport(){
            this.loadingDownloadButton = true;

            httpService().post(this.api.exportExcel.exportMixOrderReport, {
                groupIds: this.selectedOrderGroups,
                type: this.tab === 'orders' ? 'byOrders' : "byOrderGroups",
            }, {
                responseType: 'blob'
            }).then((response) => {
                if(response.status === 204) {
                    this.$message({
                    message: 'Данные по отгрузкам устарели. Обновите страницу',
                    showClose: true,
                    type: 'error'
                })
                    return
                }
                let fileName = `График ${this.tab === 'orders' ? 'отгрузок' : "заказов"} ${this.dateForDocs}.xlsx`;
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                });
            }).finally(() => {
                this.loadingDownloadButton = false;
            });
        },
    },
}
</script>
