<template>
    <svg
        :class="currentColor ? 'currentColor' : ''"
        :width="width ?? '10'"
        :height="height ?? '13'"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path :stroke-width="strokeWidth ?? '0'"
            d="M1.566 12.852V0H4.95C6.522 0 7.686 0.318 8.442 0.954001C9.21 1.59 9.594 2.514 9.594 3.726C9.594 4.542 9.408 5.244 9.036 5.832C8.664 6.408 8.112 6.852 7.38 7.164C6.66 7.476 5.766 7.632 4.698 7.632H3.186V12.852H1.566ZM0 10.404V9.234H6.156V10.404H0ZM0 7.632V6.264H4.122V7.632H0ZM4.464 6.264C5.196 6.264 5.814 6.186 6.318 6.03C6.834 5.874 7.23 5.616 7.506 5.256C7.782 4.896 7.92 4.404 7.92 3.78C7.92 2.964 7.668 2.358 7.164 1.962C6.66 1.566 5.874 1.368 4.806 1.368H3.186V6.264H4.464Z"
            fill="#1984E6"
        />
    </svg>
</template>

<script>
export default {
    name: "RubleIcon",
    props: ["currentColor", "strokeWidth", "width", "height"],
};
</script>

<style scoped lang="scss">
.currentColor {
    path {
        fill: currentColor;
        stroke: currentColor;
    }
}
</style>
