<template>
    <div :class="'mixTimelineItem' + (completed ? ' mixTimelineItemCompleted' : '') + (current ? ' mixTimelineItemCurrent' : '')">
        <div class="mixTimelineItemLine">
            <el-progress color="#1b91fd" :show-text="false" :percentage="percentage" />
        </div>
        <div class="mixTimelineItemData" v-if="status !== 'done'">
            <div class="d-flex-full" v-if="(order.type === 'take-away' ^ status === 'delivery') || order.type !== 'take-away'">
                <div class="textXS" :class="[
                    {textMedium: completed || current},
                    {textRegular: !completed && !current},
                    {primary500: completed || current},
                    {neutral300: !completed && !current}
                ]">
                    {{ name }}
                </div>
                <div class="textXS textRegular neutral300">
                    {{ time ? (!completed ? '≈' : '') + time : '--:--' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import moment from "moment/moment";

export default {
    name: 'MixPageOrderTimelineItemNew',
    components: {},
    mixins: [functionsMixin],
    props: [
        'status',
        'name',
        'completed',
        'current',
        'time',
        'order'
    ],
    data () {
      return {
          now: moment().utc(),
      }
    },
    created() {
        this.counterInterval = setInterval(
            function () {
                if (this.current) {
                    this.now = moment().utc()
                }
            }.bind(this), 8000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    computed: {
        percentage() {
            let per = 0

            if (this.completed) {
                return 100
            }

            if (this.order.status === 'new') {
                return 0
            }

            const dateStart = this.toUTC(this.order.start_at)
            const dateLoad = this.toUTC(this.order.load_at)
            const dateArrive = this.toUTC(this.order.arrive_at)
            const dateUnload = this.toUTC(this.order.unload_at)
            const dateReturn = this.toUTC(this.order.return_at)

            const currentLoading = moment.duration(this.now.diff(dateStart)).asSeconds();
            const loadingDuration = moment.duration(dateLoad.diff(dateStart)).asSeconds();

            const currentDelivery = moment.duration(this.now.diff(dateLoad)).asSeconds();
            const deliveryDuration = moment.duration(dateArrive.diff(dateLoad)).asSeconds();

            const currentObject = moment.duration(this.now.diff(dateArrive)).asSeconds();
            const objectDuration = moment.duration(dateUnload.diff(dateArrive)).asSeconds();

            const currentReturn = moment.duration(this.now.diff(dateUnload)).asSeconds();
            const returnDuration = moment.duration(dateReturn.diff(dateUnload)).asSeconds();

            if (this.status === 'new' && this.current) {
                return 0;
            } else if (this.status === 'loading' && this.current) {
                per = parseFloat(((currentLoading / loadingDuration) * 100).toFixed(2));
            } else if (this.status === 'delivery' && this.current) {
                per = parseFloat(((currentDelivery / deliveryDuration) * 100).toFixed(2));
            } else if (this.status === 'object' && this.current) {
                per = parseFloat(((currentObject / objectDuration) * 100).toFixed(2));
            } else if (this.status === 'return' && this.current) {
                per = parseFloat(((currentReturn / returnDuration) * 100).toFixed(2));
            } else {
                return 0;
            }

            return per > 100 ? 100 : per;
        },
        width() {
            // ищем общее количество минут между this.order.start_at и this.order.return_at
            if (this.order) {
                const dateStart = this.toUTC(this.order.start_at)
                const dateLoad = this.toUTC(this.order.load_at)
                const dateArrive = this.toUTC(this.order.arrive_at)
                const dateUnload = this.toUTC(this.order.unload_at)
                const dateReturn = this.toUTC(this.order.return_at)

                let allMinutes = moment.duration(dateReturn.diff(dateStart)).asMinutes();
                let currentMinutes = 0;

                if (this.status === 'new') {
                    currentMinutes = moment.duration(dateLoad.diff(dateStart)).asMinutes();
                } else if (this.status === 'loading') {
                    currentMinutes = moment.duration(dateLoad.diff(dateStart)).asMinutes();
                } else if (this.status === 'delivery') {
                    currentMinutes = moment.duration(dateArrive.diff(dateLoad)).asMinutes();
                } else if (this.status === 'object') {
                    currentMinutes = moment.duration(dateUnload.diff(dateArrive)).asMinutes();
                } else if (this.status === 'return') {
                    currentMinutes = moment.duration(dateReturn.diff(dateUnload)).asMinutes();
                } else {
                    currentMinutes = 0;
                }

                return (currentMinutes / allMinutes) * 100;
            } else {
                return 100
            }
        }
    },
    methods: {
        toUTC(date) {
            return moment.tz(date, 'YYYY-MM-DD HH:mm:ss', this.moduleTimezone ?? 'Europe/Moscow').utc();
        },
    }
}
</script>

<style scoped>

</style>
