<template>
    <svg :class="[{'currentColor' : currentColor}]" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
       <path d="M13.3333 7.99984L3.33325 2.6665V13.3332L13.3333 7.99984Z"
             stroke="white" :stroke-width="strokeWidth ?? 1.5" stroke-linecap="round"
             stroke-linejoin="round"/>
   </svg>
</template>

<script>
export default {
    name: "PlayIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
