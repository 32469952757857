<template>
    <el-dialog class="crm-contracts-create-dialog"
                   :title="details.id ? 'Редактирование договора' : 'Создание договора'"
                   :width="mobile ? '100%' : '80%'"
                   :destroy-on-close="true"
                   v-model="createAndEditDialog">
    <el-form label-position="top" class="crm-contracts-form">
        <el-row :gutter="15">
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormDateTimePicker
                    class="pointer"
                    :required="true"
                    v-model="details.date"
                    label="Дата"
                    size="default"
                    :customIcon="true"
                />
            </el-col>
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormSelectCompany v-model="details.seller_company_id"
                label="Поставщик"
                placeholder="Выберите поставщика"
                company-type="seller"
                size="large" />
                <!-- <FormSelect
                    v-model="details.seller_company_id"
                    label="Поставщик"
                    placeholder="Выберите поставщика"
                    company-type="seller"
                    new-item="company"
                    :new-item-data="details.seller_company_id"
                    size="large"
                /> -->
            </el-col>
        </el-row>

        <el-row :gutter="15">
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormInput
                    v-model="details.doc"
                    :required="true"
                    label="Номер договора"
                    placeholder="Введите номер"
                />
            </el-col>
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormSelect
                    v-model="details.manager_id"
                    label="Менеджер"
                    placeholder="Выберите менеджера"
                    size="large"
                    :items="users"
                    :required="true"
                />
            </el-col>
        </el-row>

        <el-row :gutter="15">
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormInput
                    v-model="details.name"
                    label="Наименование"
                    placeholder="Введите название"
                    :required="true"
                />
            </el-col>
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormDateTimePicker
                    class="pointer"
                    v-model="details.date_end"
                    label="Дата окончания"
                    size="default"
                    :customIcon="true"
                    :required="true"
                />
            </el-col>
        </el-row>

        <el-row :gutter="15">
            <el-col :span="12" class='crm-contracts-form__col'>
                <FormSelect
                    v-model="details.company_id"
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    new-item="company"
                    :new-item-data="details.company_id"
                    size="large"
                    :required="true"
                    :disabled="!!companyId"
                />
            </el-col>
        </el-row>
    </el-form>
    <el-tabs v-model="tab">
        <el-tab-pane label="Продукция" name="document_goods"/>
        <el-tab-pane :disabled="!details.company_id" label="Объекты" name="addresses"/>
        <el-tab-pane label="Доставка" name="mix"/>
        <el-tab-pane label="Простой" name="downtime"/>
        <el-tab-pane label="Насос" name="pump"/>
    </el-tabs>
    <el-form label-position="top" class="crm-contracts-form">
        <CRMTabsTable
            :tab="tab"
            :data="itemsForSelect"
            :vat-in-price='details.vat_in_price'
            @update:vatInPrice="(val) => details.vat_in_price = val"
            @add-new-row="handleOpenAddRowDialog"
            @delete-row="deleteRow"
        />
    </el-form>
    <hr/>

    <div class="d-flex">
        <el-button class="crm-contracts-form__save-btn" @click="create()" :loading="loadingButton" type="primary"
                   size="large">Сохранить
        </el-button>
        <CRMContractsAddRowButton
            v-if="itemsForSelect.length"
            @add-new-row="handleOpenAddRowDialog" :tab="tab"/>
        <el-button
            class="crm-contracts-form__delete-btn"
            v-if="details.id"
            @click="remove(details.id)"
            :loading="loadingButton" size="large"
            :icon="icons.circleClose"
        >
            Удалить договор
        </el-button>
    </div>

    <el-dialog
        v-model="selectDialog"
        :title="selectDialogTitle"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <SelectGoodModal
            v-if="tab === 'document_goods'"
            @select="select"
            :added-goods="details.document_goods"
            :goods="goods"
        />

        <SelectTariffModal
            v-else-if="tab === 'downtime' || tab === 'pump' || tab === 'mix'"
            @select="select"
            :added-tariffs="details.service_tariffs"
            :type="tab"
        />

        <SelectAddressModal
            v-else-if="tab === 'addresses'"
            @select="select"
            :added-addresses="details.addresses"
            :company-id="details.company_id"
            :company-name="companies.find((el) => el.id === details.company_id).name"
        />
    </el-dialog>
</el-dialog>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import iconsMixin from "@/mixins/iconsMixin";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import {mapState} from "pinia"
import {useCommonStore} from "@/store/common"
import CRMTabsTable from "@/views/crm/components/CRMTabsTable";
import CRMContractsAddRowButton from "@/views/crm/components/CRMContractsAddRowButton";
import SelectGoodModal from '@/views/components/SelectGoodModal.vue'
import SelectTariffModal from '@/views/components/SelectTariffModal.vue';
import SelectAddressModal from '@/views/components/SelectAddressModal.vue';
import FormSelectCompany from '@/views/components/Form/Select/FormSelectCompany.vue';

export default {
    name: 'CRMCreateAndEditContractDialog',
    props: [
        'id', // id договора
        'companyId' // id контрагента
    ],
    data() {
        return {
            loading: true,
            loadingButton: false,
            valueLength: 0,
            tab: 'document_goods',
            selectDialog: false,
            details: {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                date: null,
                goods: [],
                document_goods: [],
                service_tariffs: [], // ids тарифов для обновления на сервере
                addresses: [], // ids объектов для обновления на сервере
                type: 'contract',
                status: null,
                manager_id: null,
                date_end: null,
                vat_in_price: false,
            },
            filtered: [],
            search: '',
            full_price: 0,
            users: null,
        }
    },
    components: {
        FormDateTimePicker,
        FormSelect,
        FormInput,
        CRMTabsTable,
        CRMContractsAddRowButton,
        SelectGoodModal,
        SelectTariffModal,
        SelectAddressModal,
        FormSelectCompany
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, iconsMixin, CRMTabs, documentStatusMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.getUsers()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
            user: 'getUser',
            goods: 'goods'
        }),
        itemsForSelect() {
            switch (this.tab) {
                case 'document_goods':
                    return this.details.document_goods
                case 'downtime':
                case 'pump':
                case 'mix':
                    return this.details.service_tariffs.filter((el) => el.type === this.tab)
                case 'addresses':
                    return this.details.addresses
                default:
                    return []
            }
        },
        selectDialogTitle() {
            const titles = {
                document_goods: 'Выберите продукцию',
                downtime: 'Выберите тариф на простой',
                pump: 'Выберите тариф на насос',
                mix: 'Выберите тариф на доставку',
                addresses: 'Выберите объект',
            }
            return titles[this.tab]
        },
    },
    methods: {
        getContract(id) {
            this.loading = true
            httpService().post(`/api/document/getOne/${id}`).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                    const {services} = data.document
                    this.details = {
                        ...data.document,
                        service_tariffs: services,
                        document_goods: data.document.document_goods.map((item) => ({
                            ...item,
                            name: item.good.name,
                            price: item.price === 0 ? null : item.price,
                            total: item.total === 0 ? null : item.total,
                            vat: item.vat === 0 ? null : item.vat,
                            full_price: item.full_price === 0 ? null : item.full_price
                        }))
                    };
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loading = false
            })
        },
        getUsers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success) {
                    this.users = data.users.filter(user => ['Владелец', 'Администратор', 'Пользователь', 'Сотрудник'].includes(user.role))
                    if (this.user && !this.details?.manager_id) this.details.manager_id = this.user.id
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        select(item) {
            switch (this.tab) {
                case 'document_goods':
                    this.pushGoods(item)
                    break
                case 'addresses':
                    this.pushAddresses(item)
                    break
                case 'downtime':
                case 'pump':
                case 'mix':
                    this.pushTariffs(item)
                    break
            }
            this.selectDialog = false
        },
        deleteRow(item) {
            const formItem = this.tab === 'document_goods'
                ? this.details.document_goods
                : this.tab === 'addresses'
                    ? this.details.addresses
                    : this.details.service_tariffs
            var idx = formItem.indexOf(item)
            formItem.splice(idx, 1)
        },
        create() {
            this.loadingButton = true
            const isValid = this.validateCreateForm();
            if (!isValid) {
                this.loadingButton = false
                return
            }

            if (!this.details.manager_id) this.details.manager_id = null
            httpService().post(this.api.document.create, {
                details: {
                    ...this.details,
                    service_tariffs: this.details.service_tariffs.map(el => el.id),
                    addresses: this.details.addresses.map(el => el.id)
                }
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.tab = 'document_goods',
                    this.resetForm();
                    this.loadingButton = false
                    this.$emit('update')
                    this.$emit('close')
                    this.$emit('create', data.document)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm('Вы действительно удалить документ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.document.remove + '/' + id).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Документ удален',
                            showClose: true,
                            type: 'success'
                        })
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                }).finally(() => {
                    this.$emit('update')
                    this.$emit('close')
                })
            })
        },
        // нужно будет со всех полей пересчитать
        changeFullPrice() {
            if (this.details?.document_goods?.length > 0) {
                this.details.document_goods.forEach((el) => {
                    if (el.price && el.total) {
                        let fullPrice = 0
                        fullPrice = Math.abs(el.price)
                        if (!this.details.vat_in_price) {
                            fullPrice = parseFloat(Math.abs(el.price) + (Math.abs(el.price) * (Math.abs(el.vat) / 100)));
                        }
                        el.full_price = Math.round((fullPrice * Math.abs(el.total)) * 100) / 100
                    }
                })
            }
        },
        resetForm() {
            this.details = {
                id: null,
                doc: null,
                name: null,
                company: null,
                company_id: null,
                date: null,
                integration_unit_id: null,
                document_goods: [],
                service_tariffs: [],
                addresses: [],
                type: 'contract',
                manager_id: this.user.id,
                date_end: null,
                status: 'not-confirmed',
                vat_in_price: false
            }
        },
        pushTariffs(tariff) {
            this.details.service_tariffs.push(tariff)
        },
        pushAddresses(address) {
            this.details.addresses.push(address)
        },
        pushGoods(good) {
            this.details.document_goods.push({
                good_id: good.id,
                name: good.name,
                total: null,
                price: null,
                vat: null,
                full_price: null,
            })
        },
        handleOpenAddRowDialog() {
            this.selectDialog = true
            this.blockScrollWhileModalOpened(true)
        },
        validateCreateForm() {
            const validationMessages = [];
            if (!this.details.name) validationMessages.push('Укажите название документа')
            if (!this.details.company_id) validationMessages.push('Укажите контрагента')
            if (!this.details.date_end) validationMessages.push('Укажите дату окончания')
            if (!this.details.manager_id) validationMessages.push('Укажите менеджера')
            if (validationMessages.length) {
                validationMessages.forEach(message => {
                    this.$message({
                        message: message,
                        showClose: true,
                        type: 'error'
                    })
                })
                this.loadingButton = false
            }
            return !validationMessages.length
        },
    },
    watch: {
        'id': {
            handler: function (id) {
                if (id) {
                    this.getContract(id)
                }
            },
            immediate: true,
            deep: true
        },
        'details.document_goods': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        'details.company_id': {
            handler: function (val, prevVal) {
                if (!val) {
                    this.details.addresses = []
                    if (this.tab === 'addresses') this.tab = 'document_goods'
                } else {
                    if ((val && prevVal) && val !== prevVal) {
                        this.details.addresses = []
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'details.vat_in_price': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        'companyId': {
            handler: function (val) {
                if (val) {
                    this.details.company_id = val
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
