<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :class="[{'currentColor' : currentColor}]"
         width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
         xml:space="preserve" class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10 21v39a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H24L10 16h12a2 2 0 0 0 2-2V2M20 51h24M20 39h24M20 27h24M33 15h11" fill="" opacity="1"></path></g></g>
    </svg>
</template>

<script>
export default {
    name: "OtherServiceIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
