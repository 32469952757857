<template>
    <div class="textRegular textSM neutral900">
        <Truncate
            :inputText="order.output_good_name ?? order.good"
        />
    </div>
</template>

<script>
import Truncate from "@/views/components/Truncate.vue";

export default {
    name: "MixOrderGroupTableGood",
    components: {Truncate},
    props: ['order', 'cut']
}
</script>

<style scoped>

</style>
