<template>
    <el-table

        v-loading="loading"
        :data="pumps"
        empty-text="Насосы не найдены"
        class="w-100 mt-15 pumpsTable">
        <el-table-column
            prop="vehicle_number"
            label="Насос"
            width="120"
        >
            <template v-slot="scope">
                <span class="cutOverflow textMedium neutral900">
                    {{ getVehicleNumber(scope.row.vehicle_number) }}
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="driver"
            label="Водитель"
        >
            <template v-slot="scope">
                <span class="cutOverflow neutral900">
                    {{ scope.row.driver_name }}
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="plan_total"
            label="Объем (план)"
            width="120"
        >
            <template v-slot="scope">
                <span class="neutral900"
                    v-if="scope.row.plan_total">
                    {{ formatNumber(scope.row.plan_total, 2) }} м³
                </span>
                <span v-else>
                    -
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="fact_total"
            label="Объем (факт)"
            width="120"
        >
            <template v-slot="scope">
                <span class="neutral900"
                    v-if="scope.row.fact_total">
                    {{ formatNumber(scope.row.fact_total, 2) }} м³
                </span>
                <span v-else>
                    -
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="Статус"
            width="115"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: scope.row.status === 'new' },
                    { 'primary600 textMedium': scope.row.status === 'object' },
                    { 'success400 textMedium': scope.row.status === 'done' }
                ]"
                    >{{
                        scope.row.status === 'new'
                            ? 'Ожидание' : scope.row.status === 'object'
                            ? 'На объекте' : 'Завершен'
                     }}</span>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            prop="soft_id"
            width="100"
        >
            <template v-slot="scope">
                <el-button
                    class="pumpsTable__btn"
                    v-if="scope.row.soft_id"
                    size="small"
                    @click="openMapViewDialog(scope.row.vehicle_id)"
                >
                На карте
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-dialog
        title="Насосы на карте"
        v-model="mapViewDialog"
        direction="rtl"
        :size="!mobile ? '60%' : '90%'"
        :destroy-on-close="true"
        :append-to-body="true">
        <div style="height: 60vh; display: flex; justify-content: center; align-items: center" v-if="mapLoading">
            <ReportLoader/>
        </div>
        <div style="height: 60vh" v-else>
            <MixersMap
                :vehicles="mapVehicles"
                :factories="factories"
                :coords="coords"
            />
        </div>
    </el-dialog>
</template>
<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import tableMixin from "@/mixins/tableMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import MixersMap from "@/views/dispatch/components/mixers/MixersMap.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import {mapState} from "pinia";
import {useCommonStore} from '@/store/common';

export default {
    name: 'OrderPumps',
    components: {MixersMap, ReportLoader},
    props: {
        pumps: Array,
        orderGroupItem: {
            type: [Object, null],
            default: null
        },
        paginate: Boolean,
    },
    mixins: [mobileCheckMixin, functionsMixin, tableMixin, mixOrderInfo],
    data() {
        return {
            pageSize: 5,
            pageSizes: [
                5, 10
            ],
            filterStatus: null,
            mapViewDialog: false,
            mapVehicles: [],
            coords: null,
            mapLoading: true
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            factories: 'getFactories',
        }),
    },
    methods: {

        filterValues() {},
        openMapViewDialog(id) {
            this.mapViewDialog = true
            this.getMapInfo(id)
        },
        getMapInfo(id) {
            httpService().post(this.api.dispatch.mixers.getMapInfo, {
                account_modules_id: this.$route.params.id,
                module_key: 'dispatch',
                vehicle_ids: [id],
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.mapVehicles = data.vehicles
                    this.coords = [parseFloat(data.latitude), parseFloat(data.longitude)]
                    this.mapLoading = false
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.pumpsTable {
    margin-top: 0;

    &:deep(.el-table__inner-wrapper) {
        th.el-table__cell > .cell {
            font-weight: 300;
            color: variables.$neutral200;
            font-size: 13px;
        }

        thead tr th {
            padding-block: 0;
            & .cell {
                padding-inline: 8px;
            }
        }

        tbody tr td {
            padding-block: 8px;
        }

        tbody tr td .cell {
            padding-inline: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    &__btn {
        display: block;
        height: 24px !important;
    }
}
</style>
