<template>
    <div
        @click="($emit('openCard'))"
        :class="['mix-driver-task-actual p-4 bg-white br-12']"
        style="margin-bottom: 0">
        <div :class="['d-flex d-flex-direction-column gap-4',
            { 'mix-driver-task-actual_unconfirmed': !order.confirm }]">
            <div class="d-flex justify-between gap-4">
                <Label v-if="order.id" class="br-t-l-8" :text="`#${order.id}`"/>
                <div v-if='order.pump || order.comment_driver' class="labels d-flex gap-4">
                    <Label class="pr-12 pl-12" v-if="order.pump" text="Под насос" color="yellow"/>
                    <Label
                        v-if="order.comment_driver"
                        text="Есть комментарий"
                        color="blue"
                        :icon="chatCircleDotsIcon"/>
                </div>
            </div>

            <article class="br-4 br-b-l-8 br-b-r-8 time-info-container">
                <div class='time-info d-flex d-flex-direction-column gap-4' v-if="order.status !== 'pouring'">
                    <MixDriverTaskTimeline :order="order" :endTime="endTime" :status="order.status"/>
                    <div class="d-flex-full gap-4">
                        <div :class="['d-flex d-flex-direction-column',
                                isDelayed && order.status === 'loading'
                                    ? 'danger300'
                                    : 'primary600',
                            ]">
                            <!-- order.status === "loading" ? plannedTime -->
                            <p class="time">
                                {{
                                    statuses[startProcessIndex]?.time
                                }}
                            </p>
                            <div class="d-flex gap-4 align-center">
                                <div class="d-flex gap-6 align-center">
                                    <ArrowRightDownIcon/>
                                    <p>
                                        {{ statuses[startProcessIndex]?.caption }}
                                    </p>
                                </div>
                                <!-- <div v-if="isDelayed && order.status === 'loading'" class="dot br-full"/> -->
                                <!-- <Label
                                    v-if="isDelayed && order.status === 'loading'"
                                    class="br-t-l-8 p-0"
                                    :text="`Задержка - ${timeline?.find((el) => el.status === 'loading').time}`"
                                    :icon="triangleWarningIcon"
                                    color="red"
                                /> -->
                            </div>
                        </div>
                        <div class="d-flex d-flex-direction-column align-end">
                            <div class="d-flex align-center deep-blue700">
                                <ApproximatelyEqualIcon/>
                                <p class="time">
                                    {{ statuses[startProcessIndex + 1]?.time }}
                                </p>
                            </div>
                            <div class="d-flex gap-6 align-center">
                                <ArrowRightIcon/>
                                <el-text class="primary600">
                                    {{ statuses[startProcessIndex + 1]?.caption }}
                                </el-text>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="delivery-info d-flex-full">
                    <div class="d-flex p-4 gap-4 align-center">
                        <BoxIcon/>
                        <el-text class="neutral500">{{ `${order.total ?? ""} м³` }}</el-text>
                    </div>
                    <p v-if="order.status === 'pouring'" class='text-center primary600 text-500 leading-24'>Заливка</p>
                    <div class="d-flex p-4 gap-4 align-center">
                        <MixerCarIcon/>
                        <el-text class="neutral500">
                            {{ getVehicleNumber(order.vehicle_number) ?? "" }}
                        </el-text>
                    </div>
                </div>
            </article>
            <div v-if="!detailsHidden" class="location-info d-flex d-flex-direction-column gap-2">
                <div v-if="order.factory_name || order.mix_name || order.mixer_name"
                     class="location-item d-flex p-8 gap-8 align-center bg-neutral30">
                    <BuildingIcon class="neutral500" :current-color="true" width="16px" height="16px"/>
                    <NewMixDriverTaskLoadingPlace
                        :factory_name="order.factory_name"
                        :mix_name="order.mix_name"
                        :mixer_name="order.mixer_name"/>
                </div>
                <div class="location-item d-flex p-8 gap-8 align-center bg-neutral30">
                    <MapPinIcon/>
                    <el-text class="neutral700" size="large">{{ order.delivery_address }}</el-text>
                </div>
                <div class="location-item d-flex p-8 gap-8 align-center bg-neutral30">
                    <UserSquareIcon/>
                    <el-text class="neutral700" size="large">{{ order.company_name ?? "" }}</el-text>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "../../../../mixins/functionsMixin";
import Label from "@/views/components/Label/Label.vue";
import moment from "moment";

// icons
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import ArrowRightDownIcon from "@/views/components/Icons/ArrowRightDownIcon.vue";
import ArrowRightIcon from "@/views/components/Icons/ArrowRightIcon.vue";
import ApproximatelyEqualIcon from "@/views/components/Icons/ApproximatelyEqualIcon.vue";
import BoxIcon from "@/views/components/Icons/BoxIcon.vue";
import MixerCarIcon from "@/views/components/Icons/MixerCarIcon.vue";
import MapPinIcon from "@/views/components/Icons/MapPinIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import UserSquareIcon from "@/views/components/Icons/UserSquareIcon.vue";
import MixDriverTaskTimeline from "./MixDriverTaskTimeline.vue";
import NewMixDriverTaskLoadingPlace from "./NewMixDriverTaskLoadingPlace.vue";

export default {
    name: "NewMixDriverTaskActual",
    components: {
        Label,
        ArrowRightDownIcon,
        ArrowRightIcon,
        ApproximatelyEqualIcon,
        BoxIcon,
        MixerCarIcon,
        MapPinIcon,
        BuildingIcon,
        UserSquareIcon,
        MixDriverTaskTimeline,
        NewMixDriverTaskLoadingPlace
    },
    props: ["order", "text", "loading", "detailsHidden"],
    mixins: [functionsMixin],
    data() {
        return {
            chatCircleDotsIcon: ChatCircleDotsIcon,
            statuses: [
                {
                    key: 'loading',
                    time: this.getTime('loading'),
                    caption: 'Загрузка'
                },
                {
                    key: 'delivery',
                    time: this.getTime('delivery'),
                    caption: 'Доставка'
                },
                {
                    key: 'object',
                    time: this.getTime('object'),
                    caption: 'На объекте'
                },
                {
                    key: 'return',
                    time: this.getTime('return'),
                    caption: 'Возвращается'
                },
                {
                    key: 'done',
                    time: this.getTime('done'),
                    caption: 'Завершено'
                }
            ],
        };
    },
    methods: {
        getOrderTimeByStatus(status) {
            let time = null;
            switch (status) {
                case 'loading':
                    time = this.order.start_at ?? null
                    break
                case 'delivery':
                    time = this.order.load_at ?? null
                    break
                case 'object':
                    time = this.order.arrive_at ?? null
                    break
                case 'return':
                    time = this.order.unload_at ?? null
                    break
                case 'done':
                    time = this.order.return_at ?? null
                    break
            }
            return time
        },
        getTime(status) {
            const time = this.getOrderTimeByStatus(status)

            if (!time) {
                return null;
            }
            return time.split(' ')[1].slice(0, 5);
        },
        toUTC(date) {
            return moment.tz(date, 'YYYY-MM-DD HH:mm:ss', this.order.timezone).utc();
        },
    },
    computed: {
        startProcessIndex() {
            const currentStatus = this.statuses.find((status) => status.key === this.order.status)
            return this.statuses.indexOf(currentStatus)
        },
        endTime() {
            const endStatus = this.statuses[this.startProcessIndex + 1].key
            return this.toUTC(this.getOrderTimeByStatus(endStatus))
        },
    },
    watch: {
        'order': {
            handler: function () {
                this.statuses = [
                    {
                        key: 'loading',
                        time: this.getTime('loading'),
                        caption: 'Загрузка'
                    },
                    {
                        key: 'delivery',
                        time: this.getTime('delivery'),
                        caption: 'Доставка'
                    },
                    {
                        key: 'object',
                        time: this.getTime('object'),
                        caption: 'На объекте'
                    },
                    {
                        key: 'return',
                        time: this.getTime('return'),
                        caption: 'Возвращается'
                    },
                    {
                        key: 'done',
                        time: this.getTime('done'),
                        caption: 'Завершено'
                    }
                ]
            },
            deep: true,
            immediate: true
        }
    }
};
</script>
