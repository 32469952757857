<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">Отгрузка перенаправлена</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5">
                <span class="text-line-through mr-5 neutral100">Заказ #{{ info?.old_group_id }}</span>
                <span class="neutral300 mr-5">Заказ #{{ info?.new_group_id }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: "MixOrderDetailsGroupLogRedirectOrder",
    mixins: [functionsMixin, paymentMethods],
    props: ['log'],
    computed: {
        info() {
            return JSON.parse(this.log?.info)
        },
    }
}
</script>

<style scoped>

</style>
