<template>
    <div :class="['group-rows__label', {'group-rows__label--second': type === 'allMixes'}]">
        <div class="group-rows__label-info">
            <article class="group-rows__label-info-icon"
                     :class="[{'group-rows__label-info-icon--active': row.rent}]">
                <el-tooltip :content="`${row.rent ? 'Арендованное' : 'Собственное'} ТС`"
                            placement="top" :show-after="200" :hide-after="100">
                    <RentIcon :current-color="true"/>
                </el-tooltip>
            </article>
            <article class="group-rows__label-info-icon"
                     :class="[{'group-rows__label-info-icon--active': row.gidrolotok}]">
                <el-tooltip
                    :content="`${row.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'}`"
                    placement="top" :show-after="200" :hide-after="100">
                    <GidrolotokLetterIcon :current-color="true"/>
                </el-tooltip>
            </article>
            <article class="group-rows__label-info-icon--gps">
                <template
                    v-if="!row.soft_id">
                    <el-tooltip effect="dark" placement="right" content="Авто без GPS">
                        <SignalIcon :disabled="true"/>
                    </el-tooltip>
                </template>
                <template v-else>
                    <el-tooltip effect="dark" placement="left">
                        <template #content>
                            <template v-if="row.gps_last_date">
                                <template v-if="!row.gps_online">GPS сигнал потерян<br/></template>
                                Время последнего обновления:<br/>
                                {{
                                    getDateMoment(row.gps_last_date, 'DD.MM HH:mm')
                                }}
                            </template>
                            <template v-else>
                                GPS сигнал не обнаружен
                            </template>
                        </template>
                        <div class="ordersTableItemGPSIcon pt-0">
                            <SignalIcon
                                :class="{ordersTableItemGPSIconOnline: row.gps_online}"/>
                        </div>
                    </el-tooltip>
                </template>
            </article>
        </div>
        <p class="group-rows__label-text group-rows__label-text--vehicle">
            {{ getFormattedVehicleNumber(row.vehicle_number) }}</p>
        <el-tooltip content="Объём авто" placement="top" :show-after="200" :hide-after="100">
            <p class="group-rows__label-text group-rows__label-text--volume">{{ row.volume }}
                м³</p>
        </el-tooltip>
    </div>
</template>
<script>
import functionsMixin from "../../mixins/functionsMixin";
import GidrolotokLetterIcon from "@/views/components/Icons/GidrolotokLetterIcon.vue";
import RentIcon from "@/views/components/Icons/RentIcon.vue";
import SignalIcon from "@/views/components/Icons/SignalIcon.vue";

export default {
    name: "SelfGanttLabel",
    // TODO оптимизировать from/to/timezone, объединить и сократить кол-во пропсов
    mixins: [functionsMixin],
    components: {GidrolotokLetterIcon, RentIcon, SignalIcon},
    props: {
        row: Object,
        type: String
    },
}
</script>
<style scoped lang="scss">
@use "@/sass/_variables.scss";

.group-rows__label {
        margin-inline: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        //height: 50px;
        height: 42px;

        &--second {
            height: 42px;
        }

        &-info {
            display: flex;
            align-items: center;
            gap: 4px;

            &-icon {
                width: 16px;
                height: 16px;
                color: variables.$neutral100;

                &--active {
                    color: variables.$neutral500;
                }

                &--gps {
                    height: 20px;
                }
            }

            &-item {
                margin: 0;
                color: variables.$neutral400;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
            }
        }

        &-text {
            margin-block: 0;
            color: variables.$neutral400;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            &--volume {
                width: 40px;
            }

            // резерв места под полный номер автомобиля
            &--vehicle {
                width: 85px;
                font-stretch: 100%;
            }
        }
    }
</style>
