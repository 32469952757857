<template>
    <el-form class="dispatch-order-groups-create-form" label-position="left" label-width="185px">

        <el-row :gutter="30">
            <el-col :sm="windowWidth < 1600 ? 12 : 8">

                <el-row :gutter="30">
                    <el-col :sm="24">
                        <FormDateTimePicker
                            v-model="form.date_shipment"
                            label="Дата доставки"
                            size="default"
                            :required="true"
                        />
                    </el-col>
                </el-row>

                <FormSelectCompany
                    v-if="!form.individual"
                    v-model="form.buyer_company_id"
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    size="large"
                    :required="true"
                    :integration-unit-id="isOneCIntegrationRequiredForCompanies ? oneCIntegrationUnitId : null"
                />

                <FormSelectDispatchGoods
                    v-model="form.good_id"
                    :disabled="disableForm || !form.buyer_company_id"
                    :good="form.good"
                    :invoice-id="form.invoice_id"
                    :contract-id="form.contract_id"
                    @updatePrice="updatePrice"
                    @updateGood="(val) => this.form.good = val"
                    :required="true"
                    :integration-unit-id="isOneCIntegrationRequiredForGoods ? oneCIntegrationUnitId : null"
                    :by-contract="byContract"
                    :by-invoice="byInvoice"
                />

                <FormNumber
                    v-model="form.total"
                    label="Объем продукции"
                    placeholder="30"
                    precision="2"
                    :required="true"
                />
            </el-col>

            <el-col :sm="windowWidth < 1600 ? 12 : 8">
                <FormSelectDocuments
                    v-model="form.contract_id"
                    :disabled="!form.buyer_company_id || disableForm"
                    label="Договор поставки"
                    placeholder='Выберите договор'
                    :company-id="form.buyer_company_id"
                    :items="[]"
                    document-type="contract"
                    :required="form.contract_required == 1"
                />

                <FormSelect
                    v-model="form.invoice_id"
                    :disabled="!form.buyer_company_id || disableForm"
                    label="Счет"
                    :placeholder="!form.buyer_company_id ? 'Выберите контрагента' : 'Выберите счет'"
                    new-item="document"
                    :new-item-data="form.invoice_id"
                    :carrier-company="form.buyer_company_id"
                    :items="[]"
                    size="large"
                    document-type="invoice"
                />

                <div class="el-form-item" v-if="payment_type === 'contract' && form.contract_id">
                    <div class="el-form-item__label" style="width: 185px;">Остаток средств по договору</div>
                    <div class="el-form-item__content textLG primary500" style="height: 38px;">
                        {{ selectedContract.length && selectedContract.total ? selectedContract.total : 0 }} ₽
                    </div>
                </div>

                <div class="el-form-item" v-if="payment_type === 'invoice' && form.invoice_id">
                    <div class="el-form-item__label" style="width: 185px;">Статус оплаты</div>
                    <div class="el-form-item__content textLG text-500" style="height: 38px;"
                         :class="[
                             {'danger300': !selectedInvoice.payment_status},
                             {'danger300': selectedInvoice.payment_status === 'not-paid'},
                             {'danger300': selectedInvoice.payment_status === 'failed'},
                             {'primary500': selectedInvoice.payment_status === 'partially-paid'},
                             {'primary500': selectedInvoice.payment_status === 'paid'},
                        ]">
                        {{ checkPaymentStatus(selectedInvoice.payment_status) }}
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-tabs v-model="activeTab" class="mt-15">

            <el-tab-pane name="delivery">
                <template #label>
                    <span v-html="getTabLabel('delivery')"></span>
                </template>

                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-form-item label="Тип доставки" class="is-required">
                            <el-tooltip
                                effect="dark"
                                content="Невозможно изменить тип доставки для подтвержденных заказов"
                                placement="top" :disabled='!this.updateMode ||(this.updateMode && form.status !== "confirmed")'>
                                <el-radio-group
                                    v-model="form.delivery_type"
                                    :disabled='this.updateMode && form.status === "confirmed"'>
                                    <el-radio
                                        label="delivery">Доставка</el-radio>
                                    <el-radio label="take-away">Самовывоз</el-radio>
                                </el-radio-group>
                            </el-tooltip>
                        </el-form-item>

                        <FormSelectObject
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.delivery_address_id"
                            :items="form.contract_id ? getFormSelectItems(contractData.addresses) : null"
                            :company-id="form.buyer_company_id"
                            :disabled="!form.buyer_company_id || disableForm"
                            :additional-info="selectedBuyerName"
                            :disable-create-button="form.contract_id && contractData.addresses.length "
                            :required="true"
                        />

                        <template v-if="form.delivery_type === 'delivery'">
                            <FormSelect
                                v-model="form.company_manager_id"
                                :disabled="!form.buyer_company_id || disableForm"
                                label="Представитель контрагента"
                                placeholder="Укажите ФИО представителя"
                                new-item="companyManager"
                                :new-item-data="form.buyer_company_id"
                                :items="companyManagers"
                            />

                            <FormTextarea
                                v-model="form.comment_driver"
                                label="Комментарий водителю"
                                placeholder="Напишите комментарий"
                                :rows="4"
                            />

                            <el-form-item label="Наличие гидролотка">
                                <template #label>
                                    <el-link class="textXS neutral400" href="https://www.gidrolotok.ru/"
                                             target="_blank">Наличие гидролотка
                                    </el-link>
                                </template>
                                <el-switch size="large" v-model="form.gidrolotok"/>
                            </el-form-item>
                        </template>

                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8" v-if="form.delivery_type === 'delivery'">
                        <div class="time-delivery-input is-required">
                            <label>Время первой доставки</label>
                            <input type="time" v-model="form.first_order_time_delivery" placeholder="00:00">
                        </div>

                        <FormNumber
                            label="План. время разгрузки"
                            v-model="form.time_unloading"
                            placeholder="Укажите время для планирования"
                            precision="2"
                            step="1"
                            :required="true"
                        />

                        <el-form-item label="Интервал между доставками" class="is-required">
                            <el-radio-group v-model="form.type_delivery">
                                <el-radio label="withoutInterval">Без интервала</el-radio>
                                <el-radio label="withInterval">С интервалом на разгрузку</el-radio>
                                <el-radio label="withIntervalClient">Интервал от клиента</el-radio>
                                <el-radio label="intensity">Интенсивность</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <FormNumber
                            v-if="form.type_delivery === 'withIntervalClient'"
                            v-model="form.time_interval_client"
                            label="Интервал от клиента (мин)"
                            placeholder="Укажите время"
                            precision="2"
                            step="1"
                            :required="true"
                        />

                        <FormNumber
                            v-if="form.type_delivery === 'intensity'"
                            label="Интенсивность куб/ч"
                            v-model="form.intensity"
                            placeholder="Укажите объем"
                            precision="2"
                            step="1"
                            :required="true"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane name="planning">
                <template #label>
                    <span v-html="getTabLabel('planning')"></span>
                </template>

                <MixOrderGroupCreateAlgorithmBlock
                    v-model="form"
                />
            </el-tab-pane>

            <el-tab-pane v-if="form.delivery_type === 'delivery'" name="pump" :disabled="!loadDispatchInfo">
                <template #label>
                    <span v-html="getTabLabel('pumps')"></span>
                </template>

                <el-switch
                    size="large"
                    v-model="form.pump"
                    active-text="Необходимость насоса"
                />

                <template v-if="form.pump">
                    <div class="dispatchMixersTable">
                        <DispatchMixersCreateForm
                            type="pump"
                            :dateOrder="form.date_shipment"
                            :selectedIds="selectedPumpIds"
                            :orderId="form.id"
                            :pumpItems="getFormSelectItems(contractData.services.pump)"
                            @select="selectPumps"
                    />
                    </div>
                </template>
            </el-tab-pane>

            <el-tab-pane name="price" :disabled="!loadDispatchInfo">
                <template #label>
                    <span v-html="getTabLabel('price')"></span>
                </template>

                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormNumber
                            :disabled="isGoodPriceFieldDisabled"
                            v-model="form.good_price"
                            label="Стоимость продукции"
                            placeholder="Укажите стоимость за 1 м3"
                            :min="0"
                            precision="2"
                            :tooltipContent="isGoodPriceFieldDisabled ? 'Стоимость из договора' : ''"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormSelect
                            v-model="form.payment_method"
                            label="Метод оплаты"
                            placeholder="Выберите метод"
                            :required=requiredSettingsFor1C
                            :items="paymentMethods"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormNumber
                            v-if="form.payment_method === 'cash' || form.payment_method === 'transfer'"
                            v-model="form.prepayment"
                            label="Предоплата"
                            :min="0"
                            placeholder="5000"
                            precision="2"
                        />
                    </el-col>
                </el-row>

                <hr class="mt-15 mb-15"/>

                <el-row :gutter="24">
                    <el-col :sm="12">
                        <FormSelectTariff
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.mix_service_tariff_id"
                            label="Тариф доставки бетона"
                            placeholder="Выберите тариф"
                            type="mix"
                            :new-item-data="form.mix_service_tariff_id"
                            :disable-create-button="!!(form.contract_id && contractData.services.mix.length)"
                            :items="getFormSelectItems(contractData.services.mix)"
                            @update="getContractById(form.contract_id)"
                        />

                        <FormSelectTariff
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.downtime_service_tariff_id"
                            type="downtime"
                            label="Тариф за простой"
                            placeholder="Выберите тариф"
                            :disable-create-button="!!(form.contract_id && contractData.services.downtime.length)"
                            :items="getFormSelectItems(contractData.services.downtime)"
                            @update="getContractById(form.contract_id)"
                        />
                    </el-col>

                </el-row>
            </el-tab-pane>

            <el-tab-pane name="mixes">
                <template #label>
                    <span v-html="getTabLabel('mixes')"></span>
                </template>

                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-checkbox-group
                            class="inlineCheckbox"
                            v-model="form.mixer_ids"
                            @change="handleCheckedMixersChange"
                        >
                            <div
                                v-for="(mixer, mixerIndex) in mixers"
                                :key="mixerIndex"
                                class="mix-section"
                            >
                                <div class="selectedMixes cutOverflow">
                                    <el-checkbox
                                        :label="mixer.id"
                                    >
                                        {{
                                            mixer.count > 1 ? mixer.module_name + ' - ' + mixer.name : mixer.module_name
                                        }}
                                        {{ mixer.start_time }} -
                                        {{ mixer.end_time }}
                                    </el-checkbox>
                                </div>

                                <div class="d-flex mix-action">
                                    <div class="mix-load" v-show="false">
                                        100 %
                                    </div>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormTextarea
                            v-model="form.comment_operator"
                            label="Комментарий оператору"
                            placeholder="Напишите комментарий"
                            :rows="4"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane v-if="form.delivery_type === 'delivery'" name="mixers" :disabled="!loadDispatchInfo">
                <template #label>
                    <span v-html="getTabLabel('mixers')"></span>
                </template>

                <el-row :gutter="30">
                    <el-col :sm="30">
                        <el-switch
                            size="large"
                            v-model="form.custom_mixers_enabled"
                            active-text="Только выбранные"
                            inactive-text="Все"
                        />
                    </el-col>
                    <el-col v-if="form.custom_mixers_enabled" :sm="30">
                        <div class="dispatchMixersTable">
                            <DispatchMixersCreateForm
                                type="mix"
                                :dateOrder="form.date_shipment"
                                :selectedMixerIds="selectedMixerIds"
                                @select="selectCustomMixers"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane name="other" :disabled="!loadDispatchInfo">
                <template #label>
                    <span v-html="getTabLabel('other')"></span>
                </template>

                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">

                        <el-form-item label="Необходимость лаборатории" v-if="isLab()">
                            <el-switch size="large" v-model="form.lab"/>
                        </el-form-item>

                        <FormInput
                            v-model="form.constructive"
                            label="Конструктив"
                            placeholder="Опишите"
                        />

                        <FormSelect
                            v-model="form.spec_good_id"
                            v-if="false"
                            label="Спецификация"
                            placeholder="Выберите спецификацию"
                            new-item="good"
                            :good-ids="[form.good_id]"
                        />

                        <FormSelectSpecGoodByProduct
                            v-model="form.spec_good_id"
                            v-if="!form.lab"
                            :disabled="!form.good_id"
                            :good-id="form.good_id"
                            :good-name="default_spec_info.name"
                            :default-spec-id="default_spec_info.id"
                            :active="activeTab === 'other'"
                            :form-item="true"
                            :updateMode="updateMode"
                            :required="isLab()"
                        />

                        <FormTextarea
                            v-if="form.lab"
                            v-model="form.comment"
                            label="Комментарий для лаборатории"
                            placeholder="Напишите комментарий"
                            :rows="3"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-form-item label="Единый перевозчик">
                            <el-tooltip placement="top" popper-class="dispatch-order-groups-create-form__tooltip">
                                <template #content>
                                    <p>Если включена настройка “Единый перевозчик”, выбранный перевозчик будет автоматически указан в документах для всех автомобилей — как для собственных, так и для арендованных. Если настройка отключена, то:</p>
                                    <ul>
                                        <li>для <span class="textBold">собственных автомобилей</span> в документах будет указан выбранный перевозчик,</li>
                                        <li>для <span class="textBold">арендованных автомобилей</span> будет указана компания, к которой привязано авто.</li>
                                    </ul>
                                    <p>Изменить компанию, к которой привязано арендованное авто, можно в разделе:
                                        <span class="textBold">Настройки → Единицы техники.</span></p>
                                </template>
                                <el-switch size="large" v-model="form.single_carrier"/>
                            </el-tooltip>
                        </el-form-item>
                        <FormSelectCompany
                            v-model="form.seller_company_id"
                            label="Поставщик"
                            placeholder="Выберите поставщика"
                            company-type="seller"
                            :required="form.delivery_type === 'take-away' ? false : true"
                        />
                        <FormSelectCompany
                            v-model="form.carrier_company_id"
                            :label="form.single_carrier ? 'Перевозчик' : 'Перевозчик для своих авто'"
                            placeholder="Выберите перевозчика"
                            :carrier-company="true"
                            company-type="carrier"
                            :required="form.delivery_type === 'take-away' ? false : true"
                        />

                        <FormSelect
                            v-model="form.manager_id"
                            label="Менеджер"
                            placeholder="Выберите"
                            :items="users"
                        />

                        <FormTextarea
                            v-model="form.comment_dispatch"
                            label="Комментарий для диспетчера"
                            placeholder="Напишите комментарий"
                            :rows="3"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>

        </el-tabs>
    </el-form>
</template>

<script>
import DispatchMixersCreateForm from "@/views/dispatch/DispatchMixersCreateForm.vue";
import FormTextarea from "@/views/components/Form/FormTextarea.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import mixOrderGroupFormData from "@/mixins/mixOrderGroupFormData";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapActions, mapState, mapStores} from "pinia";
import {useCommonStore} from "@/store/common";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useMixStore} from "@/store/mix";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import paymentMethods from "@/mixins/paymentMethods";
import FormSelectSpecGoodByProduct from "@/views/components/Form/Select/FormSelectSpecGoodByProduct.vue";
import FormSelectDispatchGoods from "@/views/components/Form/Select/FormSelectDispatchGoods.vue";
import FormSelectCompany from "@/views/components/Form/Select/FormSelectCompany.vue";
import FormSelectObject from "@/views/components/Form/Select/FormSelectObject.vue";
import MixOrderGroupCreateAlgorithmBlock
    from "@/views/components/MixOrderGroup/components/MixOrderGroupCreateAlgorithmBlock.vue";
import FormSelectDocuments from "@/views/components/Form/Select/FormSelectDocuments.vue";
import FormSelectTariff from "@/views/components/Form/Select/FormSelectTariff.vue";

export default {
    name: "MixOrderGroupCreateForm",
    props: ['modelValue', 'mixers', 'disableForm', 'updateMode', 'defaultFormData', 'buyerCompanyId'],
    components: {
        MixOrderGroupCreateAlgorithmBlock,
        FormSelectDispatchGoods,
        FormSelectSpecGoodByProduct,
        FormDateTimePicker, FormNumber, FormSelect, FormInput, FormTextarea,
        DispatchMixersCreateForm,
        FormSelectCompany,
        FormSelectObject,
        FormSelectDocuments, FormSelectTariff
    },
    mixins: [mixOrderGroupFormData, mobileCheckMixin, functionsMixin, paymentMethods],
    data() {
        return {
            activeTab: 'delivery',
            payment_type: '',
            documentsByType: [],
            selectedContract: [],
            selectedInvoice: [],
            contractData: { // данные по контракту
                id: null,
                addresses: [],
                goods: [],
                services: {
                    mix: [],
                    downtime: [],
                    pump: []
                }
            },
            invoiceData: { // данные по счету
                id: null,
                addresses: [],
                goods: [],
                services: {
                    mix: [],
                    downtime: [],
                    pump: []
                }
            },
            defaultSellerCompanyId: null,
            defaultCarrierCompanyId: null,
            loadDispatchInfo: false,
            disablePrice: false,
            default_spec_info: {
                id: null,
                name: null
            },
            tabLabels: {
                delivery: "Доставка",
                planning: "Планирование",
                pumps: "Насосы",
                price: "Стоимость",
                mixes: "Заводы",
                mixers: "Миксеры",
                other: "Дополнительно",
            },
            tabFields: {
                delivery: ["delivery_type"],
                planning: [],
                pumps: [],
                price: [],
                mixes: [],
                mixers: [],
                other: ["seller_company_id", "carrier_company_id"],
            },
            contractSellerId: null,
            requiredSettingsFor1C: false,
            oneCIntegrationUnitId: null,
            hideContractGoods: false,
            byContract: false,
            byInvoice: false,
        }
    },
    created() {
        this.getAccountModuleParams()
        this.get1CIntegrationSettings()

        if(!this.updateMode) {
            this.form.first_order_time_delivery = null;
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            user: 'getUser',
            goods: 'getGoods',
            modules: 'getModules',
            integrations: 'getIntegrations',
            vehicles: 'getVehicles',
            users: 'getUsers',
        }),
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
            address: 'getAddress',
            companyManagers: 'getCompanyManagers',
        }),
        ...mapStores(useMixStore),
        selectedBuyerName: function () {
            return this.companies?.find(({id}) => id === this.form.buyer_company_id)?.name;
        },
        allSellers: function () {
            return this.companies?.filter((el) => el.type === 'seller');
        },
        allCarriers: function () {
            return this.companies?.filter((el) => el.type === 'carrier' || el.carrier === true);
        },
        selectedMixerIds() {
            if (this.checkArray(this.form.custom_mixers)) {
                return this.form.custom_mixers.map(item => item.id)
            } else {
                return this.form.custom_mixers
            }
        },
        selectedPumpIds() {
            return this.form.pumps.map(item => item.vehicle_id)
        },
        isGoodPriceFieldDisabled() {
            let isDisabled = false;
            // блокируем инпут, если указан договор и выбрана продукция со стоимостью, отличной от 0
            // иначе - даем его редактировать пользователю
            if(this.form.contract_id && this.contractData.goods.length && this.form.good_id) {
                let good = this.contractData?.goods.find((el => el.id === this.form.good_id)) // проверяем стоимость товара по договору
                if (good && good.price !== 0 && good.price !== null) {
                    isDisabled = true
                }
            }
            return isDisabled
        },
        oneCIntegrations() {
            //TODO сделать фильтр не по id
            return this.integrations?.filter((el) => el.integration_id == 2);
        },
        isOneCIntegrationRequiredForGoods() {
            if(!this.oneCIntegrationUnitId) return false
            if(this.form.good && !this.form.good?.integration_unit_id) return false
            if(this.form.good && this.oneCIntegrationUnitId !== this.form.good?.integration_unit_id) return false

            return true
        },
        isOneCIntegrationRequiredForCompanies() {
            if(!this.oneCIntegrationUnitId) return false
            if(this.form.buyer_company_id)  {
                const company = this.companies?.find(({id}) => id === this.form.buyer_company_id);
                if(this.oneCIntegrationUnitId !== company?.integration_unit_id) return false
            }
            return true
        },
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullAddress', 'pullCompanyManagers']),
        handleCheckedMixersChange(mixer_ids) {
            const result = this.mixers.filter(item => mixer_ids.includes(item.id));
            this.form.account_module_mix_ids = [...new Set(result.map(m => m.account_module_id))]
        },
        isLab() {
            this.form.is_lab = !!(this.modules.find((module) => +module.accountModuleId === +this.$route.params.id)?.lab.length ?? 0);
            return this.form.is_lab;
        },
        changeBuyer() {
            this.form.seller_company_id = this.defaultSellerCompanyId ? this.defaultSellerCompanyId : this.allSellers.length === 1 ? this.allSellers[0].id : null;
            this.form.carrier_company_id = this.defaultCarrierCompanyId ? this.defaultCarrierCompanyId : this.allCarriers.length === 1 ? this.allCarriers[0].id : null;
            this.form.contract_id = null
            this.form.invoice_id = null
            this.form.delivery_address_id = null
            if (this.form.buyer_company_id) {
                setTimeout(() => this.$emit('getOrderGroupInfoCreate', this.form.buyer_company_id), 150)
            }
        },
        getAccountModuleParams() {
            // берем настройки модуля из pinia
            let settings = this.modules.find(el => el.accountModuleId == this.$route.params.id)?.settings ?? []
            if (settings) {
                const { contract_required, payment_type, default_seller, default_carrier, default_rent_car_carrier, lab_required } = settings

                this.form.contract_required = contract_required;
                this.payment_type = payment_type;
                this.defaultSellerCompanyId = +default_seller ? +default_seller : null;
                this.defaultCarrierCompanyId = +default_carrier ? +default_carrier : null;
                if (lab_required && lab_required == 1) {
                    this.form.lab = true
                }
                this.form.single_carrier = default_rent_car_carrier === 'true' ? true : false
            }

            if (this.payment_type) {
                this.getAccountDocuments(this.payment_type)
            }
        },
        getAccountDocuments(payment_type) {
            if (payment_type && payment_type !== '0') {
                httpService().post(this.api.document.getByType, {
                    type: payment_type
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.documentsByType = data.document
                    }
                })
            }
        },
        getContractById(id, type = 'contract') {
            this.loading = true
            httpService().post(this.api.document.getOneByDispatch, {
                account_modules_id: this.$route.params.id,
                id: id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false

                    const {id, addresses, document_goods, services, seller_company_id} = data.document;

                    if(seller_company_id && (!this.updateMode || this.updateMode && this.defaultFormData.contract_id !== id)) {
                        this.form.seller_company_id = seller_company_id;
                    } else if(!seller_company_id && !this.updateMode) {
                        this.setSellerCompanyId()
                    }

                    this[type === 'contract' ? 'contractData' : 'invoiceData'] = {
                        id: id,
                        addresses: addresses,
                        goods: document_goods?.map((el) => {
                            return {
                                id: el.good.id,
                                name: el.good.name,
                                output_name: el.good.output_name ?? el.good.name,
                                price: !el.price ? null : el.price,
                                integration_unit_id: el.good.integration_unit_id
                            }
                        }),
                        services: {
                            mix: services.filter((el) => el.type === 'mix'),
                            downtime: services.filter((el) => el.type === 'downtime'),
                            pump: services.filter((el) => el.type === 'pump'),
                        }
                    }
                    if(this.form.good_id) {
                        const documentGood = document_goods?.find((el) => el.good_id == this.form?.good_id)

                        this[type === 'contract' ? 'byContract' : 'byInvoice'] = documentGood ? true : false
                    }
                    this.setPrice()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        selectCustomMixers(e) {
            this.form.custom_mixers = e.map(schedule => schedule.vehicle_id)
        },
        selectPumps(e) {
            this.form.pumps = e.map((pump) => {
                return {
                    vehicle_id: pump.vehicle_id,
                    driver_id: pump.driver_id,
                    comment: pump.comment ?? '',
                    plan_total: pump.plan_total ?? 0,
                    tariff_id: pump.tariff_id ?? null
                }
            })
        },
        updatePrice(price) {
            this.form.good_price = price == 0 || !price ? null : price
        },
        checkPaymentStatus(status) {
            switch (status) {
                case 'not-paid':
                    return 'Не оплачен'
                case 'failed':
                    return 'Отменен'
                case 'partially-paid':
                    return 'Оплачен частично'
                case 'paid':
                    return 'Оплачен'
                default:
                    return 'Не оплачен'
            }
        },
        resetContractData(type = 'contract') {
            this[type === 'contract' ? 'contractData' : 'invoiceData'] = {
                id: null,
                addresses: [],
                goods: [],
                services: {
                    mix: [],
                    downtime: [],
                    pump: [],
                }
            }
        },
        getFormSelectItems(contractItems, defaultItems, integrationUnitId) {
            // если есть данные по договору, берем их, если нет - данные по умолчанию
            if(!contractItems) return defaultItems ? defaultItems : null
            if(!defaultItems || integrationUnitId) return this.contractData.id && contractItems.length ? contractItems : null
            return this.contractData.id && contractItems.length ? contractItems : defaultItems
        },
        // getTariffData(type, id, key) {
        //     // получаем данные тарифа
        //     if(!id) return null
        //
        //     const tariffs = this.getFormSelectItems(this.contractData.services[type], this.serviceTariffs[type])
        //     const tariff = tariffs.find(el => el.id === id)
        //     if(!tariff) return null
        //     return tariff[key]
        // },
        getDefaultSpec(goodId) {
            if(!goodId) return
            // получаем данные о дефолтной спецификации по умолчанию для товара
            this.loading = true

            httpService().post(this.api.dispatch.orderGroups.getDefaultSpec, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                goodId: goodId
            }).then(({data}) => {
                if (data.success) {
                    if (this.updateMode) { // если продукция отличается от той, что в заказе, грузим спец. по ум. либо сбрасываем поле
                        if (this.defaultFormData.good_id !== this.form.good_id) {
                            this.form.spec_good_id = data.specGood.actual ? data.specGood.id : null
                        } else {
                            this.form.spec_good_id = this.defaultFormData.spec_good_id // подгружаем спецификацию из заказов
                        }
                    } else {
                        if (data.specGood) {
                            if (!this.form.lab) {
                                this.form.spec_good_id = data.specGood.actual ? data.specGood.id : null
                            }
                        } else {
                            this.form.spec_good_id = null
                            this.default_spec_info = {
                                id: null,
                                name: null,
                                actual: null,
                            }
                        }
                    }
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        restoreOrderFormData(key) {
            // восстанавливаем данные заказа (из getLast для новых, данные по заказу при редактировании)
            if(!this.defaultFormData) return
            this.form[key] = this.defaultFormData[key] ?? null
        },
        setFormData(dataArray, formKey) {
            // логика подстановки данных в форму
            if (dataArray?.length === 1) {
                const [firstEl] = dataArray;
                this.form[formKey] = firstEl.id; // если массив состоит из одного элемента, подставляем его
                // if(formKey === 'good_id') {
                //     if(this.defaultFormData.contract_id !== this.form.contract_id && this.defaultFormData.good_id !== this.form.good_id) {
                //         this.updatePrice(firstEl.price)
                //         this.form.good = firstEl;
                //     }
                // }
            } else if(dataArray?.length > 1) {
                // ищем, существует ли элемент в массиве,
                // если элемент есть в массиве, оставляем его
                const currentItem = dataArray.find(({id}) => id === this.form[formKey]);
                this.form[formKey] = currentItem ? currentItem.id : null;
                if(currentItem) return
                this.form[formKey] = null
            }
        },
        setSellerCompanyId() {
            if (this.defaultSellerCompanyId) {
                this.form.seller_company_id = this.defaultSellerCompanyId
            } else if (this.allSellers?.length === 1) {
                this.form.seller_company_id = this.allSellers[0].id
            } else this.restoreOrderFormData('seller_company_id')
        },
        get1CIntegrationSettings() {
            httpService().post(this.api.mixOrderGroup.getCheckOneCIntegration, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then(({data}) => {
                if (data.success) {
                    this.requiredSettingsFor1C = data.value;
                }
            }).catch((error) => {
                this.loading = false
                this.$message({
                    message: error.response.data.message,
                    type: 'error'
                })
            })
        },
        getTabLabel(tabName) {
            let fields = [...this.tabFields[tabName]];

            // Добавляет поля, при определенных условиях
            if (tabName === 'delivery' && this.form.delivery_type === 'delivery') {
                fields.push('delivery_address_id', 'first_order_time_delivery', 'time_unloading', 'type_delivery')
            }
            if (tabName === 'delivery' && this.form.type_delivery === 'withIntervalClient') {
                fields.push('time_interval_client');
            }
            if (tabName === 'delivery' && this.form.type_delivery === 'intensity') {
                fields.push('intensity');
            }
            if (tabName === 'price' && this.requiredSettingsFor1C) {
                fields.push('payment_method')
            }
            if (tabName === 'other' && this.form.is_lab && !this.form.lab) {
                fields.push('spec_good_id')
            }
            if (tabName === 'pumps' && this.form.pump) {
                fields.push('pumps')
            }

            // Проверяем, отдельно для заводов и миксеров как массивы
            if (tabName === 'mixers' && this.form.custom_mixers_enabled) {
                return Array.isArray(this.form.custom_mixers) && this.form.custom_mixers.length === 0
                    ? `<span style="color: #f56c6c;">*</span> ${this.tabLabels[tabName]}`
                    : this.tabLabels[tabName];
            }
            if (tabName === 'mixes') {
                return Array.isArray(this.form.mixer_ids) && this.form.mixer_ids.length === 0
                    ? `<span style="color: #f56c6c;">*</span> ${this.tabLabels[tabName]}`
                    : this.tabLabels[tabName];
            }
            if (tabName === 'pumps' && this.form.pump) {
                return Array.isArray(this.form.pumps) && this.form.pumps.length === 0
                    ? `<span style="color: #f56c6c;">*</span> ${this.tabLabels[tabName]}`
                    : this.tabLabels[tabName];
            }

            // Проверяем, есть ли пустое поле среди указанных для вкладки
            return fields.some((field) => !this.form[field])
                ? `<span style="color: #f56c6c;">*</span> ${this.tabLabels[tabName]}`
                : this.tabLabels[tabName];
        },
        setCarrierCompanyId() {
            if (this.defaultCarrierCompanyId) { // если есть перевозчик по умолчанию
                this.form.carrier_company_id = this.defaultCarrierCompanyId
            } else if (this.allCarriers?.length === 1) {  // если есть перевозчик в системе единственный
                this.form.carrier_company_id = this.allCarriers[0]?.id
            } else this.restoreOrderFormData('carrier_company_id') // данные из getLast

        },
        setPrice() {
            const getPrice = () => {
                const { contractData, form, defaultFormData } = this;

                if (!contractData?.id || !contractData?.goods?.length) {
                    return form?.good?.price
                        ?? (form.good_id === defaultFormData.good_id ? defaultFormData.good_price : null);
                }

                const contractGood = contractData.goods.find(g => g.id === form.good_id);

                return contractGood?.price ?? form?.good?.price ?? (form.good_id === defaultFormData.good_id ? defaultFormData.good_price : null);
            };

            this.updatePrice(getPrice());
        }
    },
    watch: {
        'form.buyer_company_id': {
            handler: async function (value) {
                // TODO при переходе запускается пересчет в форм селекте и там компания идет с null?
                if (this.buyerCompanyId !== value) {
                    if(!this.updateMode) {
                        this.$emit('updateBuyerCompanyId', value)
                        this.changeBuyer()
                    }
                }

                if (this.updateMode) {
                    this.form.contract_id = (value === this.defaultFormData.buyer_company_id) ? this.defaultFormData.contract_id : null;
                }

                if (!value) {
                    this.loadDispatchInfo = false
                    this.form.delivery_address_id = null
                    this.activeTab = 'delivery'
                }

                if (value) {
                    this.pullCompanyManagers(value)
                    if (!this.loadDispatchInfo) {
                        if (this.user && !this.form.manager_id) this.form.manager_id = this.user.id
                        this.loadDispatchInfo = true
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.form = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'form.contract_id': {
            handler: function (val, prevVal) {
                if(!val) {
                    this.resetContractData();
                    this.byContract = false;

                    if(!this.updateMode) {
                        this.setPrice()
                        this.setSellerCompanyId();
                    }
                    if(this.updateMode && !this.form.good_id) {
                        this.form.good_id = this.defaultFormData.good_id;
                        this.form.good = this.defaultFormData.good;
                        this.form.good_price = this.defaultFormData.good_price;
                    }
                    if(!this.updateMode || (this.updateMode && !!prevVal && !val && !!this.form.good_id)) {
                        this.setPrice()
                    }
                } else {
                    if(val !== prevVal) {
                        this.getContractById(val)

                        if (this.payment_type) {
                            this.selectedContract = this.documentsByType.find(el => el.id === val) ?? []
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'form.invoice_id': {
            handler: function (val, prevVal) {
                if(!val) {
                    this.resetContractData('invoice');
                    this.byInvoice = false;

                    if(this.updateMode && !this.form.good_id) {
                        this.form.good_id = this.defaultFormData.good_id;
                        this.form.good = this.defaultFormData.good;
                        this.form.good_price = this.defaultFormData.good_price;
                    }
                } else {
                    if(val !== prevVal) {
                        this.getContractById(val, 'invoice')

                        if (this.payment_type) {
                            this.selectedContract = this.documentsByType.find(el => el.id === val) ?? []
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'form': {
            handler: function () {
                this.$emit('update:modelValue', this.form)
            },
            deep: true,
            immediate: true
        },
        contractData: {
            handler: function (contract) {
                 if (contract?.id) {
                    const { addresses } = contract;

                    this.setFormData(addresses, 'delivery_address_id')

                    const { mix, downtime, pump } = contract.services || {};

                    this.setFormData(mix, 'mix_service_tariff_id')
                    this.setFormData(downtime, 'downtime_service_tariff_id')
                    this.setFormData(pump, 'pump_service_tariff_id')
                }
            },
            deep: true,
            immediate: true
        },
        'form.good_id': {
            handler: function (val) {
                if(val) {
                    this.getDefaultSpec(val)
                    if(!this.updateMode ||
                        (this.updateMode
                        && (this.form.good_id !== this.defaultFormData.good_id ||
                        this.form.good_id === this.defaultFormData.good_id
                        && this.form.good_price !== this.defaultFormData.good_price))) {

                        this.setPrice()
                    }
                    if(this.contractData?.id) {
                        const documentGood = this.contractData.goods?.find((el) => el.id == val)

                        this.byContract = documentGood ? true : false
                    }
                    if(this.invoiceData?.id) {
                        const documentGood = this.invoiceData.goods?.find((el) => el.id == val)

                        this.byInvoice = documentGood ? true : false
                    }
                }
                if(!val) {
                    this.getDefaultSpec(val)
                    this.form.good_price = null;
                    this.form.good = null
                }
            },
            deep: true,
            immediate: true
        },
        'defaultCarrierCompanyId': {
            handler: function (value) {
                if(!this.form.carrier_company_id && !this.updateMode && value) {
                    this.form.carrier_company_id = value
                }
            },
            deep: true,
            immediate: true
        },
        'defaultSellerCompanyId': {
            handler: function (value) {
                if(!this.form.seller_company_id && !this.updateMode && value) {
                    this.form.seller_company_id = value
                }
            },
            deep: true,
            immediate: true
        },
        allSellers: {
            handler: function () {
                if (this.allSellers?.length === 1 && !this.defaultSellerCompanyId && !this.updateMode) {
                    this.form.seller_company_id = this.allSellers[0]?.id
                }
            },
            deep: true,
            immediate: true
        },
        allCarriers: {
            handler: function () {
                if (this.allCarriers?.length === 1 && !this.defaultCarrierCompanyId && !this.updateMode) {
                    this.form.carrier_company_id = this.allCarriers[0]?.id
                }
            },
            deep: true,
            immediate: true
        },
        'form.lab': {
            handler: function (value) {
                if(value) {
                    this.form.spec_good_id = null
                } else if(!value && this.default_spec_info.id && this.default_spec_info.actual) {
                    this.form.spec_good_id = this.default_spec_info.id
                }
            }
        },
        'oneCIntegrations': {
            handler: function (val) {
                if (val?.length === 1) {
                    this.oneCIntegrationUnitId = this.oneCIntegrations[0]?.id
                }
            },
            deep: true,
            immediate: true
        },
        'form.date_shipment': {
            handler: function (val, prevVal) {
                if(val && prevVal && val !== prevVal) {
                    this.form.custom_mixers = []
                }

                if(val && prevVal && val === this.defaultFormData.date_shipment && this.updateMode) {
                    this.form.custom_mixers = this.defaultFormData.custom_mixers
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@use '@/sass/_variables.scss';

.selectedMixes {
    color: #393F4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.mix-action {
    align-items: center;
}

.mix-section {
    display: flex;
    justify-content: space-between;
}

.mix-load {
    color: #BD2626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: 14px;
}

.dispatchMixersTable:deep(.el-table) {
    margin-top: 5px;
}

.time-delivery-input {
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;


    &.is-required label::before {
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
        display: block;
        font-weight: 400 !important;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
    }

    label {
        width: 185px;
        color:  variables.$neutral400 !important;
        font-weight: 400 !important;
        position: relative;
        justify-content: flex-start;
        display: flex;
        font-size: 12px;
        font-family: 'Ubuntu', sans-serif;
    }

    input {
        border-color: #c9ccd0;
        padding: 1px 11px;
        transform: translateZ(0);
        box-shadow: 0 0 0 1px variables.$neutral50 inset !important;
        background: variables.$neutral30 !important;
        transition: 0s !important;
        border: none;
        border-radius: 4px;
        flex: 1;
        outline: none !important;
        height: inherit;
        font-size: 13px;
        font-family: Arial;
        color: #22252c;
    }
}
</style>
