<template>
    <article class="guide-step">
        <div class="guide-step__container">
            <div class="guide-step__round">
                {{ number }}
            </div>
            <p class="guide-step__text">
                {{text}}
                <slot v-if="!text" name="text"></slot>
            </p>
        </div>
        <img class="guide-step__img" v-if="src" :src="src" alt="screenshot">
    </article>
</template>

<script>

export default {
    name: 'GuideStep',
    props: ['text', 'src', 'number'],
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-step {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: variables.$white;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 100%;
    max-width: 100%;

    &__container {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__round {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        background: variables.$primary100;
        border-radius: 50%;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: variables.$primary600;
    }

    &__text {
        margin-block: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgb(0 0 0);
    }

    &__img {
        border-radius: 12px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        width: 100%;
        height: auto;
        aspect-ratio: 489 / 280;
    }
}
</style>
