<template>
    <div>
        <template v-if="!addNewDriverForm">
            <div class="d-flex-full">
                <el-input
                    class="searchNew w-100 mr-10"
                    v-model="search"
                    :prefix-icon="icons.search"
                    placeholder="Поиск" clearable/>
                <el-button
                    type="info"
                    class="mr-10"
                    @click="changeDriver(null)"
                    :icon="icons.remove"
                    plain
                    style=" height: 46px; width: 46px; border-radius: 8px;"/>
                <el-button
                    type="primary"
                    class="ml-0"
                    @click="openAddNewDriverForm()"
                    :icon="icons.plus"
                    style=" height: 46px; width: 46px; border-radius: 8px;"/>
            </div>
            <el-table
                :data="displayData"
                class="w-100 mt-15 mb-15 scrollTable selectDialogDriver">
                <el-table-column
                    prop="name"
                    label=""
                />
                <el-table-column
                    fixed="right"
                    label=""
                    width="120px">
                    <template v-slot="scope">
                        <el-button
                            size="small"
                            class="w-100"
                            @click="changeDriver(scope.row.id)"
                        >
                            Выбрать
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                @action="setPage"
            />
        </template>
        <template v-if="addNewDriverForm">
            <el-form ref="form" size="small" label-position="top">
                <FormInput
                    v-model="form.name"
                    label="ФИО"
                    placeholder="Пр.: Семенов Олег Владимирович"
                />
                <FormInput
                    v-model="form.phone"
                    label="Телефон водителя"
                    placeholder="Пр.: +7 903 111-11-11"
                    inputMask="+7 ### ###-##-##"
                />
            </el-form>
            <el-button @click="createDriver()" :disabled="!form.name || !form.phone" type="primary" size="large" class="w-100">
                Добавить и выбрать водителя
            </el-button>
        </template>
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import {httpService} from "@/services/http.service";

export default {
    name: "SelectDialogDrivers",
    components: {FormInput, TablePagination},
    props: ['items', 'type'],
    mixins: [tableMixin, iconsMixin],
    inject: ['api'],
    data() {
        return {
            addNewDriverForm: false,
            form: {
                name: null,
                phone: null
            },
        }
    },
    created() {
        //TODO фильтр водителей по их занятости в авто или нет
        //this.values = this.items.filter(el => el.free)
        this.values = this.items
    },
    methods: {
        changeDriver(id) {
            this.$emit('changeDriver', id)
        },
        openAddNewDriverForm() {
            this.addNewDriverForm = !this.addNewDriverForm
        },
        createDriver() {
            this.loading = true

            httpService().post(this.api.dispatch.mixers.createDriver, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: this.form,
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Водитель добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('changeDriver', data.driver.id)
                } else {
                    this.loading = false
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
    }
}
</script>

<style scoped lang="scss">
    .selectDialogDriver {
        &:deep(.el-table__row) {
            &:hover {
                &, .el-table__cell {
                    background: #f9f9f9;
                    transition: all .3s;
                }
            }
        }
    }
</style>
