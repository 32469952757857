<template>
    <div class="d-flex f-direction-column gap-20">
        <div v-if="orderGroups.length" class="manage-deliveries__orders">
            <div class="el-table ordersTable">
                <h3 class="textLG textMedium neutral700 m-0">Перенаправление отгрузки на другой заказ</h3>
                <table class="el-table__body w-100 mt-20" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th class="el-table__th--radio">
                            <div class="cell textXS textRegular neutral200"/>
                        </th>
                        <th class="el-table__th--order">
                            <div class="cell textXS textRegular neutral200">
                                Заказ
                            </div>
                        </th>
                        <th class="el-table__th--good">
                            <div class="cell textXS textRegular neutral200">
                                Продукция
                            </div>
                        </th>
                        <th class="el-table__th--else">
                            <div class="cell textXS textRegular neutral200"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="order in displayData" :key="order.id">
                        <tr class="el-table__row" :class="{'ordersTable__row--active': order.id === selectedOrder}">
                            <td class="el-table__cell--radio" style="vertical-align: middle">
                                <el-radio type="radio" v-model="selectedOrder" :value="order.id"></el-radio>
                            </td>
                            <td>
                                <div class="cell d-flex f-direction-column gap-4">
                                    <div class="ordersTableItem">
                                        <span class="group-orders-status-text group-orders-status-text-small mr-5"
                                            :class="'group-orders-status-text-' + order?.status">
                                            {{ getInfo(order?.status, mixOrderGroupStatuses) }}
                                        </span>
                                    </div>
                                    <span
                                        class="textRegular400 textSM neutral900 d-flex-full-stroke group-orders-info"
                                        style="text-wrap: nowrap;">
                                        <span>№ {{ order?.id }}</span>
                                      <svg class="buyer-circle" width="5" height="4" viewBox="0 0 5 4" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z" fill="black"/>
                                        </svg>
                                        <span class="cutOverflow">
                                            {{ order.company ?? '-' }}
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td style="vertical-align: middle;">
                                <div class="cell d-flex f-direction-column gap-4">
                                    <div class="textRegular textSM neutral900 cutOverflow">
                                        {{ order.good }}
                                    </div>
                                </div>
                            </td>
                            <td style="vertical-align: middle;">
                                <div class="cell">
                                    <div class="ordersTableItem neutral600 d-flex gap-8 d-flex-end">
                                        <div class="ordersTableItem" style="width: 170px">
                                            <OrderQuantity :orders="order.orders" :total="order.total"/>
                                        </div>
                                        <el-tooltip effect="dark" placement="top" content="Перейти в подробности по заказу">
                                            <router-link target="_blank" :to="{ name: 'DispatchOrderGroupsDetails', params: { groupId: order.id } }">
                                                <el-button @click="openMixOrderDetails(order)" size="small" style="height: 24px !important; position: relative; z-index: 2;">
                                                    i
                                                </el-button>
                                            </router-link>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>

            <TablePagination
                v-if="paginate"
                v-model="pageSize"
                :total="orderGroups?.length"
                :disable-size="true"
                @action="setPage"
            />
        </div>
        <el-checkbox
            class="redirectOrderModal__checkbox" :style="{ marginTop: paginate ? '-55px' : '0' }"
            v-model="removeOrderFromCurrentOrderGroup"
            :disabled="loading">
            Убрать отгрузку из объема текущего заказа
        </el-checkbox>
        <div class="d-flex">
            <el-button plain :disabled="loading" size="large" @click="cancel">
                Отменить
            </el-button>
            <el-button type="primary" :disabled="loading || !selectedOrder" size="large" @click="submitRedirectOrder">
                Подтвердить
            </el-button>
        </div>
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderGroupStatus from "@/mixins/mixOrderGroupStatus";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import {httpService} from '@/services/http.service'
import OrderQuantity from "@/views/dispatch/components/OrderQuantity.vue";

export default {
    name: 'RedirectOrderModal',
    components: {
        TablePagination, OrderQuantity
    },
    mixins: [tableMixin, functionsMixin, mixOrderGroupStatus],
    props: ['orderGroups', 'order'],
    data() {
        return {
            isTakeAway: false,
            pageSize: 5,
            selectedOrder: null,
            removeOrderFromCurrentOrderGroup: false,
            loading: false
        }
    },
    computed: {
        paginate() {
            return this.orderGroups?.length > this.pageSize
        }
    },
    methods: {
        filterValues() {
            this.filtered = this.orderGroups
            if (this.filterStatus) {
                this.filtered = this.filtered.filter(el => el.status === this.filterStatus)
            }
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        cancel() {
            this.$emit('cancel');
            this.selectedOrder = null;
        },
        submitRedirectOrder() {
            this.loading = true
            httpService().post(this.api.mixOrder.changeMixOrderGroup, {
                "old_group_id": this.order.order_group_id,
                "new_group_id": this.selectedOrder,
                "mix_order_id": this.order.id,
                "recalculation": this.removeOrderFromCurrentOrderGroup
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    this.$message({
                    message: 'Отгрузка успешно перенаправлена',
                    showClose: true,
                    type: 'success'
                })
                    this.$emit('close', this.selectedOrder)
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.ordersTable {

    .el-table {
        &__th {
            &--radio {
                width: 50px;
            }

            &--order {
                width: 50%;
                padding-right: 12px;
            }

            &--good {
                width: 50%;
                padding-right: 12px;
            }

            &--else {
                width: 260px;
            }
        }

        &__cell {
            &--radio {
                &:deep(.el-radio) {
                    margin-left: 16px;
                    margin-right: 16px;
                    position: static;

                    &:hover .el-radio__inner {
                        border-color: variables.$brand;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        cursor: pointer;
                    }

                    .el-radio__inner {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

    &:deep(.el-table__row) {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: variables.$neutral50;
        }
    }

    &Item {
        &__button--active {
            background: variables.$primary50;
            color: variables.$primary600;
            border-color: variables.$primary200;
        }
    }

    &__row {
        &--active {
            background: variables.$light-grey;;
        }
    }
}

.redirectOrderModal__checkbox {
    max-width: fit-content;
    margin-top: -10px;

    &:deep(.el-checkbox__label) {
        font-weight: 400;
    }
}
</style>
