<template>
  <svg :class="[{'currentColor' : currentColor}]"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 5L18 19" stroke="#151517" :stroke-width="strokeWidth ?? 2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 12L6 5V19L18 12Z" stroke="#151517" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "SkipForwardIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
