<template>
    <template v-if="from === 'MixOrderDetailsGroupPrint'">
        <MixOrderDetailsGroupPrint
            :orderGroup="orderGroupItem"
            :mix-order="currentOrder"
            :accessDocuments="accessDocuments"
            @get="$emit('get')"
            @close="$emit('close')"
            @edit="$emit('edit')"
            @print="$emit('print')"
        />
    </template>
    <template v-if="from === 'MixOrderGroupOrdersPrinter'">
        <MixOrderGroupOrdersPrinter
            :table-data="orders"
            :date="date"
        />
    </template>
    <template v-if="from === 'printDocs'">
        <PrintDocs
            :orderGroupItem="orderGroupItem"
            :orders="orders"
            :accessDocuments="accessDocuments"/>
    </template>
    <template v-if="from === 'operator'">
        <OperatorPrintDocs
            :orders="orders"
            :currentOrder="currentOrder"
            :mixId="mixId"
            :date="date"
            :accessDocuments="accessDocuments"
            :tz="tz"
        />
    </template>
</template>
<script>

import OperatorPrintDocs from "@/views/components/Dashboards/Operator/OperatorPrintDocs.vue";
import PrintDocs from "@/views/dispatch/components/PrintDocs.vue";
import MixOrderDetailsGroupPrint from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupPrint.vue";
import MixOrderGroupOrdersPrinter from "@/views/components/MixPage/OrdersGroup/MixOrderGroupOrdersPrinter.vue";

export default {
    name: 'AllPrintDocs',
    components: {MixOrderGroupOrdersPrinter, MixOrderDetailsGroupPrint, PrintDocs, OperatorPrintDocs},
    props: ['from', 'orders', 'mixId', 'date', 'tz', 'currentOrder', 'accessDocuments', 'orderGroupItem', 'hideOrders'],
    data() {
        return {}
    },
}
</script>
