<template>
    <svg :class="[{'currentColor' : currentColor}]"
        xmlns="http://www.w3.org/2000/svg"
        width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
        xml:space="preserve" class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="30" cy="38" r="24" fill="" opacity="1"></circle><path
        d="M24 2h12v5H24zM30 7v7M50.59 16.41l-4.13 4.13M47.181 13 54 19.819" fill=""
        opacity="1"></path><circle cx="30" cy="38" r="4" fill="" opacity="1"></circle><path
        d="M30 34V22M30 42v2M44 38h2M16 38h-2M30 52v2" fill="" opacity="1"></path></g></g>
    </svg>
</template>

<script>
export default {
    name: "DowntimeServiceIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path, circle {
            stroke: currentColor;
        }
    }
</style>
