<template>
    <Auth :hideTitle="true">
        <div>
            <template v-if="detailedOrder">
                <NewMixDriverTaskDetailed
                    @close-order="closeOrder"
                    :order="detailedOrder"
                    @change-status="changeStatus"
                    :actual-order="actualOrder"/>
            </template>

            <template v-else>
                <div class="header d-flex justify-between gap-12 mb-18">
                    <SwitchButton v-model="activeTab" @change-tab="changeTab" :items="statuseses"/>
                    <CustomDatePicker v-model="dateFilter"/>
                </div>

                <div v-if="activeTab === 'Очередь'" v-loading="loading" class="d-flex d-flex-direction-column gap-8">

                    <NewMixDriverTaskActual
                        class="pointer"
                        v-if="actualOrder"
                        @click="openOrder(actualOrder)"
                        :order="actualOrder"
                    />

                    <Label
                        v-if="mixOrders.length > 0"
                        class="m-auto"
                        text="В очереди"
                        :isRounded="true"/>

                    <template v-if="mixOrders.length > 0">
                        <NewMixDriverTask
                            class="pointer"
                            v-for="(order, index) in mixOrders.filter((el) => el.confirm)"
                            @click="openOrder(order)"
                            :key="index"
                            :order="order"/>
                        <NewMixDriverTask
                            class="pointer"
                            v-for="(order, index) in mixOrders.filter((el) => !el.confirm)"
                            @click="openOrder(order)"
                            :key="index"
                            :order="order"/>
                    </template>
                </div>

                <div v-if="activeTab === 'Выполненные' && mixOrdersDone?.length > 0"
                     v-loading="loading"
                     class="d-flex"
                     style="flex-direction: column; gap: 8px"
                >
                    <NewMixDriverTask
                        class="pointer"
                        v-for="(order, index) in mixOrdersDone"
                        @click="openOrder(order)"
                        :key="index"
                        :order="order"/>
                </div>

                <div
                    v-show="showNoOrdersBanner"
                    class="no-orders-section">
                    <NewNoOrdersBanner
                        :active-tab="activeTab"
                        :mix-orders="mixOrders"
                        :mix-orders-done="mixOrdersDone"/>
                </div>
            </template>
        </div>
    </Auth>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import {useMixDriverStore} from "@/store/mixDriver";
import moment from "moment";
import functionsMixin from "../../../../mixins/functionsMixin";
import mixOrderInfo from "../../../../mixins/mixOrderInfo";

import NewMixDriverTask from "./NewMixDriverTask.vue";
import NewMixDriverTaskActual from "./NewMixDriverTaskActual.vue";
import NewNoOrdersBanner from "./NewNoOrdersBanner.vue";
import NewMixDriverTaskDetailed from "./NewMixDriverTaskDetailed.vue";

import Label from "@/views/components/Label/Label.vue";
import SwitchButton from "@/views/components/SwitchButton.vue";
import CustomDatePicker from "@/views/components/CustomDatePicker.vue";
import {httpService} from "@/services/http.service";
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: "NewMixDriverDashboard",
    components: {
        Auth,
        NewMixDriverTask,
        NewMixDriverTaskActual,
        NewNoOrdersBanner,
        Label,
        SwitchButton,
        CustomDatePicker,
        NewMixDriverTaskDetailed,
    },
    inject: ["api"],
    mixins: [functionsMixin, mixOrderInfo],
    data() {
        return {
            activeTab: "Очередь",
            statuseses: ["Очередь", "Выполненные"],
            loading: false,
            detailedOrder: null,
            mixOrders: [],
            actualOrder: null,
            mixOrdersDone: [],
            dateFilter: moment().format("YYYY-MM-DD"),
            showNoOrdersBanner: false
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            user: "getUser",
            modules: 'getModules'
        }),
    },
    async created() {
        await this.getDriverApp();

        window.Echo.channel('laravel_database_ChannelDriverMixOrders')
            .listen(`.DriverId.${this.user.id}`, () => {
                this.getDriverApp();
            })
    },
    beforeCreate: function () {
        document.body.className = "mix-driver-bg";
    },
    beforeUnmount: function () {
        document.body.className = "";
        window.Echo.leave(`laravel_database_ChannelDriverMixOrders`);
    },
    methods: {
        ...mapActions(useMixDriverStore, ["setDateFilter"]),
        async getDriverApp() {
                try {
                    const {data} = await httpService().post(this.api.driverApp.index, {
                        account_modules_id: this.$route.params.id,
                        module_key: this.$route.meta.moduleKey,
                        id: this.user.id,
                        filter_date: this.dateFilter,
                    });

                    const {orders, actualOrder, mixOrdersDone} = data;
                    this.mixOrders = orders;
                    this.actualOrder = actualOrder;
                    this.mixOrdersDone = mixOrdersDone;
                    this.updateShowNoOrdersBanner();

                    if (this.detailedOrder) {
                        this.updateDetailedOrder(actualOrder, orders, mixOrdersDone);
                    }
                } catch (error) {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: "error",
                    });
                }
        },
        changeStatus(mixOrder) {
            this.loading = true;

            httpService().post(this.api.driverApp.changeStatus, {
                id: mixOrder.id,
                mix_id: mixOrder.mix_id,
                status: this.getNextStatus(mixOrder.status).key,
            }).then(({data}) => {
                if (data.success) {
                    this.$message({
                        message: 'Статус изменен',
                        showClose: true,
                        type: 'success',
                    });
                }
                this.loading = false;
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: "error",
                });
                this.loading = false;
            })
        },
        openOrder(order) {
            this.detailedOrder = order
        },
        closeOrder() {
            this.detailedOrder = null
        },
        changeTab(tabValue) {
            this.activeTab = tabValue;
            this.updateShowNoOrdersBanner();
        },
        updateShowNoOrdersBanner: function () {
            if (this.loading) this.showNoOrdersBanner = false

            if (this.activeTab === 'Очередь') {
                if (!this.actualOrder && !this.mixOrders.length) {
                    this.showNoOrdersBanner = true
                }
            } else if (this.activeTab === 'Выполненные' && !this.mixOrdersDone.length) {
                this.showNoOrdersBanner = true
            }
        },
        updateDetailedOrder(actualOrder, orders, mixOrdersDone) {
            if (actualOrder && this.detailedOrder.id === actualOrder.id) {
                this.openOrder(actualOrder);
                return;
            }

            const allOrders = [...orders, ...mixOrdersDone];
            const currentOrder = allOrders.find(order => order.id === this.detailedOrder.id);

            if (currentOrder) {
                this.openOrder(currentOrder);
                return;
            }

            this.closeOrder();
        },
        getNextStatus(currentKey) {
            // Находим индекс текущего статуса
            const currentIndex = this.statuses.findIndex(status => status.key === currentKey);

            return this.statuses[currentIndex + 1]; // Возвращаем следующий элемент
        },
    },
    watch: {
        'dateFilter': {
            handler: function () {
                this.getDriverApp();
            }
        },
    },
};
</script>
