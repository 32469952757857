<template>
    <article class="guide-callout">
        <div class="guide-callout__round">
            <Warning width="24" height="24" />
        </div>
        <p class="guide-callout__text">{{text}}</p>
    </article>
</template>

<script>
import { Warning } from '@element-plus/icons-vue'

export default {
    name: 'GuideCallout',
    props: ['text'],
    components: { Warning },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-callout {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid variables.$success300;
    background: variables.$success50;
    border-radius: 16px;
    width: 100%;
    max-width: 100%;

    &__round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: variables.$success100;
        border-radius: 50%;

        svg {
            color: variables.$success500;
        }
    }

    &__text {
        margin-block: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgb(0 0 0);
    }
}
</style>
