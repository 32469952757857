<template>
    <Auth :title="title" :tabs="tabs" :settings="$route.params.id">
        <template v-slot:additionalButtons>
            <div class="d-flex justify-between flex-1">
                <DispatchOrderHeaderButton
                    :to="{ name: 'DispatchOrderGroupsCreate', params: { redirectedFrom: 'DispatchMixers' }}"
                    />
                <EventButtons
                    :account-events="accountEvents"
                    :dialog="eventDialogOpened"
                    v-model="selectedEventTab"
                    @clickOpenDialogButton="toggleEventDialogOpened"
                    @readAllEvents="readAllEvents"
                />
            </div>
        </template>
        <div class="card">
            <div class="card-body">
                <div class="dispatchMixers">
                    <DispatchPostsTable v-if="this.$route.name === 'DispatchPosts'"/>
                    <DispatchMixersTable v-else :type="type"/>
                </div>
                <!-- <EventList
                    :selected-tab="selectedEventTab"
                    class="eventList"
                    :account-events="accountEvents"
                    @update-events="updateEvents"
                    :mix-order-groups="orderGroupsForEvents"
                    :loading="loading"
                /> -->

                <EventModal :account-events="accountEvents" :modelValue="eventDialogOpened"
                            :selectedEventTab="selectedEventTab" @update:modelValue="eventDialogOpened = $event"
                            @update-events="updateEvents" @readAllEvents="readAllEvents"
                            :mix-order-groups="orderGroupsForEvents" :loading="loadingEvents"/>
            </div>
        </div>
        <EventNotification ref="notification" @update-events="updateEvents" :mix-order-groups="values"/>
        <el-dialog
            class="group-order-action-dialog"
            v-model="orderDetailsDialog"
            title=""
            :width="dialogSize"
            :destroy-on-close="true"
            @close="closeOrderDetailsDialog"
        >
            <MixOrderDetailsGroup
                :mix-order-id="selectedMixOrderId"
                :order-group="selectedMixOrderGroup"
                @close="closeOrderDetailsDialog"
                @get="$emit('get')"
                @update-order="updateOrder"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import mobileCheckMixin from '../../mixins/mobileCheckMixin';
import {httpService} from "@/services/http.service";
import {mapActions, mapState} from 'pinia'
import {useOrderGroupsStore} from '@/store/orderGroups'
import DispatchMixersTable from "@/views/dispatch/DispatchMixersTable.vue";
import DispatchPostsTable from "@/views/dispatch/DispatchPostsTable.vue";
import EventModal from "@/views/components/Events/EventModal.vue";
import EventButtons from '../components/Events/EventButtons.vue';
import EventNotification from '@/views/components/Events/EventNotification.vue'
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import DispatchOrderHeaderButton from "@/views/dispatch/components/buttons/DispatchOrderHeaderButton.vue";

export default {
    name: 'DispatchMixers',
    mixins: [dispatchTabs, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            title: this.$route.meta.title,
            type: this.$route.name === 'DispatchMixers' ? 'mix' : 'pump',
            eventDialogOpened: false,
            accountEvents: [],
            selectedEventTab: 'new',
            orderDetailsDialog: false,
            selectedMixOrderId: null,
            selectedMixOrderGroup: null,
            loading: false,
            loadingEvents: false,
        }
    },
    components: {
        DispatchMixersTable,
        DispatchPostsTable,
        Auth,
        EventModal,
        EventButtons,
        EventNotification,
        MixOrderDetailsGroup,
        DispatchOrderHeaderButton
    },
    created() {
        this.getEvents();
        this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, this.filters)
        window.Echo.channel('laravel_database_ChannelAccountEvent')
            .listen(`.Create.${this.$route.params.id}`, (data) => {
                const newEvent = {...data.event, tz: data.tz ? data.tz : 'Europe/Moscow'};
                const isCountBehindByOne = this.accountEvents.length === data.eventsCount - 1;
                const isCountBehind = this.accountEvents.length < data.eventsCount;

                if (isCountBehindByOne) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.accountEvents.push(newEvent);
                } else if (isCountBehind) {
                    if (this.windowWidth < 1920 && !this.eventDialogOpened) {
                        this.showNotification(newEvent);
                    }
                    this.getEvents();
                }
            })
            .listen(`.Update.${this.$route.params.id}`, (data) => {
                let oldEvent = this.accountEvents.find((el) => el.id === data.event.id);

                if (oldEvent && oldEvent.status !== data.event.status) {
                    oldEvent.status = data.event.status
                }
            });
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelAccountEvent`);
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            orderGroupsForEvents: 'getOrderGroupsForEvents',
            filters: 'getFilters',
        })
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups']),
        toggleEventDialogOpened() {
            this.eventDialogOpened = !this.eventDialogOpened
        },
        getEvents() {
            this.loadingEvents = true

            httpService().post(this.api.accountEvents.index, {}).then(({data}) => {
                if (data.success) {
                    this.accountEvents = data.accountEvents.map((el) => {
                        return {...el, tz: data.tz ? data.tz : 'Europe/Moscow'}
                    })
                    this.loadingEvents = false
                }
            }).catch((error) => {
                this.loadingEvents = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        showNotification(event) {
            this.$refs.notification.addNotification({
                event: event,
            });
        },
        readAllEvents() {
            httpService().post(this.api.accountEvents.readAll, {}).then(() => {
                this.getEvents()
            })
        },
        updateEvents(mixOrderGroup, mixOrderId) {
            this.selectedMixOrderGroup = mixOrderGroup;
            this.selectedMixOrderId = mixOrderId
            this.orderDetailsDialog = true
            this.getEvents()
        },
        closeOrderDetailsDialog() {
            this.unlockOrder();
            this.orderDetailsDialog = false
            this.selectedMixOrderId = null
            this.selectedMixOrderGroup = null
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        updateOrder(id, order) {
            this.selectedMixOrderId = id
            this.selectedMixOrderGroup = order
        }
    },
    watch: {
        $route() {
            this.title = this.$route.meta.title
            this.type = this.$route.name === 'DispatchMixers' ? 'mix' : 'pump'
        }
    },
}
</script>

<style lang="scss">
//.eventList {
//    grid-area: eventList;
//    display: none;
//    border-left: 1px solid #eeeeee;
//
//    @media screen and (min-width: 1920px) {
//        display: block;
//    }
//}

.dispatchMixers {
    grid-area: dispatchMixers;
}
</style>

<style scoped lang="scss">
.card-body {
    position: relative;

    &__filters {
        padding: 12px 0;
    }

    //@media screen and (min-width: 1920px) {
    //    display: grid;
    //    gap: 10px;
    //    grid-template-columns: minmax(0, 1fr) 456px;
    //    grid-template-areas: "summary eventList" 'dispatchMixers eventList';
    //    grid-template-rows: auto 1fr;
    //}
}
</style>
