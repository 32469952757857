<template>
    <div class="p-relative">
        <div id="y-map-details" class="y-map"></div>
        <div class="pointInfo">
            <div class="pointInfoBody" v-if="pointInfo">
                <div class="textXS neutral200">
                    Время
                </div>
                <div class="textSM textMedium primary600">
                    {{ moment(pointInfo.date).format('HH:mm') }}
                </div>
                <hr/>
                <div class="textXS neutral200">
                    Скорость
                </div>
                <div class="textSM textMedium neutral900">
                    {{ pointInfo.speed }} км/ч
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, defineProps, onMounted, watch} from "vue";
import {loadYmap} from "vue-yandex-maps";
import moment from "moment";

const props = defineProps({
    coorLatFrom: {
        default: () => null,
    },
    coorLonFrom: {
        default: () => null,
    },
    coorLatTo: {
        default: () => null,
    },
    coorLonTo: {
        default: () => null,
    },
    vehicle: {
        default: () => null,
    },
    status: {
        default: () => null,
    },
    checkpoint: {
        default: () => 0
    }
});

let map = null;
let myPlacemark = null;

// computed
const pointInfo = computed(() => {
    return props.vehicle?.points && props.vehicle?.points?.length > 0 ? props.vehicle.points[props.checkpoint] : null
})

const init = async () => {
    const settings = {
        apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
    };
    await loadYmap({...settings, debug: true});
    // eslint-disable-next-line no-undef
    await ymaps.ready;

    // задаем центр карты по адресу доставки
    // eslint-disable-next-line no-undef
    map = new ymaps.Map(document.getElementById("y-map-details"), {
        center: [props.coorLatTo, props.coorLonTo],
        zoom: 13,
        controls: ['trafficControl'] // скрываем все контролы, включая кнопку слоев и поиска
    });

    displayMapContent();
};

const displayMapContent = () => {

    if (map) {
        map.geoObjects.removeAll();

        // в vehicle есть свойство glonass_route_points. внутри объект с latitude, longitude, date, speed
        // строим по нему маршрут на Яндекс.Карте
        if (props.vehicle) {
            if (props.vehicle && props.vehicle.points && props.vehicle.gps_active && props.status !== 'new') {
                // eslint-disable-next-line no-undef
                const routePoints = props.vehicle.points.map(point => [point.latitude, point.longitude]);

                // eslint-disable-next-line no-undef
                const polyline = new ymaps.Polyline(routePoints, {}, {
                    strokeColor: '#1984e6',
                    strokeWidth: 4,
                    strokeOpacity: 0.8
                });
                map.geoObjects.add(polyline);

                if (props.vehicle.latitude && props.vehicle.longitude) {

                    if (props.status === 'loading' || props.status === 'delivery') {
                        // если заявку в пути, то дорисовываем путь из яндекса до объекта
                        // eslint-disable-next-line no-undef
                        ymaps.route([
                            {
                                type: 'wayPoint',
                                point: [props.vehicle.latitude, props.vehicle.longitude]
                            },
                            {
                                type: 'wayPoint',
                                point: [props.coorLatTo, props.coorLonTo]
                            }
                        ]).then(function (route) {
                            map.geoObjects.add(route);
                            route.getPaths().options.set({
                                strokeColor: '#8a909a',
                                opacity: 0.8
                            })

                            route.getWayPoints().options.set({
                                visible: false
                            })
                        });
                    } else if (props.status === 'object' || props.status === 'unloading' || props.status === 'return') {
                        // если машина возвращается, то дорисовываем путь до завода
                        // eslint-disable-next-line no-undef
                        ymaps.route([
                            {
                                type: 'wayPoint',
                                point: [props.vehicle.latitude, props.vehicle.longitude]
                            },
                            {
                                type: 'wayPoint',
                                point: [props.coorLatFrom, props.coorLonFrom]
                            }
                        ]).then(function (route) {
                            map.geoObjects.add(route);
                            route.getPaths().options.set({
                                strokeColor: '#8a909a',
                                opacity: 0.8
                            })

                            route.getWayPoints().options.set({
                                visible: false
                            })
                        });
                    }

                }
            } else {
                if (props.coorLatFrom && props.coorLatTo) {


                    // eslint-disable-next-line no-undef
                    ymaps.route([
                        {
                            type: 'wayPoint',
                            point: [props.coorLatFrom, props.coorLonFrom]
                        },
                        {
                            type: 'wayPoint',
                            point: [props.coorLatTo, props.coorLonTo]
                        }
                    ], {
                        mapStateAutoApply: true
                    }).then(function (route) {
                        map.geoObjects.add(route);
                        route.getPaths().options.set({
                            strokeColor: '#8a909a',
                            opacity: 0.8
                        })

                        route.getWayPoints().options.set({
                            visible: false
                        })
                    });
                }
            }
        }

        // Выводим завод
        // eslint-disable-next-line no-undef
        const factoryPoint = new ymaps.Placemark([props.coorLatFrom, props.coorLonFrom], {
            iconContent: 'Завод'
        }, {
            preset: "islands#blueFactoryCircleIcon"
        });
        map.geoObjects.add(factoryPoint);

        // Выводим клиентский адрес
        // eslint-disable-next-line no-undef
        const clientPoint = new ymaps.Placemark([props.coorLatTo, props.coorLonTo], {
            iconContent: 'Объект'
        }, {
            preset: "islands#redGovernmentCircleIcon"
        });
        map.geoObjects.add(clientPoint);

        // Выводим транспортные средства на карте
        if (props.vehicle && props.vehicle.latitude && props.vehicle.longitude) {
            let lat = props.vehicle.latitude;
            let lon = props.vehicle.longitude;

            if (props.vehicle.points && props.vehicle.points.length > 0 && props.checkpoint) {
                lat = props.vehicle.points[props.checkpoint].latitude;
                lon = props.vehicle.points[props.checkpoint].longitude;
            }

            // eslint-disable-next-line no-undef
            myPlacemark = new ymaps.Placemark([lat, lon], {
                iconContent: props.vehicle.number
            }, {
                preset: `islands#${props.vehicle.color}StretchyIcon`
            });
            map.geoObjects.add(myPlacemark);
        }

        // центруем карту между объектом и заводом с отступом от краев
        // map.setBounds([[props.coorLatFrom, props.coorLonFrom], [props.coorLatTo, props.coorLonTo]], {
        //     checkZoomRange: true,
        //     zoomMargin: [20, 20, 20, 20] // добавляем отступ от краев
        // });
    }
};

watch(() => props.vehicle, () => {
    displayMapContent();
});

watch(() => props.checkpoint, () => {
    if (props.vehicle && props.vehicle.points && props.vehicle.points.length > 0) {
        const newPoint = props.vehicle.points[props.checkpoint];
        if (newPoint) {
            myPlacemark.geometry.setCoordinates([newPoint.latitude, newPoint.longitude]);
        }
    }
});

watch(() => props.status, (val, prevValue) => {
    if ((prevValue === 'new' || val === 'done') && props.vehicle) {
        displayMapContent();
    }
});

watch(() => [props.coorLatTo, props.coorLonTo, props.coorLatFrom, props.coorLonFrom],
    (val, prevValue) => {
        if (val[0] !== prevValue[0] || val[1] !== prevValue[1] || val[2] !== prevValue[2] || val[3] !== prevValue[3]) {
            displayMapContent();
    }
});


onMounted(() => init());
</script>

<style lang="scss" scoped>
.y-map {
    height: 85vh;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .y-map {
        padding-bottom: 12px;
        height: 20vh;
    }
}
</style>

<style lang="scss">
.yandex-container {
    width: 100%;
    height: 100%;
}
</style>
