<template>
    <Auth title="Подробности заказ-наряда" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <div v-if="serviceDetails && serviceDetails.name">

                    <div class="techWorkDetailsHeadBlock mb-15">
                        <div class="mb-15">
                            <div class="techWorkDetailsStatus">
                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle v-if="serviceDetails.statusId === 1 || serviceDetails.statusId === 2" cx="4"
                                            cy="4" r="4" fill="#afb3ba"/>
                                    <circle v-else-if="serviceDetails.statusId === 3" cx="4"
                                            cy="4" r="4" fill="#1984e6"/>
                                    <circle v-else-if="serviceDetails.statusId === 4" cx="4"
                                            cy="4" r="4" fill="#009272"/>
                                    <circle v-else-if="serviceDetails.statusId === 5" cx="4" cy="4" r="4"
                                            fill="#f74539"/>
                                </svg>
                                <span
                                    class="textSM textMedium"
                                    :class="[
      { danger300: serviceDetails.statusId === 5 },
      { success400: serviceDetails.statusId === 4 },
      { primary600: serviceDetails.statusId === 3 },
      { neutral200: serviceDetails.statusId === 2 || serviceDetails.statusId === 1 }
    ]"
                                >
      {{ serviceDetails.statusName }}
    </span>
                            </div>
                        </div>
                        <div>
                            <h2 class="p-0 mt-0 mb-5">{{ serviceDetails.name }}
                                ({{ getInfo(serviceDetails.type, techWorkTypes) }})</h2>
                        </div>
                    </div>

                    <div class="techDetailsInfoBlocks">

                        <TechServiceDetailsInfoBlock
                            v-if="techDetails"
                            top-text="ТС"
                            :main-text="techDetails.number"
                            :bottom-text="'Текущий пробег ' + formatNumber(serviceDetails.techActualMileage) + ' ' + serviceDetails.techUnit"
                        />

                        <TechServiceDetailsInfoBlock
                            v-if="serviceDetails.datePlan && !serviceDetails.dateDone"
                            top-text="Дата проведения работ"
                            :main-text="getDateFromUnix(serviceDetails.datePlan, 'DD.MM.YY')"
                        >
                            <template v-slot:bottomText>
                                <template v-if="serviceDetails.dateDiffNowPlan > 0">
                                    До работ осталось {{ serviceDetails.dateDiffNowPlan }} дн.
                                </template>
                                <template v-else-if="serviceDetails.dateDiffNowPlan === 0">
                                    <span class="color-error">!</span> Работы необходимо провести сегодня
                                </template>
                                <template v-else-if="serviceDetails.dateDiffNowPlan < 0">
                                    <span class="color-error">!</span> Выполнение работ просрочено на
                                    {{ Math.abs(serviceDetails.dateDiffNowPlan) }} дн.
                                </template>
                            </template>
                        </TechServiceDetailsInfoBlock>

                        <TechServiceDetailsInfoBlock
                            v-else-if="serviceDetails.datePlan && serviceDetails.dateDone"
                            :top-text="'Дата ' + (serviceDetails.statusId === 5 ? 'отмены ' : 'проведения ') + ' работ'"
                            :main-text="getDateFromUnix(serviceDetails.dateDone, 'DD.MM.YY')"
                            :bottom-text="(serviceDetails.statusId === 5 ? 'Отменены ' : 'Завершены ') + ' на пробеге ' + formatNumber(serviceDetails.indicatorDone) + ' ' + serviceDetails.techUnit"
                        />

                        <TechServiceDetailsInfoBlock
                            v-if="serviceDetails.indicatorPlan && !serviceDetails.indicatorDone && serviceDetails.statusId !== 5"
                            top-text="Проводится при наступлении"
                            :main-text="formatNumber(serviceDetails.indicatorPlan) + ' ' + serviceDetails.techUnit"
                        >
                            <template v-slot:bottomText>
                                <template
                                    v-if="serviceDetails.indicatorPlan >= serviceDetails.techActualMileage">
                                    До работ осталось {{
                                        formatNumber(serviceDetails.indicatorPlan - serviceDetails.techActualMileage)
                                    }} {{ serviceDetails.techUnit }}
                                </template>
                                <template
                                    v-else-if="serviceDetails.indicatorPlan < serviceDetails.techActualMileage">
                                    <span class="color-error">!</span> Работы просрочены на {{
                                        formatNumber(Math.abs(serviceDetails.indicatorPlan - serviceDetails.techActualMileage))
                                    }} {{ serviceDetails.techUnit }}
                                </template>
                            </template>
                        </TechServiceDetailsInfoBlock>

                        <TechServiceDetailsInfoBlock
                            v-if="serviceDetails.indicatorPlan && serviceDetails.indicatorDone"
                            :top-text="(serviceDetails.statusId === 5 ? 'Отменены ' : 'Проведены ') + ' на пробеге'"
                            :main-text="formatNumber(serviceDetails.indicatorDone) + ' ' + serviceDetails.techUnit"
                            :bottom-text="'Работы ' + (serviceDetails.statusId === 5 ? 'отменены ' : 'завершены ') + getDateFromUnix(serviceDetails.dateDone, 'DD.MM.YY')"
                        />

                    </div>

                    <template v-if="serviceDetails.statusId === 4">

                        <h3 class="p-0 mb-15 text-500">Отчет о проведенных работах</h3>

                        <p>{{ serviceDetails.report ?? '-' }}</p>

                    </template>

                    <template v-if="serviceDetails.statusId === 5">

                        <h3 class="p-0 mb-15 text-500">Причина отмены работ</h3>

                        <p>{{ serviceDetails.report ?? '-' }}</p>

                    </template>

                    <h3 class="p-0 mb-15 text-500">Описание работы</h3>

                    <p>{{ serviceDetails.description ?? '-' }}</p>

                    <ServiceCreateWorks
                        :disable-edit="serviceDetails.statusId === 4"
                        v-model="serviceDetails.serviceWorks"
                        :tech-work-id="serviceDetails?.id"
                    />

                    <ServiceCreateGoods
                        :disable-edit="serviceDetails.statusId === 4"
                        :service-status="serviceDetails.statusId"
                        v-model="serviceDetails.serviceGoods"
                        :tech-work-id="serviceDetails?.id"
                    />

                    <div class="d-flex-full">

                        <div>


                            <el-button
                                v-if="serviceDetails.statusId === 1 || serviceDetails.statusId === 2"
                                type="primary"
                                @click="updateWorkStatus(3)"
                            >
                                Взять в работу
                            </el-button>

                            <el-button
                                v-if="serviceDetails.statusId === 3"
                                type="success"
                                @click="setEndWorkDialog(true)"
                            >
                                Завершить
                            </el-button>

                        </div>

                        <div>

                            <el-button
                                v-if="serviceDetails.statusId === 1 || serviceDetails.statusId === 2 || serviceDetails.statusId === 3"
                                @click="setCancelWorkDialog(true)"
                                plain>
                                Отменить
                            </el-button>

                            <DrawerButton
                                text="Распечатать"
                                icon="print"
                                @action="notAvaliableFunction"
                                :loading="loading"
                            />

                            <DrawerButton
                                text="Редактировать"
                                icon="edit"
                                @action="notAvaliableFunction"
                                v-if="serviceDetails.statusId === 1 || serviceDetails.statusId === 2"
                                :loading="loading"
                            />

                            <DrawerButton
                                text="Удалить"
                                icon="delete"
                                @action="removeService"
                                :loading="loading"
                            />

                        </div>

                    </div>


                </div>
                <div v-else>
                    <div class="text-center mt-30">
                        <ReportLoader/>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="cancelWorkDialog"
            title="Отмена заказ-наряда"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <TechServiceCancelWork
                :service-details="serviceDetails"
                @closeDialog="setCancelWorkDialog(false)"
                @moduleGet="getServiceDetails"
            />
        </el-dialog>

        <el-dialog
            v-model="endWorkDialog"
            title="Завершение заказ-наряда"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <TechServiceEndWork
                :service-details="serviceDetails"
                @closeDialog="setEndWorkDialog(false)"
                @moduleGet="getServiceDetails"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth'
import {httpService} from '@/services/http.service'
import iconsMixin from '@/mixins/iconsMixin'
import techWorkTypes from '@/mixins/techWorkTypes'
import functionsMixin from '@/mixins/functionsMixin'
import ServiceCreateWorks from '@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateWorks'
import ServiceCreateGoods from '@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateGoods'
import DrawerButton from '@/views/components/Drawer/Buttons/DrawerButton'
import TechServiceDetailsInfoBlock from "@/views/components/TechPage/Service/TechServiceDetailsInfoBlock";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import TechServiceCancelWork from "@/views/components/TechPage/Service/TechServiceCancelWork";
import TechServiceEndWork from "@/views/components/TechPage/Service/TechServiceEndWork";
import techTabs from "@/mixins/techTabs";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'TechServiceDetails',
    components: {
        ReportLoader,
        TechServiceEndWork,
        TechServiceCancelWork,
        TechServiceDetailsInfoBlock, DrawerButton, ServiceCreateGoods, ServiceCreateWorks, Auth
    },
    inject: ['api'],
    mixins: [iconsMixin, techWorkTypes, functionsMixin, mobileCheckMixin, techTabs],
    data() {
        return {
            loading: false,
            serviceDetails: {
                'id': null,
                'techId': null,
                'techName': null,
                'techActualMileage': null,
                'techUnit': null,
                'name': null,
                'description': null,
                'number': null,
                'type': null,
                'mileageIndicator': null,
                'mileageIndicatorPlan': null,
                'mileageIndicatorDone': null,
                'baseOnWork': null,
                'periodicity': false,
                'template': false,
                'datePlan': null,
                'statusId': null,
                'statusName': null,
                'dateDone': null,
                'serviceWorks': [],
                'serviceGoods': []
            },
            techDetails: null,
            cancelWorkDialog: false,
            endWorkDialog: false
        }
    },
    created() {
        this.getServiceDetails()
    },
    methods: {
        setEndWorkDialog(value) {
            this.endWorkDialog = value;
        },
        setCancelWorkDialog(value) {
            this.cancelWorkDialog = value;
        },
        getServiceDetails() {
            this.loading = true
            httpService().post(this.api.tech.getServiceDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                serviceId: this.$route.params.serviceId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.serviceDetails = data.service
                    this.techDetails = data.techDetails
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        updateWorkStatus(statusId) {
            httpService().post(this.api.techVehicle.updateStatus, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                workId: this.serviceDetails.id,
                statusId: statusId,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                this.getServiceDetails()

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        returnBackLink() {
            if (!this.$route.params.routeName) {
                return {name: 'tech.index', params: {id: this.$route.params.id, tab: 'service'}}
            } else {
                return {
                    name: 'garageDetails',
                    params: {id: this.$route.params.id, tab: 'service', vehicleId: this.techDetails.id}
                }
            }
        },
        removeService() {
            this.$confirm('Вы действительно хотите удалить заказ-наряд?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                httpService().post(this.api.tech.removeTechService, {
                    workId: this.serviceDetails.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'tech',
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Заказ-наряд удалён',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'techService', params: {id: this.$route.params.id}})

                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                })
            }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        notAvaliableFunction() {
            this.$message({
                message: 'Функция в разработке',
                showClose: true,
                type: 'info'
            })
        }
    }
}
</script>

<style scoped>

</style>
