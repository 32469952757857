<template>
    <GuideTitle text="Складской учёт - Обороты"/>

    <GuideSteps>
        <template v-slot:steps>
            <GuideStep number="1" text='Здесь представлены обороты сырья по всем складам за выбранный период' />
            <GuideStep number="2" text='В фильтре можете выбрать нужный период'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-04/5c0d66b3-d044-471b-8e19-8538a8cc11a4/File.jpeg?tl_px=0,0&br_px=1965,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=338,111">
            </GuideStep>
        </template>
    </GuideSteps>
</template>

<script>
import GuideTitle from "@/views/components/Guide/GuideTitle.vue";
import GuideSteps from "@/views/components/Guide/GuideSteps.vue";
import GuideStep from "@/views/components/Guide/GuideStep.vue";

export default {
    name: "StorehouseFaqTurnover",
    components: { GuideTitle, GuideStep, GuideSteps },
};
</script>

<style scoped></style>
