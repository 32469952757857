<template>
    <div class="group-orders-table mb-10">
        <Filter
            :filter="filter"
            :loading="loading"
            :filter-show="filterShow"
        />
        <!-- v-if="!mobile" -->
        <el-table
            :data="orderGroups"
            class="w-100 mixOrderGroupTable"
            cell-class-name="cell-padding"
            row-class-name="row-padding"
        >
            <template #empty>
                <EmptyState
                    :values="orderGroups"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    action-text="Создайте заказ"
                    list-types="заказов"
                />
                <div style="margin-bottom: 250px">
                    <a class="el-button el-button--small el-button text-none"
                       href="https://scribehow.com/shared/Kak_sozdat_zakaz__LsoXghBfRoO7v6f-RlsxHQ"
                       target="_blank">
                        Не знаете с чего начать?
                    </a>
                </div>
            </template>
            <el-table-column :width="mobile ? 0 : 14"></el-table-column>
            <el-table-column
                prop="buyer_company"
                label=""
                min-width="150"
                v-if="windowWidth < 1600"
            >
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('company')" :disabled="true">
                        <span>Контрагенты</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <div class="d-flex-full align-center mb-5 mt-5">
                        <div class="group-orders-status-text group-orders-status-text-small mr-5"
                             :class="'group-orders-status-text-' + scope.row?.status">
                            {{ getInfo(scope.row?.status, mixOrderGroupStatuses) }}
                        </div>
                        <div class="w-100">
                            <MixOrderGroupTableOrderIntervalBlock :order-group="scope.row"/>
                        </div>
                    </div>
                    <div
                        class="textXSRegular textSM neutral900 maxLines5 group-orders-info">
                        <span>№ {{ scope.row?.id }} </span>
                        <svg class="buyer-circle" width="5" height="4" viewBox="0 0 5 4" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                fill="black"/>
                        </svg>
                        <span>{{ scope.row.company ?? '-' }}</span>
                    </div>
                    <div class="address textXS textRegular mb-5 neutral400">
                        {{ scope.row.deliveryType === 'delivery' ? (scope.row.address ?? '-') : 'Самовывоз' }}
                    </div>
                    <MixOrderGroupTableGood :order="scope.row" :cut="true"/>
                </template>
            </el-table-column>

            <el-table-column
                prop="buyer_company"
                label=""
                v-if="windowWidth >= 1600"
            >
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('company')" :disabled="true">
                        <span>Контрагенты</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <div>
                        <div class="mb-5">
                            <MixOrderGroupTableOrderIntervalBlock :order-group="scope.row"/>
                        </div>
                        <div class="textXSRegular textSM neutral900 maxLines5">
                            <span>№ {{ scope.row?.id }} </span>
                            <svg class="buyer-circle" width="5" height="4" viewBox="0 0 5 4" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                    fill="black"/>
                            </svg>
                            <span>
                                {{
                                    scope.row.company ?? '-'
                                }}
                            </span>
                        </div>
                        <div class="address textXS textRegular">
                            {{ scope.row.deliveryType === 'delivery' ? (scope.row.address ?? '-') : 'Самовывоз' }}
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="" width="155" v-if="windowWidth >= 1600">
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('status')" :disabled="true">
                        <span>Статусы</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <span class="group-orders-status-text" :class="'group-orders-status-text-' + scope.row?.status">
                        {{ getInfo(scope.row?.status, mixOrderGroupStatuses) }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="" width="150" v-if="crm && windowWidth >= 1600 && payment_type === 'invoice'">
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('payment_status')" :disabled="true">
                        <span>Статус оплаты</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <span class="textXSRegular textSM neutral900" v-if="scope.row.invoice_id">
                        {{ checkPaymentStatus(scope.row.invoice_payment_status) }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="" width="150" v-if="false && windowWidth >= 1600 && payment_type === 'contract' ">
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('payment_status')" :disabled="true">
                        <span>Статус оплаты</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <span class="textXSRegular textSM primary700" v-if="scope.row.contract_id">
                        {{ scope.row.contract_total ? scope.row.contract_total : 0 }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="good"
                label="Товар"
                v-if="windowWidth >= 1600"
            >
                <template #header>
                    <el-button class="filters-buttons-item filters-buttons-item-hover" link
                               @click="openFilterDialog('good')" :disabled="true">
                        <span>Продукция</span>
                        <FilterIcon/>
                    </el-button>
                </template>
                <template v-slot="scope">
                    <MixOrderGroupTableGood :order="scope.row"/>
                </template>
            </el-table-column>

            <el-table-column
                prop="first_order_time_delivery"
                label=""
                :width="mobile ? '220px' : '270px'"
                v-if="!crm"
            >
                <template #header>
                    <div class="filters-buttons-item">
                        <span>Текущая доставка</span>
                    </div>
                </template>
                <template v-slot="scope">
                    <template v-if="scope.row.distributed && !scope.row.orders.length">
                        <div class="table-order-item-container">
                            <el-tooltip effect="dark" placement="top" content="Идет процесс планирования"
                                        :show-after="500">
                                        <div class="d-flex-center w-100">
                                            <SmallLoader />
                                        </div>
                            </el-tooltip>
                        </div>
                    </template>
                    <template v-else>
                        <MixOrderGroupTableOrderItem
                            :orders="scope.row.orders"
                            :current="true"
                            :order-group-id="scope.row.id"
                            :timezone="moduleTimezone"
                            @openMixOrderDetails="openMixOrderDetails"
                        />
                    </template>
                </template>
            </el-table-column>

            <el-table-column
                prop="first_order_time_delivery"
                label=""
                :width="mobile ? '220px' : '270px'"
                v-if="!crm"
            >
                <template #header>
                    <div class="filters-buttons-item">
                        <span>Следующая доставка</span>
                    </div>
                </template>
                <template v-slot="scope">
                    <div>
                        <template v-if="scope.row.distributed">
                            <el-tooltip effect="dark" placement="top" content="Идет процесс планирования"
                                        :show-after="500">
                                        <div class="d-flex-center">
                                            <SmallLoader />
                                        </div>
                            </el-tooltip>
                        </template>
                        <template v-else>
                            <MixOrderGroupTableOrderItem
                                :orders="scope.row.orders"
                                :current="false"
                                :order-group-id="scope.row.id"
                                :timezone="moduleTimezone"
                                @openMixOrderDetails="openMixOrderDetails"
                            />
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="first_order_time_delivery"
                label=""
                width="64"
                class-name="blockCountOrderColumn"
                v-if="windowWidth >= 1025 && !crm"
            >
                <template #header>
                    <div class="d-flex">
                        <el-tooltip
                            raw-content
                            effect="dark"
                            content="Обновить таблицу"
                            placement="top"
                        >
                            <el-button class="dispatchTableReloadButton" @click="loadData()" :icon="icons.refresh" plain/>
                        </el-tooltip>
                        <el-tooltip
                            raw-content
                            effect="dark"
                            content="Перераспределить"
                            placement="top"
                        >
                            <el-button class="dispatchTableReloadButton" @click="distributeOrder" :icon="icons.switch"/>
                        </el-tooltip>
                        <el-tooltip
                            raw-content
                            effect="dark"
                            content="Перераспределить Вреале!!Апасна!!"
                            placement="top"
                        >
                            <el-button class="dispatchTableReloadButton" @click="distributeOrderTest" :icon="icons.switch"/>
                        </el-tooltip>
                    </div>
                </template>
                <template v-slot="scope">
                    <div class="blockCountOrder">
                        <el-tooltip
                            :show-after="500"
                            v-if="checkCountOrder(scope.row, true)"
                            placement="top"
                            content="Количество заявок"
                        >
                            <div class="beige-circle textRegular400 pointer"
                                 @click="openManageDeliveries(scope.row)">
                                <p class="m-0">+{{ checkCountOrder(scope.row, true) }}</p>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Действия"
                prop=""
                :width="mobile ? '120px' : windowWidth > 1600 ? '356px' : '300px'"
            >
                <template #header>
                    <router-link class="orders-group-create-link"
                                 :to="{ name: ($route.meta.moduleKey === 'dispatch' ? 'DispatchOrderGroupsCreate' : 'CRMOrderCreate') }">
                        Создать заказ
                    </router-link>
                </template>
                <template v-slot="scope">
                    <div class="group-order-actions">
                        <div :class="['d-flex f-direction-column group-order-actions__progress-container flex-1 gap-8', {'pt-12 pb-12': !mobile && !crm}]">
                            <OrderQuantity :orders="scope.row?.orders ?? []" :total="scope.row?.total"/>
                            <MixOrderGroupTableIconsGroup v-if="!crm" :order-group="scope.row"/>
                        </div>
                        <div class="d-flex action-buttons">
                            <div>
                                <template
                                    v-if="
                                        scope.row.status !== 'done' &&
                                        scope.row.status !== 'failed' &&
                                        scope.row.status !== 'completed' &&
                                        scope.row.status !== 'pause' &&
                                        !crm &&
                                        scope.row.done < scope.row.total
                                    "
                                >
                                    <el-tooltip v-if="scope.row.deliveryType === 'delivery'" effect="dark"
                                                :show-after="500"
                                                placement="top">
                                        <template #content>
                                            <div class="text-center">
                                                <div class="textMedium mb-5">Остановить заказ</div>
                                                <div class="textXS">Все отгрузки в очереди будут<br/>отменены, и
                                                    распределение<br/>заказа не будет происходить<br/>до возобновления
                                                </div>
                                            </div>
                                        </template>
                                        <button
                                            v-show="checkCountOrder(scope.row, false)"
                                            class="ci-btn pause-btn icon-btn"
                                            type="button"
                                            :data-order-id="scope.row.id"
                                            @click="changeStatus(scope.row.id, 'pause')"
                                        >
                                            <PauseIcon />
                                        </button>
                                    </el-tooltip>
                                    <el-tooltip
                                        v-if="scope.row.deliveryType === 'take-away'"
                                        effect="dark"
                                        placement="top"
                                        :show-after="500"
                                    >
                                        <template #content>
                                            <div class="text-center">
                                                <div class="textMedium mb-5">Добавить отгрузку</div>
                                            </div>
                                        </template>
                                        <button
                                            @click="addShippingDialog(scope.row.id)"
                                            class="ci-btn icon-btn add-an-order-btn"
                                            style="
                                                background: #1984E6;
                                                box-shadow: 0px 4px 16px -2px rgba(27, 37, 51, 0.06);
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 10px;
                                                width: 28px;
                                                color: white;
                                            "
                                            type="button"
                                            :data-order-id="scope.row.id"
                                        >
                                            +
                                        </button>
                                    </el-tooltip>
                                </template>

                                <template v-if="scope.row.status === 'pause' && !crm">
                                    <el-tooltip effect="dark" placement="top" :show-after="500">
                                        <template #content>
                                            <div class="text-center">
                                                <div class="textMedium mb-5">Возобновить заказ</div>
                                                <div class="textXS">Отгрузки по заказу будут<br/>возвращены в расписание
                                                    и<br/>распределены по условиям доставки
                                                </div>
                                            </div>
                                        </template>
                                        <button
                                            class="ci-btn work-btn icon-btn"
                                            type="button"
                                            :data-order-id="scope.row.id"
                                            @click="changeStatus(scope.row.id, 'work')"
                                        >
                                            <PlayIcon />
                                        </button>
                                    </el-tooltip>
                                </template>

                                <template
                                    v-if="(scope.row.status === 'done') || (scope.row.status === 'work' && scope.row.done >= scope.row.total)">
                                    <el-tooltip effect="dark" placement="top" :show-after="500">
                                        <template #content>
                                            <div class="text-center">
                                                <div class="textMedium mb-5">Завершить заказ</div>
                                                <div class="textXS">Подтвердите завершение<br/>заказа</div>
                                            </div>
                                        </template>
                                        <button
                                            class="ci-btn complete-btn icon-btn"
                                            type="button"
                                            :data-order-id="scope.row.id"
                                            @click="changeStatus(scope.row.id, 'completed')"
                                        >
                                            <CheckIcon />
                                        </button>
                                    </el-tooltip>
                                </template>
                            </div>

                            <template v-if="!crm">

                                <el-tooltip effect="dark" placement="top" :show-after="500">
                                    <template #content>
                                        <div class="text-center">
                                            <div class="textMedium mb-5">Подробности заказа</div>
                                            <div class="textXS">Открыть модалку<br/>подробностей заказа</div>
                                        </div>
                                    </template>
                                    <button
                                        class="ci-btn show-btn icon-btn"
                                        type="button"
                                        @click="showActionDialog(scope.row)"
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.0498 6L8 13" stroke="#505968" stroke-width="1.3"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 3H8.1V3.1H8V3Z" stroke="#505968" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </el-tooltip>
                            </template>
                            <button
                                v-if="!crm"
                                class="ci-btn ci-btn_default action-btn icon-btn"
                                type="button"
                                @click="$router.push({name: 'DispatchOrderGroupsDetails', params: { groupId: scope.row.id }})"
                            >
                                <template v-if="windowWidth > 1600">
                                    <span class="textXS textRegular neutral600">Подробнее</span>
                                </template>
                                <template v-else>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_12017_56347)">
                                            <path
                                                d="M8.00016 10.6666C9.47292 10.6666 10.6668 9.47268 10.6668 7.99992C10.6668 6.52716 9.47292 5.33325 8.00016 5.33325C6.5274 5.33325 5.3335 6.52716 5.3335 7.99992C5.3335 9.47268 6.5274 10.6666 8.00016 10.6666Z"
                                                stroke="#49515F" stroke-width="1" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path
                                                d="M12.7782 5.29336L12.7344 5.21746C12.6135 5.00811 12.5517 4.76983 12.5558 4.5281L12.5757 3.34222C12.5798 3.09987 12.452 2.8744 12.242 2.75338L10.4176 1.70205C10.2081 1.58133 9.94968 1.58349 9.74225 1.7077L8.72952 2.31409C8.52254 2.43802 8.28581 2.50348 8.04456 2.50348H7.95676C7.71499 2.50348 7.47776 2.43774 7.27048 2.31329L6.2531 1.70251C6.04494 1.57755 5.7853 1.57562 5.57531 1.6975L3.75665 2.75303C3.54766 2.87433 3.42066 3.09922 3.42473 3.34083L3.4447 4.5281C3.44877 4.76983 3.38704 5.00811 3.26611 5.21746L3.22285 5.29235C3.10186 5.5018 2.92616 5.6744 2.71459 5.79165L1.678 6.3661C1.46553 6.48384 1.33392 6.70782 1.33447 6.95073L1.33925 9.05194C1.3398 9.29337 1.47083 9.51564 1.68182 9.63301L2.71343 10.2069C2.92573 10.325 3.10173 10.4989 3.22241 10.7097L3.26876 10.7907C3.38799 10.999 3.44878 11.2356 3.44473 11.4756L3.42477 12.6576C3.42068 12.9 3.54846 13.1255 3.75849 13.2465L5.58291 14.2978C5.79239 14.4186 6.05082 14.4164 6.25825 14.2922L7.27097 13.6858C7.47795 13.5619 7.71468 13.4964 7.95593 13.4964H8.04373C8.2855 13.4964 8.52273 13.5622 8.73002 13.6866L9.7474 14.2974C9.95556 14.4224 10.2152 14.4243 10.4252 14.3024L12.2438 13.2469C12.4528 13.1256 12.5798 12.9007 12.5758 12.6591L12.5558 11.4718C12.5517 11.2301 12.6135 10.9918 12.7344 10.7824L12.7776 10.7076C12.8986 10.4981 13.0743 10.3255 13.2859 10.2082L14.3225 9.6338C14.535 9.51606 14.6666 9.29208 14.666 9.04917L14.6612 6.94795C14.6607 6.70652 14.5297 6.48425 14.3187 6.36688L13.2846 5.79161C13.0738 5.67437 12.8989 5.50218 12.7782 5.29336Z"
                                                stroke="#49515F" stroke-width="1" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_12017_56347">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </template>
                            </button>
                            <button
                                v-if="crm"
                                class="ci-btn ci-btn_default action-btn action-btn--rounded"
                                type="button"
                                @click="$router.push({name: 'CRMOrderDetails', params: { groupId: scope.row.id }})"
                            >
                                <span class="textXS textRegular neutral600 br-12">Подробнее</span>
                            </button>
                        </div>
                    </div>
                </template>
            </el-table-column>

        </el-table>

    </div>

    <el-dialog
        v-model="filterDialog"
        title="Фильтр"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <el-table
            :data="filterValuesData"
            class="w-100 mt-15 mb-15">
            <el-table-column
                prop="name"
                label="Название"
            />
            <el-table-column
                fixed="right"
                label=""
                prop="active"
                width="120px">
                <template v-slot="scope">
                    <el-checkbox size="large" v-model="scope.row.active"/>
                </template>
            </el-table-column>
        </el-table>

        <hr/>

        <el-button @click="changeFilter()" class="w-100" size="large" type="primary">
            Подтвердить выбор
        </el-button>
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="orderDetailsDialog"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
        @close="unlockOrder"
    >
        <MixOrderDetailsGroup
            :mix-order-id="selectedMixOrderId"
            :order-group="selectedGroup"
            :order-groups="orderGroups"
            @close="closeOrderDetailsDialog"
            @get="$emit('get')"
            @update-order="updateOrder"
        />
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="completedDialog"
        :width="windowWidth < 1560 ? '80%' : dialogSize"
        :destroy-on-close="true"
    >
        <MixOrderGroupCompletedForm
            :group-id="selectedGroupId"
            @closeCompletedForm="closeCompletedForm"
        />
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="shippingDialog"
        title=""
        :width="992"
        :destroy-on-close="true"
    >
        <MixOrderGroupAddShippingForm
            :order-group-item="selectedGroup"
            @close="() => shippingDialog = false"
            @get="$emit('get')"
        />
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog"
        v-model="manageDeliveriesDialog"
        :width="992"
        :destroy-on-close="true"
    >
        <div v-if="selectedGroup">
            <div class="mb-15">
                <h2 class="neutral900 textMedium">Заказ № {{ selectedGroup.id }}</h2>
            </div>
            <ManageDeliveriesOrders :show-add-button="true" :paginate="true" @update="$emit('get')"
                                    :order-group-item="selectedGroup"/>
        </div>
    </el-dialog>

    <el-dialog
        class="group-order-action-dialog__actions"
        ref="mixOrderGroupActionsDialog"
        v-model="actionDialog"
        width="90%"
        :destroy-on-close="true"
    >
        <div style="height: 90vh">
            <MixOrderGroupActions :order-group="selectedGroup" @get="$emit('get')" :access-documents='accessDocuments'/>
        </div>
    </el-dialog>

</template>
<script>
import OrderQuantity from "@/views/dispatch/components/OrderQuantity.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import tableMixin from "@/mixins/tableMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupStatus from "@/mixins/mixOrderGroupStatus";
import functionsMixin from "@/mixins/functionsMixin";
import MixOrderGroupTableGood from "@/views/components/MixOrderGroup/components/MixOrderGroupTableGood.vue";
import MixOrderGroupTableOrderItem from "@/views/components/MixOrderGroup/components/MixOrderGroupTableOrderItem.vue";
import FilterIcon from "@/views/components/Icons/FilterIcon.vue";
import {useCommonStore} from "@/store/common";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";
import MixOrderGroupCompletedForm from "@/views/components/MixOrderGroup/MixOrderGroupCompletedForm.vue";
import moment from "moment";
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";
import ManageDeliveriesOrders from "@/views/dispatch/components/actions/ManageDeliveriesOrders.vue";
import MixOrderGroupActions from "@/views/components/MixOrderGroup/components/orderGroup/MixOrderGroupActions.vue";
import MixOrderGroupTableOrderIntervalBlock
    from "@/views/components/MixOrderGroup/components/MixOrderGroupTableOrderIntervalBlock.vue";
import MixOrderGroupTableIconsGroup from "@/views/components/MixOrderGroup/components/MixOrderGroupTableIconsGroup.vue";
import PauseIcon from "@/views/components/Icons/PauseIcon.vue";
import PlayIcon from "@/views/components/Icons/PlayIcon.vue";
import CheckIcon from "@/views/components/Icons/CheckIcon.vue";
import MixOrderGroupAddShippingForm from "@/views/components/MixOrderGroup/MixOrderGroupAddShippingForm.vue";

export default {
    name: 'MixOrderGroupTable',
    props: [
        'loading',
        'orderGroups',
        'crm',
        'accessDocuments'
    ],
    mixins: [tableMixin, mobileCheckMixin, mixOrderGroupStatus, functionsMixin, iconsMixin],
    components: {
        MixOrderGroupTableOrderIntervalBlock,
        MixOrderGroupActions,
        ManageDeliveriesOrders,
        SmallLoader,
        MixOrderDetailsGroup,
        MixOrderGroupCompletedForm,
        FilterIcon,
        MixOrderGroupTableOrderItem,
        MixOrderGroupAddShippingForm,
        MixOrderGroupTableGood, EmptyState, OrderQuantity, MixOrderGroupTableIconsGroup,
        PauseIcon, PlayIcon, CheckIcon
    },
    data() {
        return {
            selectedGroupId: null,
            openActionDialog: false,
            manageDeliveriesDialog: false,
            manageDeliveriesMixOrder: [],
            isAdd: false,
            filterDialog: false,
            selectedMixOrderId: null,
            actionDialog: false,
            address: [],
            filterValuesData: [],
            selectedFilter: null,
            filter: {
                companies: {
                    value: [],
                },
                goods: {
                    value: [],
                },
                statuses: {
                    value: [],
                },
            },
            orderDetailsDialog: false,
            orderDetails: {
                id: null,
                time: null,
                volume: 0,
                status: null,
                vehicleId: null,
                confirm: false,
                number: null
            },
            completedDialog: false,
            shippingDialog: false,
            payment_type: '',
        }
    },
    created() {
        if (this.crm) this.getAccountModuleParams()
    },
    computed: {
        moment() {
            return moment
        },
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            goods: 'getGoods',
            user: 'getUser',
            modules: 'getModules'
        }),
        ...mapState(useOrderGroupsStore, {
            pagination: 'getPagination',
            filters: 'getFilters',
            getDate: 'getDate'
        }),
        selectedGroup() {
            return this.orderGroups.find((item) => item.id == this.selectedGroupId);
        }
    },
    methods: {
        distributeOrderTest() {
            this.$message({
                message: 'Перераспределяем данные по заказам...',
                type: 'success'
            })
            httpService().post(this.api.dispatch.orderGroups.distributeSaveOrderGroupTest, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            })
        },
        distributeOrder() {
            this.$message({
                message: 'Перераспределяем данные по заказам...',
                type: 'success'
            })
            httpService().post(this.api.dispatch.orderGroups.distributeSaveOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            })
        },
        getAccountModuleParams() {
            httpService().post(this.api.settings.getAccountModuleSettings, {
                module_id: this.$route.params.id,
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.payment_type = data.moduleSettings.payment_type
                }
            })
        },
        changeStatusAction(orderId, status) {
            httpService().post(this.api.dispatch.orderGroups.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_id: orderId,
                status: status
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Статус изменен',
                        showClose: true,
                        type: 'success'
                    })
                    this.selectedGroupId = null
                    this.completedDialog = false
                    this.$emit('get')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        addShippingDialog(orderId) {
            this.selectedGroupId = orderId
            this.shippingDialog = true
        },
        changeStatus(orderId, status) {
            let message = 'завершить заказ'
            if (status === 'pause') message = 'поставить заказ на паузу'
            else if (status === 'work') message = 'запустить заказ снова в работу'

            this.$confirm('Вы действительно хотите ' + message + '?', 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                if (status !== 'completed') {
                    this.changeStatusAction(orderId, status)
                } else {
                    this.selectedGroupId = orderId
                    this.completedDialog = true
                }
            })

        },
        closeCompletedForm() {
            this.$message({
                message: 'Статус изменен',
                showClose: true,
                type: 'success'
            })
            this.selectedGroupId = null
            this.completedDialog = false
            this.$emit('get')
        },

        changeFilter() {
            let arr = this.filterValuesData.filter(el => el.active).map(e => e.id)
            if (this.selectedFilter === 'company') {
                this.filter.companies.value = arr
            } else if (this.selectedFilter === 'good') {
                this.filter.goods.value = arr
            } else if (this.selectedFilter === 'status') {
                this.filter.statuses.value = arr
            }

            this.$message({
                message: 'Фильтр применен',
                type: 'success'
            })

            this.filterDialog = false
        },
        openFilterDialog(filter) {
            this.filterValuesData = []
            this.selectedFilter = filter
            if (this.selectedFilter === 'company') {
                this.filterValuesData = this.companies
            } else if (this.selectedFilter === 'good') {
                this.filterValuesData = this.goods
            } else if (this.selectedFilter === 'status') {
                this.filterValuesData = this.mixOrderGroupStatuses
            }
            this.filterDialog = true
        },
        filterValues() {
            this.filtered = this.orderGroups

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.companies.value.length) this.filtered = this.filtered.filter(data => this.filter.companies.value.includes(data.companyId))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        showActionDialog(orderGroupItem) {
            this.$emit('get')
            this.selectedGroupId = orderGroupItem.id
            this.manageDeliveriesMixOrder = orderGroupItem
            this.actionDialog = true
        },
        checkCountOrder(MixOrder, allCount) {
            let newOrder = 0;
            let workOrder = 0;
            let confirm = 0;

            MixOrder.orders.forEach((order) => {
                if (order.status === 'new') {
                    confirm += order.confirm ? 1 : 0
                    newOrder++
                }
                if (order.status !== 'new' && order.status !== 'done') {
                    confirm += order.confirm ? 1 : 0
                    workOrder++
                }
            })

            newOrder = newOrder > 0 ? newOrder - 1 : newOrder                       //1
            newOrder += (confirm > 0 || newOrder === 0) ? 0 : 1                       //2
            newOrder = (workOrder > 0 || newOrder === 0) ? newOrder : newOrder - 1    //1
            workOrder = workOrder > 0 ? workOrder - 1 : workOrder                   //0

            return allCount ? newOrder + workOrder : !(newOrder === 0 && workOrder !== 0)
        },
        openMixOrderDetails(id, groupId) {
            this.selectedMixOrderId = id
            this.selectedGroupId = groupId
            this.orderDetailsDialog = true
        },
        loadData() {
            this.$message({
                message: 'Загружаем данные по заказам...',
                type: 'success'
            })
            this.$emit('get')
        },
        openManageDeliveries(MixOrderGroup) {
            this.$emit('get')
            this.selectedGroupId = MixOrderGroup.id
            this.manageDeliveriesDialog = true
        },
        closeOrderDetailsDialog() {
            this.unlockOrder()
            this.orderDetailsDialog = false
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        checkPaymentStatus(status) {
            switch (status) {
                case 'not-paid':
                    return 'Не оплачен'
                case 'failed':
                    return 'Отменен'
                case 'partially-paid':
                    return 'Оплачен частично'
                case 'paid':
                    return 'Оплачен'
                default:
                    return 'Не оплачен'
            }
        },
        updateOrder(id) {
            this.selectedGroupId = id
        }
    },
    watch: {
        orderGroups(newValue) {
            // защита от ситуаций, когда не срабатывает сокет
            if (newValue.some(orderGroup => orderGroup.distributed === true)) {
                setTimeout(() => {
                    this.$emit('get')
                }, 10000)
            }
        }
    }
}
</script>

<style lang="scss">
.group-order-action-dialog {
    border-radius: 12px !important;

    &__footer {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }

    .el-dialog__header {
        margin-right: 0;
    }

    &__actions {
        max-height: calc(100svh - 6vh) !important;

        & .el-dialog {
            &__header {
                padding-bottom: 0;
            }

            &__headerbtn {
                z-index: 2;
                width: 38px;
                height: 36px;
                right: 2px;
                top: 2px;
            }
        }
    }
}
</style>
<style scoped lang="scss">
.dialog-header {
    display: flex;
    justify-content: end;

    .el-icon {
        cursor: pointer;
    }
}

.action-button {
    margin-left: 14px;
}

.group-order {
    &__pagination {
        display: flex;
        justify-content: center;
    }
}
</style>
<style lang="scss" scoped>

.good-icon {
    vertical-align: middle;
    margin-right: 16px;
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
}


</style>
<style lang="scss">
.group-order-pump {
    display: flex;
    width: 20px;


    &__item {
        margin-left: auto;
        margin-right: auto;
        padding: 0px 4px 0px 5px;
        align-items: center;
        border-radius: 4px;
        background: #1984e6;

        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px !important;

        height: 20px;
        width: 20px;

        display: none;

        &--active {
            display: block;
        }
    }
}

.total-count .cell {
    padding: 0;
}


.cell-container {
    display: flex;
    justify-content: center;
}

.address {
    font-size: 12px;
}
</style>
