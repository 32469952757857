<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-update-button="true"
        :disable-filter="true"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 tableWithScroll"
        v-loading="loading"
        @sort-change="sortVehicles"
        @current-change="handleCurrentChange"
        :data="displayData"
    >

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            sortable='custom'
            :sort-orders="['ascending', 'descending', null]"
        >
            <template v-slot="scope">
                <el-tooltip
                    :disabled="isTakeAway"
                    effect="dark"
                    placement="left-start"
                >
                    <template #content>
                        <div class="d-flex-full" style="min-width: 220px">
                            <span class="deep-blue200">Водитель:</span>
                            <span>{{ driverLabel(scope.row) }}</span>
                        </div>
                        <div class="d-flex-row">
                            <span class="deep-blue200">Заводы:</span>
                            <span class="d-flex f-direction-column">
                                <span v-for="item in mixLabel(scope.row)" :key="item">
                                    {{ item }}
                                </span>
                            </span>
                        </div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Вместимость:</span>
                            <span>{{ `${ scope.row.vehicle.volume} м³` }}</span>
                        </div>
                        <div>
                            <hr>
                        </div>
                        <div>Доставки</div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Количество:</span>
                            <span>{{ scope.row.count_orders }}</span>
                        </div>
                        <div class="d-flex-full">
                            <span class="deep-blue200">Общий объём:</span>
                            <span>{{ `${scope.row.total_volume} м³`}}</span>
                        </div>
                    </template>
                    <span class="textSM textMedium"
                          :class="isTakeAway ? 'neutral200' : order.vehicle_id === scope.row?.vehicle?.id ? 'primary600' : 'neutral200'">
                            #{{ formatNumber(isTakeAway ? scope.row.id : scope.row.vehicle.id) }}
                    </span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column
            prop="rent"
            label=""
            width="70px"
        >
            <template v-slot="scope">
                <div class="d-flex-center d-flex-center gap-8">
                    <el-tooltip
                        :content="(isTakeAway ? scope.row.rent : scope.row.vehicle.rent) ? 'Арендованное ТС' : 'Собственное ТС'"
                        placement="top" :show-after="200" :hide-after="100">
                        <RentIcon :class="[(isTakeAway ? scope.row.rent : scope.row.vehicle.rent) ? 'neutral500' : 'neutral100']" :current-color="true"/>
                    </el-tooltip>
                    <el-tooltip :content="(isTakeAway ? scope.row.gidrolotok : scope.row.vehicle.gidrolotok) ? 'Есть гидролоток' : 'Нет гидролотка'"
                        placement="top" :show-after="200" :hide-after="100">
                        <GidrolotokLetterIcon :class="[(isTakeAway ? scope.row.gidrolotok : scope.row.vehicle.gidrolotok) ? 'neutral500' : 'neutral100']" :current-color="true"/>
                    </el-tooltip>
            </div>
            </template>
        </el-table-column>

        <!-- <el-table-column
            prop="rent"
            label=""
            width="36"
            class-name="pointer"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="isTakeAway ? scope.row.rent : scope.row.vehicle.rent ? 'Арендованное ТС' : 'Собственное ТС'"
                    placement="top"
                >
                    <span :class="'textSM textMedium ' + (isTakeAway ? scope.row.rent : scope.row.vehicle.rent ? 'neutral500' : 'neutral100' )">
                        А
                    </span>
                </el-tooltip>
            </template>
        </el-table-column> -->

        <!-- <el-table-column
            prop="gidrolotok"
            label=""
            width="36"
            class-name="pointer"
        >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content=" isTakeAway ? scope.row.gidrolotok : scope.row.vehicle.gidrolotok ? 'Есть гидролоток' : 'Нет гидролотка'"
                    placement="top"
                >
                    <span
                        :class="'textSM textMedium ' + (isTakeAway ? scope.row.gidrolotok : scope.row.vehicle.gidrolotok ? 'primary500' : 'neutral100' )">
                        Г
                    </span>
                </el-tooltip>
            </template>
        </el-table-column> -->

        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            :width="isTakeAway ? 'auto' : '220'"
            sortable='custom'
            :sort-orders="['ascending', 'descending', null]"
        >
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">
                    {{ isTakeAway ? scope.row.number : scope.row.vehicle.number ?? '-' }}
                </span>

            </template>
        </el-table-column>

        <el-table-column v-if="!isTakeAway"
            prop="time"
            label="График"
            class-name="pointer"
            min-width="100px"
        >
            <template v-slot="scope">
                <span class="textSM textRegular neutral400">
                    {{ scope.row.start_time + ' - ' + scope.row.end_time }}
                </span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import {mapState} from 'pinia';
import {useCommonStore} from '@/store/common';
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import moment from "moment/moment"

import GidrolotokLetterIcon from "@/views/components/Icons/GidrolotokLetterIcon.vue";
import RentIcon from "@/views/components/Icons/RentIcon.vue";

export default {
    name: "MixOrderDetailsGroupEditSelectVehicles",
    components: {TableHeadBlock, TablePagination, GidrolotokLetterIcon, RentIcon},
    mixins: [tableMixin, functionsMixin],
    props: ['order', 'drivers', 'mixers', 'orderType'],
    inject: ['api'],
    data() {
        return {
            loading: true,
            defaultVehicles: null,
        }
    },
    created() {
        if(this.isTakeAway) {
            const mixes = this.vehicles.filter((vehicle) => vehicle.type === 'mix')

            this.values = mixes;
            this.defaultVehicles = mixes;
            this.valueLength = mixes.length;
            this.loading = false;
        } else this.getSchedules()

    },
    computed:{
        ...mapState(useCommonStore, {
                vehicles: 'getVehicles',
            }),
            isTakeAway() {
                return this.orderType === 'take-away';
            }
        },
    methods: {
        getSchedules() {
            httpService().post(this.api.vehicle.getVehicleSchedulesForDay, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.order.date ?? moment()
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.schedules
                    this.defaultVehicles = data.schedules;
                    this.valueLength = data.schedules.length
                    this.loading = false
                }
            })
        },
        filterValues() {
            let filtered = this.values;

            if (this.search) {
                const searchTerm = this.search.toLowerCase();
                filtered = filtered.filter(data => {
                    return this.isTakeAway
                        ? data.name.toLowerCase().includes(searchTerm) || data.number.toLowerCase().includes(searchTerm)
                        : data.vehicle.name.toLowerCase().includes(searchTerm) || data.vehicle.number.toLowerCase().includes(searchTerm);
                });
            }

            this.valueLength = filtered.length;
            return filtered.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
        handleCurrentChange(val) {
            if (val) {
                this.$emit('updateItem', val)
            }
        },
        driverLabel(row) {
            const driver = this.drivers.find(el => el.id === row.driver_id)

            const parts = driver.name.trim().split(' ');
            if (parts.length < 2) return driver.name; // Если только одно слово

            const surname = parts[0]; // Фамилия
            const initials = parts.slice(1).map(part => `${part[0].toUpperCase()}.`).join(' '); // Инициал имени и отчества
            return `${surname} ${initials}`;
        },
        mixLabel(row) {
            return row.mixers.map(mixer => {
                const matched = this.mixers.find(t => t.id === mixer.id);
                return matched ? matched.name : null;
            });
        },
        sortVehicles(sort) {
            if (!sort.prop || !sort.order) {
                this.values = this.defaultVehicles;
                return;
            }

            if (sort.prop === 'name') {
                this.values = this.sortByName(sort.order);
            } else if (sort.prop === 'id') {
                this.values = this.sortById(sort.order);
            }
        },
        sortByName(order) {
            return [...this.values].sort((a, b) => {
                const aValue = this.isTakeAway ? a.number : a.vehicle.number;
                const bValue = this.isTakeAway ? b.number : b.vehicle.number;

                return order === 'ascending'
                    ? aValue.localeCompare(bValue, 'ru', { numeric: true })
                    : bValue.localeCompare(aValue, 'ru', { numeric: true });
            });
        },
        sortById(order) {
            return [...this.values].sort((a, b) => {
                const aValue = this.isTakeAway ? a.id : a.vehicle.id;
                const bValue = this.isTakeAway ? b.id : b.vehicle.id;

                return order === 'ascending'
                    ? aValue - bValue
                    : bValue - aValue;
            });
        },
    }
}
</script>
