<template>
    <component v-if="isComponentRegistered" :is="component"></component>
    <div v-else class="d-flex align-center gap-4">
        <h3 class="textMedium neutral400">Справка не найдена</h3>
        <Failed width='20' height='20'/>
    </div>
</template>

<script>
// cashbox
import CashboxFaqHowCreateNewOperations from "@/views/components/FaqPage/cashbox/CashboxFaqHowCreateNewOperations";
import CashboxFaqHowEditDefaultAccount from "@/views/components/FaqPage/cashbox/CashboxFaqHowEditDefaultAccount";

// settings
import SettingsFaqHowInviteNewUser from "@/views/components/FaqPage/settings/SettingsFaqHowInviteNewUser";
import SettingsFaqHowCreateNewModule from "@/views/components/FaqPage/settings/SettingsFaqHowCreateNewModule";
import DispatchHowToCreateNewOrder from "@/views/components/FaqPage/dispatch/DispatchHowToCreateNewOrder.vue";

// tech
import HowAddTechToService from "@/views/components/FaqPage/tech/HowAddTechToService.vue";
import HowCreateAndPerformService from "@/views/components/FaqPage/tech/HowCreateAndPerformService.vue";
import HowManageSparePartsStorehouse from "@/views/components/FaqPage/tech/HowManageSparePartsStorehouse.vue";

// storehouse
import StorehouseFaqTurnover from "@/views/components/FaqPage/storehouse/StorehouseFaqTurnover.vue";
import StorehouseFaqOperations from "@/views/components/FaqPage/storehouse/StorehouseFaqOperations.vue";
import StorehouseFaqInfo from "@/views/components/FaqPage/storehouse/StorehouseFaqInfo.vue";
import StorehouseFaqAddOperation from "@/views/components/FaqPage/storehouse/StorehouseFaqAddOperation.vue";

import {Failed} from "@element-plus/icons-vue";

export default {
    name: 'FaqRouter',
    components: {
        SettingsFaqHowCreateNewModule,
        SettingsFaqHowInviteNewUser, CashboxFaqHowEditDefaultAccount, CashboxFaqHowCreateNewOperations,
        DispatchHowToCreateNewOrder, Failed, HowAddTechToService, HowCreateAndPerformService, HowManageSparePartsStorehouse,
        StorehouseFaqTurnover, StorehouseFaqOperations, StorehouseFaqInfo, StorehouseFaqAddOperation
    },
    props: ['component'],
    computed: {
        isComponentRegistered() {
            return this.$options.components[this.component] !== undefined;
        },
    },
}
</script>

<style scoped>

</style>
