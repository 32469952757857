<template>
    <Auth title="Справка">
        <div class="card" id="faq">
            <div class="card-body">

                <h2 class="mt-30">10 популярных вопросов</h2>

                <FaqCard
                    :unlimited="true"
                    limit="10"
                    :questions="popularFaqQuestions"
                />

                <h2 class="mt-30">Основная документация</h2>

                <div class="faqModules">
                    <FaqCard
                        name="settings"
                        :questions="faqQuestions['settings']"
                    />

                    <FaqCard v-for="(module, index) in uniqueModules" :key="index"
                        v-show="faqQuestions[module.key]?.length"
                        :name="module?.key"
                        :questions="faqQuestions[module.key] ?? []"
                    />
                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import faqQuestions from '@/mixins/faqQuestions'
import FaqCard from '@/views/components/FaqPage/FaqCard'

export default {
    name: 'FAQ',
    components: {
        FaqCard,
        Auth,
    },
    mixins: [
        faqQuestions
    ],
    computed: {
        ...mapState(useCommonStore, {
            links: 'getNav',
            modules: 'getModules'
        }),
        uniqueModules() {
            return this.modules.reduce((acc, obj) => {
                if (!acc.some(item => item.key === obj.key)) {
                  acc.push(obj);
                }
            return acc;
            }, []);
        }
    },
}
</script>

<style scoped>

</style>
