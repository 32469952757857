<template>
    <GuideTitle text="Как создать заказ"/>

    <GuideDescription text="Пошаговая инструкция как создать заказ на доставку" />

    <section class="guide-steps pl-10 pr-10 pt-42">
        <div class="guide-steps__container d-flex f-direction-column gap-48">
            <GuideCallout text="Заходим в Диспетчерскую" />

            <GuideStep number="1">
                <template #text>
                    <span>Перейти по ссылке</span>
                    <GuideLink text="https://app.cifra.ai" link="https://app.cifra.ai/dashboard" />
                </template>
            </GuideStep>

            <GuideStep number="2" text='Перейти в модуль "Диспетчерская - СДК"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/54732b95-33c7-4988-8518-dd415a823855/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=66,263">
            </GuideStep>

            <GuideCallout text="Переходим в создание заказа" />    <GuideStep number="3" text='Нажать "Создать заказ"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/5e97ef08-9e78-43e4-b100-04234053ed88/ascreenshot.jpeg?tl_px=1266,0&br_px=2986,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=741,260">
            </GuideStep>

            <GuideCallout text="Указываем основные данные по заказу" />    <GuideStep number="4" text='Выбрать дату заказа. Заказ можно создать на сегодня и любой будущий день'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/b87bd804-de4d-4f51-8b32-436b3b2a0913/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=518,155">
            </GuideStep>

            <GuideStep number="5" text='Выбираем дату'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/6fdc7f8f-8e24-44ac-a5cb-a09597b1b6f5/ascreenshot.jpeg?tl_px=256,195&br_px=1976,1156&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="6" text='Выберите контрагента'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/013c1b3e-7946-499d-96da-cc41a1f7b3ad/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=410,215">
            </GuideStep>

            <GuideStep number="7" text='Вы можете отфильтровать контрагентов как вам удобно'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/315b36af-c0ef-43c3-908c-240d769e9bf9/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=396,255">
            </GuideStep>

            <GuideStep number="8" text='Выбираем контрагентов из "1С: ООО "СДК" в фильтре'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/8ce31b98-3e89-4f2b-9bb6-79a3d81d5289/ascreenshot.jpeg?tl_px=0,145&br_px=1719,1106&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=345,277">
            </GuideStep>

            <GuideStep number="9" text='Выберите нужного контрагента. Все контрагенты загружены из 1С'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/bb31bc13-d341-44dd-be84-572ce66ceabe/ascreenshot.jpeg?tl_px=0,563&br_px=1719,1524&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=380,277">
            </GuideStep>

            <GuideStep number="10" text='Выберите продукцию'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/991a3179-99bd-4f7d-b0e6-32c5dcc0ce7c/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=406,271">
            </GuideStep>

            <GuideStep number="11" text='Вы можете отфильтровать продукцию как вам удобно'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/f69a2df6-80bd-4b4e-9721-9368e5938194/ascreenshot.jpeg?tl_px=306,0&br_px=2025,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,258">
            </GuideStep>

            <GuideStep number="12" text='По умолчанию выбираем интеграцию "1С: ООО "СДК"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/0fcf7f85-cb96-441a-adf0-b903239c7b57/ascreenshot.jpeg?tl_px=380,161&br_px=2100,1122&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,276">
            </GuideStep>

            <GuideStep number="13" text='По продукции работает поиск'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/c5a19947-42b9-4d78-a64d-4b0b9bf26ee1/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=118,240">
            </GuideStep>

            <GuideStep number="14" text='Выбираем, например "Бетон мелкозернистый В25 (М350) П4 F1 150 W4"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/5becc1b3-884d-416c-b8f4-01203f0918ae/ascreenshot.jpeg?tl_px=0,137&br_px=1719,1098&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=496,277">
            </GuideStep>

            <GuideStep number="15" text='Указываем "Объем продукции"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/244a80c1-b005-44f4-9786-a4472d43e6c0/ascreenshot.jpeg?tl_px=0,105&br_px=1719,1066&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=464,277">
            </GuideStep>

            <GuideStep number="16" text='Выберите договор для этого заказа'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/1db3fafe-4e11-41f1-b78e-a6147efd9057/ascreenshot.jpeg?tl_px=1184,0&br_px=2904,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,156">
            </GuideStep>

            <GuideStep number="17" text='У вас выведутся все договора для этого контрагента из 1С'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/6f16560d-080a-4596-b7ff-cc8a235a72fe/ascreenshot.jpeg?tl_px=464,157&br_px=2184,1118&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="18" text='Выберите счет'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/4cda44ce-229d-411b-9eb8-7fa20dec924e/ascreenshot.jpeg?tl_px=1188,0&br_px=2908,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,224">
            </GuideStep>

            <GuideCallout text="Указываем данные по доставке" />    <GuideStep number="19" text='Выберите тип заказа - Доставка или Самовывоз'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/cbfc104b-76c0-44b0-a487-be5130d9eb04/ascreenshot.jpeg?tl_px=0,325&br_px=1719,1286&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=366,277">
            </GuideStep>

            <GuideStep number="20" text='Выберите объект, адрес доставки заказа'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/952f5226-e6e6-4eab-b2c5-db0899e4f74e/ascreenshot.jpeg?tl_px=0,397&br_px=1719,1358&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=378,276">
            </GuideStep>

            <GuideStep number="21" text='Если у контрагента уже есть адреса, куда доставляли ранее - они появятся в списке для выбора'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/690a1b9f-470d-47e2-be3e-6c8703b6c907/ascreenshot.jpeg?tl_px=88,171&br_px=1807,1132&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="22" text='Если адресов нет или нужен новый - нажмите "+" для создания'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/66fbee31-64a8-43d7-a8f1-004605bcdf1f/ascreenshot.jpeg?tl_px=992,0&br_px=2712,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,251">
            </GuideStep>

            <GuideStep number="23" text='Начните вводить адрес'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/867aeb72-1ebb-43be-b032-992b7fddf80b/ascreenshot.jpeg?tl_px=36,0&br_px=1756,961&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=776,232">
            </GuideStep>

            <GuideStep number="24" text='Система предложит вам адреса, как при поиске в Яндекс Картах'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/ef853ba1-eeca-4e2e-a3ed-69812637848b/ascreenshot.jpeg?tl_px=0,35&br_px=1719,996&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=398,277">
            </GuideStep>

            <GuideStep number="25" text='Можете передвигать карту, чтобы указать точное место'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/335c30fc-f2c0-4c59-be1f-ca734beede1d/ascreenshot.jpeg?tl_px=628,521&br_px=2348,1482&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="26" text='Введите удобное для вас и водителя название адреса'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/efdc73c7-e006-4f11-a01a-33e082783f6e/ascreenshot.jpeg?tl_px=68,802&br_px=1787,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,323">
            </GuideStep>

            <GuideStep number="27" text='Нажмите "Добавить адрес"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/ba02d3c1-3100-4aea-abc8-da8301b1eac5/ascreenshot.jpeg?tl_px=0,802&br_px=1719,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=186,423">
            </GuideStep>

            <GuideStep number="28" text='Выберите представителя заказа. ФИО и телефон представителя увидит водитель у себя в приложении и сможет с ним связаться'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/3865b530-7d75-46ff-87ba-bcd9b78a89e9/ascreenshot.jpeg?tl_px=0,495&br_px=1719,1456&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=413,277">
            </GuideStep>

            <GuideStep number="29" text='Можете создать нового представителя для контрагента'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/4b3a6f50-b46b-4a24-99bb-76a1fd65fcf6/ascreenshot.jpeg?tl_px=0,623&br_px=1719,1584&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=441,277">
            </GuideStep>

            <GuideStep number="30" text='Указываем телефон и ФИО'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/ade24401-9968-4fb4-8e93-1c7e00d5a710/ascreenshot.jpeg?tl_px=648,395&br_px=2368,1357&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=263,223">
            </GuideStep>

            <GuideStep number="31" text='Сохраняем нового представителя'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/0a58d4a1-7d6b-4a02-9375-894b7851041a/ascreenshot.jpeg?tl_px=640,431&br_px=2360,1392&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=250,394">
            </GuideStep>

            <GuideStep number="32" text='Можете оставить комментарий водителю, который он так же увидит у себя в приложении'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/fd1d9837-56fe-4417-8385-221d2db00d62/ascreenshot.jpeg?tl_px=105,619&br_px=1825,1580&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="33" text='Если по заказу нужны только автомобили с гидролотком - выберите его, и другие машины не будут участвовать в распределении'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/fe2e0425-f875-4c5a-b7ca-8be0a85b93aa/ascreenshot.jpeg?tl_px=0,801&br_px=1719,1762&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=361,277">
            </GuideStep>

            <GuideStep number="34" text='Укажите время первой доставки. Это время, к которому алгоритм запланирует первую доставку'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/14a55711-a56a-479f-9595-a8751742fdc1/ascreenshot.jpeg?tl_px=1132,333&br_px=2852,1294&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,277">
            </GuideStep>

            <GuideStep number="35" text='Укажите планируемое время разгрузки. Это время не бесплатного времени разгрузки, а именно планируемое для разгрузки по факту, чтобы алгоритм распланировал сколько будут по плану разгружаться миксера.'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/402da309-1616-4e80-b8b6-e1966613ff69/ascreenshot.jpeg?tl_px=1158,409&br_px=2878,1370&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="36" text='Укажите с каким интервалом планировать доставки. Время указывается в минутах. Есть варианты:'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/05cb3045-6168-4af1-83d0-24ff51df717e/ascreenshot.jpeg?tl_px=1266,499&br_px=2986,1460&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=651,276">
            </GuideStep>

            <GuideCallout text="Выбираем насос" />    <GuideStep number="37" text='Переходим на кладку "Насос", если он необходим по заказу'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/e5a752e8-d680-46fb-b926-9d54baf8a89b/ascreenshot.jpeg?tl_px=0,215&br_px=1719,1176&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=414,277">
            </GuideStep>

            <GuideStep number="38" text='Включите необходимость насоса'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/0ef485dd-91ba-4022-9ae9-a5e59e764fa3/ascreenshot.jpeg?tl_px=0,329&br_px=1719,1290&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=339,277">
            </GuideStep>

            <GuideStep number="39" text='У вас выведутся списком все насосы, которые указаны в графике "Насосы" на сегодня или по умолчанию. Выбираем нужный'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/0189ee98-eb8f-43ac-8b40-ffd6c9d2e171/ascreenshot.jpeg?tl_px=0,0&br_px=2986,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=984,332">
            </GuideStep>

            <GuideCallout text="Указываем стоимость продукции и тарифы на: Доставку, Простой, Насос" />    <GuideStep number="40" text='Переходим на кладку "Стоимость"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/1b512755-b4f3-406a-9040-a69995d5b704/ascreenshot.jpeg?tl_px=39,229&br_px=1759,1190&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="41" text='Указываем Стоимость продукции" за один куб. Если в договоре есть стоимость продукции по договору - она подставится автоматически'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/c720358b-fae3-4f87-ade9-81e18c038690/ascreenshot.jpeg?tl_px=0,309&br_px=1719,1270&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=490,277">
            </GuideStep>

            <GuideStep number="42" text='Выберите метод оплаты по заказу'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/288ec691-12c3-415d-8b0f-8a00223e365f/ascreenshot.jpeg?tl_px=1238,333&br_px=2958,1294&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,277">
            </GuideStep>

            <GuideStep number="43" text='Выберите метод оплаты по заказу'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/291f3edf-efb1-4f6b-ba6a-237d5dbe1ebe/ascreenshot.jpeg?tl_px=1208,435&br_px=2928,1396&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="44" text='Выберите тариф на доставку'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/475beabc-5fd0-402a-a27f-eb8e7e4c027e/ascreenshot.jpeg?tl_px=0,475&br_px=1719,1436&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=521,277">
            </GuideStep>

            <GuideStep number="45" text='Вы можете воспользоваться поиском и найти нужный тариф. Тарифы загружаются из 1С'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/591e3bcd-4134-41f7-90fc-ea4f7eeb590d/ascreenshot.jpeg?tl_px=0,663&br_px=1719,1624&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=479,277">
            </GuideStep>

            <GuideStep number="46" text='Выберите тариф на простой. Они так же загружены из 1С'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/d1285e36-51a4-402c-8558-6c0e50f0b5b6/ascreenshot.jpeg?tl_px=0,581&br_px=1719,1542&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=423,276">
            </GuideStep>

            <GuideStep number="47" text='Выберите тариф на насос. Тарифы так же загружены из 1С'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/3d99ab33-adb1-46b8-bea4-63ced7de0043/ascreenshot.jpeg?tl_px=0,667&br_px=1719,1628&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=457,276">
            </GuideStep>

            <GuideCallout text="Выбираем посты отгрузки для заказа и оставляем комментарий оператору" />    <GuideStep number="48" text='Переходим на вкладку "Заводы"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/0fd43e64-480f-4fc0-be5e-8982bba8937b/ascreenshot.jpeg?tl_px=212,227&br_px=1932,1188&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,276">
            </GuideStep>

            <GuideStep number="49" text='По умолчанию включены все посты для заказа. Можем выключить те, с которых не нужно отгружать этот заказ (нет нужного цемента, например)'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/16fa7d0b-5bd2-432d-8b7c-d93c6c21698e/ascreenshot.jpeg?tl_px=0,299&br_px=1719,1260&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=199,277">
            </GuideStep>

            <GuideStep number="50" text='Можем оставить комментарий оператору'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/62a74b9c-744c-4950-adbd-9d940140d5d2/ascreenshot.jpeg?tl_px=1188,329&br_px=2908,1290&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideCallout text="Выбираем миксеры, которые будут работать по заказу" />    <GuideStep number="51" text='Переходим на вкладку "Миксеры"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/fc06c54b-24e7-4f21-844b-dad63e33d22a/ascreenshot.jpeg?tl_px=428,231&br_px=2148,1192&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideStep number="52" text='Можем оставить "Все" - тогда алгоритм будет использовать все миксеры, доступные по графику на день'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/e7dbb458-c339-47f6-a046-6a6add5e2890/ascreenshot.jpeg?tl_px=0,319&br_px=1719,1280&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=161,277">
            </GuideStep>

            <GuideStep number="53" text='Или включаем "Только выбранные", чтобы по этому заказу работали только выбранные машины'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/c6aed3e2-ab70-47e2-b4d5-2ad42cebc410/ascreenshot.jpeg?tl_px=0,507&br_px=1719,1468&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=103,277">
            </GuideStep>

            <GuideStep number="54" text='И галочками выбираем нужные'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/c8bf39e0-9618-4607-b081-7ca4cdb0964e/ascreenshot.jpeg?tl_px=0,601&br_px=1719,1562&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=102,276">
            </GuideStep>

            <GuideCallout text="Указываем дополнительную информацию по заказу: комментарий диспетчеру, данные для документов" />    <GuideStep number="55" text='Переходим на вкладку "Дополнительно"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/7c7dbed2-1549-4450-b08a-ea966614c03a/ascreenshot.jpeg?tl_px=636,217&br_px=2356,1178&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,276">
            </GuideStep>

            <GuideStep number="56" text='Можем указать конструктив, который будет заливаться. Например "Плита" или "Перекрытие"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/12a97389-3f4a-4657-a661-a8a64163281b/ascreenshot.jpeg?tl_px=0,305&br_px=1719,1266&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=481,277">
            </GuideStep>

            <GuideStep number="57" text='Указываем поставщика для документов'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/c7098641-e6ee-4fc0-a12a-de79042d5991/ascreenshot.jpeg?tl_px=1180,315&br_px=2900,1276&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,277">
            </GuideStep>

            <GuideStep number="58" text='Указываем перевозчика для ТН'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/9887d53a-effd-4fc8-888a-ddc8ad49c284/ascreenshot.jpeg?tl_px=1200,411&br_px=2920,1372&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=523,277">
            </GuideStep>

            <GuideStep number="59" text='Оставляем комментарий для диспетчера'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/338bdfa1-7027-4963-a202-d18e5e55540d/ascreenshot.jpeg?tl_px=1178,629&br_px=2898,1590&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=524,277">
            </GuideStep>

            <GuideCallout text="Проверяем распределение заказа" />    <GuideStep number="60" text='Нажимаем "Распределить заказ"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/5db252f0-4ebf-4e26-a318-1cbe7a1d5171/ascreenshot.jpeg?tl_px=0,802&br_px=1719,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=419,293">
            </GuideStep>

            <GuideStep number="61" text='Смотрим предварительный график распределения заказа сквозь все заказы на этот день. Выводится информация по загрузке постов и машин'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/bf8dc18c-aafe-4f33-aa1c-ad2c39efb695/ascreenshot.jpeg?tl_px=0,0&br_px=2986,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=106,586">
            </GuideStep>

            <GuideStep number="62" text='После корректиро "Распределить заказ"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/bfa06d42-5adc-4af1-9049-e6954adaf330/ascreenshot.jpeg?tl_px=0,802&br_px=1719,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=356,302">
            </GuideStep>

            <GuideStep number="63" text='Когда всё поправили и с распределением предварительным согласны - жмём "Создать заказ"'
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-05/a73d9abb-ea82-4746-8540-24f4e20041ee/ascreenshot.jpeg?tl_px=0,802&br_px=1719,1764&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=342,500">
            </GuideStep>

            <GuideCallout text="Заказ создан!" />
        </div>
    </section>
</template>

<script>
import GuideTitle from '@/views/components/Guide/GuideTitle.vue';
import GuideDescription from '@/views/components/Guide/GuideDescription.vue';
import GuideCallout from '@/views/components/Guide/GuideCallout.vue';
import GuideStep from '@/views/components/Guide/GuideStep.vue';
import GuideLink from '@/views/components/Guide/GuideLink.vue';

export default {
    name: "CashboxFaqHowCreateNewOperations",
    components: { GuideTitle, GuideCallout, GuideStep, GuideLink, GuideDescription },
};
</script>

<style scoped lang="scss">
.guide-steps {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        max-width: 80%;
        justify-self: center;
    }
}
</style>
