<template>
    <Auth title="Складской учет">
        <div></div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth';

export default {
    name: 'StorehouseIndex',
    components: {
        Auth,
    },
    created() {
        this.$router.push({ name: 'StorehouseInfo',  params:{ id: this.$route.params.id }})
    },
}
</script>

<style scoped>

</style>
