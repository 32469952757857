<template>
    <el-tooltip
        :content="`Загрузка завода - ${task.total} м³`"
        :show-after="200"
        :hide-after="100"
    >
        <div
            :class="[
                taskClasses
            ]"
            @click.stop="$emit('click')"
            :style="getTaskStyle(task)"
        >
            {{ task?.total }}
        </div>
    </el-tooltip>
</template>
<script>
import moment from "moment-timezone";

export default {
    name: "SelfGanttMixTask",
    props: {
        from: String,
        to: String,
        tz: {
            type: String,
            default: "UTC", // Часовой пояс по умолчанию
        },
        task: Object,
        currentTask: Boolean,
        highlight: Boolean,
    },
    computed: {
        taskClasses() {
            const classes = ["mix-task"];

            if(this.task.color === 'red') {
                if(!this.currentTask) return classes.concat(['mix-task-colored--red']);
            }

            if(!this.currentTask) return classes

            classes.push("mix-task-colored");

            const isCurrentOrderGroupTask =
                this.currentTask?.order_group_id === this.task.order_group_id;

            if (
                this.task.color !== "gray" &&
                (!this.currentTask || isCurrentOrderGroupTask)
            ) {
                classes.push(`mix-task-colored--${this.task.color}`);
            }

            if (isCurrentOrderGroupTask) {
                classes.push("mix-task-colored--highlight");
            }

            if (this.currentTask?.order_id === this.task.order_id) {
                classes.push("mix-task-colored--current-task");
            }

            return classes;
        },
    },
    methods: {
        getTaskStyle(task) {
            const start = moment.tz(
                this.from,
                "YYYY-MM-DD HH:mm",
                this.timezone
            );
            const end = moment.tz(this.to, "YYYY-MM-DD HH:mm", this.timezone);
            // Время начала и окончания задачи
            const taskStart = moment.tz(
                task.start,
                "YYYY-MM-DD HH:mm",
                this.timezone
            );
            const taskEnd = moment.tz(
                task.end,
                "YYYY-MM-DD HH:mm",
                this.timezone
            );
            // Всего минут в диапазоне времени на диаграмме
            const totalMinutes = end.diff(start, "minutes");
            // Длительность задачи в минутах
            const taskDurationMinutes = taskEnd.diff(taskStart, "minutes");
            // Смещение задачи от начала временного диапазона
            const offsetMinutes = taskStart.diff(start, "minutes");
            // Вычисляем стиль для правильного отображения задачи

            return {
                left: `${(offsetMinutes / totalMinutes) * 100}%`,
                width: `${(taskDurationMinutes / totalMinutes) * 100}%`,
            };
        },
    },
};
</script>
<style scoped lang="scss">
@use "@/sass/_variables.scss";

.mix-task {
    position: absolute;
    height: 31px;
    color: variables.$neutral700;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$neutral100;
    transform: translate(0, -50%);
    top: 50%;
    border-radius: 2px;
    z-index: 2;
    cursor: pointer;

    &:after {
        content: "";
        width: 1px;
        height: 100%;
        background: variables.$neutral50;
        position: absolute;
        right: -1px;
        top: 0;
    }

    &:hover {
        transition: all 0.3s ease;
        z-index: 3;
        box-shadow : 0 0 0 .3px currentColor;
        border-color: currentColor;
    }
}

.mix-task-colored {
    background: variables.$neutral50;
    color: variables.$neutral200;

    &:hover,
    &--highlight {
        transition: all 0.3s;
        z-index: 3;
        border-color: currentColor;
    }

    &--highlight {
        background: #ededed;
        color: #9da1aa;
    }

    &--current-task {
        background: #e0e0e0;
        color: #9296a0;
        z-index: 4;
    }

    &--middle-gray {
        background: variables.$neutral50;
        color: variables.$neutral300;
    }

    &--highlight.mix-task-colored--middle-gray {
        background: #dfdfe2;
        color: #7c828d;
    }

    &--current-task.mix-task-colored--middle-gray {
        background: #d4d4d8;
        color: #727883;
        z-index: 4;
    }

    // Темно-серая карточка
    &--dark-gray {
        background: #dee1e3;
        color: #596273;
    }

    &--highlight.mix-task-colored--dark-gray {
        background: #d3d6d9;
        color: variables.$neutral600;
    }

    &--current-task.mix-task-colored--dark-gray {
        background: #c8ccd0;
        color: #474f5c;
    }

    // Зеленая карточка
    &--green {
        background: #d5f7f0;
        color: #007f74;
    }

    &--highlight.mix-task-colored--green {
        background: #baf2e7;
        color: #00665e;
    }

    &--current-task.mix-task-colored--green {
        background: #a9efe1;
        color: #00524b;
    }

    // Голубая карточка
    &--blue {
        background: #d1e9ff;
        color: variables.$primary600;
    }



    &--highlight.mix-task-colored--blue {
        background: #add8ff;
        color: #1673ca;
    }

    &--current-task.mix-task-colored--blue {
        background: #99ceff;
        color: #1469b8;
    }

    // Желтая карточка
    &--yellow {
        background: #fbefcb;
        color: #ae641f;
    }

    &--highlight.mix-task-colored--yellow {
        background: #f9e8b3;
        color: #8b5018;

    }

    &--current-task.mix-task-colored--yellow {
        background: #f7e2a1;
        color: #7a4615;
    }

    // Красная карточка
    &--red {
        background: #fdc6c2;
        color: variables.$danger400;
    }

    &--highlight.mix-task-colored--red {
        background: #fca29c;
        color: #952a23;

    }

    &--current-task.mix-task-colored--red {
        background: #fb9088;
        color: #84261f;
    }
}
</style>
