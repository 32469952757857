<template>
    <div v-if="order" class="mix-order-group-actions">
        <el-row :gutter="20" class="mix-order-group-actions__details">
            <el-col :sm="8" v-if="order.deliveryType === 'delivery'">
                <MixOrderGroupActionsMap :factories="factories" :address="address" :coords="coords"
                                         :vehicles="vehicles"/>
                <div
                    class="textSM neutral300 textRegular"
                    v-if="loadingVehicles"
                    style="position: relative; top: 10px;"
                >
                    Загрузка GPS-данных по транспортным средствам...
                </div>
            </el-col>
            <el-col class="mix-order-group-actions__info" :sm="order.deliveryType === 'delivery' ? 16 : 24">
                <el-row :gutter="20" class="pt-20 pb-20">
                    <el-col :sm="16">
                        <h2 class="neutral900 textMedium mt-0 mb-0">Заказ № {{ order.id }}</h2>
                    </el-col>
                    <el-col :sm="8">
                        <div class="d-flex d-flex-end">
                            <el-tooltip
                                raw-content
                                effect="dark"
                                :content="order.status === 'done'
                                    ? 'Заказ выполнен'
                                    : order.status === 'completed'
                                    ? 'Заказ завершен'
                                    : order.status !== 'pause'
                                    ? 'Остановить'
                                    : 'Возобновить'"
                                placement="top"
                            >
                                <el-button
                                    class="regular"
                                    @click="changeStatus(order.id, order.status === 'pause' ? 'work' : 'pause')"
                                    :disabled="order.status === 'done' || order.status === 'completed' || order.status === 'failed'"
                                    >
                                        <PauseIcon v-if="order.status !== 'pause'" :currentColor="true" :strokeWidth="0.9" />
                                        <PlayIcon :currentColor="true" :strokeWidth="0.9" v-else />
                                    </el-button>
                            </el-tooltip>
                            <el-tooltip
                                raw-content
                                effect="dark"
                                :content="order.status === 'completed'
                                    ? 'Заказ завершен'
                                    : order.status === 'failed'
                                    ? 'Заказ отменен'
                                    : order.orders.every((o) => o.status === 'new')
                                    ? 'Отменить'
                                    : 'Завершить'"
                                placement="top"
                            >
                                <el-button
                                    class="regular"
                                    @click="completeOrCancelOrder(order)"
                                    :disabled="order.status === 'completed' || order.status === 'failed'"
                                    >
                                        <CloseMDIcon v-if="order.orders.every((o) => o.status === 'new')" :strokeWidth="0.9" :currentColor="true" />
                                        <CheckIcon v-else :strokeWidth="0.9" :currentColor="true" />
                                    </el-button>
                            </el-tooltip>
                            <el-tooltip
                                raw-content
                                effect="dark"
                                content="Перераспределить"
                                placement="top"
                            >
                                <el-button @click="distributeOrder" :icon="icons.switch"/>
                            </el-tooltip>

                            <el-tooltip
                                raw-content
                                effect="dark"
                                content="Редактировать"
                                placement="top"
                                :show-after="200"
                            >
                                <el-button @click="editOrder" :icon="icons.edit"/>
                            </el-tooltip>
                        </div>
                    </el-col>
                </el-row>

                <OrderQuantity :orders="order.orders ?? []" :total="order.total"/>

                <hr class="mb-15 mt-15"/>

                <el-row :gutter="20">
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Контрагент"
                            overflowClass="maxLines5"
                            :text="order.company ?? '-'"
                        />
                        <CRMInfoText
                            head="Представитель"
                            :text="companyManagerText"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Тип доставки"
                            :text="order.deliveryType === 'delivery' ? 'Доставка' : 'Самовывоз'"
                        />
                        <CRMInfoText
                            head="Время первой доставки"
                            :text="order.firstOrderTimeDelivery ? moment.unix((order.firstOrderTimeDelivery)).utc().tz(this.moduleTimezone).format('HH:mm') : '-'"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Продукция"
                            :text="getProductText(order)"
                        />
                    </el-col>
                    <el-col :sm="12" v-if="order.deliveryType === 'delivery'">
                        <CRMInfoText
                            head="Адрес доставки"
                            :text="order.address ?? '-'"
                        />
                    </el-col>
                </el-row>
                <el-tabs class="mix-order-group-actions__tabs" v-model="tab" @tab-click="handleTabClick">
                    <el-tab-pane label="Отгрузки" name="orders">
                        <!-- max-height="50vh" -->
                        <el-scrollbar class="mix-order-group-actions__orders">
                            <ManageDeliveriesOrders :hidden-footer="true"
                                                    :order-group-item="order" @update="$emit('get')"
                                                    :orders="order.orders"/>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="Документы" name="docs">
                        <AllPrintDocs
                            from="printDocs"
                            :orderGroupItem="order"
                            :orders="order.orders"
                            :accessDocuments="accessDocuments"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="История" name="log">
                        <div class="mix-order-group-actions__history">
                            <MixOrderGroupLogs ref="mixOrderGroupLogs" :group-id="orderGroup.id" :company-id="order.companyId" :tab="tab" :tz="order.timezone"/>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>

    <el-dialog
        v-model="completedDialog"
        :width="992"
        :destroy-on-close="true"
    >
        <MixOrderGroupCompletedForm
            :group-id="order.id"
            @closeCompletedForm="closeCompletedForm"
        />
    </el-dialog>
</template>

<script>
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import OrderQuantity from "@/views/dispatch/components/OrderQuantity.vue";
import iconsMixin from "@/mixins/iconsMixin";
import ManageDeliveriesOrders from "@/views/dispatch/components/actions/ManageDeliveriesOrders.vue";
import AllPrintDocs from "@/views/dispatch/components/AllPrintDocs.vue";
import MixOrderGroupActionsMap
    from "@/views/components/MixOrderGroup/components/orderGroup/MixOrderGroupActionsMap.vue";
import {httpService} from "@/services/http.service";
import MixOrderGroupLogs from "@/views/components/MixOrderGroup/components/mixOrderGroupLogs/MixOrderGroupLogs.vue";
import MixOrderGroupCompletedForm from "@/views/components/MixOrderGroup/MixOrderGroupCompletedForm.vue";

// icons
import PauseIcon from "@/views/components/Icons/PauseIcon.vue";
import PlayIcon from "@/views/components/Icons/PlayIcon.vue";
import CheckIcon from "@/views/components/Icons/CheckIcon.vue";
import CloseMDIcon from "@/views/components/Icons/CloseMDIcon.vue";

export default {
    name: "MixOrderGroupActions",
    components: {
        MixOrderGroupActionsMap, AllPrintDocs, ManageDeliveriesOrders, OrderQuantity, MixOrderGroupCompletedForm,
        CRMInfoText, MixOrderGroupLogs, PauseIcon, PlayIcon, CheckIcon, CloseMDIcon
    },
    mixins: [functionsMixin, iconsMixin],
    props: ['orderGroup', 'accessDocuments'],
    inject: ['api'],
    computed: {
        moment() {
            return moment
        },
        factories() {
            if (this.order && this.order.orders) {
                return [...new Map(this.order.orders.map(order => [
                    `${order.mix.latitude},${order.mix.longitude}`,
                    {
                        latitude: parseFloat(order.mix.latitude),
                        longitude: parseFloat(order.mix.longitude)
                    }
                ])).values()]
            }
            return [];
        },
        address() {
            if (this.order) {
                return {
                    name: this.order.address,
                    latitude: parseFloat(this.order.latitude),
                    longitude: parseFloat(this.order.longitude)
                }
            } else {
                return null
            }
        },
        coords() {
            if (this.order) {
                return [parseFloat(this.order.latitude), parseFloat(this.order.longitude)]
            } else {
                return null
            }
        },
        companyManagerText() {
        let text = ''
            if(this.order.companyManager.id) {
                text = this.order.companyManager.name

                if(this.order.companyManager.phone) {
                    text += ` (${this.formatPhoneNumber(this.order.companyManager.phone)})`
                }
            } else {
                text = '-'
            }

            return text
        }
    },
    data() {
        return {
            tab: 'orders',
            order: null,
            vehicles: [],
            loadingVehicles: true,
            windowHeight: window.innerHeight,
            completedDialog: false
        }
    },
    created() {
        if (this.order) {
            this.getMapData()

            window.Echo.channel('laravel_database_ChannelMixOrderGroupsLogs')
            .listen(`.CreateNew.${this.orderGroup.id}`, () => {
                if(this.$refs.mixOrderGroupLogs) {
                    this.$refs.mixOrderGroupLogs.getLogs()
                }
            })

            this.$nextTick(function () {
                this.resizeModalContent()
            })
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.resizeModalContent);
    },
    beforeUnmount: function () {
        window.removeEventListener('resize', this.resizeModalContent);
        window.Echo.leave(`laravel_database_ChannelMixOrderGroupsLogs`);
    },
    methods: {
        getMapData() {
            httpService().post(this.api.dispatch.orderGroups.getMapData, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_group_id: this.order.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.values
                    this.loadingVehicles = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingVehicles = false
            })
        },
        editOrder() {
            this.$router.push(
                {
                    name: 'DispatchOrderGroupsUpdate',
                    params: {
                        id: this.$route.params.id,
                        groupId: this.orderGroup.id,
                    }
                }
            );
        },
        distributeOrder() {
            this.$router.push({
                    name: 'ReDistributeOrderGroup',
                    params: {
                        id: this.$route.params.id,
                        groupId: this.orderGroup.id,
                    }
                }
            );
        },
        changeStatus(orderId, status) {
            let message = 'завершить заказ'
            if (status === 'pause') message = 'поставить заказ на паузу'
            if (status === 'failed') message = 'отменить заказ'
            else if (status === 'work') message = 'запустить заказ снова в работу'

            this.$confirm('Вы действительно хотите ' + message + '?', 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                if (status !== 'completed') {
                    this.changeStatusAction(orderId, status)
                } else {
                    this.completedDialog = true
                }
            })
        },
        changeStatusAction(orderId, status) {
            httpService().post(this.api.dispatch.orderGroups.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_id: orderId,
                status: status
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Статус изменен',
                        showClose: true,
                        type: 'success'
                    })
                    this.completedDialog = false
                    this.$emit('get')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        closeCompletedForm() {
            this.$message({
                message: 'Статус изменен',
                showClose: true,
                type: 'success'
            })
            this.completedDialog = false
            this.$emit('get')
        },
        resizeModalContent() {
            const dialogElement = document.querySelector('.group-order-action-dialog__actions > .el-dialog__body');
            const infoElement = document.querySelector('.mix-order-group-actions__info');
            const tabsElement = document.querySelector('.mix-order-group-actions__tabs > .el-tabs__header');

            const dialogHeight = dialogElement?.offsetHeight || 0;
            const tabsHeight = tabsElement?.offsetHeight || 0;

            const elementsHeight = Array.from(infoElement.children)
                .filter(el => !el.classList.contains('mix-order-group-actions__tabs'))
                .reduce((total, item) => total + item.offsetHeight, 0);

            // 30 - высота элемента hr с margin, который не учитывается в offsetHeight
            const availableHeight = dialogHeight - elementsHeight - tabsHeight - 30;

            const ordersElement = document.querySelector('.mix-order-group-actions__orders > .el-scrollbar__wrap');
                    if (ordersElement) {
                        ordersElement.style.maxHeight = `${availableHeight}px`;
                    }

            const historyElements = document.querySelector('.mix-order-group-actions__history');
                if (historyElements) {
                    historyElements.style.maxHeight = `${availableHeight}px`;
                }
        },
        completeNotDone(orderId) {
            this.$confirm('Заказ не завершен и находится в работе. При завершении будут удалены отгрузки из очереди и пересчитан объем. Вы подтверждаете действие?', 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.changeStatusAction(orderId, 'done')
            })
        },
        completeOrCancelOrder(order) {
            if(order.status === 'work') {
                this.completeNotDone(order.id)
            } else {
                this.changeStatus(order.id, (order.status === 'done') || (order.status === 'work' && order.done >= order.total) ? 'completed' : 'failed')
            }
        },
        getProductText(order) {
            if (!order) return '-';

            if (order.output_good_name && order.output_good_name !== order.good) {
                return `${order.output_good_name} (${order.good})`;
            }

            return order.good || '-';
        },
    },
    watch: {
        orderGroup: {
            handler() {
                this.order = this.orderGroup
            },
            immediate: true
        },
    }
}
</script>

<style scoped lang="scss">
.mix-order-group-actions {
    height: 100%;

    &__details {
        height: 100%;
    }

    &__info {
        display: flex !important;
        flex-direction: column;
    }

    &__tabs {
        &:deep(.el-tabs__header) {
            margin: 0;
            padding: 0 0 15px;
        }

        &:deep(.el-tabs__content) {
            flex: 1;
            .el-tab-pane {
                height: 100%;
            }
        }
    }

    &__orders {
        min-height: initial;

        &:deep(.el-scrollbar__wrap) {
            .el-scrollbar__view {
                height: 100%;

                .manage-deliveries {
                    height: 100%;

                    &__orders {
                        &--empty {
                            height: 100%;
                            min-height: initial;
                        }
                    }
                }
            }
        }
    }

    &__history {
        min-height: initial;
        overflow: auto;

        &:deep(.el-scrollbar__wrap) {
            .el-scrollbar__view {
                height: 100%;

                .manage-deliveries {
                    height: 100%;

                    &__history {
                        &--empty {
                            height: 100%;
                            min-height: initial;
                        }
                    }
                }
            }
        }
    }
}
</style>
