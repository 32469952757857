<template>
    <div v-if="loading" class="text-center mt-30">
        <ReportLoader/>
        <h4 class="neutral700">Загрузка..</h4>
    </div>

    <template v-else-if="!loading && service?.id">

        <el-row :gutter="20">
            <el-col :sm="12">
                <CRMInfoText
                    head="Тип тарифа"
                    :text="getInfo(service.type, serviceTypes)"
                />
                <CRMInfoText
                    head="Модель расчета"
                    :text="getInfo(service.depends_on, dependsTypes)"
                />
            </el-col>
            <el-col :sm="12">
                <CRMInfoText
                    head="Тип"
                    :text="getInfo(service.type, serviceTypes)"
                />
                <CRMInfoText
                    head="Модель расчета"
                    :text="getInfo(service.depends_on, dependsTypes)"
                />
            </el-col>
        </el-row>
        <template v-if="service.type === 'mix'">
            <ServiceDetailsMixDistance v-if="service.depends_on === 'distance'" :service="service"/>
            <ServiceDetailsMixOther v-if="service.depends_on === 'other'" :service="service"/>
        </template>
    </template>
</template>

<script>
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import serviceInfo from "@/mixins/serviceInfo";
import functionsMixin from "@/mixins/functionsMixin";
import ServiceDetailsMixDistance
    from "@/views/settings/serviceTariffs/components/details/ServiceDetailsMixDistance.vue";
import ServiceDetailsMixOther from "@/views/settings/serviceTariffs/components/details/ServiceDetailsMixOther.vue";

export default {
    name: 'ServiceTariffDetails',
    props: ['id'],
    inject: ['api'],
    components: {ServiceDetailsMixOther, ServiceDetailsMixDistance, CRMInfoText, ReportLoader},
    mixins: [serviceInfo, functionsMixin],
    data() {
        return {
            loading: true,
            service: {
                id: null,
                name: null,
                company_id: null,
                integration_unit_id: null,
                type: null,
                depends_on: null
            },
        }
    },
    created() {
        this.getOne()
    },
    methods: {
        getOne() {
            if (this.id) {
                this.loading = true
                httpService().post(this.api.serviceTariff.getOne, {
                    id: this.id
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.service = data.service
                        this.loading = false
                    }

                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
