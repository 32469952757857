<template>
    <Auth title="Добавление продукции">
        <div class="card">
            <div class="card-body orderGroupsTable">
                <el-form label-position="top" class="mb-30">
                    <FormSelect
                        label="Что производим?"
                        placeholder="Выберите продукцию"
                        v-model="order.goodId"
                        new-item="good"
                        :new-item-data="order.goodId"
                        :items="goods"
                        :required="true"
                    />

                    <FormNumber
                        label="Сколько единиц продукции получим по выполнению?"
                        placeholder="Укажите количество"
                        v-model="order.total"
                        :precision="2"
                        :required="true"
                    />

                    <p class="textRegular textXS neutral400 mt-0">Вам необходимо указать, сколько единиц продукции
                        получится в результате производства продукции. Например, вы делаете блоки ФБС, и из одного
                        заказа у вас получается 4 блока (по количеству блоков в форме)</p>

                    <el-form-item label="">
                        <el-checkbox v-model="order.oneOrder"
                                     label="Не разделять заказанный объем на отдельные партии по 1 шт"/>
                    </el-form-item>

                    <hr/>

                    <h2>Технология производства</h2>

                    <div class="bg-neutral30 orderGroupItem" style="padding: 20px">
                        <div v-for="(operation, index) in order.operations" :key="index"
                             class="mb-15 orderGroupItem bg-white">
                            <div class="text-right">
                                <CloseIcon color="#c9ccd0" class="pointer" style="margin-bottom: -20px"
                                           @click="removeOperation(index)"/>
                            </div>

                            <el-form-item label="Необходимое действие" :required="true">
                                <el-radio-group v-model="operation.type" class="d-block w-100">
                                    <el-radio :label="actionType.id" v-for="(actionType, indexType) in actionTypes"
                                              @change="changeOperationName(actionType.id, index)"
                                              :key="indexType" border>
                                        {{ actionType.name }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <FormInput
                                label="Название операции (коротко, лучше одним словом)"
                                placeholder="Н-р: сборка"
                                v-model="operation.name"
                                :required="true"
                            />

                            <div v-if="operation.type" class="orderGroupItem">

                                <div v-if="operation.type === 'findOrCreate'">

                                    <FormSelect
                                        label="Необходимый компонент"
                                        placeholder="Выберите продукцию"
                                        v-model="operation.goodId"
                                        new-item="good"
                                        :items="goods"
                                    />

                                    <FormNumber
                                        label="Кол-во"
                                        placeholder="Укажите кол-во"
                                        v-model="operation.total"
                                        :precision="2"
                                    />

                                    <FormSelect
                                        label="С какого склада взять"
                                        placeholder="Выберите модуль"
                                        v-model="operation.storehouseId"
                                        value-key="module_reserve_id"
                                        :items="modules.filter(el => el.module_key === 'storehouse')"
                                    />

                                    <FormSelect
                                        v-if="operation.storehouseId"
                                        label="Выберите внутренний склад"
                                        placeholder="Выберите склад"
                                        v-model="operation.storehouseUnitId"
                                        value-key="module_reserve_id"
                                        :items="getStorehouseUnits(operation.storehouseId)"
                                    />

                                    <hr/>

                                    <el-form-item label="Что делать, если продукции нет на складе?">
                                        <el-radio-group v-model="operation.noGoodOnStorehouseAction"
                                                        class="d-block w-100">
                                            <el-radio label="make" border>Произвести</el-radio>
                                            <el-radio label="buy" border>Закупить</el-radio>
                                        </el-radio-group>
                                    </el-form-item>

                                    <template v-if="operation.noGoodOnStorehouseAction === 'make'">

                                        <FormSelect
                                            label="Где производить"
                                            placeholder="Выберите модуль"
                                            v-model="operation.productionId"
                                            value-key="module_reserve_id"
                                            :items="modules.filter(el => el.module_key === 'production')"
                                        />

                                        <p>Убедитесь, что для этой продукции сущестует карта производства</p>

                                    </template>

                                    <template v-else-if="operation.noGoodOnStorehouseAction === 'buy'">

                                        <p>Компонент будет помечен на складе как недостающий и требующий закупки</p>

                                    </template>

                                </div>

                                <div v-else-if="operation.type === 'concrete'">

                                    <FormSelect
                                        label="Где производить"
                                        placeholder="Выберите модуль"
                                        v-model="operation.mixId"
                                        value-key="module_reserve_id"
                                        :items="modules.filter(el => el.module_key === 'mix')"
                                    />

                                    <FormSelect
                                        v-if="operation.mixId"
                                        label="Рецепт"
                                        placeholder="Выберите рецепт"
                                        v-model="operation.goodId"
                                        value-key="goodId"
                                        :items="getMixGoods(operation.mixId)"
                                    />

                                    <FormNumber
                                        v-if="operation.goodId"
                                        label="Кол-во на одну отгрузку"
                                        placeholder="Укажите кол-во"
                                        v-model="operation.total"
                                        :precision="2"
                                    />

                                    <el-form-item label="">
                                        <el-checkbox v-model="operation.needSecondMixOrder"
                                                     label="Нужен второй слой бетона"/>
                                    </el-form-item>

                                    <template v-if="operation.needSecondMixOrder">
                                        <FormSelect
                                            v-if="operation.mixId"
                                            label="Рецепт"
                                            placeholder="Выберите рецепт"
                                            v-model="operation.secondGoodId"
                                            value-key="goodId"
                                            :items="getMixGoods(operation.mixId)"
                                        />

                                        <FormNumber
                                            v-if="operation.goodId"
                                            label="Кол-во на одно изделие"
                                            placeholder="Укажите кол-во"
                                            v-model="operation.secondTotal"
                                            :precision="2"
                                        />
                                    </template>

                                    <!--                                    <template v-if="operation.goodId">-->

                                    <!--                                        <div-->
                                    <!--                                            v-for="(component, index) in getData(operation.goodId, 'good', 'components')"-->
                                    <!--                                            :key="index">-->
                                    <!--                                            <h4>{{ getData(component.component_good_id, 'good') }}</h4>-->
                                    <!--                                            <el-form-item label="Что делать, если продукции нет на складе?">-->
                                    <!--                                                <el-radio-group v-model="component.noGoodOnStorehouseAction"-->
                                    <!--                                                                class="d-block w-100">-->
                                    <!--                                                    <el-radio label="make" border>Произвести</el-radio>-->
                                    <!--                                                    <el-radio label="buy" border>Закупить</el-radio>-->
                                    <!--                                                </el-radio-group>-->

                                    <!--                                                <template v-if="component.noGoodOnStorehouseAction === 'make'">-->

                                    <!--                                                    <FormSelect-->
                                    <!--                                                        label="Пост производства"-->
                                    <!--                                                        placeholder="Выберите пост"-->
                                    <!--                                                        v-model="component.productionPost"-->
                                    <!--                                                        :items="posts"-->
                                    <!--                                                    />-->

                                    <!--                                                </template>-->

                                    <!--                                                <template v-else-if="component.noGoodOnStorehouseAction === 'buy'">-->

                                    <!--                                                    <p>Компонент будет помечен на складе как недостающий и требующий-->
                                    <!--                                                        закупки</p>-->

                                    <!--                                                </template>-->
                                    <!--                                            </el-form-item>-->
                                    <!--                                        </div>-->

                                    <!--                                    </template>-->

                                </div>

                                <div v-else-if="operation.type === 'production'">

                                    <FormSelect
                                        label="Производственный пост"
                                        placeholder="Выберите пост"
                                        v-model="operation.productionPostId"
                                        :items="posts"
                                        :required="true"
                                    />

                                    <FormSelect
                                        label="На какой склад положить после выполнения"
                                        placeholder="Выберите модуль"
                                        v-model="operation.storehouseId"
                                        value-key="module_reserve_id"
                                        :items="modules.filter(el => el.module_key === 'storehouse')"
                                    />

                                    <FormSelect
                                        v-if="operation.storehouseId"
                                        label="Выберите внутренний склад"
                                        placeholder="Выберите склад"
                                        v-model="operation.storehouseUnitId"
                                        value-key="module_reserve_id"
                                        :items="getStorehouseUnits(operation.storehouseId)"
                                    />

                                    <el-form-item label="">
                                        <el-checkbox v-model="operation.autoComplete"
                                                     label="Завершать этап автоматически"/>
                                    </el-form-item>

                                </div>

                                <div v-else-if="operation.type === 'control'">
                                    <FormSelect
                                        v-if="order.operations.length > 1"
                                        label="С какого склада списать"
                                        placeholder="Выберите модуль"
                                        v-model="operation.oldStorehouseId"
                                        value-key="module_reserve_id"
                                        :items="modules.filter(el => el.module_key === 'storehouse')"
                                    />

                                    <FormSelect
                                        v-if="operation.oldStorehouseId"
                                        label="Выберите внутренний склад"
                                        placeholder="Выберите склад"
                                        v-model="operation.oldStorehouseUnitId"
                                        value-key="module_reserve_id"
                                        :items="getStorehouseUnits(operation.oldStorehouseId)"
                                    />

                                    <FormSelect
                                        label="На какой склад положить после выполнения"
                                        placeholder="Выберите модуль"
                                        v-model="operation.storehouseId"
                                        value-key="module_reserve_id"
                                        :items="modules.filter(el => el.module_key === 'storehouse')"
                                    />
                                </div>
                            </div>
                        </div>

                        <el-link :icon="icons.plus" type="primary" @click="addOperation()">
                            <span class="ml-10">Добавить этап</span>
                        </el-link>
                    </div>


                </el-form>
                <hr/>
                <el-button :disabled="!order.goodId" type="primary" class="mt-15" @click="createGood()">Добавить
                    продукцию
                </el-button>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from "@/views/layouts/Auth.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import CloseIcon from "@/views/components/Icons/CloseIcon.vue";

export default {
    name: "ProductionGoodsCatalogCreate",
    components: {CloseIcon, FormInput, FormNumber, FormSelect, Auth},
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            order: {
                goodId: null,
                total: null,
                oneOrder: false,
                operations: [{
                    name: null,
                    type: null,
                    storehouseId: null,
                    storehouseUnitId: null,
                    oldStorehouseId: null,
                    oldStorehouseUnitId: null,
                    noGoodOnStorehouseAction: null,
                    productionPostId: null
                }]
            },
            actionTypes: [{
                id: 'concrete',
                name: 'Замешать бетон',
                shortName: 'Замес'
            }, {
                id: 'production',
                name: 'Произвести на посту',
                shortName: 'Пост'
            }, {
                id: 'control',
                name: 'Контроль качества',
                shortName: 'ОТК'
            }, {
                id: 'findOrCreate',
                name: 'Добавить компонент со склада',
                shortName: 'Склад'
            }],
            posts: [],
            modules: []
        }
    },
    created() {
        this.getModules()
        this.getPostsAll()
    },
    methods: {
        createGood() {
            this.loading = true

            httpService().post(this.api.productionGood.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                order: this.order
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Продукция добавлена',
                        showClose: true,
                        type: 'success'
                    })

                    this.$router.push({name: 'ProductionGoods', params: {id: this.$route.params.id}})
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getPostsAll() {
            httpService().post(this.api.productionPost.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.posts = data.values
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        addOperation() {
            this.order.operations.push({
                name: null,
                type: null,
                storehouseId: null,
                storehouseUnitId: null,
                noGoodOnStorehouseAction: null,
                productionPostId: null
            })
        },
        removeOperation(index) {
            this.order.operations.splice(index, 1)
        },
        getMixGoods(mixId) {
            let el = this.modules.find(el => el.module_reserve_id === mixId && el.module_key === 'mix')
            if (el) {
                return el.goods
            } else {
                return []
            }
        },
        getStorehouseUnits(storehouseId) {
            let el = this.modules.find(el => el.module_reserve_id === storehouseId && el.module_key === 'storehouse')
            if (el) {
                return el.units
            } else {
                return []
            }
        },
        getModules() {
            httpService().post(this.api.production.getInfoForGoodCreate, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.modules = data.modules
                }

            })
        },
        changeOperationName(actionTypeId, index) {
            let action = this.actionTypes.find(el => el.id === actionTypeId)
            if (this.order.operations[index] && action) {
                this.order.operations[index].name = action.shortName
            }

        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        })
    },
}
</script>

<style scoped>

</style>
