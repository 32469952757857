<template>
    <svg :class="{'currentColor': currentColor}" :width="width ?? '16'" :height="height ?? '16'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 6V4.66667C2 4.29848 2.29848 4 2.66667 4H13.3333C13.7015 4 14 4.29848 14 4.66667V6M2 10V11.3333C2 11.7015 2.29848 12 2.66667 12H13.3333C13.7015 12 14 11.7015 14 11.3333V10"
            stroke="#8A909A" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 10C12.8954 10 12 9.10457 12 8C12 6.89543 12.8954 6 14 6" stroke="#8A909A" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10" stroke="#8A909A" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M9.33325 4V12" stroke="#8A909A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "TariffIcon",
    props: ['currentColor', 'width', 'height']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
