<template>
    <Auth title="Склады" :tabs="tabs" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    create-button-label="Добавить значение"
                    @moduleGet="moduleGet"
                    :disable-filter="true"
                    @openCreateValueDrawer="openCreateDialog"
                    :new="true"
                />

                <div v-if="displayData">
                    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                        <template v-slot:body>
                            <MobileTableHR class="mt-30"/>
                            <MobileTableInfo
                                title="Название склада"
                                :body="item.name"
                                :no-border="true"
                            />

                            <MobileTableButton
                                title="Редактировать"
                                :disabled="true"
                            />
                            <MobileTableButton
                                title="Удалить"
                                :disabled="true"
                            />
                        </template>
                    </MobileTableFramework>
                </div>

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!smallMobile"
                    class="w-100 mt-15"
                >

                    <el-table-column prop="name" label="Название склада" sortable min-width="220px">
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900 cutOverflow maxLines3">
                                {{ scope.row.name }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="" width="110">
                        <template v-slot="scope">
                            <div>
                                <el-tooltip effect="dark" content="Редактировать" placement="top">
                                    <el-button
                                        :icon="icons.edit"
                                        @click="openEdit(scope.row.id)"
                                        size="small"
                                    />
                                </el-tooltip>
                                <el-tooltip effect="dark" content="Удалить" placement="top">
                                    <el-button
                                        size="small"
                                        @click="remove(scope.row.id)"
                                        :icon="icons.delete"
                                    />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
        <el-dialog
            v-model="addStorehouseUnitDialog"
            title="Добавление склада"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <div>
                <el-form>
                    <FormInput
                        v-model="newUnitForm.name"
                        :label-width="125"
                        label="Название склада"
                        placeholder="Название склада"
                        :required="true"
                    />
                    <el-form-item label="Тип склада" :label-width="125" :required="true">
                        <el-select v-model="newUnitForm.defect">
                            <el-option
                                :key="false"
                                label="Обычный"
                                :value="false"
                            />
                            <el-option
                                :key="true"
                                label="Брак"
                                :value="true"
                            />
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="mb-30 d-flex-center">
                    <el-button :loading="loadingButton" type="primary" @click="doCreateStorehouseUnit">Сохранить
                        изменения
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import tableMixin from '../../mixins/tableMixin'
import functionsMixin from '../../mixins/functionsMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import storehouseTabs from "@/mixins/tabs/storehouseTabs";
import TablePagination from "@/views/components/Table/TablePagination";
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import FormInput from "../components/Form/FormInput.vue";

export default {
    name: 'StorehouseUnits',
    components: {
        FormInput,
        TableHeadBlock,
        Auth,
        TablePagination,
        MobileTableFramework,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, functionsMixin, mobileCheckMixin, iconsMixin, storehouseTabs
    ],
    data() {
        return {
            loading: false,
            loadingButton: false,
            pageSize: 30,
            newUnitForm: {
                id: null,
                name: null,
                defect: false
            },
            addStorehouseUnitDialog: false,
            mockData: [
                {"id": 1, "name": "Основной склад"},
                {"id": 2, "name": "Склад цемента"},
                {"id": 3, "name": "Склад инертных материалов"},
                {"id": 4, "name": "Склад добавок"},
                {"id": 5, "name": "Резервный склад"},
                {"id": 6, "name": "Склад песка"},
                {"id": 7, "name": "Склад гравия"},
                {"id": 8, "name": "Склад щебня"},
                {"id": 9, "name": "Склад воды"},
                {"id": 10, "name": "Склад химических добавок"},
                {"id": 11, "name": "Склад металлических конструкций"},
                {"id": 12, "name": "Склад инструментов"},
                {"id": 13, "name": "Склад запасных частей"},
                {"id": 14, "name": "Склад топлива"},
                {"id": 15, "name": "Временный склад"},
            ]
        }
    },
    created() {
        this.moduleGet()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        async moduleGet() {
            this.loading = true
            let url = this.api.storehouse.getUnits
            try {
                const response = await httpService().post(url, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'storehouse',
                });

                let data = response.data;
                if (data.success) {
                    this.values = data.units;
                    this.valueLength = data.units.length;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        async openEdit(id) {
            await this.getOne(id);
            this.addStorehouseUnitDialog = true;
        },
        async getOne(id) {
            try {
                const response = await httpService().post(this.api.storehouseUnits.getOne, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'storehouse',
                    id: id
                });

                if (!response.data || !response.data.unit) {
                    throw new Error('Данные единицы склада не найдены');
                }

                const data = response.data;
                this.newUnitForm.id = data.unit.id;
                this.newUnitForm.name = data.unit.name;
                this.newUnitForm.defect = data.unit.defect;
            } catch (error) {
                this.$message({
                    message: error.response?.data?.message || error.message || 'Произошла ошибка',
                    type: 'error',
                    showClose: true
                });
            }
        },

        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        doCreateStorehouseUnit() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.storehouseUnits.createOrUpdate,
                {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'storehouse',
                    ...this.newUnitForm,
                })
                .then((responce) => {
                    if (responce.data.success) {
                        this.$message({
                            message: 'Сохранено',
                            showClose: true,
                            type: 'success'
                        })
                        this.addStorehouseUnitDialog = false
                        window.setTimeout(() => {
                            this.moduleGet()
                        }, 50)

                        this.loading = false
                        this.loadingButton = false
                    }
                }).catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const fieldErrors = error.response.data.errors;

                    Object.keys(fieldErrors).forEach((field) => {
                        fieldErrors[field].forEach((message) => {
                            this.$message({
                                message: `${message}`,
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: false
                            });
                        });
                    });
                } else {
                    this.$message({
                        message: error.response?.data?.message,
                        type: 'error',
                        showClose: true
                    });
                }
                this.loading = false
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm(
                'Удалить значение и пересчитать количество данной продукции?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            ).then(() => {
                    this.loading = true
                    httpService().post(this.api.storehouseUnits.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'storehouse',
                        id: id
                    }).then(() => {
                        window.setTimeout(() => {
                            this.moduleGet()
                        }, 50)
                    }).catch((error) => {
                        this.$message({
                            message: error.response?.data?.message,
                            type: 'error',
                            showClose: true
                        });
                    })
                    this.loading = false
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        openCreateDialog() {
            this.newUnitForm.id = null
            this.newUnitForm.name = null
            this.newUnitForm.defect = false

            this.addStorehouseUnitDialog = true
        }
    },
}
</script>

<style scoped>

</style>
