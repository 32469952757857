<template>
    <h2 class="mt-0 mb-2">Денежный учет</h2>
    <p>Данный модуль дает возможность вести финансовый учет операций, автоматически подсчитывать
        баланс. Модуль будет полезен для учета наличных средств, небольших касс или отображения
        финансовых операций из 1с Бухгалтерия (при подключении соответствующей интеграции).</p>
</template>

<script>
export default {
    name: "CashboxFaq",
    data() {
        return {}
    },
}
</script>

<style scoped>

</style>
