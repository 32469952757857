<template>
    <div class="goodsCreateComponent">
        <p class="neutral400 mb-5 mt-20">Сырье для производства:</p>
        <el-form-item class="mb-30 goodsCreateComponent__form">
                <el-row gutter="10" v-for="(component, index) in components" :key="index">
                    <el-col :sm="14">

                        <FormSelect
                            label="Продукция"
                            placeholder="Выберите"
                            new-item="good"
                        :items="goods"
                            v-model="component.goodId"
                        />

                    </el-col>
                    <el-col :sm="10">
                        <div class="d-flex">

                            <FormNumber
                                class="mr-10"
                                v-bind:class="{ 'w-100': mobile }"
                                label="Количество"
                                placeholder="10 000"
                                v-model="component.total"
                            />

                            <el-form-item label="Удалить" v-bind:class="{ 'hideLabel': mobile }" label-width="0">
                                <el-button class="goodsCreateComponent__delete-btn" :loading="loadingButton" type="danger" :icon="icons.delete" plain
                                           @click.prevent="deleteComponent(index, component)">
                                </el-button>
                            </el-form-item>
                        </div>
                    </el-col>
                </el-row>
                <el-button @click="addNewComponent" :icon="icons.plus" class="w-100-btn">Добавить продукцию</el-button>
        </el-form-item>
    </div>
</template>

<script>
import FormNumber from '../../Form/FormNumber'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import iconsMixin from "@/mixins/iconsMixin";
import {mapState} from "pinia/dist/pinia";
import {useCommonStore} from "@/store/common";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import _ from "lodash";

export default {
    name: 'GoodsCreateComponent',
    components: {FormSelect, FormNumber},
    props: [
        'goodComponents'
    ],
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        })
    },
    data() {
        return {
            loading: false,
            loadingButton: false,
            components: [{
                goodId: '',
                total: '',
            }],
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    created() {
        if (this.goodComponents) {
            if (this.goodComponents.length > 0) this.components = _.cloneDeep(this.goodComponents)
        }
    },
    methods: {
        addNewComponent() {
            this.components.push({
                //goodId: '',
                total: '',
            })
        },
        deleteComponent(index, value) {
            var idx = this.components.indexOf(value);
            if (idx > -1) {
                this.components.splice(idx, 1);
            }
        },
    },
    watch: {
        'components': {
            handler: function () {
                this.$emit('components', {components: this.components})
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.buttonAdd {
    width: 100%;
}

.goodsCreateComponent {
    &__form {
        &:deep(.el-form-item__content) {
            display: block;

            .el-row {
                width: 100%;
            }
        }
    }

    &__delete-btn {
        height: 38px !important;
        width: 50px !important;
    }
}
</style>
