<template>
    <div :class="['mix-driver-task p-4 bg-white br-12']"
         style="margin-bottom: 0">
        <div :class="['d-flex d-flex-direction-column gap-4', { 'mix-driver-task_unconfirmed': !order.confirm }]">
            <div class="d-flex justify-between gap-4">
                <div class="d-flex gap-4 flex-wrap">
                    <Label v-if="order.id" class="br-t-l-8" :text="`#${order.id}`"/>
                    <Label v-if="!order.confirm" text="Не подтверждена"/>
                </div>

                <!-- <Label v-if="isDelayed && order.status === 'new'"
                        class="br-t-l-8 flex-wrap justify-center"
                        :text="`Задержка - ${factTime}`"
                        :icon="triangleWarningIcon"
                        color="red" /> -->

                <div v-if="order.pump || order.comment_driver" class="labels d-flex gap-4">
                    <Label class="pl-12 pr-12" v-if="order.pump" :text="fullLabels || order.status === 'done' ? 'Под насос' : 'Н'" color="yellow"/>
                    <Label v-if="order.comment_driver" color="blue" :text="fullLabels || order.status === 'done' ? 'Есть комментарий' : null" :icon="chatCircleDotsIcon"/>
                </div>
            </div>

            <article class="time-info-container d-flex gap-4">
                <div v-if="order.status === 'new'" class="time-info br-4 p-8 d-flex align-center deep-blue700">
                    <p :class="['time', { danger300: isDelayed && order.status === 'new'}]">
                        <!-- plannedTime -->
                        {{ statuses[currentStatus]?.time }}
                    </p>
                </div>

                <div class="delivery-info d-flex d-flex-direction-column justify-between br-4 flex-1 p-4">
                    <div v-if="!detailsHidden"
                         class="d-flex gap-8 align-center location-item bg-neutral30 flex-wrap">
                        <div v-if="order.factory_name || order.mix_name || order.mixer_name"
                             class="mix-driver-task-detailed__body-card-loading">
                            <BuildingIcon :current-color="true" width="16px" height="16px"/>
                            <NewMixDriverTaskLoadingPlace
                                :factory_name="order.factory_name"
                                :mix_name="order.mix_name"
                                :mixer_name="order.mixer_name"/>
                        </div>
                    </div>

                    <div class="d-flex justify-between p-8 br-4 flex-1 flex-wrap">
                        <div class="d-flex pt-4 pb-4 gap-4 align-center">
                            <BoxIcon/>
                            <el-text class="neutral500">{{ `${order.total ?? 0} м³` }}</el-text>
                        </div>
                        <div class="d-flex pt-4 pb-4 gap-4 align-center">
                            <MixerCarIcon/>
                            <el-text class="neutral500">
                                {{ getVehicleNumber(order.vehicle_number) ?? "" }}
                            </el-text>
                        </div>
                    </div>
                </div>
            </article>

            <div v-if="!detailsHidden" class="location-info d-flex d-flex-direction-column gap-2">
                <div class="location-item d-flex p-8 gap-8 align-center bg-neutral30">
                    <UserSquareIcon/>
                    <el-text class="neutral700" size="large">{{ order.company_name ?? "" }}</el-text>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "../../../../mixins/functionsMixin";
import Label from "@/views/components/Label/Label.vue";
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import BoxIcon from "@/views/components/Icons/BoxIcon.vue";
import MixerCarIcon from "@/views/components/Icons/MixerCarIcon.vue";
import UserSquareIcon from "@/views/components/Icons/UserSquareIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import TriangleWarningIcon from "@/views/components/Icons/Notifications/TriangleWarningIcon.vue";
import NewMixDriverTaskLoadingPlace from "./NewMixDriverTaskLoadingPlace.vue";

export default {
    name: "NewMixDriverTask",
    components: {
        Label,
        BoxIcon,
        MixerCarIcon,
        UserSquareIcon,
        BuildingIcon,
        NewMixDriverTaskLoadingPlace,
    },
    props: ["order", "detailsHidden", 'fullLabels'],
    mixins: [functionsMixin],
    data() {
        return {
            chatCircleDotsIcon: ChatCircleDotsIcon,
            triangleWarningIcon: TriangleWarningIcon,
            buttonText: this.text,
            statuses: [
                {
                    key: 'new',
                    time: this.getTime('new'),
                    caption: 'Очередь'
                },
                {
                    key: 'done',
                    time: this.getTime('done'),
                    caption: 'Завершена'
                }

            ],
        };
    },
    created() {
    },
    methods: {
        getOrderTimeByStatus(status) {
            let time = null;
            switch (status) {
                case 'new':
                    time = this.order.start_at ?? null
                    break
                case 'done':
                    time = this.order.return_at ?? null
                    break
            }
            return time
        },
        getTime(status) {
            const time = this.getOrderTimeByStatus(status)

            if (!time) {
                return null;
            }
            return time.split(' ')[1].slice(0, 5);
        },
        orderUpdateStatus() {
            this.statuses = [
                {
                    key: 'new',
                    time: this.getTime('new'),
                    caption: 'Очередь'
                },
                {
                    key: 'done',
                    time: this.getTime('done'),
                    caption: 'Завершена'
                }
            ]
        },
    },
    computed: {
        status() {
            return this?.statuses[this.currentStatus]?.caption
        },
        // plannedTime() {
        //     return moment.parseZone(this?.order.plan_date_start).format("HH:mm")
        // },
        // factTime() {
        //     return moment.parseZone(this?.order.start_at).format("HH:mm")
        // },
        isDelayed() {
            // return moment(this?.order.start_at).diff(moment(this?.order.plan_date_start))
            return false
        },
        currentStatus() {
            const currentStatus = this.statuses.find((status) => status.key === this.order.status)
            return this.statuses.indexOf(currentStatus)
        },
    },
    watch: {
        'order': {
            handler: function () {
                this.orderUpdateStatus();
            }
        },
        deep: true
    },
};
</script>
