<template>
    <Auth title="Обороты" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    create-button-label="Добавить значение"
                    @moduleGet="moduleGet(true)"
                    :disable-filter="true"
                    :disable-create-button="true"
                    :new="true"
                    >
                    <template v-slot:afterSearchAndFilter>
                        <FilterDatePeriod
                            class="ml-10"
                            v-model="date"
                            @update-date="updateDateFilter"
                        />
                    </template>
                </TableHeadBlock>

                <div v-if="values">
                    <MobileTableFramework v-for="(item, index) in values" :key="index">
                        <template v-slot:body>
                            <MobileTableHR class="mt-30"/>
                            <MobileTableInfo
                                title="Продукция"
                                :body="item.product.name"
                            />

                            <MobileTableInfo
                                title="Ед. измерения"
                                :body="item.product.unit.name"
                                :no-border="true"
                            />

                            <MobileTableHR/>

                            <MobileTableInfo
                                title="Начало периода"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :no-border="true"
                                :body="item.startPeriod.count"
                            />

                            <MobileTableInfo
                                title="Сумма"
                                :no-border="true"
                                :body="item.startPeriod.sum"
                            />

                            <MobileTableHR/>

                            <MobileTableInfo
                                title="Приход"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :no-border="true"
                                :body="item.coming.count"
                            />

                            <MobileTableInfo
                                title="Сумма"
                                :no-border="true"
                                :body="item.coming.sum"
                            />

                            <MobileTableHR/>

                            <MobileTableInfo
                                title="Расход"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :no-border="true"
                                :body="item.spending.count"
                            />

                            <MobileTableInfo
                                title="Сумма"
                                :no-border="true"
                                :body="item.spending.sum"
                            />

                            <MobileTableHR/>

                            <MobileTableInfo
                                title="Конец периода"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :no-border="true"
                                :body="item.endPeriod.count"
                            />

                            <MobileTableInfo
                                title="Сумма"
                                :no-border="true"
                                :body="item.endPeriod.sum"
                            />
                        </template>
                    </MobileTableFramework>
                </div>

            <el-table
                v-loading="loading"
                :data="values"
                v-if="!smallMobile"
                class="w-100 mt-15"
            >

                <el-table-column prop="product" label="Продукция" sortable min-width="220px">
                    <template v-slot="scope">
                        <span class="textMedium textSM neutral900 cutOverflow maxLines3">
                            {{ scope.row.product.name }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="storehouse.name" label="Склад" sortable min-width="220px">
                    <template v-slot="scope">
                        <span class="textMedium textSM primary600 cutOverflow maxLines3">
                            {{ scope.row.storehouse.name }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="product.unit.name"
                    label="Ед. измерения"
                    sort-by="product.unit.name"
                    width="140px"
                    sortable>
                    <template v-slot="scope">
                        <span class="textMedium textSM">
                            {{ scope.row.product.unit.name ?? 'ед.' }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="Начало периода">
                    <el-table-column
                        prop="startPeriod"
                        label="Кол-во"
                        width="120">
                        <template v-slot="scope">
                            <span class="textMedium">
                                <span :class="[scope.row.startPeriod.count ? 'neutral900' : 'neutral400']">{{ getPrice(scope.row.startPeriod.count) }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="startPeriod"
                        label="Сумма"
                        width="120">
                        <template v-slot="scope">
                            <span>
                                {{ getPrice(scope.row.startPeriod.sum) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column label="Приход">
                    <el-table-column
                        prop="coming"
                        label="Кол-во"
                        width="120">
                        <template v-slot="scope">
                            <span class="textMedium">
                                <span :class="[scope.row.coming.count ? 'success400' : 'neutral400']">{{ getPrice(scope.row.coming.count) }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="coming"
                        label="Сумма"
                        width="120">
                        <template v-slot="scope">
                            <span>
                                {{ getPrice(scope.row.coming.sum) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column label="Расход">
                    <el-table-column
                        prop="spending"
                        label="Кол-во"
                        width="120">
                        <template v-slot="scope">
                            <span class="textMedium">
                                <span :class="[scope.row.spending.count ? 'danger300' : 'neutral400']">{{ getPrice(scope.row.spending.count) }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="spending"
                        label="Сумма"
                        width="120">
                        <template v-slot="scope">
                            <span>
                                {{ getPrice(scope.row.spending.sum) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column label="Конец периода">
                    <el-table-column
                        prop="endPeriod"
                        label="Кол-во"
                        width="120">
                        <template v-slot="scope">
                            <span class="textMedium neutral900">
                                <span>{{ getPrice(scope.row.endPeriod.count) }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sum"
                        label="Сумма"
                        width="120">
                        <template v-slot="scope">
                            <span>
                                {{ getPrice(scope.row.endPeriod.sum) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>

            </el-table>

            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                @action="setPage"
            />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import tableMixin from '../../mixins/tableMixin'
import functionsMixin from '../../mixins/functionsMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import storehouseTabs from "@/mixins/tabs/storehouseTabs";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import TablePagination from "@/views/components/Table/TablePagination";
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import FilterDatePeriod from "@/views/components/Table/Filter/FilterDatePeriod";
import moment from "moment";

export default {
    name: 'StorehouseTurnover',
    components: {
        Auth, TableHeadBlock, TablePagination,
        MobileTableFramework,
        MobileTableInfo,
        MobileTableHR,
        FilterDatePeriod
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, functionsMixin, mobileCheckMixin, iconsMixin, storehouseTabs
    ],
    data() {
        return {
            loading: false,
            pageSize: 30,
            filter: {
                date: {
                    value: moment.tz(this.moduleTimezone).startOf("month"),
                    type: 'month'
                },
            },
            date: moment.tz(this.moduleTimezone).startOf("month"),
        }
    },
    created() {
        this.moduleGet(true)
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 60000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        async moduleGet(showLoading) {
                if (showLoading) this.loading = true;
                let url = this.api.storehouse.getTurnover;

                try {
                    const response = await httpService().post(url, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'storehouse',
                        paginate: true,
                        page: this.page,
                        pageSize: this.pageSize,
                        search: this.search,
                        filter: this.filter
                    });

                    let data = response.data;
                    if (data.success) {
                        this.values = data.values.data;
                        this.valueLength = data.values.total;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
        },

        filterValues() {},
        getPrice(price) {
            return price ? this.formatNumber(price) : 0
        },
        updateDateFilter(value, type) {
            this.filter.date.type = type
            if(type === 'daterange') {
                this.filter.date.value = [
                    value[0],
                    value[1],
                ]
            } else this.filter.date.value = value
        }
    },
    watch: {
        pageSize() {
            this.moduleGet(true)
        },
        page() {
            this.moduleGet(true)
        },
        search() {
            this.moduleGet()
        },
        'date': {
            handler(val, prevVal) {
                if(val !== prevVal) {
                    this.moduleGet(true)
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
