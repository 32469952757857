<template>
    <div v-loading="loading">
        <h2 class="neutral900 mt-0 mb-5">Подтвердите данные</h2>
        <p class="neutral300 mt-0 mb-20">Подтвердите отгрузки, товары и услуги по заказу:</p>
        <el-tabs v-model="activeTab">
            <el-tab-pane label="Отгрузки" name="orders">
                <OrderMixOrders :order-group-item="order" :paginate="true" :orders="orders ?? []"/>
            </el-tab-pane>
            <el-tab-pane label="Товары" name="goods">
                <OrderServices
                    :goods-type="true"
                    :group-id="groupId"
                    :goods="order.services.filter(el => el.type === 'good')"
                    :count="count"
                    :cost="cost"
                    :orders="orders ?? []"
                    @update="getOrder()"
                    :loading="loading"
                    :paginate="true"
                />
            </el-tab-pane>
            <el-tab-pane label="Услуги" name="services">
                <OrderServices
                    :group-id="groupId"
                    :services="order.services.filter(el => el.type !== 'good')"
                    :orders="orders ?? []"
                    @update="getOrder()"
                    :loading="loading"
                    :paginate="true"
                    :count="count"
                    :cost="cost"
                />
            </el-tab-pane>
            <el-tab-pane label="1C" name="oneC" v-if="oneCNeed">
                <template #label>
                    <div class="d-flex" style="gap: 10px">
                        <div>1C</div>
                        <div>
                            <GreenSuccessIcon v-if="!checkButtonDisable" />
                            <RedWarningIcon v-else />
                        </div>
                    </div>
                </template>

                <p class="textXS neutral400">
                    Данный заказ участвует в обмене данных с 1С. Проверьте, что в нем указаны
                    все необходимые данные для проведения реализации:
                </p>

                <el-form class="pb-40" label-position="top">

                    <div v-for="(attribute, index) in attributesRequired" :key="index">
                        <FormSelect
                            v-if="attribute === 'seller'"
                            v-model="order.sellerCompanyId"
                            label="Поставщик"
                            placeholder="Выберите поставщика"
                            new-item="company"
                            :new-item-data="order.sellerCompanyId"
                            company-type="seller"
                        />

                        <FormSelect
                            v-if="attribute === 'invoice'"
                            v-model="order.invoiceId"
                            :disabled="!order.companyId"
                            label="Счет"
                            :placeholder="!order.companyId ? 'Выберите контрагента' : 'Выберите счет'"
                            new-item="document"
                            :new-item-data="order.invoiceId"
                            :carrier-company="order.companyId"
                            :items="[]"
                            size="large"
                            document-type="invoice"
                        />

                        <FormSelect
                            v-if="attribute === 'contract'"
                            v-model="order.contractId"
                            :disabled="!order.companyId"
                            label="Договор поставки"
                            :placeholder="!order.companyId ? 'Выберите контрагента' : 'Выберите договор'"
                            new-item="document"
                            :new-item-data="order.contractId"
                            :carrier-company="order.companyId"
                            :items="[]"
                            size="large"
                            document-type="contract"
                        />

                        <FormNumber
                            v-if="attribute === 'productPrice'"
                            v-model="order.productPrice"
                            label="Стоимость продукции"
                            placeholder="Укажите стоимость за 1 м3"
                            precision="2"
                        />

                        <FormSelect
                            v-model="order.paymentMethod"
                            v-if="attribute === 'paymentMethod'"
                            label="Метод оплаты"
                            placeholder="Выберите метод"
                            :items="paymentMethods"
                        />
                    </div>
                </el-form>

            </el-tab-pane>
        </el-tabs>
        <el-button
            class="saveButton"
            :disabled="checkButtonDisable"
            :icon="icons.check"
            @click="changeStatusAction()"
            type="primary">
            Подтвердить и завершить заказ
        </el-button>
    </div>
</template>


<script>
import {httpService} from "@/services/http.service";
import OrderMixOrders from "@/views/components/MixOrderGroup/components/order/OrderMixOrders.vue";
import OrderServices from "@/views/components/MixOrderGroup/components/order/OrderServices.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import paymentMethods from "@/mixins/paymentMethods";

// icons
import iconsMixin from "@/mixins/iconsMixin";
import GreenSuccessIcon from "@/views/components/Icons/GreenSuccessIcon.vue";
import RedWarningIcon from "@/views/components/Icons/RedWarningIcon.vue";

export default {
    name: "MixOrderGroupCompletedForm",
    components: {FormNumber, FormSelect, OrderServices, OrderMixOrders, GreenSuccessIcon, RedWarningIcon},
    mixins: [iconsMixin, paymentMethods],
    props: ['groupId'],
    inject: ['api'],
    data() {
        return {
            loading: true,
            activeTab: 'orders',
            attributes: [],
            order: {
                id: this.groupId,
                paymentMethod: null,
                companyId: null,
                goodId: null,
                total: 0,
                done: 0,
                status: null,
                services: []
            },
            events: [],
            orders: [],
            users: [],
            info: {
                pump: false,
                deliveryAddressId: null,
                companyManager: null,
                mixIds: [],
                managerId: null,
                productPrice: 0,
                deliveryPrice: 0,
                deliveryType: null,
                prepayment: 0,
                gidrolotok: false,
                intervalType: null,
                timeFreeUnloading: 0,
                timeIntervalClient: 0,
                maxVolume: null,
                maxAxle: null,
                commentDriver: null
            },
            oneCNeed: false,
            oneCSettings: null,
            attributesRequired: [],
            count: null,
            cost: null,
        }
    },
    async created() {
        await this.getOrder()
        await this.checkOneCIntegration()
    },
    computed: {
        checkButtonDisable() {
            let result = false
            if (this.oneCNeed) {
                this.attributesRequired.forEach(el => {
                    if (!result && el === 'seller' && !this.order.sellerCompanyId) {
                        result = true
                    } else if (!result && el === 'invoice' && !this.order.invoiceId) {
                        result = true
                    } else if (!result && el === 'contract' && !this.order.contractId) {
                        result = true
                    } else if (!result && el === 'productPrice' && (!this.order.productPrice || this.order.productPrice === 0)) {
                        result = true
                    } else if (!result && el === 'paymentMethod' && !this.order.paymentMethod) {
                        result = true
                    }
                })
            }

            return result
        },
    },
    methods: {
        async getOrder() {
            await httpService().post(this.api.mixOrderGroup.getOrder, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                type: 'full'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.order = data.order
                    this.events = data.order.events
                    this.orders = data.order.orders
                    this.info = data.order.info
                    this.count = data.count;
                    this.cost = data.cost;
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        async checkOneCIntegration() {
            await httpService().post(this.api.mixOrderGroup.checkOneCIntegration, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.oneCNeed = true
                    this.oneCSettings = data.settings
                    this.attributesRequired = data.attributes
                    this.loading = false
                } else {
                    this.loading = false
                }
            }).catch((error) => {
                this.loading = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        changeStatusAction() {
            this.loading = true

            httpService().post(this.api.mixOrderGroup.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                status: 'completed',
                order: this.order
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$emit('closeCompletedForm')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>
