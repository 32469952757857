<template>
    <div>
        <div class="add-shipping">
            <div class="add-shipping__header__title neutral900 w-100">
                <div class="d-flex-full align-center">
                    <div v-if="isUpdateMode" class="cutOverflow">
                        Подробности отгрузки
                        #{{ formatNumber(details.id) }} -
                        {{
                            orderGroup?.company?.name ?? orderGroup?.company
                        }}
                    </div>
                    <div v-else>
                        Заказ
                        #{{ formatNumber(orderGroup.id) }} -
                        {{
                            orderGroup?.company?.name ?? orderGroup?.company
                        }}
                    </div>
                </div>
            </div>

            <div class="add-shipping__content">
                <h3 class="textMD textMedium neutral700 mt-0">{{isUpdateMode ? 'Редактирование' : 'Новая отгрузка'}}</h3>
                <hr class="add-shipping__content__hr"/>
                <div>
                    <el-row :gutter="16">
                        <!-- Объём -->
                        <el-col :sm="windowWidth  < 1900 ? 5 : 4" class="d-flex gap-8 align-center addDeliveryFormCol">
                            <div class="addDeliveryFormItem flex-1 addDeliveryFormTotal">
                                <div>
                                    <el-tooltip effect="dark" content="Объём" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <PackageIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <el-input-number
                                    v-model="details.total"
                                    placeholder="0"
                                    :step="0.1"
                                    controls-position="right"
                                />
                            </div>
                            <el-tooltip v-if="!isClientTotalInputActive && appSettings?.volumeCorrection === 'dispatcher'" popper-class="content__delivery__item__tooltip" effect="dark" content="Объём" placement="top" :disabled="isClientTotalInputActive">
                                <template #content>
                                    <p>Корректировка для учёта различия данных у заявки для оборудования и для учетной документации.</p>
                                    <p><b>Объём заявки</b> - фактический объём, который необходимо отгрузить на БСУ. <b>Запрошенный объём</b> - объём для документации.</p>
                                </template>
                                <EditIcon class="pointer editIcon neutral500" width="20" height="20" :currentColor="true"
                                          @click="isClientTotalInputActive = true"/>
                            </el-tooltip>
                            <el-tooltip v-if="isClientTotalInputActive" popper-class="content__delivery__item__tooltip" effect="dark" content="Объём" placement="top">
                                <template #content>
                                    <p>Корректировка для учёта различия данных у заявки для оборудования и для учетной документации.</p>
                                    <p><b>Объём заявки</b> - фактический объём, который необходимо отгрузить на БСУ.</p>
                                </template>
                                <QuestionIcon class="questionIcon" />
                            </el-tooltip>
                        </el-col>
                        <el-col v-if="isClientTotalInputActive && appSettings?.volumeCorrection === 'dispatcher'"
                            :sm="windowWidth  < 1900 ? 5 : 4" class="d-flex gap-8 align-center addDeliveryFormCol">
                            <div class="addDeliveryFormItem addDeliveryFormTotal flex-1">
                                <div class="addDeliveryFormItemIcon">
                                    <PackageIcon />
                                </div>
                                <el-input-number
                                    v-model="details.client_total"
                                    placeholder="0"
                                    :step="0.1"
                                    controls-position="right"
                                />
                            </div>
                             <el-tooltip popper-class="content__delivery__item__tooltip" effect="dark" content="Объём" placement="top">
                                <template #content>
                                    <p><b>Запрошенный объём</b> - объём для документации.</p>
                                </template>
                                 <QuestionIcon class="questionIcon" />
                            </el-tooltip>
                        </el-col>

                        <!-- Завод -->
                        <el-col :sm="(windowWidth < 1900 || !isLab) ? 8 : 6" v-if="orderMixersAmount">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('mix')">
                                <div>
                                    <el-tooltip effect="dark" content="Завод" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <BuildingIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.mixer_id && isUpdateMode" class="neutral200">Выберите завод</div>
                                    <div class="cutOverflow" v-else>
                                        {{
                                            isUpdateMode
                                                ? mixerName(details.factory_name, details.mix.mix_name, details.mixer.mixer_name)
                                                : getLabel('mix')
                                        }}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <!-- Время загрузки -->
                        <el-col :sm="windowWidth < 1900 ? 5 : 4" :style="{ minWidth: '150px', maxWidth: mobile ? '100%' : '250px' }">
                            <el-row gutter="10">
                                <el-col :span="24">
                                    <div class="addDeliveryFormItem addDeliveryFormTotal">
                                        <div>
                                            <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                                <div class="addDeliveryFormItemIcon">
                                                    <ArrowDownRightIcon class="neutral700" :currentColor="true" :width='20' :height='20' :strokeWidth='1' />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                        <el-input
                                            v-model="details.start_at"
                                            placeholder="00:00"
                                            type="time"
                                            :disabled="!details.total"
                                        />
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>

                        <!-- Рецепт -->
                        <el-col v-if="!isLab" :sm="(!orderMixersAmount && windowWidth < 1900) || !isLab && (!isLab && orderMixersAmount) ? 8 : 6">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('good')">
                                <div>
                                    <el-tooltip effect="dark" content="Рецепт" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <RecipeIcon class="neutral700" width="20" height="20" :currentColor="true" :strokeWidth="2"/>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.good?.id" class="neutral200">
                                        Выберите рецепт
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ details?.good?.name ?? '-' }}
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <!-- Заполняет пустое пр-во для переноса строки, если рецепта нет -->
                        <el-col v-if="showHiddenBlock" :sm="!orderMixersAmount ? 5 : 4"/>

                        <!-- Авто -->
                        <el-col :sm="windowWidth < 1900 ? 5 : 4">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('vehicle')">
                                <div>
                                    <el-tooltip effect="dark" content="ТС" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <SolidMixerIcon class="neutral700" :currentColor="true" :width='20' :height='20' />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.vehicle_id" class="neutral200">
                                        <span v-if="orderType !== 'take-away'">Выберите авто</span>
                                        <span v-else>Авто</span>
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ getFormattedVehicleNumber(details.number) ?? '-' }}
                                    </div>
                                </div>
                                <div
                                    class="inputClearBtn"
                                    v-show="details.vehicle_id && orderType === 'take-away'"
                                    @click.stop="clearInput('vehicle_id')">
                                    <CloseRoundIcon/>
                                </div>
                            </div>
                        </el-col>

                        <!-- Водитель -->
                        <el-col :sm="windowWidth < 1900 ? 8 : 6">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('driver')">
                                <div>
                                    <el-tooltip effect="dark" content="Водитель" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <SolidUserProfileIcon class="neutral700" :currentColor="true" :width='20' :height='20'  :strokeWidth="2"/>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText">
                                    <div v-if="!details.driver?.id && !details.driver_id" class="neutral200">
                                        <span v-if="orderType !== 'take-away'">Выберите водителя</span>
                                        <span v-else>Водитель</span>
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ driverName }}
                                    </div>
                                </div>
                                <div
                                    class="inputClearBtn"
                                    v-show="details.driver_id && orderType === 'take-away'"
                                    @click.stop="clearInput('driver_id')">
                                    <CloseRoundIcon/>
                                </div>
                            </div>
                        </el-col>

                        <!-- Тариф -->
                        <el-col v-if="orderType !== 'take-away'" :sm="windowWidth < 1900 ? 8 : 6">
                            <div class="addDeliveryFormItem pointer" @click="openSelectDialog('service')">
                                <div>
                                    <el-tooltip effect="dark" content="Тариф на доставку" placement="top">
                                        <div class="addDeliveryFormItemIcon">
                                            <TariffIcon class="neutral700" :currentColor="true" :width='22' :height='22'/>
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="addDeliveryFormItemInputText cutOverflow">
                                    <div v-if="!details.service_id" class="neutral200">
                                        Выберите тариф
                                    </div>
                                    <div class="cutOverflow" v-else>
                                        {{ details.service.name }}
                                    </div>
                                </div>
                                <div class="inputModalClear" v-if="details.service_id"  @click.stop="clearInput('service_id')">
                                    <i class="el-icon el-input__icon el-input__clear">
                                        <CloseRoundIcon/>
                                    </i>
                                </div>
                            </div>
                        </el-col>

                    </el-row>
                </div>

                <hr class="add-shipping__content__hr mt-0" v-if="!isUpdateMode"/>

                <div class="add-shipping__content__order-terms" v-if="!isUpdateMode">
                    <div class="add-shipping__content__order-terms__title">
                        <div class="add-shipping__content__order-terms__title__item">
                            <ArrowDownRightIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" :width='20' :height='20' :strokeWidth="1"/>
                            <div class="neutral900"><span class="neutral300">Время на загрузку:</span> {{ timeInfo.loading }} мин</div>
                        </div>
                        <div class="add-shipping__content__order-terms__title__item">
                            <RouteIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :strokeWidth="1" :currentColor="true" :width='20' :height='20' />
                            <div class="neutral900"><span class="neutral300">До объекта:</span> {{ timeInfo.dist }} км</div>
                        </div>
                        <div class="add-shipping__content__order-terms__title__item">
                            <ClockIcon class="add-shipping__content__order-terms__title__item__icon neutral300" :currentColor="true" :width='20' :height='20' />
                            <div class="neutral900"><span class="neutral300">Время на дорогу:</span> {{ timeInfo.toObject }} мин</div>
                        </div>
                    </div>
                </div>

                <div class="add-shipping__comments">
                    <hr/>

                    <template v-if="!details.mix_order_groups_comment_driver && !details?.mix_order_comment && !isEditCommentFormOpened">
                        <el-button
                            style="border: none; padding: 0; background-color: transparent; color: #409eff;"
                            :icon="icons.plus"
                            type="primary"
                            @click="openEditCommentForm"
                        >
                            Добавить комментарий для этой отгрузки
                        </el-button>
                    </template>
                    <template v-else>
                        <div class="d-flex f-direction-column gap-5">
                            <div v-if="!isEditCommentFormOpened" class="d-flex align-center mb-5">
                                <el-tooltip :disabled='!details.mix_order_comment' content="Индивидуальный комментарий"
                                            position="top">
                                    <ChatCircleDotsIcon
                                        :class="[details.mix_order_comment ? 'warning300 pointer' : 'neutral300']"
                                        :currentColor="true"/>
                                </el-tooltip>
                                <p class="textSM textMedium neutral700 m-0 ml-5 mr-10">Комментарий для водителя:</p>
                                <EditPen class="primary600 pointer" width="16" height="16"
                                         @click="openEditCommentForm"/>
                            </div>
                        </div>

                        <div
                            v-if="!isEditCommentFormOpened"
                            v-html="getFormattedComment(details.mix_order_comment && details.mix_order_comment.length ? details.mix_order_comment : details.mix_order_groups_comment_driver)" />
                    </template>

                    <div v-if="isEditCommentFormOpened" class="addDeliveryFormItem editDriverComment">
                        <el-tooltip effect="dark" content="Индивидуальный комментарий" placement="top">
                            <div class="addDeliveryFormItemIcon">
                                <ChatCircleDotsIcon :currentColor="true" width="20" height="20"/>
                            </div>
                        </el-tooltip>
                        <div class="addDeliveryFormItemInputText">
                            <el-input v-model="details.mix_order_comment" placeholder="Комментарий для водителя"
                                      type="textarea" :autosize="{ minRows: 1, maxRows: 4 }"/>
                        </div>
                    </div>

                </div>
            </div>

            <div class="add-shipping__footer">
                <button
                    class="ci-btn back-g-none mr-10"
                    @click="() => $emit('edit')"
                >
                    Назад
                </button>
                <el-button
                    class="ci-btn ci-btn_blue"
                    :disabled="loadingButton"
                    @click="submit"
                >
                    Сохранить
                </el-button>
            </div>
        </div>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="selectDialog"
        :title="selectDialogTitle"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="selectType === 'mix'">
            <MixOrderDetailsGroupEditSelectMixes
                @updateItem="updateMixes"
                :modelValue="details.mixer_id"
                :items="orderMixers"
                :is-all-mixers-is-in-order="isAllMixersIsInOrder"
                :order-mixers="orderMixers"
            />
        </template>
        <template v-else-if="selectType === 'vehicle'">
            <MixOrderDetailsGroupEditSelectVehicles
                :order="details"
                :orderType="orderType"
                :drivers="drivers"
                :mixers="orderMixers"
                @updateItem="updateVehicle"
            />
        </template>
        <template v-else-if="selectType === 'driver'">
            <MixOrderDetailsGroupEditSelectDrivers
                @updateItem="updateDriver"
                :items="drivers"
            />
        </template>
        <template v-else-if="selectType === 'good'">
            <MixOrderDetailsGroupEditSelectGoods
                @updateItem="updateGood"
                :integration-unit-id="integrationUnitId"
            />
        </template>
        <template v-else-if="selectType === 'service'">
            <MixOrderDetailsGroupEditSelectService
                @updateItem="updateService"
                :items="serviceTariffs"
                type="mix"
            />
        </template>
    </el-dialog>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import iconsMixin from "@/mixins/iconsMixin";
import moment from "moment/moment";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapState, mapActions} from 'pinia'
import {useOrderGroupsStore} from '@/store/orderGroups'
import {useCommonStore} from '@/store/common';
import MixOrderDetailsGroupEditSelectMixes
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectMixes.vue";
import {httpService} from "@/services/http.service";
import MixOrderDetailsGroupEditSelectDrivers
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectDrivers.vue";
import MixOrderDetailsGroupEditSelectGoods
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectGoods.vue";
import MixOrderDetailsGroupEditSelectVehicles
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectVehicles.vue";
import MixOrderDetailsGroupEditSelectService
    from "@/views/components/MixOrderGroup/components/editSelects/MixOrderDetailsGroupEditSelectService.vue";

// icons
import ChatCircleDotsIcon from "@/views/components/Icons/ChatCircleDotsIcon.vue";
import PackageIcon from "@/views/components/Icons/PackageIcon.vue";
import BuildingIcon from "@/views/components/Icons/BuildingIcon.vue";
import ArrowDownRightIcon from '@/views/components/Icons/ArrowDownRightIcon.vue';
import SolidMixerIcon from "@/views/components/Icons/SolidMixerIcon.vue";
import SolidUserProfileIcon from "@/views/components/Icons/SolidUserProfileIcon.vue";
import RecipeIcon from "@/views/components/Icons/RecipeIcon.vue";
import { EditPen } from "@element-plus/icons-vue";
import QuestionIcon from "@/views/components/Icons/QuestionIcon.vue";
import EditIcon from "@/views/components/Icons/EditIcon.vue";
import TariffIcon from "@/views/components/Icons/TariffIcon.vue";
import CloseRoundIcon from "@/views/components/Icons/CloseRoundIcon.vue";

export default {
    name: "MixOrderDetailsGroupEdit",
    components: {
        MixOrderDetailsGroupEditSelectVehicles,
        MixOrderDetailsGroupEditSelectGoods,
        MixOrderDetailsGroupEditSelectDrivers,
        MixOrderDetailsGroupEditSelectMixes,
        MixOrderDetailsGroupEditSelectService,
        EditIcon,
        ChatCircleDotsIcon,
        EditPen,
        PackageIcon,
        BuildingIcon,
        ArrowDownRightIcon,
        SolidMixerIcon,
        SolidUserProfileIcon,
        RecipeIcon,
        QuestionIcon,
        TariffIcon,
        CloseRoundIcon
    },
    props: ['orderGroup', 'mixOrder', 'loading', 'isUpdateMode', 'start_at', 'arrive_at'],
    mixins: [functionsMixin, mixOrderInfo, iconsMixin, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            details: {
                total: null,
                client_total: null,
                mix_id: null,
                mixer_id: null,
                start_at: null,
                arrive_at: null,
                vehicle_id: null,
                driver_id: null,
                mix_order_comment: '',
                good_id: null,
                vehicle: null,
                driver: null,
                good: null,
                service_id: null,
                service: null,
            },
            selectDialogTitle: null,
            selectType: null,
            selectDialog: false,
            loadingButton: false,
            isEditCommentFormOpened: false,
            isClientTotalInputActive: false,
            mixes: [],
            integrationUnitId: null,
            timeInfo: {
                loading: 0,
                toObject: 0,
                unloading: 0,
                return: 0,
                dist: 0
            },
            orderType: this.orderGroup.deliveryType,
        }
    },
    computed: {
        ...mapState(useCommonStore, {
                drivers: 'getDrivers',
                modules: 'getModules',
                appSettings: 'getAppSettings',
                integrations: 'getIntegrations',
                serviceTariffs: 'getServiceTariffs',
            }),
        orderMixers() {
            const orderMixerIds = this.orderGroup.info.mixerIds;
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const selectedMixers = mixers.filter((mixer) => orderMixerIds.includes(mixer.id));
            return selectedMixers.map((mixer) => {
                return {
                    id: mixer.id,
                    mix_id: mixer.mix_id,
                    name: this.setMixName(mixer, mixers)}
            })
        },
        isLab() {
            return this.modules.find((module) => module.key === this.$route.meta.moduleKey).lab.length ?? 0;
        },
        orderMixersAmount() {
            return this.orderMixers.length > 1
        },
        isAllMixersIsInOrder() {
            const mixers = this.modules.find((module) => module.key === this.$route.meta.moduleKey)?.mixers;
            const orderMixerIds = this.orderGroup.info.mixerIds;
            return mixers.every((mixer) => orderMixerIds.includes(mixer.id));
        },
        showHiddenBlock() {
            if(this.isClientTotalInputActive) return false
            if(this.isLab && !this.orderMixersAmount) return false
            if(!this.isLab && this.orderMixersAmount) return false

            return this.windowWidth < 1900 ? true : false
        },
        driverName() {
            let label = this.isUpdateMode
                ? this.drivers.find(el => el.id === this.details.driver?.id)?.name
                : this.drivers.find(el => el.id === this.details.driver_id)?.name
            if (label) label = this.formatName(label)
            return label ?? '-'
        },
    },
    created() {
        if(this.isUpdateMode) {
            this.details = {
                id: this.mixOrder.id,
                mix_id: this.mixOrder.mix_id,
                mixer_id: this.mixOrder.mixer_id,
                total: this.mixOrder.total,
                client_total: this.mixOrder.client_total,
                vehicle_id: this.mixOrder.vehicle?.id,
                number: this.mixOrder.number ?? null,
                driver_id: this.mixOrder.driver?.id,
                driver: this.mixOrder.driver ?? null,
                start_at: this.mixOrder.start_at,
                arrive_at: this.mixOrder.arrive_at,
                good_id: this.mixOrder.good?.id,
                mix_order_comment: this.mixOrder.mix_order_comment,
                good: this.mixOrder.good,
                mix: this.mixOrder.mix,
                mixer: this.mixOrder.mixer,
                service_id: this.orderType !== 'take-away' ? this.mixOrder.service.id : null,
                service: this.orderType !== 'take-away' ? this.mixOrder.service : null,
            }
            this.details.start_at = moment(this.details?.start_at).format('HH:mm')
        }
        if(this.details.client_total) this.isClientTotalInputActive = true;

        this.$nextTick(() => {
            this.blockScrollWhileModalOpened(true);
        })
    },
    unmounted() {
        this.blockScrollWhileModalOpened(false);
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups']),
        update(confirm) {
            this.loadingButton = true

            const order = {
                mix_id: this.details.mix_id,
                mixer_id: this.details.mixer_id,
                total: this.details.total,
                client_total: this.details.client_total,
                vehicle_id: this.details.vehicle_id ?? null,
                driver_id: this.details.driver?.id ?? null,
                start_at: this.details.start_at,
                good_id: this.details.good?.id,
                mix_order_comment: this.details.mix_order_comment,
                service_id: this.orderType !== 'take-away' ? this.details.service_id : null,
            }

            httpService().post(this.api.dispatch.orders.update + '/' + this.mixOrder.id, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: order,
                confirm: confirm
            }).then(({data}) => {
                this.$message({
                    message: 'Отгрузка успешно обновлена',
                    showClose: true,
                    type: 'success'
                })
                if (confirm !== null) this.confirm = confirm
                this.editOrder = false
                this.loadingButton = false
                if (data.success) {
                    this.$emit('update', data.data)
                }
                this.$emit('get')

                this.$emit('edit')
            }).catch((error) => {
                const {errors} = error.response.data

                if (errors) {
                    for (let key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            errors[key].forEach(errorMessage => {
                                this.$message({
                                    message: errorMessage,
                                    showClose: true,
                                    type: 'error'
                                });
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.$emit('get')
                }
                this.loadingButton = false

            })
        },
        openSelectDialog(type) {
            this.selectType = type

            if (type === 'mix') {
                this.selectDialogTitle = 'Выберете бетонный завод'
            } else if (type === 'vehicle') {
                this.selectDialogTitle = 'Выберите транспортное средство'
            } else if (type === 'driver') {
                this.selectDialogTitle = 'Выберите водителя'
            } else if (type === 'service') {
                this.selectDialogTitle = 'Выберите тариф на доставку'
            } else if (type === 'good') {
                if (this.orderGroup.lab === true) {
                    this.$message({
                        message: 'Для выбора рецепта по отгрузке обратитесь к лаборатории',
                        showClose: true,
                        type: 'error'
                    })
                    return
                }
                this.selectDialogTitle = 'Выберите рецепт'
            }

            this.selectDialog = true
        },
        updateMixes(mixer) {
            const parts = mixer.name.split(" - ");

            if(this.isUpdateMode) {
                this.details.mix_id = mixer.mix_id
                this.details.mixer_id = mixer.id
                this.details.mix.mix_name = parts[0];
                this.details.mixer.mixer_name = parts[1] || null;
            }
            this.details.mix_id = mixer.mix_id
            this.details.mixer_id = mixer.id

            this.selectDialog = false
        },
        updateDriver(driver) {
            if(this.isUpdateMode) {
                this.details.driver.id = driver.id
                this.details.driver.name = driver.name
            } else this.details.driver_id = driver.id

            this.selectDialog = false
        },
        updateGood(good) {
            this.details.good.id = good.id
            this.details.good.name = good.name

            this.selectDialog = false
        },
        updateVehicle(schedule) {
            const isTakeAway = this.orderType === 'take-away';
            const vehicle = isTakeAway ? schedule : schedule.vehicle;
            let driver = this.drivers.find(f => f.id === schedule.driver_id)
                        || this.drivers.find(f => f.id === vehicle.driver_id);

            this.details.number = vehicle.number;
            this.details.vehicle_id = vehicle.id;

            if (driver) {
                this.details.driver = driver;
                this.details.driver_id = driver.id;
            }

            this.selectDialog = false;
        },
        updateService(service) {
            this.details.service_id = service.id;
            this.details.service = service;

            this.selectDialog = false
        },
        openEditCommentForm() {
            this.isEditCommentFormOpened = true
            if (!this.details.mix_order_comment) {
                this.details.mix_order_comment = this.details.mix_order_groups_comment_driver
            }
        },
        setMixName(mixer, allMixers) {
            const amountOfMixersPerMix = allMixers.filter((item) => item.mix_id === mixer.mix_id)?.length;

            return amountOfMixersPerMix > 1 ? mixer.name : mixer.mix_name;
        },
        setIntegrationUnitId() {
            const mix_id = this.orderMixers.find((mixer) => mixer.id == this.details.mixer_id)?.mix_id; // находим id завода
            if(mix_id) {
                const integration = this.integrations.find((integration) => integration.account_module?.module_reserve_id === mix_id); // находим нужную интеграцию
                    if (integration) {
                    this.integrationUnitId = integration.id;
                }
            }
        },
        async getTimeInfo() {
            if (!this.details?.start_at) {
                this.details.start_at = moment().tz(this.orderGroup.timezone).add(5,'minutes').format('HH:mm')
                this.changedOrder('start_at')
            }
            if (this.details.mix_id) {
                await httpService().post(this.api.dispatch.orderGroups.getTimeInfo, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    order_group_item_id: this.orderGroup.id,
                    mixId: this.details.mix_id,
                    total: this.details.total
                }).then((response) => {
                    let data = response.data
                    if (data.success) {
                        this.timeInfo = data.timeInfo
                    }
                    this.details.arrive_at = moment(this.convertTimeToDateTime(this.details?.start_at, 'arrive_at')).format("HH:mm:ss")
                })
            }
        },
        createOrder() {
            this.loadingButton = true
            const now = moment(this.orderGroup.date_shipment);
            const order = {
                    order_group_id: this.orderGroup.id,
                    order_group_delivery_type: this.orderGroup.delivery_type,
                    mix_id: this.details.mix_id,
                    mixer_id: this.details.mixer_id,
                    total: this.details.total,
                    client_total: this.details.client_total,
                    vehicle_id: this.details.vehicle_id,
                    driver_id: this.details.driver_id,
                    good_id: this.details.good?.id,
                    mix_order_comment: this.details.mix_order_comment,
                    start_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.details.start_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                    arrive_at: moment.tz(`${now.format('YYYY-MM-DD')}T${this.details.arrive_at}`, 'YYYY-MM-DDTHH:mm', this.moduleTimezone).utc(),
                    service_id: this.orderType !== 'take-away' ? this.details.service_id : null,
                };
            httpService().post(this.api.dispatch.orders.create, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order: order,
                timeInfo: {
                    loading: 0,
                    toObject: 0,
                    unloading: 0,
                    return: 0,
                }
            }).then(() => {
                this.$message({
                    message: 'Доставка успешно добавлена',
                    showClose: true,
                    type: 'success'
                });
                    setTimeout(() => {
                        this.$emit('get')
                        this.$emit('close')
                    }, 150)
                    this.loadingButton = false
            }).catch((error) => {
                this.loadingButton = false
                const {errors} = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
                if(!errors){
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                }
            })
        },
        submit() {
            this.isUpdateMode ? this.update(true) : this.createOrder()
        },
        convertTimeToDateTime(time, type) {
            const now = moment(this.orderGroup.date_shipment);
            const timeMoment = moment(`${now.format('YYYY-MM-DD')}T${time}`);

            let addMinutes = 0

            if (type === 'load_at') {
                addMinutes = this.timeInfo.loading
            } else if (type === 'unload_at') {
                addMinutes = this.timeInfo.unloading
            } else if (type === 'return_at') {
                addMinutes = Math.abs(this.timeInfo.unloading) + Math.abs(this.timeInfo.return)
            } else if (type === 'arrive_at') {
                addMinutes = Math.abs(this.timeInfo.loading) + Math.abs(this.timeInfo.toObject)
            }

            return timeMoment.add(addMinutes, 'minutes').format();
        },
        getLabel(type) {
            let label = null

            if (type === 'mix') {
                label = this.orderMixers.find(el => el.id === this.details.mixer_id)?.name ?? '-'
            }

            if (type === 'vehicle') {
                if (this.details.vehicle) {
                    label = this.getFormattedVehicleNumber(this.details.vehicle?.number)
                } else {
                    if (this.details.vehicle_id) {
                        label = this.getFormattedVehicleNumber(this.allVehicles.find(el => el.id === this.details.vehicle_id)?.number)
                    } else if (this.vehicle) {
                        label = this.getFormattedVehicleNumber(this.vehicle.number)
                    }
                }
            }

            if (type === 'driver') {
                label = this.drivers.find(el => el.id === this.details.driver_id)?.name
                if (label) label = this.formatName(label)
            } else if (type === 'good') {
                label = this.getData(this.details.good_id, 'good')
            }

            return label ?? '-'
        },
        async changedOrder(time_type) {
            await this.getTimeInfo()
            if (this.start_at !== this.arrive_at && this.total) {
                const timeLoading = this.total * 2.5 * 60

                let timeLoad = moment(this.start_at)
                let timeObject = moment(this.arrive_at).subtract(timeLoading, 'seconds').add(this.timeLoading, 'seconds')

                // let minutesDiffLoad = timeObject.diff(timeLoad, "minutes") + 1
                let minutesDiffLoad = timeObject.diff(timeLoad, "minutes")


                if (time_type === "start_at") {

                    this.details.arrive_at = moment(this.details.start_at, 'HH:mm')
                        .add(minutesDiffLoad, "minutes")
                        .format("HH:mm")
                }


                if (time_type === "arrive_at") {
                    this.details.start_at = moment(this.details.arrive_at, 'HH:mm')
                        .subtract(minutesDiffLoad, "minutes")
                        .format("HH:mm");
                }
            } else {
                if (this.details.start_at || this.details.arrive_at) {
                    const timeLoading = this.timeInfo.loading ?? 0
                    const timeDelivery = this.timeInfo.toObject ?? 0

                    const addTime = timeLoading + timeDelivery
                    if (time_type === "start_at") {
                        this.details.arrive_at = moment(this.details.start_at, 'HH:mm')
                            .add(addTime, "minutes")
                            .format("HH:mm")
                    }

                    if (time_type === "arrive_at") {
                        this.details.start_at = moment(this.details.arrive_at, 'HH:mm')
                            .subtract(addTime, "minutes")
                            .format("HH:mm");
                    }
                }
            }
        },
        clearInput(key) {
            this.details[key] = null

            if(key === 'driver_id') {
                this.details.driver.id = null;
                this.details.driver.name = null
            }
            if(key === 'vehicle_id') {
                this.details.number = null
            }
            if(key === 'service_id') {
                this.details.service = null;
            }
        }
    },
    watch: {
        'details.mixer_id': {
            handler: function () {
                if (this.orderMixers.length) {
                    this.setIntegrationUnitId();
                    const isDefaultData = this.isUpdateMode ? this.details.mixer_id === this.mixOrder.mixer_id
                        && this.details.mix_id === this.mixOrder.mix_id
                        && this.details.good_id === this.mixOrder.good?.id
                        : false;

                    if(!isDefaultData && !this.isLab) {
                        this.details.good = {
                            id: null,
                            name: null
                        };
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'details.mix_id': {
            handler: function (val) {
                if (!this.isUpdateMode && val) this.getTimeInfo()
            },
            deep: true,
        },
        'orderMixers': {
            handler: function(val) {
                if(val.length && !this.isUpdateMode) {
                    this.details.mix_id = val[0].mix_id
                    this.details.mixer_id = val[0].id
                }
            },
            immediate: true,
            deep: true,
        },
        'details.total': {
            handler: function() {
                if (!this.isUpdateMode) this.getTimeInfo()
            }
        },
        'selectDialog': {
            handler: function(val) {
                this.blockScrollWhileModalOpened(val);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@use "@/sass/_variables.scss";

.addDeliveryFormItem {
    position: relative;

    &:has(.inputModalClear) {
        padding-right: 24px;
    }
}

.inputModalClear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: block;
    z-index: 2;

    .el-icon {
        position: relative;
        top: 2px;

        svg path {
            fill: #a8abb2;
        }
    }
    &:hover {
        .el-icon {
            svg path {
                fill: variables.$neutral400;
            }
        }
    }
}

.inputClearBtn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: block;
    z-index: 2;
    width: 14px;
    height: 14px;

    svg {
        display: block;

        &:deep(path) {
            fill: #a8abb2;
        }
    }

    &:hover {
        svg {
            &:deep(path) {
                fill: variables.$neutral400;
            }
        }
    }
}
</style>
