<template>
        <div class="card card__faq-modal-view">
                <div class="w-100 card__faq-modal-view-wrapper">
                    <el-row class="h-100" gutter="20">
                        <el-col class="h-100" :sm="6">
                                <div class="faqMenu h-100">
                                    <div class="d-flex align-center gap-12 mb-8 faqMenu__header">
                                        <div class="faqMenu__icon">
                                            <component :is="modulesInfo[module]?.icon" />
                                        </div>
                                        <h4 class="faqMenu__title textXL pl-0 mt-0 mb-0">
                                            {{ modulesInfo[module].title }}
                                        </h4>
                                    </div>
                                    <div class="faqMenu__list">
                                        <div v-for="(questions, section) in faqQuestionsBySection[module]" :key="section">
                                            <h3>{{ section === 'general' ? 'Общие вопросы' : getSectionName(section) }}</h3>

                                            <el-button
                                                class="textMD"
                                                v-for="question in questions"
                                                :key="question.key"
                                                :class="{ active: question.key === guideItem.key }"
                                                @click="setGuideItem(question)"
                                            >
                                                {{ question.title }}
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                        </el-col>
                        <el-col :sm="18" class="h-100" id="faq-details">
                            <template v-if="guideItem.key">
                                <FaqRouter
                                    :component="guideItem.key"
                                />
                            </template>
                            <template v-else>
                                <component :is="componentName" />
                            </template>

                        </el-col>
                    </el-row>
                </div>
            </div>
</template>

<script>
import Auth from '@/views/layouts/Auth'
import FaqRouter from "@/views/components/FaqPage/FaqRouter";
import iconsMixin from "@/mixins/iconsMixin";
import faqQuestions from "@/mixins/faqQuestions";
import FlashIcon from "@/views/components/Icons/FlashIcon";
import CashboxIcon from "@/views/components/Icons/CashboxIcon";
import WeighingIcon from "@/views/components/Icons/WeighingIcon";
import IntegrationsIcon from "@/views/components/Icons/IntegrationsIcon";
import RelationsIcon from "@/views/components/Icons/RelationsIcon";
import SettingsIcon from "@/views/components/Icons/SettingsIcon";
import ContractIcon from '@/views/components/Icons/ContractIcon'
import StorehouseIcon from '@/views/components/Icons/StorehouseIcon'
import ProductionIcon from '@/views/components/Icons/ProductionIcon'
import OrderIcon from '@/views/components/Icons/OrderIcon'
import TechIcon from '@/views/components/Icons/TechIcon'
import AdminIcon from '@/views/components/Icons/AdminIcon'
import TTSIcon from '@/views/components/Icons/TTSIcon'
import MixIcon from '@/views/components/Icons/MixIcon'
import LogisticsIcon from "@/views/components/Icons/LogisticsIcon";
import FuelIcon from "@/views/components/Icons/FuelIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";
import modulesInfo from '@/mixins/modulesInfo';

import CashboxFaq from "@/views/components/FaqPage/cashbox/CashboxFaq";
import SettingsFaq from "@/views/components/FaqPage/settings/SettingsFaq";
import DispatchFaq from "@/views/components/FaqPage/dispatch/DispatchFaq";

export default {
    name: 'FaqModalView',
    components: {
        SettingsFaq,
        SettingsIcon,
        CashboxFaq, RelationsIcon, IntegrationsIcon, WeighingIcon, CashboxIcon, FlashIcon, FaqRouter, Auth,
        ContractIcon, StorehouseIcon, ProductionIcon, OrderIcon, TechIcon, AdminIcon, TTSIcon, MixIcon,
        LogisticsIcon, FuelIcon, DispatchIcon, LabIcon, CRMIcon, DispatchFaq
    },
    props: ['module', 'isDialog', 'guide'],
    mixins: [iconsMixin, faqQuestions, modulesInfo],
    data() {
        return {
            guideItem: this.guide,
        }
    },
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName]
            var top = element.offsetTop

            window.scrollTo({
                top: top,
                behavior: 'smooth'
            })
        },
        getComponentName(name) {
            const firstLetter = this.module[0].toUpperCase();
            return firstLetter + name.slice(1) + 'Faq'
        },
        setGuideItem(item) {
            this.guideItem = item;
        },
        getSectionName(section) {
            return this.modulesInfo[this.module]?.sections[section]?.title
        }
    },
    computed: {
        componentName() {
            const firstLetter = this.module[0].toUpperCase();

            return firstLetter + this.module.slice(1) + 'Faq'
        }
    },
    watch: {
        'guideItem': {
            handler: function (val) {
                this.guideItem = val
            }
        }
    }
}
</script>

<style scoped lang="scss">
.card {
    &__faq-modal-view {
        height: 100%;

        @media screen and (max-width: 768px) {
            overflow: auto;
        }

        &:deep(.guide-steps), .faqMenu__list {
            flex: 1;
            overflow-y: auto;
        }

        &-wrapper {
            height: 100%;

            @media screen and (max-width: 768px) {
                height: auto;
            }
        }
    }
}

.faqMenu__title {

    &__title {
        @media screen and (max-width: 1440px) {
            font-size: 18px;
        }
    }
}
</style>
