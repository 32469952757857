<template>
    <el-form ref="form" label-position="top" v-loading="loading">

        <template v-if="!form.type">
            <p>Выберите тип услуги:</p>

            <div class="d-flex" style="gap: 15px">
                <el-button class="w-100" style="height: auto !important;" @click="() => form.type = type.id"
                           v-for="(type, index) in serviceTypes"
                           :key="index">
                    <div>
                        <div class="text-center mt-15 mb-15">
                            <svg
                                v-if="type.id === 'pump'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="21" cy="46" r="3" fill="" opacity="1"></circle><circle
                                cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="11" cy="46" r="3"
                                                                                           fill=""
                                                                                           opacity="1"></circle><path
                                d="m25 39 4 6h14" fill="" opacity="1"></path><path
                                d="M47 39V21h6.353a3 3 0 0 1 2.531 1.39l5.647 8.873A3 3 0 0 1 62 32.874V43.5a1.5 1.5 0 0 1-1.5 1.5h-2.669a1 1 0 0 1-.972-.767A7.276 7.276 0 0 0 52 39H8l-4 5H2V33l45-3"
                                fill="" opacity="1"></path><path
                                d="M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M25.27 31.45 5.52 24.14a.969.969 0 0 1-.66-.71l-.76-2.16a.857.857 0 0 1-.05-.27.662.662 0 0 1 .01-.28l.71-3.12a.987.987 0 0 1 .9-.68L50.97 15a1.029 1.029 0 0 1 .85.42 1.054 1.054 0 0 1 .11.96L50.52 21M7 21h40M4.05 21H7"
                                fill="" opacity="1"></path><path
                                d="m8 21 25.466 5.877a1.5 1.5 0 0 1 .863.562l2.431 3.241"
                                fill="" opacity="1"></path></g></g>
                            </svg>
                            <svg
                                v-if="type.id === 'mix'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="19" cy="46" r="3" fill="" opacity="1"></circle><circle
                                cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="9" cy="46" r="3"
                                                                                           fill=""
                                                                                           opacity="1"></circle><path
                                d="m2 44 4-5h46a7.274 7.274 0 0 1 4.859 5.233 1 1 0 0 0 .972.767H60.5a1.5 1.5 0 0 0 1.5-1.5V32.873a3 3 0 0 0-.469-1.61l-5.647-8.874A3 3 0 0 0 53.353 21H47"
                                fill="" opacity="1"></path><path
                                d="m22 39 5 6h16M47 39V17.621a1.5 1.5 0 0 0-.44-1.061L45 15M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M39.1 39l3.7-5.9a1.345 1.345 0 0 0-.125-1.6l-6.734-7.9-14.77-7.7-9.671-.893a1.649 1.649 0 0 0-1.423.571L5.23 23.106a1.426 1.426 0 0 0 .119 1.711l6.319 7.442L26.438 39"
                                fill="" opacity="1"></path><circle cx="44" cy="27" r="3" fill=""
                                                                   opacity="1"></circle><path
                                d="M35.933 23.593 27.493 39M21.163 15.889l-9.495 16.37M8 39V27.94" fill=""
                                opacity="1"></path></g></g>
                        </svg>
                            <svg
                                v-if="type.id === 'downtime'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="30" cy="38" r="24" fill="" opacity="1"></circle><path
                                d="M24 2h12v5H24zM30 7v7M50.59 16.41l-4.13 4.13M47.181 13 54 19.819" fill=""
                                opacity="1"></path><circle cx="30" cy="38" r="4" fill="" opacity="1"></circle><path
                                d="M30 34V22M30 42v2M44 38h2M16 38h-2M30 52v2" fill="" opacity="1"></path></g></g>
                        </svg>
                            <svg
                                v-if="type.id === 'other'"
                                xmlns="http://www.w3.org/2000/svg"
                                width="90" height="90" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                                class="serviceIconBig"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10 21v39a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H24L10 16h12a2 2 0 0 0 2-2V2M20 51h24M20 39h24M20 27h24M33 15h11" fill="" opacity="1"></path></g></g>
                        </svg>
                        </div>
                        <p class="textSM textMedium primary600">
                            {{ type.name }}
                        </p>
                    </div>
                </el-button>
            </div>
        </template>

        <template v-else>

            <div class="d-block w-100 mb-15" v-if="form.type !== 'good'">
                <el-switch
                    size="large"
                    v-model="form.manual"
                    active-text="Ручной ввод"
                    inactive-text="Услуга по тарифу"
                    :disabled="form.type === 'other'"
                />
            </div>

            <template v-if="!form.manual">
                <FormSelect
                    v-model="form.tariff_id"
                    label="Тариф"
                    placeholder="Выберите тариф"
                    @changeValue="getInfoTariff"
                    :items="tariffs.filter(el => el.type === form.type)"
                />
            </template>
            <template v-if="form.manual || form.tariff_id">

                <FormInput
                    v-if="form.type !== 'good'"
                    v-model="form.name"
                    label="Название услуги"
                    placeholder="Введите название"
                />

                <FormSelect
                    v-if="form.type === 'good'"
                    v-model="form.good_id"
                    label="Продукция"
                    placeholder="Выберите продукцию"
                    new-item="good"
                    :items="goods"
                />

                <el-row :gutter="15">
                    <el-col :sm="6">
                        <FormNumber
                            v-model="form.price"
                            label="Стоимость (за ед., без НДС)"
                            placeholder="0"
                            :precision="2"
                        />
                    </el-col>
                    <el-col :sm="6">
                        <FormNumber
                            v-model="form.total"
                            label="Количество"
                            placeholder="0"
                            :precision="2"
                        />
                    </el-col>
                    <el-col :sm="6">
                        <el-form-item>
                            <template #label>
                                % НДС (
                                <el-checkbox class="vatCheckbox" v-model="form.vat_in_price" label="Включен в цену"
                                             size="small"/>
                                )
                            </template>
                            <el-input
                                class="custom-input"
                                v-model="form.vat"
                                type="number"
                                placeholder="0"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :sm="6">
                        <FormNumber
                            v-model="form.full_price"
                            label="Итоговая стоимость"
                            placeholder="0"
                            :precision="2"
                        />
                    </el-col>
                </el-row>
                <el-form-item label="Привязать к отгрузке">
                    <el-select v-model="form.mix_order_id" placeholder="Выберите отгрузку" class="w-100">
                        <el-option
                            v-for="order in orders"
                            :key="order.id"
                            :label="order.id"
                            :value="order.id"
                        >
                            <span style="float: left">#{{ order.id }}</span>
                            <span
                                style="
          float: right;
          color: var(--el-text-color-secondary);
          font-size: 13px;
        "
                            >
        {{ moment(order.start_at).format('HH:mm') }} - {{ order.vehicle?.number }}
      </span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
            <hr class="mb-15"/>
            <el-button :disabled="loadingButton" type="primary" @click="addService">
                {{ service ? 'Сохранить' : 'Добавить' }} {{ form.type === 'good' ? 'продукцию' : 'услугу' }}
            </el-button>
        </template>
    </el-form>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import serviceInfo from "@/mixins/serviceInfo";
import {httpService} from "@/services/http.service";
import moment from "moment";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import fullPriceMixin from "@/mixins/fullPriceMixin";

export default {
    name: "MixOrderGroupCreateService",
    components: {FormSelect, FormInput, FormNumber},
    props: ['groupId', 'orders', 'service'],
    mixins: [serviceInfo, fullPriceMixin],
    inject: ['api'],
    computed: {
        moment() {
            return moment
        },
        ...mapState(useCommonStore, {
            goods: 'getGoods',
        }),
    },
    created() {
        this.getTariffs()
        if (this.service) {
            this.form = this.service
            this.form.manual = !this.service.tariff_id
        }
    },
    data() {
        return {
            loading: true,
            loadingButton: false,
            form: {
                id: null,
                name: null,
                type: null,
                depends_on: null,
                vat: 0,
                total: null,
                vat_in_price: false,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                manual: false,
                tariff_id: null,
                mix_order_id: null,
                vehicle_id: null,
                good_id: null
            },
            tariffs: []
        }
    },
    methods: {
        addService() {
            this.loadingButton = true

            httpService().post(this.api.mixOrderGroup.addService, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.addServiceDialog = false
                    this.$message({
                        message: this.service ? 'Сохранено' : 'Добавлено',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('update', this.form.type)
                    this.form = {
                        id: null,
                        name: null,
                        type: 'other',
                        depends_on: null,
                        vat: 0,
                        vat_in_price: false,
                        price: null,
                        full_price: null,
                        price_type: null,
                        volume_min: null,
                        volume_max: null,
                        distance_from: null,
                        distance_to: null,
                        manual: false,
                        tariff_id: null,
                        good_id: null
                    }
                    this.loadingButton = false
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const fieldErrors = error.response.data.errors;

                    Object.keys(fieldErrors).forEach((field) => {
                        fieldErrors[field].forEach((message) => {
                            this.$message({
                                message: `${message}`,
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: false
                            });
                        });
                    });
                } else {
                    this.$message({
                        message: error.response?.data?.message,
                        type: 'error',
                        showClose: true
                    });
                }
            })
            this.loadingButton = false
        },
        getTariffs() {
            this.loading = true

            httpService().post(this.api.mixOrderGroup.getAllTariffs,{
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.tariffs = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getInfoTariff() {
            if (this.form.tariff_id) {
                let tariff = this.tariffs.find(el => el.id === this.form.tariff_id)

                if (tariff) {
                    this.form.price = tariff.good_price.price ?? null
                    this.form.price_type = tariff.price_type
                    this.form.vat = tariff.good_price.vat ?? null
                    this.form.vat_in_price = tariff.good_price.vat_in_price ?? false
                    this.form.full_price = this.getFullPrice(tariff.good_price.price, tariff.good_price.vat, tariff.good_price.vat_in_price, this.form.total)
                }
            }
        }
    },
    watch: {
        'form.type': {
            handler: function () {
                if (!this.service) {
                    if (this.form.type) {
                        this.form.name = this.serviceTypes.find(el => el.id === this.form.type)?.name
                        if (this.form.type === 'other') this.form.manual = true
                    }
                    if (this.form.price) {
                        let vat = this.form.vat ? Math.abs(this.form.vat) : 0
                        let vat_in_price = this.form.vat_in_price ?? false
                        let price = Math.abs(this.form.price)
                        let total = this.form.total ?? 1

                        this.form.full_price = this.getFullPrice(price, vat, vat_in_price, total)
                    }
                }
            },
            deep: false,
            immediate: false
        },
        'form': {
            handler: function () {
                if (this.form.price) {
                    let vat = this.form.vat ? Math.abs(this.form.vat) : 0
                    let vat_in_price = this.form.vat_in_price ?? false
                    let price = Math.abs(this.form.price)
                    let total = this.form.total ?? 1

                    this.form.full_price = this.getFullPrice(price, vat, vat_in_price, total)
                }
            },
            deep: true,
            immediate: true
        },
        'form.tariff_id': {
            handler: function () {
                if (this.form.tariff_id) this.getInfoTariff()
            },
            deep: false,
            immediate: false
        },
        'form.mix_order_id': {
            handler: function () {
                if (this.form.mix_order_id) {
                    let order = this.orders.find(el => el.id === this.form.mix_order_id)
                    if (order) this.form.vehicle_id = order.vehicle_id
                }
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
