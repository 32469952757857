export default {
    data() {
        return {
            faqQuestions: {
                cashbox: [
                    {
                        title: "Операции: создание, редактирование и удаление",
                        module: "cashbox",
                        key: "CashboxFaqHowCreateNewOperations",
                        popular: false,
                    },
                    {
                        title: "Как изменить баланс и значение счета по умолчанию",
                        module: "cashbox",
                        key: "CashboxFaqHowEditDefaultAccount",
                        popular: false,
                    },
                ],
                settings: [
                    {
                        title: "Как пригласить нового пользователя",
                        module: "settings",
                        key: "SettingsFaqHowInviteNewUser",
                        popular: true,
                    },
                    {
                        title: "Как добавить модуль и ссылку в меню",
                        module: "settings",
                        key: "SettingsFaqHowCreateNewModule",
                        popular: true,
                    },
                ],
                dispatch: [
                    {
                        title: "Как создать заказ",
                        key: "DispatchHowToCreateNewOrder",
                        module: "dispatch",
                        popular: true,
                        path: ["groups", "create"],
                    },
                    {
                        title: "Как создать отгрузку",
                        key: "DispatchHowToCreateNewShipment",
                        module: "dispatch",
                        popular: false,
                        path: ["groups", ':id', "details"]
                    },
                    {
                        title: "Как создать график миксеров",
                        key: "DispatchHowToCreateNewSchedule",
                        module: "dispatch",
                        popular: false,
                        path: ["mixers"]
                    },
                    {
                        title: "С чего начать?",
                        key: "DispatchHowToStart",
                        module: "dispatch",
                        popular: false,
                    },

                ],
                mix: [
                    {
                        title: "Как добавить рецепты",
                        module: "mix",
                        key: "how-add-new-mix-goods",
                        popular: false,
                    },
                ],
                tech: [
                    {
                        title: "Как добавить технику на обслуживание",
                        module: "tech",
                        key: "HowAddTechToService",
                        popular: false,
                    },
                    {
                        title: 'Как создать и провести обслуживание',
                        module: "tech",
                        key: "HowCreateAndPerformService",
                        popular: false,
                    },
                    {
                        title: 'Управление складом запчастей',
                        module: "tech",
                        key: "HowManageSparePartsStorehouse",
                        popular: false,
                    },
                ],
                storehouse: [
                    {
                        title: "Складской учёт - Остатки",
                        module: "storehouse",
                        key: "StorehouseFaqInfo",
                        popular: false,
                        path: ["info"],
                    },
                    {
                        title: "Складской учёт - Обороты",
                        module: "storehouse",
                        key: "StorehouseFaqTurnover",
                        popular: false,
                        path: ["turnover"],
                    },
                    {
                        title: "Складской учёт - Операции",
                        module: "storehouse",
                        key: "StorehouseFaqOperations",
                        popular: false,
                        path: ["operations"],
                    },
                    {
                        title: "Складской учёт  - Добавление операции",
                        module: "storehouse",
                        key: "StorehouseFaqAddOperation",
                        popular: false,
                        path: ["operations"],
                    },
                ]
            }
        };
    },
    computed: {
        // Формирует объект, где ключи — модули, а значения — вложенные структуры,
        // соответствующие URL-пути каждого вопроса.
        faqQuestionsByUrl() {
            const result = {};
            for (const [module, questions] of Object.entries(this.faqQuestions)) {
                result[module] = {};
                questions.forEach((faq) => {
                    if (faq.path) {
                        faq.path.reduce((obj, key, index, arr) => {
                            if (!obj[key]) obj[key] = {}; // Создает вложенные объекты по ключам пути
                            if (index === arr.length - 1) obj[key] = faq; // Записывает сам вопрос в последний ключ пути
                            return obj[key];
                        }, result[module]);
                    }
                });
            }
            return result;
        },
        // Группирует вопросы по разделам в рамках каждого модуля.
        // Раздел определяется первым элементом массива `path`, если он есть,
        // иначе вопрос попадает в раздел genera`.
        faqQuestionsBySection() {
            const result = {};
            for (const [module, questions] of Object.entries(this.faqQuestions)) {
                if (!result[module]) result[module] = {};
                questions.forEach((faq) => {
                    const section = faq.path?.[0] || "general"; // Первый элемент `path`, либо "general" по умолчанию
                    if (!result[module][section]) result[module][section] = [];
                    result[module][section].push(faq); // Добавляет вопрос в соответствующий раздел
                });
            }
            return result;
        },
        // Возвращает массив всех популярных вопросов без привязки к модулям и разделам.
        popularFaqQuestions() {
            return Object.values(this.faqQuestions)
                .flat() // Преобразует объект модулей в единый массив вопросов
                .filter(q => q.popular); // Фильтрует только вопросы с `popular: true`
        }
    },
};
