<template>
    <el-tooltip placement="bottom-end" effect="light" :disabled="!mixOrders.length" :show-after="100">
        <template #content>
            <div style="width: 500px">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th style="width: 125px">
                            <div class="cell textXS textRegular neutral200 pb-15 pl-5">
                                ТС
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Объем ТС
                            </div>
                        </th>
                        <th>
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Водитель
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Доставки
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15 pr-5">
                                Объем
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(vehicle, index) in vehicles" :key="index" class="hover-row">
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 no-wrap pl-5">
                                {{ vehicle.number ? getVehicleNumber(vehicle.number) : '-' }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textRegular neutral400 pt-2-5 pb-2-5">
                                {{ vehicle.vehicle_volume }} м³
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 cutOverflow">
                                {{ vehicle.driver ? formatName(vehicle.driver) : '-' }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5">
                                {{ vehicle.count }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium success400 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(vehicle.total, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                Итого
                            </div>
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ allCount }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(allTotal, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <div class='d-flex content-center pl-10 pr-10 summary-order-groups-container'>
            <div :style="`min-width: ${windowWidth < 1280 ? 'max-content' : '100px'}`">
                <div class="order-groups">
                    <img :src="require('@/assets/truck.svg')" class="order-groups__icon"/>
                    <div class="order-groups__info">
                        <div class="order-groups__info__title">
                            <div class="d-flex">
                                <AnimateNumber :count="vehiclesCount"/>
                                <span class="ml-5">авто</span>
                            </div>
                        </div>
                        <div class="order-groups__info__desc">
                            Активны
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'opacity-0-1': vehiclesCount === 0 }" :style="{ opacity: vehiclesCount > 0 ? 1 : 0.2 }"
                 class="transition-opacity duration-500">
                <SummaryVehiclesCountBar :mixOrders="mixOrders" :hours="hours"/>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import SummaryVehiclesCountBar from "@/views/dispatch/components/summaryBlocks/SummaryVehiclesCountBar.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "SummaryVehiclesCount",
    components: {SummaryVehiclesCountBar, AnimateNumber},
    mixins: [functionsMixin, mobileCheckMixin],
    props: {
        hours: Array,
        summaryVehiclesCount: Array,
        mixOrders: Array
    },
    computed: {
        vehiclesCount() {
            return this.summaryVehiclesCount.vehicles_count ?? 0;
        },
        allCount() {
            return this.summaryVehiclesCount.vehicles_all_count ?? 0;
        },
        allTotal() {
            return this.summaryVehiclesCount.vehicles_all_total ?? 0;
        },
        vehicles() {
            return this.summaryVehiclesCount.vehicles ?? 0;
        },
    },
    methods: {}
}
</script>

<style scoped lang="scss">

</style>
