<template>
    <h2>Диспетчерская</h2>
    <p></p>
</template>

<script>
export default {
    name: "DispatchFaq"
}
</script>

<style scoped>

</style>
