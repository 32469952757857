<template>
    <div class="TableHeadBlock TableHeadBlock--mixer">
        <div class="d-flex align-center">
            <div class="mr-20 switcherBlock d-flex" v-if="editView">
                <slot name="switchBlock"/>
            </div>
            <div class="mr-20 searchAndFilter d-flex gap-20">
                <div class="dates date-picker-container" v-if="showDate">
                    <div class="current-day">{{ currentDayName }}</div>
                    <el-date-picker
                        class="summary-date-picker pointer"
                        v-model="filtersData['filter[date]']"
                        :type="'date'"
                        :format="'D MMMM'"
                        :clearable="false"
                        :editable="false"
                        :size="'default'"
                        @change="$emit('changeDate', filtersData['filter[date]'])"
                    />
                </div>
                <el-input
                    v-if="searchInput"
                    class="searchAndFilter__input"
                    v-model="search"
                    ref="searchInputRef"
                    :prefix-icon="icons.search"
                    placeholder="Поиск" clearable/>
            </div>
            <div class="d-flex justify-between align-center">
                <slot name="scheduleDefault"></slot>
            </div>
        </div>
        <div class="btns-group">
            <slot name="buttonsBlock"></slot>
        </div>
    </div>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";
import moment from "moment";

export default {
    name: 'ComponentsHeadBlockMixer',
    components: {
    },
    props: [
        'loading',
        'getDate',
        'filters',
        'showDate',
        'editView',
        'searchInput'
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    data() {
        return {
            search: this.modelValue,
            filtersData: this.filters,
        }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.getDate).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const yesterday = moment().add({day: -1}).format(format);

            if (currentMomentDate === yesterday) {
                return 'Вчера'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        }
    },
    watch: {
        'search': {
            handler: function () {
                this.$emit('update:modelValue', this.search)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.search = this.modelValue
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped lang="scss">
.TableHeadBlock {
    &--mixer {
        &:deep(.searchAndFilter__input .el-input__wrapper) {
            border-radius: 8px;
            height: 47px !important;
            max-width: 200px;
            width: 200px;

            @media screen and (max-width: 720px) {
                min-width: 120px;
                max-width: 200px;
                width: 100%;
            }
        }

        &:deep(.searchAndFilter__input) {
            max-width: 100%;
        }
    }

    .searchAndFilter {
        gap: 20px;

        .date-picker-container:deep(.el-input__inner) {
            margin-right: 0;
        }
    }
}
</style>
