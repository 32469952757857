<template>
    <OrderGroupsCreate
        :accountModulesId="accountModulesId"
        :moduleKey="moduleKey"
    />
</template>
<script>

import OrderGroupsCreate from "@/views/components/OrderGroupsCreate.vue";
export default {
    name: 'CRMOrderCreate',
    components: {OrderGroupsCreate},
    data() {
        return {
            accountModulesId: this.$route.params.id,
            moduleKey: this.$route.meta.moduleKey,
        }
    },
}
</script>

