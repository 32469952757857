<template>
    <template v-if="serviceGoods.length === 0 || addGood">
        <el-form label-position="top">

            <el-row :gutter="20">
                <el-col :md="16">

                    <FormSelect
                        v-model="newGood.id"
                        label="Запчасть"
                        load-from-store="good_id"
                        :items="techStoreGoods"
                        placeholder="Выберите запчасть"
                    />

                </el-col>
                <el-col :md="8">

                    <FormNumber
                        v-model="newGood.total"
                        placeholder="Укажите количество"
                        label="Кол-во"
                    />

                </el-col>
            </el-row>
        </el-form>

        <el-form-item label="">
            <el-button :icon="icons.plus" type="primary" :disabled="!newGood.id" v-if="indexEditGood === null"
                       @click.prevent="addNewRowGoods" plain>Добавить запчасть
            </el-button>
            <el-button :icon="icons.check" type="primary" :disabled="!newGood.id" v-if="indexEditGood === 0"
                       @click.prevent="saveEditGood" plain>Сохранить запчасть
            </el-button>
            <el-button :icon="icons.arrowLeft" v-if="serviceGoods.length !== 0"
                       @click.prevent="cancelAddNewGood()" plain>Отмена
            </el-button>

            <el-button
                type="info"
                @click="saveGoods"
                v-if="serviceGoods.length === 0"
                plain
            >
                Оставить список запчастей пустым
            </el-button>
        </el-form-item>
    </template>
    <template v-else>

        <div class="techServiceList d-flex-full" v-for="(good, index) in serviceGoods" :key="index">
            <div v-if="good.store_id">{{ good.store?.good?.name ?? '-' }}</div>
            <div v-else>{{ getName(good.id) ?? '-' }}</div>
            <div>{{ formatNumber(good.total) }}
                {{ good.store_id ? (good.store?.good?.unit?.short_name ?? 'ед.') : getGoodUnit(good.id) }}
            </div>
            <div>
                <el-button type="info" :icon="icons.edit"
                           @click.prevent="editRowGoods(index, good)"></el-button>
                <el-button type="danger" :icon="icons.delete"
                           @click.prevent="deleteRowGoods(index, good)"></el-button>
            </div>
        </div>

        <el-button @click="openAddGoodForm()" class="w-100 mb-30" :icon="icons.plus" plain>Добавить запчасть</el-button>

        <el-button type="primary" class="ml-0" :icon="icons.check" @click="saveGoods">Сохранить список запчастей
        </el-button>

    </template>
</template>

<script>
import FormNumber from '@/views/components/Form/FormNumber'
import iconsMixin from '@/mixins/iconsMixin'
import FormSelect from "@/views/components/Form/FormSelect";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'ServiceCreateGoodsDialog',
    components: {FormSelect, FormNumber},
    mixins: [iconsMixin, functionsMixin],
    props: ['modelValue', 'techStoreGoods'],
    data() {
        return {
            serviceGoods: JSON.parse(JSON.stringify(this.modelValue)),
            newGood: {
                id: null,
                total: null
            },
            addGood: false,
            indexEditGood: null,
        }
    },
    methods: {
        getName(goodId) {
            let good = this.techStoreGoods.find(el => el.id === goodId)

            if (good) {
                return good.good?.name ?? '-'
            }

            return '-'
        },
        getGoodUnit(goodId) {
            let good = this.techStoreGoods.find(el => el.id === goodId)

            if (good) {
                return good.good?.unit?.short_name ?? 'ед.'
            }
        },
        editRowGoods(index, good) {
            this.indexEditGood = this.serviceGoods.indexOf(good)
            this.newGood = {
                id: good.id,
                total: good.total
            }
            this.$emit('changeTitle', 'Изменение запчасти')
            this.addGood = true
        },
        saveEditGood() {
            this.serviceGoods[this.indexEditGood] = {
                id: this.newGood.id,
                total: this.newGood.total
            }

            this.newGood = {
                id: null,
                total: null
            }

            this.$emit('changeTitle', 'Список запчастей для проведения заказ-наряда')
            this.indexEditGood = null
            this.addGood = false
        },
        deleteRowGoods(index, good) {
            var idx = this.serviceGoods.indexOf(good)
            if (idx > -1) {
                this.serviceGoods.splice(idx, 1)
            }
            if (this.serviceGoods.length === 0) this.$emit('changeTitle', 'Добавление запчасти')
        },
        addNewRowGoods() {
            this.serviceGoods.push({
                id: this.newGood.id,
                total: this.newGood.total
            })

            this.newGood = {
                id: null,
                total: null
            }

            this.$emit('changeTitle', 'Список запчастей для проведения заказ-наряда')
            this.addGood = false
        },
        saveGoods() {
            this.$emit('update:modelValue', this.serviceGoods)
            this.$message({
                message: 'Список сохранен',
                showClose: true,
                type: 'success'
            })
            this.$emit('closeDialog')
        },
        openAddGoodForm() {
            this.$emit('changeTitle', 'Добавление запчасть')
            this.addGood = true
        },
        cancelAddNewGood() {
            this.$emit('changeTitle', 'Список запчастей для проведения заказ-наряда')
            this.addGood = false
            this.newGood = {
                id: null,
                total: null
            }
        }
    }
}
</script>

<style scoped>

</style>
