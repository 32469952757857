<template>
    <template v-if="!loading">
        <TechDetailsTopBlock
            :tech-details="techDetails"
            @getTechDetails="getTechDetails()"
            @moduleGet="$emit('moduleGet')"
        />
        <div class="techDetailsInfo" v-if="techDetails">

            <div class="techDetailsInfoIndicators">

                <div class="techDetailsInfoIndicatorsBlock">
                    <div class="item gps">
                        <div class="item-body">
                            <div class="topText">Местоположение</div>
                            <div v-if="techDetails.soft_id" style="height: 320px; margin-top: 10px">
                                <div style="height: 320px; display: flex; justify-content: center; align-items: center" v-if="mapLoading">
                                    <ReportLoader/>
                                </div>
                                <div style="height: 320px" v-else>
                                    <MixersMap
                                        v-if="coords"
                                        :vehicles="vehicles"
                                        :factories="factories"
                                        :coords="coords"
                                        :zoom="15"
                                    />
                                </div>
                            </div>
                            <div class="no-service" v-else>
                                <p>Нет подключения к GPS мониторингу</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="techDetailsInfoIndicatorsBlock">

                    <div class="d-flex mb-20">

                        <div class="item fuel pointer" @click="addFuelDialog=true" v-show="false">
                            <div class="item-body">
                                <span class="topText">Топливо</span>
                                <span class="mainText">
                    <AnimateNumber :count="techDetails.fuel_total"/>
                    л
                    <el-progress :stroke-width="12" :percentage="fuelPercentage()" color="#FF5C5E"></el-progress>
                </span>
                                <span
                                    class="bottomText">В среднем <strong>8 л.</strong> в день<br/>Хватит примерно на <strong>7 дней</strong></span>
                            </div>
                        </div>

                        <div class="item mileage pointer" @click="addMileageDialog=true">
                            <div class="item-body">
                                <span class="topText">Пробег</span>
                                <div class="mainText">
                                    <template v-if="techDetails.mileage">
                                        <AnimateNumber
                                            :count="techDetails.mileage"
                                        />
                                        <span> км.</span>
                                    </template>
                                    <template v-if="techDetails.engine_hours">
                                        <span v-if="techDetails.mileage"> / </span>
                                        <AnimateNumber
                                            :count="techDetails.engine_hours"
                                        />
                                        <span> мтч.</span>
                                    </template>
                                </div>
                                <span class="bottomText">{{
                                        getAverageMileage()
                                    }} км. в день в среднем</span>
                            </div>
                        </div>
                    </div>

                    <div class="item service pointer" @click="$emit('serviceActive')">
                        <div class="item-body">
                            <div class="topText">Сервис</div>
                            <div class="mainText">
                                <span v-if="!techWork">-</span>
                                <span v-else>{{ techWork.name }}</span>
                                </div>
                            <div class="bottomText">
                                <span v-if="!techWork">Технические работы не обнаружены</span>
                                <span v-else>Старт на пробеге {{ techWork.mileageIndicatorPlan ? techWork.mileageIndicatorPlan + ' км.' : techWork.hoursIndicatorPlan + ' мтч.' }}</span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </template>
    <template v-else>
        <div id="emptyState">
            <div class="text-center">
                <ReportLoader/>
            </div>
        </div>
    </template>
    <el-dialog
        v-model="addMileageDialog"
        title="Изменить значения пробега для транспорта"
        :width="drawerSize"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
    >
        <TechChangeMileage
            :tech="techDetails"
            @closeDialog="closeDialog()"
            @moduleGet="getTechDetails()"
        />
    </el-dialog>

    <el-dialog
        v-model="addFuelDialog"
        title="Изменить значение топлива и пробега"
        :width="drawerSize"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
    >
        <TechChangeFuel
            :vehicle-id="techDetails.id"
            @closeDialog="closeDialog()"
            @moduleGet="getTechDetails()"
        />
    </el-dialog>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import AnimateNumber from "@/views/components/AnimateNumber";
import TechDetailsTopBlock from "@/views/components/TechPage/Garage/TechDetails/TechDetailsTopBlock";
import TechChangeMileage from "@/views/components/TechPage/Garage/TechChangeMileage";
import TechChangeFuel from "@/views/components/TechPage/Garage/TechChangeFuel";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import MixersMap from "@/views/dispatch/components/mixers/MixersMap.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'TechDetailsInfo',
    components: {MixersMap, ReportLoader, TechChangeFuel, TechChangeMileage, TechDetailsTopBlock, AnimateNumber},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [mobileCheckMixin],
    data() {
        return {
            techDetails: null,
            addMileageDialog: false,
            addFuelDialog: false,
            mileageHistory: [],
            loading: true,
            vehicles: [],
            coords: null,
            mapLoading: true,
            techWork: null,
            intervalId: null,
        }
    },
    created() {
        this.getTechDetails()
    },
    computed: {
        ...mapState(useCommonStore, {
            factories: 'getFactories',
        }),
    },
    beforeUnmount() {
        this.stopInterval();
    },
    methods: {
        getTechDetails() {
            this.loading = true
            httpService().post(this.api.tech.getTechDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.techDetails = data.details
                    if (this.techDetails.soft_id) {
                        this.getTechMapInfo()
                        this.startInterval();
                    }
                    this.techWork = data.techWork
                    this.mileageHistory = data.mileageHistory
                    this.loading = false
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        getTechMapInfo() {
            httpService().post(this.api.tech.getTechMapInfo, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.vehicles
                    this.coords = [parseFloat(data.latitude), parseFloat(data.longitude)]
                    this.mapLoading = false
                }
            })
        },
        fuelPercentage() {
            if (this.techDetails.fuel_total && this.techDetails.fuel_tank) {
                return Math.round(this.techDetails.fuel_total / this.techDetails.fuel_tank * 100)
            } else {
                return 0
            }
        },
        closeDialog() {
            this.addFuelDialog = false
            this.addMileageDialog = false
            this.getTechDetails()
        },
        getAverageMileage() {
            let result = this.mileageHistory.reduce(function (sum, elem) {
                return sum + elem
            }, 0);

            return Math.round(result / 6)
        },
        startInterval() {
            this.intervalId = setInterval(() => {
                this.getTechMapInfo();
            }, 15000);
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
    }
}
</script>

<style scoped>

</style>
