<template>
    <template v-if="order">
        <div @click="openMixOrderDetails"
             class="dispatchOrderItemTable"
             :class="[
                 {pointer: !current},
                 {dispatchOrderItemTableNext: !current},
                 {orderNotConfirm: !order.confirm},
                 {orderNotConfirmActual: !order.confirm && order.diff < 60}
             ]">
            <div class="dispatchOrderItemTableBody">
                <div class="dispatchOrderItemTableContent">
                    <div class="d-flex-full-stroke dispatchOrderItemTableContentInfo">
                        <div class="d-flex-full-stroke">
                            <svg class="mr-5" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.21338 7.46053C5.21338 7.10081 5.52118 6.80921 5.90088 6.80921H8.65088C9.03058 6.80921 9.33838 7.10081 9.33838 7.46053C9.33838 7.82024 9.03058 8.11184 8.65088 8.11184H5.90088C5.52118 8.11184 5.21338 7.82024 5.21338 7.46053Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10.0259 7.46053C10.0259 6.7411 10.6415 6.15789 11.4009 6.15789H14.1509C14.9103 6.15789 15.5259 6.7411 15.5259 7.46053V13.9737C15.5259 14.3334 15.2181 14.625 14.8384 14.625H10.7134C10.3337 14.625 10.0259 14.3334 10.0259 13.9737V7.46053ZM14.1509 7.46053H11.4009V13.3224H14.1509V7.46053Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.21338 5.50658C5.21338 5.14687 5.52118 4.85526 5.90088 4.85526L8.65088 4.85526C9.03058 4.85526 9.33838 5.14687 9.33838 5.50658C9.33838 5.86629 9.03058 6.15789 8.65088 6.15789H5.90088C5.52118 6.15789 5.21338 5.86629 5.21338 5.50658Z"
                                      fill="#22252C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M3.15088 3.55263C3.15088 2.83321 3.76649 2.25 4.52588 2.25H10.0259C10.7853 2.25 11.4009 2.83321 11.4009 3.55263V13.9737C11.4009 14.3334 11.0931 14.625 10.7134 14.625H3.83838C3.45868 14.625 3.15088 14.3334 3.15088 13.9737V3.55263ZM10.0259 3.55263H4.52588V13.3224H10.0259V3.55263Z"
                                      fill="#22252C"/>
                            </svg>
                            <div>{{ order.time }}</div>
                        </div>
                        <svg class="mr-10 ml-10" width="5" height="4" viewBox="0 0 5 4" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.28954 2.058C4.28954 2.30067 4.24754 2.52933 4.16354 2.744C4.07954 2.95867 3.95821 3.14533 3.79954 3.304C3.65021 3.46267 3.46354 3.59333 3.23954 3.696C3.02487 3.78933 2.77754 3.836 2.49754 3.836C2.21754 3.836 1.96554 3.78933 1.74154 3.696C1.52687 3.59333 1.34021 3.46267 1.18154 3.304C1.03221 3.14533 0.915539 2.95867 0.831539 2.744C0.747539 2.52933 0.705539 2.30067 0.705539 2.058C0.705539 1.82467 0.747539 1.60067 0.831539 1.386C0.915539 1.162 1.03221 0.970666 1.18154 0.812C1.34021 0.653333 1.52687 0.527333 1.74154 0.434C1.96554 0.331333 2.21754 0.28 2.49754 0.28C2.77754 0.28 3.02487 0.331333 3.23954 0.434C3.46354 0.527333 3.65021 0.653333 3.79954 0.812C3.95821 0.970666 4.07954 1.162 4.16354 1.386C4.24754 1.60067 4.28954 1.82467 4.28954 2.058Z"
                                fill="black"/>
                        </svg>
                        <el-tooltip :disabled="!order.client_volume" popper-class="content__delivery__item__tooltip gap-8" effect="dark" content="Объём" placement="top">
                            <template #content>
                                <p class="m-0"><b>Объём заявки: {{ order.volume }} м³</b></p>
                                <p class="m-0"><b>Запрошенный объём: {{ order.client_volume }} м³</b></p>
                            </template>
                            <div class="d-flex-full-stroke">
                                <PackageIcon class="mr-5" width="16" height="16"/>
                                <div>{{ order.volume }} м³</div>
                            </div>
                        </el-tooltip>
                        <!-- <div class="d-flex-full-stroke">
                            <svg class="mr-5" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.61993 1.65336L3.05993 3.62669C2.25326 4.07336 1.59326 5.19336 1.59326 6.11336V9.88002C1.59326 10.8 2.25326 11.92 3.05993 12.3667L6.61993 14.3467C7.37993 14.7667 8.6266 14.7667 9.38659 14.3467L12.9466 12.3667C13.7533 11.92 14.4133 10.8 14.4133 9.88002V6.11336C14.4133 5.19336 13.7533 4.07336 12.9466 3.62669L9.38659 1.64669C8.61993 1.22669 7.37993 1.22669 6.61993 1.65336Z"
                                    fill="#22252C"/>
                                <path d="M2.11328 4.95996L7.99995 8.36663L13.8466 4.97996" stroke="white"
                                      stroke-width="1.2"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 14.4065V8.35986" stroke="white" stroke-width="1.2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <div class="no-wrap">{{ formatNumber(order.volume, 2) }} м³</div>
                        </div> -->
                    </div>
                    <div v-if="order.confirm" class="neutral100 textRegular pr-5 dispatchOrderItemTableContentNumber no-wrap">
                        #{{ formatNumber(order.id) }}
                    </div>
                    <div v-else class="pr-5 p-relative">
                        <div class="dispatchOrderItemTableContentInfoNotConfirm">
                            <el-tooltip effect="dark" placement="top" :show-after="500">
                                <template #content>
                                    <div class="text-center">
                                        <div class="textMedium mb-5">Необходимо подтвердить отгрузку</div>
                                        <div class="textXS mb-10">Неподтверждённые отгрузки<br/>невозможно запустить
                                            в<br/>работу
                                            оператору и водителю
                                        </div>
                                        <div class="textXS">Не подтверждайте отгрузки<br/>сильно заранее, так
                                            алгоритм<br/>будет
                                            менять их подбирая<br/>наилучшее время и миксер
                                        </div>
                                    </div>
                                </template>
                                <svg height="20" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="orderNotConfirmIconColor"
                                          d="M12.1134 4.38669V8.94667C12.1134 9.69333 11.7134 10.3867 11.0667 10.7667L7.10669 13.0534C6.46002 13.4267 5.66001 13.4267 5.00667 13.0534L1.04667 10.7667C0.400005 10.3934 0 9.7 0 8.94667V4.38669C0 3.64002 0.400005 2.94666 1.04667 2.56666L5.00667 0.28C5.65334 -0.0933333 6.45336 -0.0933333 7.10669 0.28L11.0667 2.56666C11.7134 2.94666 12.1134 3.63336 12.1134 4.38669Z"
                                          fill="#ECAF02"/>
                                    <path
                                        d="M6.06006 7.8335C5.78673 7.8335 5.56006 7.60683 5.56006 7.3335V3.8335C5.56006 3.56016 5.78673 3.3335 6.06006 3.3335C6.33339 3.3335 6.56006 3.56016 6.56006 3.8335V7.3335C6.56006 7.60683 6.33339 7.8335 6.06006 7.8335Z"
                                        fill="white"/>
                                    <path
                                        d="M6.05998 10.1668C5.97331 10.1668 5.88664 10.1468 5.80664 10.1135C5.71997 10.0801 5.6533 10.0335 5.58663 9.9735C5.52663 9.90683 5.47998 9.83349 5.43998 9.75349C5.40665 9.67349 5.39331 9.58682 5.39331 9.50015C5.39331 9.32682 5.45996 9.15347 5.58663 9.0268C5.6533 8.9668 5.71997 8.92016 5.80664 8.88683C6.05331 8.78016 6.34666 8.84014 6.53333 9.0268C6.59333 9.09347 6.63997 9.16015 6.6733 9.24681C6.70663 9.32681 6.72664 9.41348 6.72664 9.50015C6.72664 9.58682 6.70663 9.67349 6.6733 9.75349C6.63997 9.83349 6.59333 9.90683 6.53333 9.9735C6.40666 10.1002 6.23998 10.1668 6.05998 10.1668Z"
                                        fill="white"/>
                                </svg>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="dispatchOrderItemTableFooter">
                    <div class="dispatchOrderItemTableStatus dispatchOrderItemTableStatusCurrent">
                        <div class="dispatchOrderItemTableStatusBody">
                            {{ order.status }} - {{ order.timeStatus }}
                        </div>
                    </div>

                    <div class="dispatchOrderItemTableStatus">
                        <div class="dispatchOrderItemTableStatusBody">
                            <div class="cutOverflow text-center" style="min-width: 85px">
                                {{ order.number ? getVehicleNumber(order.number) : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <p class="m-0 dispatchOrderItemTableEmpty">-</p>
    </template>

</template>

<script>
import mixOrderInfo from "@/mixins/mixOrderInfo";
import PackageIcon from "@/views/components/Icons/PackageIcon.vue";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: 'MixOrderGroupTableOrderItem',
    components: {PackageIcon},
    props: ['orders', 'current', 'orderGroupId', 'timezone'],
    mixins: [mixOrderInfo, functionsMixin],
    data() {
        return {}
    },
    created() {

    },
    methods: {
        openMixOrderDetails() {
            this.$emit('openMixOrderDetails', this.order.id, this.orderGroupId)
        },
    },
    computed: {
        order() {
            let time = null
            let timeStatus = null
            let orderData = null
            let currentData = null
            let ordersData = []
            let nextData = null

            // ищем текущую
            ordersData = this.orders.filter(el => el.status !== 'wait' && el.status !== 'done' && el.confirm).sort((a, b) => new Date(b.start_at) - new Date(a.start_at)) ?? []
            if (ordersData.length) {
                currentData = ordersData[0]
            }

            // ищем следующую
            ordersData = this.orders.filter(el => el.status === 'new').sort((a, b) => new Date(a.start_at) - new Date(b.start_at)) ?? []
            if (ordersData.length) {
                nextData = ordersData[0]
                if (currentData && nextData.id === currentData.id) {
                    if (ordersData.length > 1) {
                        nextData = ordersData[1]
                    } else {
                        nextData = null
                    }
                }
            }

            if (currentData && currentData.confirm && nextData && nextData.confirm) {
                let c = currentData
                let n = nextData
                currentData = n
                nextData = c
            }

            if (this.current) {
                orderData = currentData
            } else {
                orderData = nextData
            }

            if (orderData) {
                time = orderData.arrive_at

                if (orderData.status === 'new' || orderData.status === 'wait') {
                    timeStatus = orderData.start_at
                } else if (orderData.status === 'loading') {
                    timeStatus = orderData.load_at
                } else if (orderData.status === 'delivery') {
                    timeStatus = orderData.arrive_at
                } else if (orderData.status === 'object' || orderData.status === 'pouring') {
                    time = orderData.return_at
                    timeStatus = orderData.unload_at
                } else if (orderData.status === 'return') {
                    time = orderData.return_at
                    timeStatus = orderData.return_at
                }

                let status = this.getStatusLabel(orderData.status)

                if (status === 'На объекте') {
                    status = 'Объект'
                } else if (status === 'Возвращается') {
                    status = 'Возвращ.'
                }

                let diff = moment(timeStatus).utc().tz(this.timezone).diff(moment().utc().tz(this.timezone), 'minutes')

                let number = null

                if (orderData.type === 'delivery' && orderData.vehicle_id) {
                    number = orderData.number
                } else {
                    number = 'Самовывоз'
                }

                return {
                    id: orderData.id,
                    time: moment(time).format('HH:mm'),
                    volume: orderData.total,
                    client_volume: orderData.client_total,
                    status: status,
                    timeStatus: moment(timeStatus).format('HH:mm'),
                    number: number,
                    confirm: orderData.confirm,
                    diff: diff
                }
            } else {
                return null
            }
        }
    },
}
</script>

<style scoped>

</style>
