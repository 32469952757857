<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавление нового рецепта"/>

            <el-form ref="form" :model="createMixGoodForm" size="small" label-position="top">

                <FormInput
                    v-model="createMixGoodForm.name"
                    label="Название рецепта"
                    placeholder="Введите название рецепта"
                />

                <FormSelect
                    v-model="createMixGoodForm.category"
                    label="Категория"
                    placeholder="Выберите категорию"
                    :items="categories"
                />

                <FormSelect
                    v-model="createMixGoodForm.unit"
                    label="Ед. измерения"
                    placeholder="Выберите единицу измерения"
                    :items="units"
                />


                <GoodsCreateComponent @components="getComponents"/>

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <el-tooltip content="Укажите количество для всех компонентов" :disabled='!isSaveButtonDisabled'>
                <DrawerCreateButton :disabled="isSaveButtonDisabled" title="Создать рецепт" @action="createValue"/>
            </el-tooltip>
        </template>
    </DrawerFramework>
</template>

<script>
import FormInput from '../../Form/FormInput'
import DrawerFramework from '../../Drawer/DrawerFramework'
import DrawerCreateButton from '../../Drawer/Buttons/DrawerCreateButton'
import DrawerHeadTitle from '../../Drawer/DrawerHeadTitle'
import FormSelect from '../../Form/FormSelect'
import GoodsCreateComponent from '../../SettingsPage/GoodsPage/GoodsCreateComponent'
import { mapActions, mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    components: {
        GoodsCreateComponent,
        FormSelect, DrawerHeadTitle, DrawerCreateButton, DrawerFramework, FormInput
    },
    props: [
        'categories',
    ],
    name: 'MixPageGoodCreate',
    data() {
        return {
            loading: false,
            createMixGoodForm: {
                name: null,
                category: null,
                unit: null,
                components: [{
                    'id': null,
                    'total': null,
                }],
            },
        }
    },
    inject: [
        'api'
    ],
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        }),
        isSaveButtonDisabled() {
            if(!this.createMixGoodForm.components || !this.createMixGoodForm.components.length) return false

            return !this.createMixGoodForm?.components.every((el) => el.total)
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        createValue() {
            this.loading = true

            httpService().post(this.api.mix.createGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                module: this.createMixGoodForm
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Рецепт добавлен',
                        showClose: true,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                    return
                }


                this.pullGoods()
                this.$emit('moduleGet')
                this.$emit('closeDrawer')
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        getComponents({ components }) {
            this.createMixGoodForm.components = components
        },
    },
}
</script>

<style scoped>

</style>
