<template>
    <Auth title="Рабочее место оператора">
        <div class="operator-container">

            <div class="operator-header text-right">
                <!-- <el-link @click="doLogout">
                    Выйти
                </el-link> -->
            </div>

            <div class="operator-body">

                <el-row :gutter="20">
                    <el-col :sm="18">

                        <TableHeadBlock
                            v-model="search"
                            :loading="loading"
                            :icon="icons.plus"
                            :disable-filter="true"
                            @moduleGet="moduleGet(true)"
                            :disable-create-button="!operatorSettings.operatorCreateOrder"
                            @openCreateValueDrawer="openCreateMixOrderDialog"
                            tooltip-text="Добавить отгрузку"
                        >
                            <template #afterSearchAndFilter>
                                <el-radio-group class="operatorOrdersTab" v-model="ordersStatus">
                                    <el-radio-button label="В работе" value="В работе"/>
                                    <el-radio-button label="Выполненные" value="Выполненные"/>
                                    <el-radio-button label="Все" value="Все"/>
                                </el-radio-group>

                                <div
                                    class="operatorDates"
                                >
                                    <day-list-filter
                                        :date="currentDate"
                                        @onChangeDate="changeDate"
                                    ></day-list-filter>
                                </div>
                            </template>
                            <template v-slot:buttonsBlock v-if="operatorSettings.operatorPrint">
                                <button
                                    @click="() => printerDialogShow = true"
                                    v-if="!mobile"
                                    class="ci-btn ci-btn_default"
                                    :disabled="values.length === 0"
                                >
                                    <PrinterIcon />
                                    <span v-if="windowWidth > 1500">Печать</span>
                                </button>
                            </template>
                        </TableHeadBlock>


                        <div v-loading="loading" style="min-height: 520px">

                            <div class="mt-30" v-if="orders.length">
                                <OperatorTable
                                    :orders="orders"
                                    :mixerCount="mixerCount"
                                    :operatorCheckTimeStart="operatorSettings.operatorCheckTimeStart"
                                    :factory-name="factoryName"
                                    :tz="tz"
                                    :operator-print="operatorSettings.operatorPrint"
                                    @openMixOrderAction="openMixOrderAction"
                                    @printOrder="printOrder"
                                />
                            </div>

                            <div class="text-center" style="padding-top: 150px; padding-bottom: 150px"
                                v-else-if="!loading && !firstLoad">
                                <div>
                                    <EmptyListIcon :height="160"/>
                                    <div>
                                        <h3 class="neutral700">Отгрузок не найдено</h3>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </el-col>
                    <el-col :sm="6">

                        <div class="card operator-mixers">
                            <div class="operator-mixers-body">
                                <div style="height: 550px; display: flex; justify-content: center; align-items: center"
                                     v-if="firstLoad">
                                    <ReportLoader/>
                                </div>
                                <div v-else>
                                    <OperatorMixers
                                        :mix-id="mixId"
                                        :tz="tz"
                                        :coords="coords"
                                        :factories="factories"
                                        :postSchedules="postSchedules"
                                        :orders="values"
                                        :statistics="statistics"
                                        @changeStatus="changeStatus"
                                    />
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
    </Auth>

    <el-dialog
        v-model="orderDetailsDialog"
        :title="(orderDetails?.type ? getTypeLabel(orderDetails.type) : 'Отгрузка') + (orderDetails.doc ? ' №' + orderDetails.doc : '')"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <OperatorOrderDetails
            :order="orderDetails"
            :mix-id="mixId"
        />
    </el-dialog>

    <el-dialog
        v-model="orderActionDialog"
        :title="(orderDetails?.type ? getTypeLabel(orderDetails.type) : 'Отгрузка') + (orderDetails.doc ? ' №' + orderDetails.doc : '')"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <OperatorOrderAction
            :order="orderDetails"
            :mix-id="mixId"
            @update="moduleGet"
            @close="orderActionDialogClose()"
        />
    </el-dialog>

    <el-dialog
        title="Создание отгрузки"
        v-model="createMixOrderDialog"
        :width="mobile ? '100%' : '60%'"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <OperatorOrderCreate
            :mix-id="mixId"
            @update="moduleGet"
            @close="orderCreateDialogClose()"
        />

    </el-dialog>

    <el-dialog
        class="operator-print-dialog"
        v-model="printerDialogShow"
        title="Печать документов"
        :width="dialogSize"
        :destroy-on-close="true"
        @close="currentOrder = null"
    >
        <AllPrintDocs
            from="operator"
            :orders="values"
            :currentOrder="currentOrder"
            :mixId="mixId"
            :date="currentDate"
            :accessDocuments="accessDocuments"
            :tz="tz"
        />
    </el-dialog>
</template>

<script>

import {authService} from '@/services/auth.service'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import Auth from '@/views/layouts/Auth.vue';
import {httpService} from '@/services/http.service'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import iconsMixin from '@/mixins/iconsMixin'
import OperatorOrderAction from "@/views/components/Dashboards/Operator/OperatorOrderAction.vue";
import mixOrderStatuses from "@/mixins/mixOrderInfo";
import OperatorTable from "./OperatorTable.vue";
import OperatorMixers from "@/views/components/Dashboards/Operator/OperatorMixers.vue";
import OperatorOrderDetails from "@/views/components/Dashboards/Operator/OperatorOrderDetails.vue";
import OperatorOrderCreate from "@/views/components/Dashboards/Operator/OperatorOrderCreate.vue";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon.vue";
import moment from "moment";
import DayListFilter from "@/views/components/Table/DayListFilter.vue";
import AllPrintDocs from "@/views/dispatch/components/AllPrintDocs.vue";
import PrinterIcon from "@/views/components/Icons/PrinterIcon.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: "OperatorDashboard",
    components: {
        ReportLoader,
        AllPrintDocs,
        DayListFilter,
        EmptyListIcon,
        OperatorOrderCreate,
        OperatorTable,
        OperatorOrderDetails, OperatorMixers, OperatorOrderAction, TableHeadBlock, Auth, PrinterIcon
    },
    inject: ['api'],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, mixOrderStatuses, mixOrderInfo],

    data() {
        return {
            loading: false,
            printerDialogShow: false,
            ordersLoading: false,
            firstLoad: true,
            search: null,
            ordersStatus: 'В работе',
            module: [],
            mixId: null,
            tableData: [],
            orderDetails: {
                doc: null
            },
            orderDetailsDialog: false,
            orderActionDialog: false,
            createMixOrderDialog: false,
            filter: {},
            values: [],
            filtered: [],
            statistics: [],
            mixIntegrations: [],
            operatorSettings: {
                operatorCreateOrder: false,
                operatorCheckTimeStart: false,
                operatorPrint: false,
            },
            currentDate: moment(),
            tz: 'Europe/Moscow',
            factoryName: null,
            mixerCount: 0,
            accessDocuments: [],
            currentOrder: null,
            coords: [],
            factories: [],
            postSchedules: []
        }
    },
    async created() {
        if (this.links.length > 0) {
            this.mixId = this.links.find(el => el.type === 'mix').module_id
        }

        this.mixModuleGet()

        await this.moduleGet(true)
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
        window.Echo.leave(`laravel_database_ChannelMixBroadcast`);
        clearInterval(this.counterInterval)
    },
    computed: {
        ...mapState(useCommonStore, {
            links: 'getNav'
        }),
        orders() {
            this.filterOrdersByStatus();
            this.filterOrdersBySearch();
            return this.filtered;
        }
    },
    methods: {
        filterOrdersByStatus() {
            this.filtered = this.values;
            if (this.ordersStatus === 'В работе') {
                this.filtered = this.values.filter(el => el.status === 'new' || el.status === 'loading');
            } else if (this.ordersStatus === 'Выполненные') {
                this.filtered = this.values.filter(el => el.confirm && (el.status !== 'new' && el.status !== 'loading'));
            }
        },
        filterOrdersBySearch() {
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || (data.order_good_name.toLowerCase().includes(this.search.toLowerCase()) || data.vehicle.toLowerCase().includes(this.search.toLowerCase())));
            }
        },
        changeDate(date) {
            this.currentDate = moment(date).utcOffset(0, true).startOf('day')
            this.moduleGet(true)
            this.getMixPostSchedules()
        },
        mixModuleGet() {
            httpService().post(this.api.operator.getModule, {
                date: this.currentDate
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.module = data.module
                    this.operatorSettings = data.settings
                    this.coords = [parseFloat(data.latitude), parseFloat(data.longitude)]
                    this.factories = data.factories
                    this.postSchedules = data.postSchedules

                    this.factoryName = data.module.integrations.find((i) => i.integration.key === 'SmartMix')?.product ?? null;
                    this.mixId = data.mix_id;

                    window.Echo.channel('laravel_database_ChannelMixBroadcast')
                        .listen(`.Algorithm.${data.mix_id}`, () => {
                            this.moduleGet()
                        })
                        .listen(`.Create.${data.mix_id}`, () => {
                            this.moduleGet()
                        })
                        .listen(`.Update.${data.mix_id}`, () => {
                            this.moduleGet()
                        })
                        .listen(`.UpdateMix.${data.mix_id}`, () => {
                            this.getMixPostSchedules()
                        })
                        .listen(`.Delete.${data.mix_id}`, () => {
                            this.moduleGet()
                        });

                    window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
                        .listen(`.Algorithm.${data.dispatch_account_module_id}`, () => {
                            this.moduleGet()
                        })
                }
            })
        },
        changeStatus(scheduleId, status) {
            // 1 - возобновить, 0 - на паузу
            const message = status ?
                'возобновить работу поста? Это действие вернет пост в расписание' :
                'приостановить работу поста? Это действие отключит пост из расписания'

            this.$confirm('Вы действительно хотите ' + message, 'Подтвердите смену статуса', {
                confirmButtonText: 'Сменить статус',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                httpService().post(this.api.operator.changePostStatus, {
                    id: scheduleId,
                    status: status,
                    date: this.currentDate,
                }).then(({data}) => {
                    this.postSchedules = data.postSchedules
                    this.$message({
                        message: 'Статус изменен',
                        showClose: true,
                        type: 'info'
                    })
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                })
            })

        },
        getMixPostSchedules(){
            httpService().post(this.api.operator.getMixPostSchedules, {
                mix_id: this.mixId,
                date: this.currentDate,
            }).then(({data}) => {
                this.postSchedules = data.postSchedules
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openMixOrderDetails(index, row) {
            this.orderDetailsDialog = true
            this.orderDetails = row
        },
        openMixOrderAction(row) {
            this.orderActionDialog = true
            this.orderDetails = row
        },
        orderActionDialogClose() {
            this.orderActionDialog = false
            this.orderDetails = {
                doc: null
            }
        },
        orderCreateDialogClose() {
            this.createMixOrderDialog = false
        },
        openCreateMixOrderDialog() {
            this.createMixOrderDialog = true
        },
        async moduleGet(loading) {
            if (this.ordersLoading) {
                return;
            }
            this.ordersLoading = true;
            if (loading) {
                this.loading = true;
            }
            try {
                const response = await httpService().post(this.api.operator.getOrders, {
                    date: this.currentDate
                });

                let data = response.data;
                if (data.success) {
                    this.values = data.values;
                    this.mixerCount = data.mixerCount;
                    this.tz = data.tz
                    this.accessDocuments = data.accessDocuments;
                    this.statistics = data.statistics;

                    this.firstLoad = false

                    window.setTimeout(() => {
                        this.loading = false;
                    }, 300)
                }
                this.ordersLoading = false
            } catch (error) {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false;
                this.ordersLoading = false
            }
        },
        filterValues() {

        },
        doLogout() {
            this.$confirm(
                'Вы действительно хотите выйти?',
                'Подтвердите выход из системы',
                {
                    confirmButtonText: 'Да, выйти',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    authService().doLogout()
                })
                .catch(() => {
                    this.$message({
                        message: 'Отменено',
                        showClose: true,
                        type: 'info',
                    });
                    this.loading = false;
                })
        },
        printOrder(order) {
            this.currentOrder = order;
            this.printerDialogShow = true;
        }
    },
}
</script>

<style lang="scss">
.operator-print-dialog {
    .el-dialog__header {
        padding-bottom: 12px !important;
    }
}
</style>
