<template>
    <article class="edit-volume-input d-flex gap-4 align-center">
        <el-input-number class="edit-volume-input__input"
                         placeholder="0"
                         controls-position="right"
                         v-model="client_total"
                         :step="0.1" />
        <el-button class="edit-volume-input__button"
                   type="primary"
                   plain
                   width="24"
                   height="24"
                   @click="$emit('submit')"
                   :disabled="!isSubmitButtonActive"
                   :icon="icons.select" />
        <el-button class="edit-volume-input__button"
                   type="info"
                   plain
                   width="24"
                   height="24"
                   @click="$emit('close')"
                   :icon="icons.closeBold" />
    </article>

</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";
export default {
    name: "EditVolumeInput",
    mixins: [iconsMixin],
    props: {
        modelValue: {
            type: Number,
        },
    },
    data: () => ({
        client_total: null,
        default_client_total: null,
        isSubmitButtonActive: false,
    }),
    mounted() {
        this.client_total = this.modelValue === 0 ? null : this.modelValue
        this.default_client_total = this.modelValue === 0 ? null : this.modelValue
        this.isSubmitButtonActive = false
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.client_total = this.modelValue
                this.isSubmitButtonActive = this.client_total !== this.default_client_total
            },
            deep: true,
            immediate: true
        },
        'client_total': {
            handler: function () {
                this.$emit('update:modelValue', this.client_total);
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
