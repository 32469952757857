<template>
    <section class="guide-steps pl-10 pr-10 pt-42 pb-10">
        <div class="guide-steps__container d-flex f-direction-column gap-48">
            <slot name="steps"></slot>
        </div>
    </section>
</template>

<script>

export default {
    name: 'GuideSteps',
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
@use "@/sass/_variables.scss";

.guide-steps {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 8px;
    }

    &__container {
        max-width: 80%;
        justify-self: center;

        @media screen and (max-width: 768px) {
            padding-top: 6px;
            max-width: 100%;
        }
    }
}
</style>
