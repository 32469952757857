<template>
    <EmptyState
        :values="values"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="заявок"
    />

    <div v-if="values.length > 0">
        <div class="el-table ordersTable mt-15">
            <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                <thead>
                <tr>
                    <th style="width: 70px">
                        <div class="cell textXS textRegular neutral200">
                            #
                        </div>
                    </th>
                    <th style="width: 84px" v-if="isDocRequired">
                        <div class="cell textXS textRegular neutral200">
                            # с АСУ
                        </div>
                    </th>
                    <th style="width: 90px">
                        <div class="cell textXS textRegular neutral200">
                            Заказ
                        </div>
                    </th>
                    <th :style='windowWidth > 1220 ? "width: 130px" : "width: 100px"'>
                        <div class="cell textXS textRegular neutral200">
                            Статус
                        </div>
                    </th>
                    <th style="width: 85px">
                        <div class="cell textXS textRegular neutral200">
                            Объем
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '65' : '75'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Старт загрузки на заводе"
                                        :show-after="150">
                                Загрузка
                            </el-tooltip>
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '70' : '80'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Время прибытия на объект"
                                        :show-after="150">
                                Доставка
                            </el-tooltip>
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '65' : '70'}px`">
                        <div class="cell textXS textRegular neutral200">
                            <el-tooltip effect="dark" placement="top" content="Время возвращения на завод"
                                        :show-after="150">
                                Возврат
                            </el-tooltip>
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '120px' : 'initial'}`">
                        <div class="cell textXS textRegular neutral200">
                            Контрагент
                        </div>
                    </th>
                    <th :style="`width: ${vehicleColumnWidth}px`">
                        <div class="cell textXS textRegular neutral200 nowrap">
                            ТС
                        </div>
                    </th>
                    <th :style="`width: ${driverColumnWidth}px`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Водитель
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '120px' : 'initial'}`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Рецепт
                        </div>
                    </th>
                    <th :style="`width: ${windowWidth < 1400 ? '80' : mixersColumnWidth}px;`">
                        <div class="cell textXS textRegular neutral200 maxLines2">
                            Завод
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="actual && previousData.count > 0"
                    class="el-table__row dispatchActualOrdersButtonBlock pointer" @click="showOtherHours('previous')">
                    <td :colspan="isDocRequired ? 13 : 12">
                        <div class="cell text-center">
                            <div class="primary600 textMedium">Отобразить прошедшие часы: {{ previousData.count }}
                                {{ declension(previousData.count, 'отгрузка') }} на {{ previousData.total }} м³
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="actual && previousData.count === 0 && previousDataCount > 0"
                    class="el-table__row dispatchActualOrdersButtonHistory pointer" @click="() => previousCount = 0">
                    <td :colspan="isDocRequired ? 13 : 12">
                        <div class="cell text-center">
                            <div class="neutral500 textMedium">Скрыть прошедшие отгрузки</div>
                        </div>
                    </td>
                </tr>
                <template v-for="mixOrdersByHour in (actual ? displayOrders : mixOrdersByHours)"
                          :key="'hours' + mixOrdersByHour.hour">
                    <tr class="el-table__row ordersTableBorder">
                        <td :colspan="isDocRequired ? 4 : 3" class="bg-neutral30">
                            <div class="cell">
                                <div
                                    :class="['textMedium', mixOrdersByHour.diff === 0 ? 'primary600' : mixOrdersByHour.diff < 0 ? 'neutral400' : 'neutral900']">
                                    {{ mixOrdersByHour.hour }}:00
                                </div>
                            </div>
                        </td>
                        <td colspan="1" class="bg-neutral30">
                            <div class="cell">
                                <div class="textMedium neutral900">
                                    {{ getSumOrdersByHour(mixOrdersByHour.orders) }} м³
                                </div>
                            </div>
                        </td>
                        <td colspan="8" class="bg-neutral30">

                        </td>
                    </tr>
                    <template v-for="order in mixOrdersByHour.orders" :key="order.id">
                        <tr class="el-table__row"
                            @click="order.order_group_id ? openMixOrderDetails(order, order.order_group_id) : null"
                            :class="[
                                {ordersTableBorder: order.status === 'new' || order.status === 'done'},
                                {pointer: order.order_group_id}
                                ]">
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span class="textMedium" :class="[
                                            { warning300 : order.confirm && order.status === 'new' },
                                            { primary600 : order.confirm && (order.status !== 'new' && order.status !== 'done') },
                                            { success400 : order.status === 'done' },
                                        ]">#{{
                                                order.id
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td v-if="isDocRequired">
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">
                                                {{ order.doc ? '#' + order.doc : '-' }}
                                            </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <span class="textMedium primary600 pointer"
                                              @click="$router.push({name: 'DispatchOrderGroupsDetails', params: { groupId: order.order_group_id }})">{{
                                                order.order_group_id ? '№' + order.order_group_id : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem">
                                        <template v-if="order.confirm">
                                            <span class="textMedium success400">{{
                                                    getStatusLabel(order.status)
                                                }}</span>
                                        </template>
                                        <template v-else>
                                            <span class="textRegular neutral200">Не подтвержд.</span>
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItem__volume"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span>{{
                                                (order.status === 'new' || order.status === 'loading') ? order.total : (order.done === 0 ? order.total : order.done)
                                            }} м³</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__loading-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.start_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.arrive_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub ordersTableItemTime"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <div class="ordersTableItem__market-time">
                                            <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    dateToTime(order.return_at)
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-tooltip
                                            placement="top"
                                            :content="order.company" :disabled="windowWidth > 1560">
                                            <span class="maxLines2">{{ order.company }}</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem" :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span class="cutOverflow">{{
                                                order.vehicle?.number ? getVehicleNumber(order.vehicle.number) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                order.driver?.name ? formatName(order.driver?.name) : '-'
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <el-tooltip effect="dark" placement="top"
                                                    :content="order.good?.name ?? '-'"
                                                    :disabled="windowWidth > 1560">
                                            <span class="maxLines2"
                                                  :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                    order.good?.name ?? '-'
                                                }}</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cell">
                                    <div class="ordersTableItem ordersTableItemSub"
                                         :class="{ ordersTableItemConfirm: order.confirm }">
                                        <span :class="order.confirm ? 'neutral900' : 'neutral200'">{{
                                                mixerName(order.factory_name, order.mix.mix_name, order.mixer.mixer_name)
                                            }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
                <tr v-if="actual && nextData.count > 0" class="el-table__row dispatchActualOrdersButtonBlock pointer"
                    @click="showOtherHours('next')">
                    <td :colspan="isDocRequired ? 13 : 12">
                        <div class="cell text-center">
                            <div class="primary600 textMedium">Отобразить предстоящие часы: {{ nextData.count }}
                                {{ declension(nextData.count, 'отгрузка') }} на {{ nextData.total }} м³
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="actual && nextData.count === 0"
                    class="el-table__row dispatchActualOrdersButtonHistory pointer" @click="() => nextCount = 1">
                    <td :colspan="isDocRequired ? 13 : 12">
                        <div class="cell text-center">
                            <div class="neutral500 textMedium">Скрыть предстоящие отгрузки</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <el-dialog
        class="group-order-action-dialog"
        v-model="orderDetailsDialog"
        title=""
        :width="dialogSize"
        :destroy-on-close="true"
        @close="unlockOrder"
    >
        <MixOrderDetailsGroup
            v-if="!orderDetailsDialogLoading"
            :mix-order-id="selectedMixOrderId"
            :order-group="selectedGroup"
            :mix-order-details="selectedMixOrder"
            @close="closeOrderDetailsDialog"
            @get="moduleGet"
            @update-order="updateOrder"
        />
        <div v-else class="text-center mt-30">
            <ReportLoader/>
            <h3>Загрузка данных об отгрузке</h3>
        </div>
    </el-dialog>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import functionsMixin from '@/mixins/functionsMixin'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import {mapState} from 'pinia'
import {httpService} from '@/services/http.service'
import {useCommonStore} from '@/store/common'
import iconsMixin from "@/mixins/iconsMixin";
import EmptyState from "@/views/components/EmptyState.vue";
import mixTabs from "@/mixins/tabs/mixTabs";
import moment from "moment/moment";
import mixOrderInfo from "@/mixins/mixOrderInfo";
import MixOrderDetailsGroup from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroup.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";

export default {
    name: 'DispatchOrdersTable',
    props: [
        'tab',
        'date',
        'needUpdate'
    ],
    components: {
        ReportLoader,
        MixOrderDetailsGroup,
        EmptyState,
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin, getUnitFromGoodsMixin, iconsMixin, mixTabs, mixOrderInfo, mixOrderGroupStatusDeclension
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: false,
            loadOrders: false,
            createMixOrderDrawer: false,
            createMixOrderMixDialog: false,
            orderDetailsDrawer: false,
            orderEdit: null,
            orderDetails: {
                createMix: false,
            },
            tableData: [],
            firstLoad: true,
            orderDetailsDialog: false,
            selectedMixOrderId: null,
            selectedGroup: null,
            selectedMixOrder: null,
            orderDetailsDialogLoading: true,
            actual: false,
            previousCount: 0,
            nextCount: 1
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations'
        }),
        isDocRequired() {
            const mixIntegrations = this.integrations.filter(el => el.account_module?.module_key === 'mix');
            return !!mixIntegrations.length
        },
        mixOrdersByHours() {
            let actualHour = moment().hour();
            // берем values, по свойсту hour (час) объединяем заявки. Заявки с одинаковым часом объединяем в один список
            let data = this.values.reduce((acc, item) => {
                const hour = item.hour;
                if (!acc.find(obj => obj.hour === hour)) {
                    acc.push({hour, orders: [item]});
                } else {
                    const existingObj = acc.find(obj => obj.hour === hour);
                    existingObj.orders.push(item);
                }
                return acc;
            }, []).map(obj => ({
                ...obj,
                orders: obj.orders.sort((a, b) => new Date(a.start_at) - new Date(b.start_at))
            }));

            const hoursLessThanActual = data.filter(obj => obj.hour < actualHour).sort((a, b) => a.hour - b.hour);
            const hoursEqualToActual = data.filter(obj => obj.hour === actualHour);
            const hoursMoreThanActual = data.filter(obj => obj.hour > actualHour).sort((a, b) => a.hour - b.hour);

            hoursLessThanActual.forEach((obj, index) => {
                obj.diff = -index - 1;
            });

            hoursEqualToActual.forEach(obj => {
                obj.diff = 0;
            });

            hoursMoreThanActual.forEach((obj, index) => {
                obj.diff = index + 1;
            });

            data = [...hoursLessThanActual, ...hoursEqualToActual, ...hoursMoreThanActual];

            return data;
        },
        displayOrders() {
            // нужно учесть previousCount и nextCount, по ним отфильтровать mixOrdersByHours по свойству diff, чтобы попали только значения в интервале от previousCount до nextCount
            let filteredOrders = this.mixOrdersByHours.filter(el => el.diff >= this.previousCount && el.diff <= this.nextCount);
            if (filteredOrders.length === 1) {
                this.showOtherHours('next')
                filteredOrders = this.mixOrdersByHours.filter(el => el.diff >= this.previousCount && el.diff <= this.nextCount);
            }
            return filteredOrders;
        },
        previousDataCount() {
            if (this.actual) {
                return this.mixOrdersByHours.filter(el => el.diff < 0).reduce((acc, order) => acc + order.orders.length, 0);
            } else {
                return null
            }
        },
        previousData() {
            if (this.actual) {
                const previousOrders = this.mixOrdersByHours.filter(el => el.diff < this.previousCount);
                return {
                    count: previousOrders.reduce((acc, order) => acc + order.orders.length, 0),
                    total: previousOrders.reduce((acc, order) => acc + order.orders.reduce((acc, item) => acc + item.total, 0), 0)
                };
            } else {
                return null
            }
        },
        nextData() {
            if (this.actual) {
                const nextOrders = this.mixOrdersByHours.filter(el => el.diff > this.nextCount);
                return {
                    count: nextOrders.reduce((acc, order) => acc + order.orders.length, 0),
                    total: nextOrders.reduce((acc, order) => acc + order.orders.reduce((acc, item) => acc + item.total, 0), 0)
                };
            } else {
                return null
            }
        },
        uniqueNames() {
            const extractors = {
                mixers: (order) =>
                    this.mixerName(order.factory_name, order.mix.mix_name, order.mixer.mixer_name),
                drivers: (order) =>
                    order.driver?.name ? this.formatName(order.driver.name) : '-',
                vehicles: (order) =>
                    order.vehicle?.number ? this.getVehicleNumber(order.vehicle.number) : '-',
            };

            const types = ['mixers', 'drivers', 'vehicles'];

            return types.reduce((result, type) => {
                result[type] = [...new Set(
                    this.mixOrdersByHours.flatMap(mixOrder =>
                        mixOrder.orders.map(extractors[type])
                    )
                )];
                return result;
            }, {});
        },
        mixersColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.mixers, 60);
        },
        driverColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.drivers, 100, 160);
        },
        vehicleColumnWidth() {
            return this.calculateColumnWidth(this.uniqueNames.vehicles, 110);
        },
    },
    created() {
        this.moduleGet()
        this.firstLoad = false
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.moduleGet()
            });

    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    methods: {
        dateToTime(data) {
            if (data) {
                return moment.parseZone(data).format('HH:mm')
            }
            return '-'
        },
        showOtherHours(type) {
            if (type === 'previous') {
                this.previousCount = this.previousCount - 1
            }
            if (type === 'next') {
                this.nextCount = this.nextCount + 1
            }
        },
        unlockOrder() {
            if (this.selectedMixOrderId) {
                httpService().post(this.api.mixOrder.unlockOrder + '/' + this.selectedMixOrderId, {
                    account_modules_id: this.$route.params.id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                })
            }
        },
        getSumOrdersByHour(orders) {
            let sum = 0
            orders.forEach((order) => {
                sum += order.total
            })
            return sum
        },
        async moduleGet() {
            if (this.loadOrders) {
                return;
            }
            this.loadOrders = true
            this.loading = true

            try {
                const response = await httpService().post(this.api.dispatch.orders.getOrders, {
                    account_module_reserve_id: this.$route.params.id,
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    date: this.date
                });

                let data = response.data;
                if (data.success) {
                    this.values = data.values;
                    this.valueLength = data.total;
                    this.$emit('updateCountOrders', this.values)
                }
            } catch (error) {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            }

            this.loading = false;
            this.loadOrders = false
        },
        openMixOrderDetails(order, groupId) {
            this.selectedMixOrder = order
            this.selectedMixOrderId = order.id
            this.selectedGroupId = groupId
            this.selectedGroup = null
            this.orderDetailsDialogLoading = true
            this.orderDetailsDialog = true

            httpService().post(this.api.mixOrderGroup.getOrderFull, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.selectedGroupId,
                type: 'full'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.selectedGroup = data.order
                    this.orderDetailsDialogLoading = false
                } else {
                    this.$message({
                        message: 'Ошибка получения заказа',
                        showClose: true,
                        type: 'error'
                    })
                    this.$router.push({name: 'dashboard'})
                    this.orderDetailsDialogLoading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.orderDetailsDialogLoading = false
            })

        },
        closeOrderDetailsDialog() {
            this.orderDetailsDialog = false
        },
        calculateColumnWidth(names, minWidth = 0, maxWidth) {
            const padding = this.windowWidth > 1440 ? 28 : 20;
            const stringWidth = this.findLongestStringWidth(names) + padding;
            if (maxWidth) {
                return (stringWidth < minWidth) ? minWidth : (stringWidth > maxWidth) ? maxWidth : stringWidth;
            }
            return (stringWidth < minWidth) ? minWidth : stringWidth;
        },
        updateOrder(id, order) {
            this.selectedMixOrderId = id
            this.selectedMixOrderGroup = order
        }
    },
    watch: {
        'date': {
            handler: function () {
                if (this.date) {
                    this.values = []
                    this.previousCount = 0
                    this.nextCount = 1
                    // проставляем actual - true если дата сегодня
                    if (moment().isSame(this.date, 'day')) {
                        // пока выключил разбитие на часы
                        this.actual = false
                    } else {
                        this.actual = false
                    }

                    this.moduleGet()
                }
            },
            deep: true,
            immediate: true
        },
        'needUpdate': {
            handler: function () {
                this.moduleGet()
            },
            deep: false,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
