<template>
    <el-table
        empty-text="Информация не найдена"
        v-if="!mobile"
        :data="data"
        v-loading="loading"
        :show-header="false"
        class="w-100">
        <el-table-column
            prop="date"
            label="Дата">
            <template v-slot="scope">
                {{ getDateFromUnix(scope.row.date, isGrouped ? dateFormat : null, tz) }}
            </template>
        </el-table-column>

        <el-table-column
            prop="operation"
            label="Операция"
            sortable
            width="140px"
        >
            <template v-slot="scope">
                            <span class="textSM textRegular neutral600">
                                {{ storehouseActionTypes.find(el => el.id === scope.row.action)?.name }}
                            </span>
            </template>
        </el-table-column>

        <el-table-column
            prop="value"
            label="Значение">
            <template v-slot="scope">
                <span :class="scope.row.value < 0 ? 'typeSubtraction' : 'typeAddition'">{{ scope.row.value  < 0 ? '-' : '+' }} {{
                        formatNumber(Math.abs(scope.row.value), 2)
                    }} {{ scope.row.unit ? scope.row.unit.short_name : 'ед.' }}</span>
                <div v-if="scope.row.unit_to !== null" class="">
                     <p class="d-flex-full-stroke gap-4 m-0">
                        <span>{{ scope.row.unit_from }}</span>
                        <Right width="14" height="14"/>
                        <span>{{ scope.row.unit_to }}</span>
                     </p>
                     <p class="m-0">
                        <span>Количество: </span>
                        <span class="text-bold">
                            {{ formatNumber(scope.row.value, 2) }}
                            {{scope.row.unit ? scope.row.unit.short_name : 'ед. ' }}
                        </span>
                     </p>
                </div>

            </template>
        </el-table-column>
        <el-table-column
            prop="value"
            fixed="right"
            v-if="!isGrouped"
            width="70px"
            label="">
            <template v-slot="scope">
                <el-button :loading="loading" type="info" size="mini" plain :icon="icons.delete"
                           @click.prevent="doRemoveValue(scope.row.id)"></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";
import {Right} from "@element-plus/icons-vue";
import storehouseActionTypes from "@/mixins/storehouseActionTypes";

export default {
    name: "StorehouseGoodDetailsValuesTable",
    components: {Right},
    props: [
        'data',
        'isGrouped',
        'loading',
        'dateFormat',
        'tz'
    ],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, tableMixin, storehouseActionTypes],
    methods: {
        filterValues() {
            return this.data;
        },

        doRemoveValue(id) {
            this.$emit('doRemoveValue', id);
        }
    }
}
</script>

<style scoped>

</style>
